import { NavController, AlertController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { SnackBarService } from './../services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../entity/user';
import { Location } from '@angular/common';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.scss'],
})
export class SetpasswordComponent implements OnInit {
  password: string;
  confirmpassword: string;
  clientId: string;
  currentUser: User;
  registrationType: string;
  resetPassword = false;
  constructor(
    public fireAuth: AngularFireAuth, private snackBarService: SnackBarService, private route: ActivatedRoute,
    private router: NavController, private userService: UserService, private location: Location,
    private alertController: AlertController, private loaderService: LoaderService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.feature === 'reset-password') {
        this.resetPassword = true;
      } else {
        this.resetPassword = false;
      }
    });

    this.userService.getCurrentUser().subscribe((user) => {
      // console.log(SetpasswordComponent.name, user, 'currentuser');
      this.currentUser = user;
    });
  }


  setPassword() {
    this.loaderService.presentLoading('Working on it...');
    if (this.password === this.confirmpassword) {
      if (this.resetPassword) {
        this.userService.updatePassword(this.confirmpassword).then(() => {
          this.presentAlert('Password has been reset successfully');
          //this.userService.setCurrentUser({ ...this.userService.currentUser });
          this.loaderService.dismissLoading();
          this.navigateToHome();
        }, (error) => {
          this.loaderService.dismissLoading();
          this.presentAlert('Password reset failed');
        });
      } else {
        if (!this.currentUser) {
          this.loaderService.dismissLoading();
          this.snackBarService.showToaster('You have to login for changing the password.');
          this.navigateToHome();
        }
        // console.log(SetpasswordComponent.name, this.currentUser.phonenumber, 'this.currentUser.phoneNumber');
        const phoneTOEmail = this.currentUser.phonenumber + '@ivist.com';
        const credential = firebase.auth.EmailAuthProvider.credential(phoneTOEmail, this.password);
        this.fireAuth.auth.currentUser.linkWithCredential(credential).then((usercred) => {
          this.snackBarService.showToaster('Successfully set password.');
          this.userService.updateCurrentUser(this.currentUser.id, this.currentUser.clientId, this.currentUser.registrationType, true);
          this.userService.setCurrentUser(this.userService.currentUser);
          this.loaderService.dismissLoading();
          this.navigateToHome();
        }, (error) => {
          this.snackBarService.showToaster(error);
          this.loaderService.dismissLoading();
        });
      }
    } else {
      this.loaderService.dismissLoading();
      this.snackBarService.showToaster('Password does not match');
      this.password = '';
      this.confirmpassword = '';
    }

  }

  navigateToHome() {
    this.router.navigateRoot(["/home"]);
  }

  onGoBack() {
    this.location.back();
  }

  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: alertData,
      buttons: ['OK'],
      cssClass: 'p-3',
    });
    await alert.present();
  }

}
