import { NavController, PopoverController, AlertController, ModalController, MenuController } from '@ionic/angular';
import { PitStop, EventTemplate } from 'src/app/entity/event.template';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { ClientService } from 'src/app/services/client.service';
import { PatrolService } from 'src/app/services/patrol.service';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { Event, EventInfo } from 'src/app/entity/event';
import { Customer } from 'src/app/entity/clients';
import { User } from 'src/app/entity/user';
import { EventService } from 'src/app/services/event.services';
import { UploadService } from 'src/app/services/upload.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Upload } from 'src/app/entity/upload';
import { File } from '@ionic-native/file/ngx';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { AccountMenuComponent } from 'src/app/shared/account-menu/account-menu.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Attendance } from 'src/app/entity/attendance';
import { CustomFormModalComponent } from 'src/app/shared/custom-form-modal/custom-form-modal.component';
import { OnBoardingModalComponent } from 'src/app/on-boarding-modal/on-boarding-modal.component';
import { PatrolDetailsModalComponent } from 'src/app/shared/patrol-details-modal/patrol-details-modal.component';
import { MapService } from 'src/app/services/map-data';
import { firestore } from 'firebase';
import { getDistance, getPreciseDistance } from 'geolib';
import { filter } from 'rxjs/operators';
import { AppLocationService } from "src/app/services/app-location.service";
import { FireDrillCallService } from 'src/app/services/fire-drill-call.service';

@Component({
  selector: 'app-patrol-dashboard',
  templateUrl: './patrol-dashboard.component.html',
  styleUrls: ['./patrol-dashboard.component.scss'],
  providers: [UploadService]
})
export class PatrolDashboardComponent implements OnInit {
  eventTemplateItemList: PitStop[] = [];
  currentCustomer: Customer;
  eventList: Event[] = [];
  patrolName: string;
  activePatrol = false;
  filterFromDateRawValue: any;
  filterDate: Date;
  currentUser: User;
  patrolHistory: Event[] = [];
  patrolTemplates: EventTemplate[] = [];
  uploadedImageUrl: string;
  selectedValue: any;
  viewImage = false;
  backBtnposition = '';
  imageGalleryList = [];
  title = 'Patrols';
  pitStopTemplateId: string;
  currentEmployeeAttendance: Attendance;
  employerName: string;
  activePitstop = false;
  currentPatrol: Event;
  spentTime: any = 0;
  client: any;
  imageEle: any;
  imageWidth: any;
  imageHeight: any;
  activeFireDrills: boolean = true;
  showDrill: boolean = false;
  fireDrillAlerts = [];
  constructor(
    private userService: UserService, private router: NavController, private route: ActivatedRoute,
    private clientService: ClientService, private patrolService: PatrolService, private uploadService: UploadService,
    private angularFirestore: AngularFirestore, private snackBarService: SnackBarService, private loaderService: LoaderService,
    private eventService: EventService, private file: File, private camera: Camera, public alertController: AlertController,
    public popoverController: PopoverController, public modalController: ModalController, private menu: MenuController,
    private navCtrl: NavController, private mapService: MapService,
    private locationService: AppLocationService,private fireDrillCallService: FireDrillCallService
    ) {
    this.filterDate = new Date();
    this.filterDate.setHours(0);
    this.filterDate.setMinutes(0);
    this.filterDate.setSeconds(0);
    this.filterFromDateRawValue = this.filterDate.toISOString();
  }

  ionViewDidEnter() {
    this.performOnLoad().then(() => console.log("dashboard init completed"))
  }

  async performOnLoad() {
    this.loaderService.presentLoading(`Loading patrols, please wait..`);
    await this.onBoarding();
    await this.loadAllData();
    this.fetchAttendance();
    this.currentEmployeeAttendance = this.userService.getEmployeeAttendance();
    if (this.currentEmployeeAttendance) {
      this.calcLoginTime();
    }
    if (this.eventTemplateItemList.length > 0 && this.eventList.length > 0) {
      await this.checkAllPitStopCompleted(this.eventTemplateItemList, this.eventList);
    }
  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.navCtrl.navigateRoot(['/home']);
    }
  }

  async onBoarding() {
    //// console.log(PatrolDashboardComponent.name, 'local storage', localStorage.getItem('onPatrolBoarding'));
    if (localStorage.getItem('onPatrolBoarding') === 'shown') {
      return;
    }
    const modal = await this.modalController.create({
      component: OnBoardingModalComponent,
      componentProps: {
        type: 'patrol',
      }
    });
    localStorage.setItem('onPatrolBoarding', 'shown');
    return await modal.present();
  }



  calcLoginTime() {
    const currentTime = new Date();
    console.log('currentPatrol ', this.currentPatrol[0]);
    
    if (this.currentEmployeeAttendance) {
      const diffTime = Math.abs(currentTime.getTime() - this.currentEmployeeAttendance.startTime.toMillis());
      this.spentTime = this.validateDuePunchOut(diffTime);
      if (this.spentTime > 23) {
        this.currentEmployeeAttendance.endTime = firestore.FieldValue.serverTimestamp();
        this.userService.updateAttendance(this.currentEmployeeAttendance.id, this.currentEmployeeAttendance).then(() => {
          this.userService.setEmployeeAttendance(null);
        });
        this.endCurrentPatrol().then(() => {
          this.currentPatrol = undefined;
        })
        // this.patrolService.deletePatrol(this.currentPatrol.id).then(() => {
        //   this.currentPatrol = undefined;
        // })
      }
    } else {
      this.spentTime = 0;
    }
  }

  validateDuePunchOut(mins) {
    if (mins <= 0) {
      return 'Incomplete';
    }
    mins = mins / 60000;
    const num = mins;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    return rhours;
  }

  async checkAllPitStopCompleted(pitstopTemplates: PitStop[], pitstopList: Event[]) {
    if (pitstopTemplates.length && pitstopList.length) {
      const allClosed = pitstopList.filter(pitstop => !pitstop.endTime || pitstop.endTime === 0).length === 0;

      // // console.log('all pitstopTemplates', pitstopTemplates);
      // // console.log('all pitstopList', pitstopList);
      if (pitstopList.length >= pitstopTemplates.length && allClosed) {
        console.log('all pitstops completed', allClosed);
        await this.endCurrentPatrol();
      } else {
        console.log(PatrolDashboardComponent.name, 'not-completed the patrol yet or not created a patrol');
      }
    }
  }

  fetchAttendance() {
    this.currentEmployeeAttendance = this.userService.getEmployeeAttendance();
  }

  ngOnInit() {
    //this.loadAllData();
    this.userService.listenToAttendanceChange().subscribe((attendance) => {
      this.currentEmployeeAttendance = attendance;
      this.calcLoginTime();
    });
    if(localStorage.getItem("responseStatus") === "pending") {
      this.showDrill = true;
      console.log("key exists ");
      this.locationService.getCurrentLocation().then((loc) => {
        console.log(" alert locationCoords ", loc);
        this.snackBarService.showFireDrillToaster("Fire Drill Alert", loc, localStorage.getItem("fireDrillDocId"), localStorage.getItem("responseDocId"));
      });
    }
  }

  async getCurrentClientDetails() {
    this.client = await this.clientService.getClientData(this.currentUser.clientId);
    console.log("getCurrentClientDetails -> client", this.client);
  }

  async loadAllData() {
    const subEndDateValue = sessionStorage.getItem("subscription");
    console.log("subscription value from session storage", subEndDateValue);
    this.subEndDateCheck(subEndDateValue);
    //this.loaderService.presentLoading(`Loading patrols, please wait..`);
    this.fetchAttendance();
    this.calcLoginTime();
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    if (this.currentUser) {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      await this.getPatrolsOfEmployeeByDate(today);
      await this.getPatrolTemplatesOfClient(this.currentUser.clientId);
      await this.getActivePatrol(this.currentUser.clientId);
      await this.getEmployerName(this.currentUser.clientId);
      await this.getCurrentClientDetails();
      console.log("active drill check call start");
      this.activeDrillCallCheck();    // this.loaderService.dismissLoading();
    }
  }
  async getPatrolsOfEmployeeByDate(filterDate: Date) {
    console.log(filterDate, "filterdate");
    // errored clientId??
    this.patrolHistory = await this.patrolService.getPatrolsOfEmployeeByDate(this.currentUser.clientId, this.currentUser.id, filterDate).toPromise();
    console.log(PatrolDashboardComponent.name, 'patrol History', this.patrolHistory);
    return this.patrolHistory;
  }

  async onFilterByDate() {
    this.patrolHistory = [];
    const fDate = new Date(this.filterDate.getTime())
    fDate.setHours(0, 0, 0, 0);
    await this.getPatrolsOfEmployeeByDate(fDate);
  }

  async getPatrolTemplatesOfClient(clientId) {
    this.patrolTemplates = await this.eventService.getAllPatrolTemplatesOfClient(clientId).toPromise();
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    //// console.log(HaltDashboardComponent.name, 'from date val : ', new Date(date));
    this.filterDate = new Date(date);
    //// console.log(HaltDashboardComponent.name, 'setting from date', this.filterDate, date);
  }

  async getActivePatrol(clientId: string) {
    const patrols: Event[] = await this.patrolService.getActivePatrol(clientId, this.currentUser.id).toPromise()
    console.log('active patrol found', patrols);
    if (patrols.length > 0) {
      // console.log(PatrolDashboardComponent.name, patrol, 'patrol');
      this.activePatrol = true;
      this.currentPatrol = patrols[0];
      this.patrolService.setCurrentPatrol(patrols[0]);
      await this.getPitStopForActivePatrol(patrols[0].startTime ? patrols[0].startTime.toDate() : new Date(), patrols[0].templateId);
      this.getEventTemplateById(patrols[0].templateId);
    } else {
      this.activePatrol = false;
      this.currentPatrol = null;
    }
  }

  getPatrolName(patrolTemplateId: string): string {
    const patrolTemplate = this.patrolTemplates.find(template => template.id === patrolTemplateId);
    return patrolTemplate ? patrolTemplate.name : 'Unknown';
  }

  async getPitStopForActivePatrol(startTime: Date, templateId: string) {
    this.eventList = await this.patrolService.getPitStopsForPatrol(templateId, startTime, null, this.currentUser.id).toPromise();
    console.log('getPitStopForActivePatrol -> pitstops', this.eventList);
  }

  async getEventTemplateById(patrolTemplateId: string) {
    const eventTemplate: EventTemplate = await this.patrolService.getEventTemplateById(patrolTemplateId);
    console.log(PatrolDashboardComponent.name, eventTemplate, 'eventTemplate');
    if (eventTemplate) {
      this.patrolName = eventTemplate.name;
      await this.getCustomerInfo(eventTemplate.customerId);
      await this.getAllEventTemplateItems(eventTemplate.id);
    }
  }

  async getAllEventTemplateItems(templateId: string) {
    const itemList = await this.patrolService.getAllEventTemplateItems(templateId).toPromise();
    if (itemList) {
      this.eventTemplateItemList = itemList;
      this.eventTemplateItemList.sort((pitstopA: PitStop, pitstopB: PitStop) => {
        if (pitstopA.name.toLowerCase() === pitstopB.name.toLowerCase()) { return 0; }
        return (pitstopA.name.toLowerCase() > pitstopB.name.toLowerCase()) ? 1 : -1;
      });
      console.log(PatrolDashboardComponent.name, 'eventTemplateItemList', this.eventTemplateItemList);
      if (this.eventList) {
        await this.checkAllPitStopCompleted(this.eventTemplateItemList, this.eventList);
      }
    }
  }

  getEventBasedOnTemplateItemId(templateItemId: string): Event {
    return this.eventList.find((item) => item.templateItemId === templateItemId);
  }

  getMissedPitStopTemplateItemId() {
    return this.eventTemplateItemList.filter(pitstop => {
      const events = this.eventList.find(event => event.templateItemId === pitstop.id);
      return events ? false : true;
    }).map((item) => {
      return item.id;
    });
  }

  getPitstopsWithError(): string[] {
    //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> Err Event list:', this.eventList);
    //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> Err EventTemplateItemlist:', this.eventTemplateItemList);
    const errorPitstops = [];
    this.eventList.map((scannedPitStop: Event) => {
      //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> scannedPitStop', scannedPitStop);
      // tslint:disable-next-line: max-line-length
      const pitStopTemplate = this.eventTemplateItemList.find((item: PitStop) => scannedPitStop.templateItemId === item.id);
      //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> pitStopTemplate', pitStopTemplate);
      let startError = false;
      let endError = false;

      if (scannedPitStop.startLocation && pitStopTemplate && pitStopTemplate.location) {
        const pitStopDistance = this.calculatePitstopDistance(
          scannedPitStop.startLocation.latitude,
          scannedPitStop.startLocation.longitude,
          pitStopTemplate.location.latitude,
          pitStopTemplate.location.longitude,
          'K');
        //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> pitStopDistance', pitStopDistance);

        if ((pitStopDistance * 1000) > IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE) {
          startError = true;
        }
      }

      if (scannedPitStop.endLocation && pitStopTemplate && pitStopTemplate.location) {
        const pitStopEndDistance = this.calculatePitstopDistance(scannedPitStop.endLocation.latitude,
          scannedPitStop.endLocation.longitude, pitStopTemplate.location.latitude, pitStopTemplate.location.longitude, 'K');
        //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> pitStopEndDistance', pitStopEndDistance);
        if (pitStopEndDistance * 1000 > IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE) {
          endError = true;
        }
      }

      //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> PITSTOP_DIFFERENCE_RANGE', IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE);
      if (startError || endError) {
        errorPitstops.push(scannedPitStop.templateItemId);
      }
      //console.log(PatrolDashboardComponent.name, 'getPitstopsWithError() -> errorPitstops', errorPitstops);
    });
    return errorPitstops;
  }

  getDeviceIdAssesment(): string[] {
    const deviceIDs = [];
    this.eventList.map((scannedPitStop: Event) => {
      console.log(PatrolDashboardComponent.name, scannedPitStop, 'scannedPitStop');
    })
    return deviceIDs;
  }

  async endCurrentPatrol() {
    if (!this.patrolService.currentPatrol) {
      //this.snackBarService.showToaster('No active patrol found');
      console.log("no active patrol found");
      return;
    }
    if (!this.checkIfStartTimeIsLessthanEndTime(this.patrolService.currentPatrol.startTime, new Date())) {
      //this.snackBarService.showToaster('End Time be Should Greater Than Start Time To End Patrol');
      console.log("cannnot end patrol: start time is greater than end time");
      return;
    }
    console.log('ending current patrol');
    const missedPitStop = this.getMissedPitStopTemplateItemId();
    const errorPitstops = this.getPitstopsWithError();
    const deviceIdAssesment = this.getDeviceIdAssesment();
    const currentPatrol = this.patrolService.currentPatrol.id;
    // console.log(PatrolDashboardComponent.name, currentPatrol, 'currentPatrol');
    await this.patrolService.endPatrol(currentPatrol, missedPitStop, errorPitstops)
    this.snackBarService.showToaster(IAceeptMessageConstant.SUCESSFULLYENDEDPATROL);
    this.activePatrol = false;
  }


  checkIfStartTimeIsLessthanEndTime(startTime: any, endTime: any): boolean {
    if (startTime && startTime.seconds && endTime) {
      const startTimeDate = new Date(startTime.seconds * 1000);
      //// console.log(startTimeDate, 'startTimeDate');
      //// console.log(endTime, 'endtime');
      if (endTime > startTimeDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  async getCustomerInfo(customerId: string) {
    this.currentCustomer = await this.clientService.getCustomer(this.currentUser.clientId, customerId).toPromise();
  }

  // getCustomerInfo(customerId: string) {
  //   this.loaderService.presentLoading('Loading customers list..');
  //   this.clientService.getCustomerById(customerId).subscribe((customer) => {
  //     this.loaderService.dismissLoading();
  //     if (customer) {
  //       this.currentCustomer = customer;
  //     }
  //   });
  // }

  // imageUpload(event, pitstop: PitStop, images: string[]) {
  //   this.uploadedImageUrlList = images;
  //   // console.log(PatrolDashboardComponent.name, this.uploadedImageUrlList, 'this.uploadedImageUrlList');
  //   const pitStopId = this.getEventBasedOnTemplateItemId(pitstop.id);
  //   // console.log(PatrolDashboardComponent.name, pitStopId, 'pitStopId');
  //   if (pitStopId) {
  //     const reader = new FileReader();
  //     if (event.target.files &&
  //       event.target.files.length > 0) {
  //       const file = event.target.files[0];
  //       reader.readAsDataURL(file);
  //       this.startUpload(file, pitStopId.id);
  //     }
  //   }
  // }

  startUpload(filePath: any, fileName: string, pitstopId: string) {
    // storage path
    const imagePath = `events/${this.currentUser.id}/${fileName}`;
    const uplaod = new Upload();
    uplaod.imagePath = imagePath;
    uplaod.filePath = filePath;
    uplaod.name = fileName;
    this.loaderService.presentLoading('Uploading image');
    this.uploadService.fileUpload(uplaod).subscribe((imageData) => {
      this.loaderService.dismissLoading();
      // // console.log(PatrolDashboardComponent.name, imageData, 'imagedata');
      if (imageData) {
        this.uploadedImageUrl = imageData;
        this.updatePitStopImages(pitstopId);
        this.snackBarService.showToaster('Uploaded successfully');
      }
    });
  }

  copyFileToLocalDir(namePath, currentName, newFileName): Promise<string> {
    // // console.log(PatrolDashboardComponent.name, 'Copy from ', namePath, currentName, ' to ', this.file.dataDirectory);
    return new Promise((resolve, reject) => {
      this.file.copyFile(namePath, currentName, this.file.dataDirectory, newFileName).then(success => {
        let filePath = this.file.dataDirectory + newFileName;
        resolve(filePath);
      }, error => {
        // // console.log(PatrolDashboardComponent.name, 'Error loading image', error);
        reject('Error loading image');
      });
    });
  }

  onAddImageClick(pitstopId: string, event: any) {
    // // console.log('event', event);
    event.stopPropagation();
    this.uploadedImageUrl = null;
    const options: CameraOptions = {
      quality: 20,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true
    };

    let uploadedImageUrl;
    //datetime stamp
    this.camera.getPicture(options).then((imagePath) => {
        // console.log(PatrolDashboardComponent.name, 'captured image', imagePath);
        const currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
        const correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
        const fileName = this.angularFirestore.createId() + '.jpg';
        this.copyFileToLocalDir(correctPath, currentName, fileName).then(filePath => {
          this.uploadService.getBlob(filePath).then(blob => {
            this.createCanvas(blob, fileName, pitstopId);
          });
        })
    }, (err) => {
      console.error(err);
    });
  }

  createCanvas(imageBlob, fileName, pitstopId) {
    let loadingObject;
    const url = URL.createObjectURL(imageBlob);
    console.log("src attribute url is", url);
    this.imageEle = new Image();
    this.imageEle.src = url;
    this.imageEle.addEventListener("load" , async () => {
      loadingObject = await this.loaderService.newLoader("Please wait while the Image is being uploaded");
      loadingObject.present();
      console.log("intrinsic width", this.imageEle.naturalWidth);
      console.log("intrinsic height", this.imageEle.naturalHeight);
      this.imageWidth = this.imageEle.naturalWidth;
      this.imageHeight = this.imageEle.naturalHeight;
      let canvas  = document.createElement('canvas');
      canvas.width = this.imageWidth;
      canvas.height = this.imageHeight + 500;
      let ctx = canvas.getContext('2d');
      ctx.font = '32px "Sans"';
      ctx.drawImage(this.imageEle, 0, 0, this.imageWidth, this.imageHeight);
      ctx.fillStyle = '#FFFFFF';
      await this.drawOnCanvas(canvas, ctx, fileName, pitstopId);
      URL.revokeObjectURL(url);
      loadingObject.dismiss();
    });
  }

  async drawOnCanvas(canvas, ctx, fileName, pitstopId) {
    const dateTimeObj = new Date();

    ctx.fillText("Start Latitude: " + `${this.currentPatrol.startLocation.latitude.toFixed(2).toString()}` , this.imageWidth/10 , this.imageHeight+ 100);
    ctx.fillText("Start Longitude: " + `${this.currentPatrol.startLocation.longitude.toFixed(2).toString()}`, this.imageWidth/10 , this.imageHeight+ 200);
    ctx.fillText("Start Date: " + `${this.currentPatrol.startTime.toDate().toDateString()}`, this.imageWidth/10 , this.imageHeight+ 300);
    ctx.fillText("Start Time: " + `${this.currentPatrol.startTime.toDate().toLocaleTimeString()}`, this.imageWidth/10, this.imageHeight+ 400);

    // ctx.fillText("Upload Latitude:" + `${this.locationCoords.coords.latitude}` , this.imageWidth/2 , this.imageHeight+ 100);
    // ctx.fillText("Upload Longitude:" + `${this.locationCoords.coords.longitude}`, this.imageWidth/2 , this.imageHeight+ 200);
    ctx.fillText("Uploaded Date: " + `${dateTimeObj.toDateString()}`, this.imageWidth/2 , this.imageHeight+ 100); 
    ctx.fillText("Uploaded Time: " + `${dateTimeObj.toLocaleTimeString()}`, this.imageWidth/2 , this.imageHeight+ 200);

    await this.canvasToBlobAndUpload(canvas, fileName, pitstopId);
  }

  async canvasToBlobAndUpload(canvas, fileName, pitstopId) {
    let uploadedImageUrl;
    await canvas.toBlob(async (blob) => {
      console.log("blob inside the toBlob()", blob);
      uploadedImageUrl = await this.uploadService.uploadBlobForImage(blob, fileName, this.currentUser);
      console.log("upload", uploadedImageUrl);
      
      this.uploadedImageUrl = uploadedImageUrl;
      this.updatePitStopImages(pitstopId);
    },'image/jpeg', 1.0);
  }

  updatePitStopImages(pitStopId: string) {
    // // console.log(PatrolDashboardComponent.name, 'updating pitstop images ', pitStopId);
    this.patrolService.updatePitStopImages(pitStopId, this.uploadedImageUrl).then(() => {
      // // console.log(PatrolDashboardComponent.name, 'uploaded sucessfully');
      this.uploadedImageUrl = null;
    });
  }

  removePitStopImage(pitStopId: string, imageUrl: string) {
    // // console.log(PatrolDashboardComponent.name, 'removing pitstop images ', pitStopId);
    this.patrolService.removePitStopImages(pitStopId, imageUrl).then(() => {
      // // console.log(PatrolDashboardComponent.name, 'removed sucessfully');
      this.uploadedImageUrl = null;
    });
  }

  viewImages(images: string[], pitStopId: string, event: any) {
    // // console.log('event', event);
    event.stopPropagation();
    // // console.log(PatrolDashboardComponent.name, images, 'images');
    this.pitStopTemplateId = pitStopId;
    this.viewImage = true;
    this.backBtnposition = 'start';
    this.title = 'View Images';
    this.imageGalleryList = images;
  }

  onClickBackBtn() {
    if (this.viewImage) {
      this.viewImage = false;
      this.backBtnposition = '';
      this.title = 'Patrols';
    }
  }

  deleteImage(url) {
    // to remove locally
    const index = this.imageGalleryList.indexOf(url);
    this.imageGalleryList.splice(index, 1);
    this.title = 'Patrols';
    this.viewImage = false;
    this.backBtnposition = '';
    this.removePitStopImage(this.pitStopTemplateId, url);
    // to remove in firbase
    this.uploadService.deleteFileStorage(url).then(() => {
      // // console.log(PatrolDashboardComponent.name, 'deleted');
      this.snackBarService.showToaster('successfully deleted');
    });
  }

  //https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates?rq=1

  calculatePitstopDistance(lat1, lon1, lat2, lon2, unit) {
    console.log('calculatePitstopDistance', lat1, lon1, lat2, lon2, unit)
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'M') {
        dist = dist * 0.8684;
      }
      console.log(PatrolDashboardComponent.name, 'calculatePitstopDistance() -> dist', dist);
      return dist;
    }
  }

  logout() {
    this.userService.logout().then(() => {
      this.router.navigateRoot('home');
    });
  }

  async getEmployerName(clientId: string) {
    const employer = await this.userService.getClientDataBasedOnClientId(clientId).toPromise();
    this.employerName = employer && employer.length > 0 ? employer[0].companyName : "";
  }

  createAttendance() {
    this.router.navigateForward(['attendance']);
  }

  createPatrol() {
    this.router.navigateForward(['/patrol/create-patrol']);
  }

  navigateToValidateLocation(pitstopId, shortCode) {
    const eventsPitstop = this.getEventBasedOnTemplateItemId(pitstopId);
    if(eventsPitstop && eventsPitstop.startTime){
      const differenceInDuration = Math.floor(Date.now() / 1000) - eventsPitstop.startTime.seconds;
      console.log('diff ->' , differenceInDuration);
      if(differenceInDuration > 10){
        console.log("difference in startTime duration and now is more than 10 secs ");
      } else {
        console.log("difference in startTime duration and now is under 10 secs ");
        this.snackBarService.showToasterMiddle(`Please wait at the pitstop for another ${10-differenceInDuration} secs`);
        return;
      }
    }
    this.router.navigateForward(['/patrol/validate-location'], {
      queryParams: {
        shortcode: shortCode,
      }
    });
  }

  async presentPopover(ev: any) {
    // const popover = await this.popoverController.create({
    //   component: AccountMenuComponent,
    //   event: ev,
    //   translucent: true
    // });
    // popover.onDidDismiss().then(res => {
    //   if (res.data === 'logout') {
    //     this.presentAlert('Are you sure that you want to logout?');
    //     // this.userService.logout().then(() => {
    //     //   this.router.navigateRoot(['home']);
    //     // });
    //   }
    // });
    // return await popover.present();
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.logout();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  async confirmEndPatrol() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: 'Are you sure that you want to end current Patrol',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.endCurrentPatrol();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  getTimeinHHMMFormat(mins) {
    if (mins >= 0) {
      const num = mins;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.floor(minutes);
      const seconds = (minutes - rminutes) * 60;
      const rseconds = Math.round(seconds);
      return rhours + 'h' + ':' + rminutes + 'm' + ':' + rseconds + 's';
    } else {
      return 0 + 'hours' + ':' + 0 + 'mins';
    }
  }


  async onAddNotes(pitStopId, event: any) {
    // // console.log('event', event);
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: CustomFormModalComponent,
      componentProps: {
        pitStopId,
        swipeToClose: true,
      }
    });
    return await modal.present();
  }

  onSelectPatrol() {
    this.router.navigateForward(['/patrol/select-patrol']);
  }

  getCompletedEventsLength() {
    return this.eventList ? this.eventList.filter(pitstop => pitstop.endTime).length : 0;
  }

  onViewDetails(patrol) {
    const patrolTemplate = this.patrolTemplates.find(template => template.id === patrol.templateId);
    this.showPatrolDetails(patrol, patrolTemplate);
  }

  async showPatrolDetails(patrol: Event, template: EventTemplate) {
    // console.log('showPatrolDetails', patrol)
    // this.mapService.setPatrol(patrol);
    // this.mapService.setPatrolTemplate(template);
    // this.navCtrl.navigateRoot(['/employer/view-patrol-details']);
    const modal = await this.modalController.create({
      component: PatrolDetailsModalComponent,
      componentProps: {
        patrol,
        template
      }
    });
    return await modal.present();
  }

  onNavigateBack() {
    this.viewImage = false;
  }

  onDeletePopOver(event: any, patrol: Event) {
    event.preventDefault();
    event.stopPropagation();
    // console.log('delete patrol', patrol);
    this.confirmDelete('Are you sure to delete this entry', patrol);
  }

  async deletePatrol(patrol) {
    // console.log('delete patrol', patrol);
    if (this.currentPatrol) {
      if (this.currentPatrol.id === patrol.id) {
        this.patrolService.setCurrentPatrol(null);
        await this.getActivePatrol(this.currentUser.clientId);
      }
    }
    await this.patrolService.deletePatrol(patrol.id)
    await this.loadAllData();
    this.snackBarService.showToaster('Patrol has been deleted');
  }

  async confirmDelete(data, patrol) {
    const alert = await this.alertController.create({
      header: 'Alert',
      backdropDismiss: false,
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        },
        {
          text: 'Delete',
          handler: async () => {
            await this.deletePatrol(patrol);
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });

    await alert.present();
  }



  // rad(x) {
  //   return x * Math.PI / 180;
  // };

  // getDistance = function (p1, p2) {
  //   var R = 6378137; // Earth’s mean radius in meter
  //   var dLat = rad(p2.lat() - p1.lat());
  //   var dLong = rad(p2.lng() - p1.lng());
  //   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
  //     Math.sin(dLong / 2) * Math.sin(dLong / 2);
  //   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   var d = R * c;
  //   return d; // returns the distance in meter
  // };

  navigateToIncidents() {
    this.router.navigateForward(['/incidents']);
  }


  async activeDrillCallCheck() {
    console.log("active drill call checks ");
    console.log("fire drill service", this.fireDrillCallService);
    this.fireDrillCallService.activeDrillCallCheck(this.currentUser.id).subscribe((drills) => {
      console.log("drills subscribe ", drills);
      this.fireDrillAlerts = drills;
      this.locationService.getCurrentLocationPromise().then((loc) => {
        console.log("alert locationCoords ", loc);
        console.log("fire drill alerts array ", this.fireDrillAlerts);
        if(this.fireDrillAlerts.length === 1) {
          console.log("only one active drill call raised ");
          if(this.fireDrillAlerts[0]) {
            // localStorage.setItem("responseStatus", "pending");
            // localStorage.setItem("fireDrillDocId", this.fireDrillAlerts[0].docId);
            // localStorage.setItem("responseDocId", this.fireDrillAlerts[0].id);
            this.angularFirestore.collection('fireAlerts').ref.where('clientId','==' , this.currentUser.clientId).get().then((listr) => {
              listr.forEach((documentSnapshot) => {
                const drillDocument = documentSnapshot.data();
                this.angularFirestore.collection('fireAlerts').doc(drillDocument.id).collection('responses').ref.where('id', '==', this.fireDrillAlerts[0].id).where('responded', '==', false).get().then((data) => {
                  // console.log("data ", data);
                  data.forEach((dat) => {
                    // console.log("dat ", dat.ref.path);
                    if(this.fireDrillCallService.toasterShown === false) {
                      this.snackBarService.showFireDrillToaster("Fire Drill Alert", loc, drillDocument.id, dat.data().id);  
                      this.fireDrillCallService.toasterShown = true;
                    }
                  })
                })
              })
            });
          }
        }
         if(this.fireDrillAlerts.length >1) {
            console.log("multiple drill calls raised ");
            this.presentMultipleDrillCallsAlert();
        }
      }).catch((err) => {
        console.error("ERROR ", err);
      });
    })
  }


  async presentMultipleDrillCallsAlert() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: "Multiple drill calls have been raised. Please respond!",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'Respond',
          handler: () => {
            this.navCtrl.navigateForward(['/employee-alert']);
          }
        }
      ],
      cssClass: 'p-3',
    });
    await alert.present();
  }


  // async startTrackingLocation() {
  //   try {
  //     // this.locationCoords = await this.locationService.getCurrentLocation();
  //     this.locationService.startLocation().subscribe(loc => {
  //       console.log("logging locationCoords ", loc);
  //       this.locationCoords = loc;
  //       // if (loc.coords.accuracy < 100) {
  //       // this.locationAccuracy = true;
  //       // } else {
  //       // this.locationAccuracy = false;
  //       // }
  //     });
  //   } catch (err) {
  //     this.snackBarService.showToaster("Failed to fetch location", 2000);
  //   }
  // }
}
