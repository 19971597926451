import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from "@angular/fire/firestore";
import { User } from '../../entity/user';
import { map } from 'rxjs/operators';
import {
  GoogleMap,
  GoogleMaps,
  MarkerCluster,
  GoogleMapsEvent,
  Marker,
  LatLng,
  Environment,
  Polyline,
} from '@ionic-native/google-maps/ngx';
import { GoogleMapOptions, CameraPosition } from '@ionic-native/google-maps';
import { MapService } from 'src/app/services/map-data';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-alert-date-info',
  templateUrl: './alert-date-info.component.html',
  styleUrls: ['./alert-date-info.component.scss'],
})
export class AlertDateInfoComponent implements OnInit {
  title: String = "Fire drill call";
  employerName: String = '';
  runDate: Date;
  searchString: String;
  filteredList: any[] = [];
  employeeAlertsList: any[]= [];
  employees: any[] = [];
  map: GoogleMap;
  marker: any;
  markerCluster: MarkerCluster;
  docId: any;
  constructor(
    private navCtrl: NavController,
    private userService: UserService,
    private loaderService: LoaderService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private mapService: MapService
    ) { }

  ngOnInit() {
    this.loaderService.presentLoading('Loading collected responses');
    this.getRoutingParams();
    this.userService.getCurrentUser().subscribe(user => {
      // this.currentUser = user;
      this.getEmployeesOfClient(user.clientId);
    });
  }

  getRoutingParams() {
    this.route.queryParams.subscribe((params) => {
      if(params) {
        console.log("params ", params);
        this.runDate = new Date(params.alertDate * 1);
        console.log("date recorded ", this.runDate);
        this.employerName = params.employerName;
        this.docId = params.docId;
        this.afs.collection('fireAlerts').doc(params.docId).collection('responses').get().subscribe((doc) => {
          doc.docs.map(d => {
            this.employeeAlertsList.push(d.data());
            console.log("subCollectionData ", this.employeeAlertsList);
          });
        })
      }
    });
  }

  getEmployeesOfClient(clientId: string) {
    this.userService.getAllUsersBasedOnEmployeeRoles(clientId).subscribe(employees => {
      this.employees = employees;
    });
  }

  searchStringFilter(event) {
    this.searchString = event.target.value;
  }
  
  responseStatus(employee) {
    let status = "Not sent";
    this.employeeAlertsList.forEach((run) => {
      if(run.employeeName === employee.name) {
        // console.log("a match found ", run);
        if (run.responded === true) {
          return status = "Yes"
        } else {
          status = "No"
        }
      } else {
        // console.log("no match");
        return status;
      }
    });
    return status;
  }

  // markLocationOnMap(run) {
  //   const markers = this.getMarkersForRuns(run);
  //   console.log('markers', markers);
  //   this.addCluster(markers);
  // }

  // getMarkersForRuns(run) {
  //   return {
  //       name: run.employeeName,
  //       // datetime: run.data.time,
  //       position: {
  //         lat: run.data.coords.latitude,
  //         lng: run.data.coords.longitude,
  //       },
  //     }
  // }

  // addCluster(data) {
  //   this.markerCluster = this.map.addMarkerClusterSync({
  //     markers: data,
  //     icons: [
  //       {
  //         min: 3,
  //         max: 9,
  //         url: '../../../assets/images/logo32x32.png',
  //         label: {
  //           color: 'white',
  //         },
  //       },
  //       {
  //         min: 10,
  //         url: '../../../assets/images/logo32x32.png',
  //         label: {
  //           color: 'white',
  //         },
  //       },
  //     ],
  //   });
  //   this.map.setCameraTarget({
  //     lat: data.position.lat,
  //     lng: data.position.lng,
  //   });
  //   // this.map.setCameraZoom(15);
  //   this.markerCluster.on(GoogleMapsEvent.MARKER_CLICK).subscribe((params) => {
  //     const marker: Marker = params[1];
  //     marker.setTitle(marker.get('name'));
  //     // marker.setSnippet(marker.get('address'));
  //     marker.showInfoWindow();
  //   });
  // }

  
  // ionViewDidEnter() {
  //   this.loadMap();
  // }
  
  // loadMap() {
  //   Environment.setEnv({
  //     API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI',
  //     API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI'
  //   });
  //   this.map = GoogleMaps.create('map_canvas', {
  //     preferences: {
  //       zoom: {
  //         minZoom: 3,
  //         maxZoom: 18,
  //       },
  //     },
  //   });
  // };

  mapCoords() {
    this.navCtrl.navigateForward(['employer/alert-map'], {
      queryParams: { alertsId: this.docId }
    });
  }

  // addMarkerToMap(employeeName:string, lat: number, long: number) {
  //   if (this.map) {
  //     // if (this.marker) {
  //     //   this.map.clear();
  //     // }
  //     this.map.addMarker({
  //       title: employeeName,
  //       icon: 'orange',
  //       animation: 'DROP',
  //       position: {
  //         lat: lat,
  //         lng: long
  //       }
  //     }).then(marker => {
  //       // this.marker = marker;
  //       this.map.setCameraTarget({ lat: lat, lng: long });
  //     });
  //   }
  // }
}

