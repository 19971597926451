import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { ClientService } from 'src/app/services/client.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { MustMatch } from 'src/app/utilities/must-match.validator';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss'],
})
export class CustomerLoginComponent implements OnInit {
  customerLoginForm: FormGroup;
  btnLoading = false;

  constructor(
    private formBuilder: FormBuilder, private loaderService: LoaderService, private alertController: AlertController,
    private router: Router, private location: Location, private userService: UserService,
    private snackBarService: SnackBarService, private navCtrl: NavController,
    private route: ActivatedRoute, private clientService: ClientService) { }

  ngOnInit() {
    this.initCustomerLogin();
  }

  getValidationError() {
    return "invalid"
  }

  initCustomerLogin() {
    this.customerLoginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    })
  }


  handleError(error) {
    if (error.code === 'auth/popup-blocked') {
      this.presentAlert('Your browser has disabled Popups. Please Try again');
    } else if (error.code === 'auth/popup-closed-by-user') {
      this.presentAlert('Please reload and try again');
    } else {
      this.snackBarService.showToaster(error.message);
    }
    return error.message;
  }

  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Alert',
      message: alertData,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  onLogin(loginFormValue) {
    this.btnLoading = true;
    this.userService.emailLogin(loginFormValue.email, loginFormValue.password).then((res) => {
      //console.log('res', res);
      this.btnLoading = false;
    }, (error) => {
      this.snackBarService.showToaster(error.message);
      this.btnLoading = false;
    });
  }


  onGoBack() {
    this.navCtrl.navigateBack(['/home']);
  }

  onForgotPassword() {
    this.navCtrl.navigateRoot(['/forgot-password']);
  }

}
