import { AlertController } from '@ionic/angular';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { LoaderService } from 'src/app/services/loader.service';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-deactivated-employee-list',
  templateUrl: './deactivated-employee-list.component.html',
  styleUrls: ['./deactivated-employee-list.component.scss'],
})
export class DeactivatedEmployeeListComponent implements OnInit {
  currentUser: User;
  employees: User[] = [];
  searchString: string;
  isMobile: boolean;

  constructor(private userService: UserService, private loaderService: LoaderService,
              private snackBar: SnackBarService, private router: Router, private alertController: AlertController,
              private excelService: ExcelService, private platform: Platform) { }

  ngOnInit() {
    this.loaderService.presentLoading('Loading employees list');
    this.userService.getCurrentUser().subscribe(user => {
      this.loaderService.dismissLoading();
      // console.log(DeactivatedEmployeeListComponent.name, user, 'user');
      this.currentUser = user;
      this.getEmployeeInActiveList(this.currentUser.clientId);
    });
  }

  getEmployeeInActiveList(clientId: string) {
    this.userService.getdeactivatedEmployeeList(clientId).subscribe(employees => {
      this.loaderService.dismissLoading();
      this.employees = employees;
      this.employees.sort((employeeA: User, employeeB: User) => {
        if (employeeA.name.toLowerCase() === employeeB.name.toLowerCase()) { return 0; }
        return (employeeA.name.toLowerCase() > employeeB.name.toLowerCase()) ? 1 : -1;
      });
    });
    this.loaderService.dismissLoading();
  }

  activateEmploayee(employee: User) {
    this.loaderService.presentLoading('Activating......');
    this.userService.deActivateEmployee(employee.id, true).then(() => {
      this.snackBar.showToaster('Sucessfully Activated');
      this.loaderService.dismissLoading();
      this.getEmployeeInActiveList(this.currentUser.clientId);
    }).catch((err) => {
      this.snackBar.showToaster(err);
      this.loaderService.dismissLoading();
    });
  }

  async presentAlert(data, employee) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.activateEmploayee(employee);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  onExportDeactivatedEmloyees() {
    const employeesArray = [];
    this.employees.map(employee => {
      employeesArray.push({
        name: employee.name,
        contactNumber: employee.phonenumber,
        type: employee.registrationType
      });
    });
    // console.log('onExportEmloyees');
    this.excelService.exportDeactivatedEmployeesToExcel(employeesArray, 'DeactivedEmployeesList', this.isMobile);
  }

}
