import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-email-not-verified',
  templateUrl: './email-not-verified.component.html',
  styleUrls: ['./email-not-verified.component.scss'],
})
export class EmailNotVerifiedComponent implements OnInit {

  resetPassword = false;
  constructor(private route: ActivatedRoute,
    private navCtrl: NavController,) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.userType === 'employer') {
        this.resetPassword = true;
      } else {
        this.resetPassword = false;
      }
    });
  }

  onClickBackBtn() {
    this.navCtrl.navigateRoot(['/home']);
  }
}
