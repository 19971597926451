import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore } from "@angular/fire/firestore";
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavController, AlertController } from '@ionic/angular';
import { handleErrorObservable } from '../../utilities/helper';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-fire-alerts',
  templateUrl: './fire-alerts.component.html',
  styleUrls: ['./fire-alerts.component.scss'],
})

export class FireAlertsComponent implements OnInit {
  currentUser: any;
  activeEmpCount: any;
  employees: any[] = [];
  private topLimit: number = 100;
  previousRuns: any[] = [];
  filteredRuns: any[] = [];
  showFilter: boolean = false;
  showDatePickers: boolean = false;
  fromDateRawValue: any;
  toDateRawValue: any;
  fromDate: Date;
  toDate: Date;
  title: any;

  constructor(
    private afs: AngularFirestore, private userService: UserService, private loaderService: LoaderService,
    private router: NavController, private alertController: AlertController,
    private http: HttpClient) {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.fromDateRawValue = this.fromDate.toISOString();
      this.toDateRawValue = this.toDate.toISOString();
      this.title = "All Alarms";
   }

  ngOnInit() {
    this.loaderService.presentLoading('Loading fire drill calls');
    this.userService.getCurrentUser().subscribe(user => {
      // this.loaderService.dismissLoading();
      this.currentUser = user;
      this.lastRunChecker();
      // this.activeEmployeeWithToken();
      this.activeEmployeesCount();
    });

  }

  async getActiveTokenStatus(employeeId) {
    let bool;
    const status = await this.afs.collection("fcmToken").ref.
        where('userId', '==', employeeId)
        .get();
    // console.log("employeeId ", employeeId);
    if (status.empty) {
      // console.log("empty bool");
      bool = "false";
    } else {
      // console.log("not empty bool");
      bool = "true";
    }

    return bool;
  }

  async lastRunChecker() {
    const fireAlertsCollections = this.afs.collection("fireAlerts");
    const querydocuments = await fireAlertsCollections.ref.where('employerId', '==', this.currentUser.id)
      .orderBy('alarmRaisedAt', 'asc')
      .get();

    querydocuments.forEach((doc) => {
      let data = doc.data();
      let totalCount;
      this.afs.collection('fireAlerts').doc(data.id).collection('responses').get().subscribe((doc) => {
        totalCount = doc.size;
        this.previousRuns.push({ ...data, responsesCount: totalCount });
        this.filteredRuns.push({ ...data, responsesCount: totalCount });
        // console.log("previousRuns ", this.previousRuns);
      });
    });
  }

  async activeEmployeesCount() {
    this.userService.getNumberOfActiveEmployeeOfClient(this.currentUser.clientId).subscribe((count) => {
      this.activeEmpCount = count;
      console.log("active employee count", this.activeEmpCount);
    });
  }

  onSendAlerts() {
    console.log("broadcasting alerts");
    this.presentBroadcastAlert("Are you sure you want to send a fire drill notification");
  }
  
  async presentBroadcastAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        },{
          text: 'Ok',
          handler: () => {
            alert.dismiss();
            this.presentTypePickerAlert();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  async presentTypePickerAlert() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: "Select employee type",
      buttons: [
        {
          text: 'For Halt users',
          handler: () => {
            this.loaderService.presentLoading('Sending halt alert request');
            console.log("Halt users alerts");
            console.log("clientId ", this.currentUser.clientId);
            this.fcmNotify(this.currentUser.clientId, this.currentUser.id, this.currentUser.name, "halt").subscribe((res) => {
              console.log("res ", res);
            });
          }
        }, {
          text: 'For Patrol users',
          handler: () => {
            this.loaderService.presentLoading('Sending patrol alert request');
            console.log("Patrol users alerts");
            this.fcmNotify(this.currentUser.clientId, this.currentUser.id, this.currentUser.name, "patrol").subscribe((res) => {
              console.log("res ", res);
              
            });
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }
  
  fcmNotify(clientId, employerId, employerName, empType) {
      // const httpOptions = {
      //   headers: new HttpHeaders({ 
      //     'Access-Control-Allow-Origin':'*',
      //   })
      // };
      const url = "https://us-central1-ivisit-f2ce6.cloudfunctions.net/alertHttp";
      return this.http.post(url, { clientId, employerId, employerName, empType }).pipe(
        catchError(handleErrorObservable<any>('')));
  }

  onAlertRunClick(run) {
    console.log("clicked run ", run);
    this.router.navigateRoot(['/employer/alert-date'], { queryParams: { docId: run.id, employerName: run.employerName, alertDate: run.alarmRaisedAt.toDate().getTime() } });
  }

  onShowFilter() {
    this.showFilter = !this.showFilter;
  }

  showDateFilter(){
    this.showDatePickers = !this.showDatePickers;
    console.log("show ", this.showDatePickers);
  }

  onFilterCheck(event:any, label){
    console.log(`${label} and value - ${event.detail.checked}`);
    if(event.detail.checked === true) {
      switch (label) {
        case "today":
          this.title = "Alarms for Today";
          this.applyFilter();
          break;
        case "last 3 days":
          this.title = "Alarms in last 3 days"
          this.fromDate.setDate(this.fromDate.getDate()-3);        
          this.applyFilter();
          break;
        case "last week":
          this.title = "Alarms in last one week"
          this.fromDate.setDate(this.fromDate.getDate()-7);
          this.applyFilter();
          break;
        case "last month":
          this.title = "Alarms in last one months"
          this.fromDate.setMonth(this.fromDate.getMonth()-1);
          this.applyFilter();
          break;
        default:
          break;
      }
    } else {
      this.title = "All Alarms";
      this.fromDate = new Date();
      this.fromDate.setHours(0,0,0);
      this.filteredRuns = this.previousRuns;
    }

  }

  get fromDateValue() {
    return this.fromDateRawValue;
  }

  get toDateValue() {
    return this.toDateRawValue;
  }

  set fromDateValue(rawDate) {
    this.fromDate = new Date(rawDate);
    this.fromDate.setHours(0,0,0);
    console.log("fromDate ", this.fromDate);
  }

  set toDateValue(rawDate) {
    this.toDate = new Date(rawDate);
    this.toDate.setHours(23,59,59);
    console.log("toDate ", this.toDate);
  }

  applyFilter() {
    this.filteredRuns = this.previousRuns.filter((run) => {
      // console.log("time ms ", run.alarmRaisedAt.toDate().getTime());
      let runTime = run.alarmRaisedAt.toDate().getTime();
      if(runTime >= this.fromDate.getTime() && this.toDate.getTime() >= runTime) {
        // console.log("run ", run);
        return run;
      }
    });
  }

  resetFilter() {
    this.filteredRuns = this.previousRuns;
    this.fromDate = new Date();
    this.toDate = new Date();
    this.toDate = new Date();
    this.fromDateRawValue = this.fromDate.toISOString();
    this.toDateRawValue = this.toDate.toISOString();
  }

}
