import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../utilities/must-match.validator';
import { UserService } from '../services/user.service';
import { Invite } from '../entity/invite';
import { SnackBarService } from '../services/snackbar.service';
import { LoaderService } from '../services/loader.service';
import { AlertController, NavController } from '@ionic/angular';
import { Location } from '@angular/common';
import { UserSubscription } from '../entity/subscription';


@Component({
  selector: 'app-team-member-signup',
  templateUrl: './team-member-signup.component.html',
  styleUrls: ['./team-member-signup.component.scss'],
})
export class TeamMemberSignupComponent implements OnInit {
  isTeamMemberInvited = false;
  teamMemberSignUpForm: FormGroup;
  currentEmployerInvite: Invite;
  btnLoading = false;
  isEmailVerified = true;

  constructor(
    private formBuilder: FormBuilder, private userService: UserService, private alertController: AlertController,
    private snackBarService: SnackBarService, private loaderService: LoaderService, public location: Location,
    private navCtrl: NavController) { }

  ngOnInit() {
    this.initTeamMemberSignUp();
  }

  initTeamMemberSignUp() {
    this.teamMemberSignUpForm = this.formBuilder.group({
      name: ['', Validators.required],
      // tslint:disable-next-line: max-line-length
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/)])],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*\d).{4,30}$/)]],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });
  }

  employerInviteCheck(event) {
    // console.log(TeamMemberSignupComponent.name, event.srcElement.value, 'event');
    const emailAdress = event.srcElement.value;
    this.userService.getInvitesSentByClientForTeamMember(emailAdress).subscribe((invite) => {
      // console.log(TeamMemberSignupComponent.name, invite, 'invite');
      if (invite.length) {
        this.currentEmployerInvite = invite[0];
        this.isTeamMemberInvited = true;
      }
    });
  }

  onCreateAccount(teamMemberLogin) {
    this.btnLoading = true;
    // console.log(TeamMemberSignupComponent.name, teamMemberLogin, 'team');
    this.loaderService.presentLoading('Verifying user details');
    this.userService.checkUserAlreadySignUp(teamMemberLogin.email)
      .then((userexists) => {
        // console.log(TeamMemberSignupComponent.name, userexists.length, 'userexists');
        this.loaderService.dismissLoading();
        if (!userexists.length ) {
          if (!this.currentEmployerInvite) {
            this.btnLoading = false;
            this.loaderService.dismissLoading();
            this.presentAlert('No invites present fot this email ID. Kindly contact your employer');
            return;
          }
          this.btnLoading = false;
          this.userService.emailSignUp(teamMemberLogin.email, teamMemberLogin.password).then((user) => {
            // console.log(TeamMemberSignupComponent.name, 'authenticated user', user);
            this.userService.createUserInDB(user, 'client', null, null,
              teamMemberLogin.name, this.currentEmployerInvite.contactNumber, true, this.currentEmployerInvite.clientId,
              this.currentEmployerInvite.employeeType);
            this.presentAlert('Successfully created, Kindly verfiy your email to login');
            this.userService.deleteInvite(this.currentEmployerInvite.id);
            this.userService.sendEmailVerification().then((a) => {
              this.userService.logout();
            });
          }).catch(error => {
            this.btnLoading = false;
            this.handleError(error);
            this.loaderService.dismissLoading();
          });
        } else {
          this.btnLoading = false;
          this.loaderService.dismissLoading();
          this.presentAlert('User already registered with this email ');
        }
      });
  }

  handleError(error) {
    if (error.code === 'auth/popup-blocked') {
      this.presentAlert('Your browser has disabled Popups. Please Try again');
    } else if (error.code === 'auth/popup-closed-by-user') {
      this.presentAlert('Please reload and try again');
    } else {
      this.snackBarService.showToaster(error.message);
    }
    return error.message;
  }


  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Alert',
      message: alertData,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  onGoBack() {
    this.teamMemberSignUpForm.reset();
    this.navCtrl.navigateBack(['/home']);
  }

  checkUserAsVerifiedHisEmail(clientId) {
    this.userService.checkUserAsVerifiedHisEmail().subscribe((isEmailVerifed) => {
      // console.log(TeamMemberSignupComponent.name, isEmailVerifed, 'is-Email-verfied');
      if (isEmailVerifed) {
        this.isEmailVerified = isEmailVerifed;
        this.checkCurrentUserSubscrption(clientId);
      } else {
        this.isEmailVerified = false;
        return;
      }
    });
  }

  checkCurrentUserSubscrption(clientId) {
    this.userService.fetchCurrentSubscrption(clientId).subscribe((sub: UserSubscription[]) => {
      // console.log(TeamMemberSignupComponent.name, sub, 'subscription');
      if (sub) {
        this.navCtrl.navigateRoot(['/employer/dashboard']);
      } else {
        // console.log(TeamMemberSignupComponent.name, 'subscription-not-done');
        this.navCtrl.navigateForward(['/subscription']);
        this.presentAlert('Kindly confirm your subscription');
      }
    });
  }

  onSignIn(userType) {
    this.navCtrl.navigateForward(['/employer-login'], {
      queryParams: {
        userType: 'team-member',
      }
    });
  }
}
