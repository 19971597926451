import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Device } from '@ionic-native/device/ngx';
import { AlertController, NavController } from '@ionic/angular';
import { firestore } from 'firebase';
import { forkJoin, Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Attendance } from '../entity/attendance';
import { CustomerAccess } from '../entity/customerAccess';
import { Invite } from '../entity/invite';
import { MenuItem, SubscrptionList, SubscrptionPackageList } from '../entity/menu';
import { Package } from '../entity/package';
import { UserSubscription } from '../entity/subscription';
import { TravelHistory } from '../entity/travelHistory';
import { User } from '../entity/user';
import { handleErrorObservable } from '../utilities/helper';
import { Clients } from './../entity/clients';
import { SnackBarService } from './snackbar.service';

interface StatusObject {
  statusCode: string;
  statusMessage: string;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {
  currentUser: User = null;
  currentEmployeeAttendance: Attendance = null;
  currentSubscrption: UserSubscription = null;
  userCollections: AngularFirestoreCollection<User>;
  subscriptionCollections: AngularFirestoreCollection<UserSubscription>;
  clientsCollections: AngularFirestoreCollection<Clients>;
  packageCollections: AngularFirestoreCollection<Package>;
  attendanceCollections: AngularFirestoreCollection<Attendance>;
  isPasswordSet = false;
  isPasswordResetFlow = false;
  utilsCollections: AngularFirestoreCollection<any>;

  private isUserLogedInChangedSource = new Subject<any>();
  isUserLogedInChangedObservable = this.isUserLogedInChangedSource.asObservable();

  private isUserAttendanceChangedSource = new BehaviorSubject<any>(null);
  isUserAttendanceChangedObservable = this.isUserAttendanceChangedSource.asObservable();

  private currentSubscrptionChangedSource = new Subject<any>();
  currentSubscrptionChangedObservable = this.currentSubscrptionChangedSource.asObservable();

  constructor(
    public fireAuth: AngularFireAuth, private navCtrl: NavController,
    private angularFirestoreDb: AngularFirestore,
    private snackBarService: SnackBarService, private device: Device,
    private http: HttpClient, private alertController: AlertController) {
    this.userCollections = this.angularFirestoreDb.collection<User>(IAceeptMessageConstant.COLLECTION_USERS);
    this.subscriptionCollections = this.angularFirestoreDb.collection<UserSubscription>(IAceeptMessageConstant.COLLECTION_SUBSCRIPTION);
    this.clientsCollections = this.angularFirestoreDb.collection<Clients>(IAceeptMessageConstant.COLLECTION_CLIENTS);
    this.packageCollections = this.angularFirestoreDb.collection<Package>(IAceeptMessageConstant.COLLECTION_PACKAGE);
    this.attendanceCollections = this.angularFirestoreDb.collection<Attendance>(IAceeptMessageConstant.COLLECTION_ATTENDANCE);
    this.utilsCollections = this.angularFirestoreDb.collection<any>(IAceeptMessageConstant.COLLECTION_UTILS);
  }

  setPasswordResetFlow() {
    this.isPasswordResetFlow = true;
  }

  getPasswordResetFlow() {
    return this.isPasswordResetFlow;
  }

  // to check login status and set the user data on every refresh and onload
  authenticationStatusCheck() {
    this.fireAuth.authState.subscribe(user => {
      //console.log('auth state', user);
      if (user) {
        localStorage.setItem('userCreation', 'complete');
        // console.log(UserService.name, 'user retrieved ', user);
        this.saveLoggedInUserData(user.phoneNumber, user.uid, user.email);
      } else {
        this.saveLoggedInUserData(null, null, null);
      }
    });
  }

  checkIfUserSetPassword(currentUser: any): boolean {
    if (currentUser) {
      const user = currentUser.providerData.find(item => item.providerId === 'password');
      if (user) {
        return true;
      } else {
        return false;
      }
    }
  }

  verifyIfUserExists(email: string) {
    return this.angularFirestoreDb.collection<CustomerAccess>(IAceeptMessageConstant.COLLECTION_CUSTOMER_ACCESS,
      ref => ref.where('email', '==', email)).get().pipe(map((res) => {
        const user = [];
        res.docs.forEach(item => user.push(item.data()));
        return user;
      }));
  }

  verifyUserStatus(): Observable<StatusObject> {
    //console.log(UserService.name, this.checkIfUserSetPassword(this.fireAuth.auth.currentUser), ' this.fireAuth.auth.currentUser;');
    return new Observable<any>((observer) => {
      forkJoin({
        emailVerified: this.checkUserAsVerifiedHisEmail().pipe(take(1)),
        subscription: this.fetchCurrentSubscrption(this.currentUser.clientId).pipe(take(1))
      }).subscribe((verifyUser) => {
        if (verifyUser) {
          console.log('verifyUser', verifyUser);
          if (!verifyUser.emailVerified && (this.currentUser.role === IAceeptMessageConstant.USER_ROLE_CLIENT || this.currentUser.role === IAceeptMessageConstant.USER_ROLE_CUSTOMER)) {
            return observer.next({
              statusCode: IAceeptMessageConstant.REGISTRATION_EMAIL_IS_NOT_VERIFED,
              statusMessage: IAceeptMessageConstant.REGISTRATION_EMAIL_IS_NOT_VERIFED
            });
          }
          if (verifyUser.emailVerified && this.currentUser.role === IAceeptMessageConstant.USER_ROLE_CUSTOMER) {
            return observer.next({
              statusCode: IAceeptMessageConstant.USERSTATUSCHECK,
              statusMessage: IAceeptMessageConstant.USERSTATUSCHECK
            });
          }
          if (verifyUser.subscription.length === 0) {
            return observer.next({
              statusCode: IAceeptMessageConstant.SUBSCRIPTION_NOT_PAID,
              statusMessage: IAceeptMessageConstant.SUBSCRIPTION_NOT_PAID
            });
          }
          if (this.currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE &&
            !this.checkIfUserSetPassword(this.fireAuth.auth.currentUser)) {
            return observer.next({
              statusCode: IAceeptMessageConstant.EMPLOYEE_SET_PASSWORD,
              statusMessage: IAceeptMessageConstant.EMPLOYEE_SET_PASSWORD
            });
          }
          if (this.currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE &&
            this.isPasswordResetFlow) {
            return observer.next({
              statusCode: IAceeptMessageConstant.EMPLOYEE_RESET_PASSWORD,
              statusMessage: IAceeptMessageConstant.EMPLOYEE_RESET_PASSWORD
            });
          }
          if (verifyUser.emailVerified && verifyUser.subscription ||
            !verifyUser.emailVerified && this.currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE) {
            return observer.next({
              statusCode: IAceeptMessageConstant.USERSTATUSCHECK,
              statusMessage: IAceeptMessageConstant.USERSTATUSCHECK
            });
          }
        }
      }, (err) => {
        // console.log(UserService.name, err, 'something-went-wrong');
      }, () => {
        observer.complete();
      });
    });
  }

  // set current user
  setCurrentUser(user: User) {
    this.currentUser = user;
    this.isUserLogedInChangedSource.next(user);
  }

  // get current user
  getCurrentUser(): Observable<User> {
    if (this.currentUser) {
      return of(this.currentUser);
    } else {
      return this.isUserLogedInChangedObservable;
    }
  }

  // set Employee Attendance
  setCurrentSubscrption(sub: UserSubscription) {
    this.currentSubscrption = sub;
    this.currentSubscrptionChangedSource.next(sub);
  }

  // get Employee Attendance
  getCurrentSubscrption(): Observable<UserSubscription> {
    if (this.currentSubscrption) {
      return of(this.currentSubscrption);
    } else {
      return this.currentSubscrptionChangedObservable;
    }
  }

  // set Employee Attendance
  setEmployeeAttendance(attendance: Attendance) {
    this.currentEmployeeAttendance = attendance;
    // console.log(UserService.name, 'setEmployeeAttendance', this.currentEmployeeAttendance);
    this.isUserAttendanceChangedSource.next(attendance);
  }

  listenToAttendanceChange(): Observable<Attendance> {
    return this.isUserAttendanceChangedObservable;
  }

  // get Employee Attendance
  getEmployeeAttendance() {
    // console.log(UserService.name, 'getEmployeeAttendance', this.currentEmployeeAttendance);
    return this.currentEmployeeAttendance;
  }

  //// Email/Password Auth ////
  emailSignUp(email: string, password: string) {
    return this.fireAuth.auth.createUserWithEmailAndPassword(email.toLowerCase(), password).then(user => {
      if (user) {
        return user.user;
      } else {
        return null;
      }
    });
  }

  createUserInDB(
    user: any, role: string, clientAddress?: any, companyName?: string, userName?: string, phoneNumber?: string,
    isTeamMemberInvited = false, clientId?: string, regestrationType?: string, gstNumber?: string) {
    const id = this.angularFirestoreDb.createId(); // clients id in clients and user clientId in users have to be same.
    // console.log(UserService.name, 'creating new user', id, user);
    if (isTeamMemberInvited) {
      clientId = clientId;
    } else {
      if (id) {
        clientId = id;
      } else {
        // console.log('no ID  found');
        return;
      }
    }
    if (!isTeamMemberInvited) {
      const userId = user.uid;
      setTimeout(() => {
        this.setClientDoc(clientAddress, clientId, companyName);
        this.setUserDoc(userId, user.email, role, clientId, userName, phoneNumber,
          regestrationType, true, gstNumber);
      }, 1000);
      // console.log("created user", clientId, userId);
    } else {
      const userId = user.uid;
      this.setUserDoc(userId, user.email, role, clientId, userName, phoneNumber, regestrationType, true).then(() => {
        // console.log(UserService.name, 'team member created user successfully');
      }).catch((err) => {
        this.snackBarService.showToaster(err);
      });
    }
  }

  //// Email/Password Login ////

  emailLogin(email: string, password: string): Promise<any> {
    const loginTask = this.fireAuth.auth.signInWithEmailAndPassword(email.toLowerCase(), password);
    console.log(UserService.name, 'loginTask', loginTask);
    loginTask.then((user) => {
      console.log(UserService.name, 'user data after login', user);
      // if (user) {
      //   this.saveLoggedInUserData(user.user.phoneNumber, null);
      // }
    });
    return loginTask;
  }


  checkUserAsVerifiedHisEmail(): Observable<boolean> {
    return this.fireAuth.authState.pipe(map((user) => {
      if (user) {
        if (user.emailVerified) {
          return true;
        } else {
          return false;
        }
      }
    }));
  }

  // save current login user data
  saveLoggedInUserData(phoneNumber: string, userId: string, email: string) {
    //console.log(UserService.name, 'saveLoggedInUserData()', phoneNumber, userId, email);
    if (phoneNumber || userId) {
      this.fetchLogInUserData(phoneNumber, userId).subscribe((user: User) => {
        //console.log(UserService.name, user, 'userdata');
        if (user) {
          // console.log(UserService.name, 'saveLoggedInUserData() user', user);
          this.setCurrentUser(user);
          this.saveLoggedInUserAttendanceData(user.clientId, user.id);
        } else {
          this.angularFirestoreDb.collection<CustomerAccess>(IAceeptMessageConstant.COLLECTION_CUSTOMER_ACCESS,
            ref => ref.where('email', '==', email))
            .get().pipe(map(res => {
              const customer = res.docs.map(doc => doc.data());
              const user = new User();
              user.id = customer[0].id;
              user.email = customer[0].email;
              user.clientId = customer[0].clientId;
              user.role = "customer";
              const userObj = { ...user }
              this.setCurrentUser(userObj);
            })).subscribe();
        }
      });
    } else {
      this.setCurrentUser(null);
      this.setEmployeeAttendance(null);
    }
  }


  // save current Employee login Attendance data
  saveLoggedInUserAttendanceData(clientId: string, userId: string) {
    // console.log(UserService.name, 'saveLoggedInUserAttendanceData()', clientId, userId);
    if (clientId && userId) {
      this.getCurrentEmployeeAttendance(clientId, userId).subscribe((attendance: Attendance[]) => {
        // console.log(UserService.name, attendance, 'attendance');
        if (attendance.length) {
          this.setEmployeeAttendance(attendance[0]);
        }
      });
    } else {
      this.setEmployeeAttendance(null);
    }
  }


  // to send email varification on email and password signup to employee
  async sendEmailVerification() {
    await this.fireAuth.auth.currentUser.sendEmailVerification();
  }

  // to send email verification on email and password signup to customer
  sendEmailVerificationToCustomer(email, password) {
    const url = "https://us-central1-ivisit-f2ce6.cloudfunctions.net/createAuthUser";
    return this.http.post(url, { email, password }).pipe(
      catchError(handleErrorObservable<any>('')));
  }

  // to send password reset link to email
  sendPasswordResetLink(emailAddress): Promise<any> {
    return this.fireAuth.auth.sendPasswordResetEmail(emailAddress);
  }

  // to update password
  updatePassword(newPassword): Promise<any> {
    return this.fireAuth.auth.currentUser.updatePassword(newPassword);
  }

  // to check user already exxits
  checkUserAlreadySignUp(email) {
    return this.fireAuth.auth.fetchSignInMethodsForEmail(email);
  }

  // Sets user data to firestore after succesful login
  setUserDoc(
    id: string, email: string,
    role: string, clientId?: string, userName?: string, phonenumber?: string,
    registrationType?: string, isActive?: boolean, description?: string,
    gstNumber?: string) {      
    const data: User = {
      id,
      email: email ? email.toLowerCase() : null,
      role,
      clientId,
      name: userName,
      phonenumber: phonenumber || null,
      registrationType: registrationType ? registrationType : 'none',
      isActive,
      description: description || null,
      createdDate: firestore.FieldValue.serverTimestamp(),
      gstNumber: gstNumber || null,
      logo: ''
    };
    const creation = this.angularFirestoreDb.collection('users').doc(id).set(data);
    return creation;
  }


  // Sets client data to firestore after succesful signup
  setClientDoc(client: any, id: string, companyName?: string, logo?: string) {
    const companyDetails = {
      id,
      companyName,
      logo: logo || "",
    };
    if (client) {
      const doc = this.angularFirestoreDb.doc(`${IAceeptMessageConstant.COLLECTION_CLIENTS}/${id}`);
      // console.log('client doc created', doc);
      doc.set(companyDetails);
      const addrDoc = this.angularFirestoreDb.doc(`${IAceeptMessageConstant.COLLECTION_CLIENTS}/${id}`)
        .collection(IAceeptMessageConstant.COLLECTION_ADDRESSES).add(client);
      return addrDoc;
    }
  }

  // Sets subscrption data to firestore 
  setSubscrptionDoc(subscrption: UserSubscription) {
    const subscrptionRef = this.subscriptionCollections;
    subscrptionRef.add({ ...subscrption }).then((ref) => {
      ref.update({
        id: ref.id
      });
    });
    return subscrptionRef.snapshotChanges();
  }


  // Sets subscrption data to firestore 
  updateSubscrption(subscrptionId: string, subscrption: UserSubscription) {
    return this.subscriptionCollections.doc(subscrptionId).update(subscrption);
  }

  // featch user data  based on User ID
  fetchCurrentUserBasedOnUserId(userId: string): Observable<any> {
    return this.angularFirestoreDb.collection<User>(IAceeptMessageConstant.COLLECTION_USERS).doc(userId).get().pipe(map(res => res.data()));
  }

  // featch user data  based on User ID
  fetchUserByPhoneNumber(phoneNumber: string): Observable<any> {
    return this.angularFirestoreDb.collection<User>(IAceeptMessageConstant.COLLECTION_USERS,
      ref => ref
        .where('isActive', '==', true).where('phonenumber', '==', phoneNumber)).get().pipe(map(res => res.docs.map(doc => doc.data())));
  }

  // featch subscrption data  based on User ID
  fetchSubscrptionDataBasedOnClientId(clientId: string): Observable<any> {
    return this.angularFirestoreDb.collection<any>(IAceeptMessageConstant.COLLECTION_SUBSCRIPTION,
      ref => ref
        .where('clientId', '==', clientId).orderBy('subscribedDate', 'desc').limit(1))
      .get().pipe(map(res => res.docs.map(doc => doc.data())));
  }


  fetchCurrentSubscrption(clientId: string): Observable<UserSubscription[]> {
    return this.fetchSubscrptionDataBasedOnClientId(clientId).pipe(map((subscrptionList) => {
      console.log('subscription list', subscrptionList);
      if (subscrptionList.length) {
        const currDate = new Date();
        return subscrptionList.filter(item => {
          if (item.subscribedDate && item.subscriptionEndDate) {
            const endDate = typeof item.subscriptionEndDate !== 'number' ?
              new Date((item.subscriptionEndDate).seconds * 1000) : new Date((item.subscriptionEndDate));
            console.log("endDate: ", endDate);
            if (endDate >= currDate) {
              this.setCurrentSubscrption(item);
              return true;
            }
          }
          return false;
        });
      } else {
        this.setCurrentSubscrption(null);
        return [];
      }
    }));
  }


  fetchLogInUserData(phoneNumber: string, userId: string): Observable<User> {
    if (phoneNumber) {
      return this.fetchUserByPhoneNumber(phoneNumber).pipe(map((users: User[]) => {
        // console.log(UserService.name, 'fetched user data: ', users);
        if (users && users.length > 0) {
          return users[0];
        }
      }));
    } else {
      return this.fetchCurrentUserBasedOnUserId(userId);
    }
  }

  // to update current user data
  updateCurrentUser(userId: string, clientId: string, registrationType?: string, isActive?: boolean, logo?: string) {
    this.userCollections.doc(userId).update({
      clientId,
      registrationType,
      isActive: isActive || false,
      logo: logo || null,
    });
  }


  getPackages() {
    return this.packageCollections.get().pipe(map(res => res.docs.map(doc => doc.data() as Package)));
  }

  // to check subscrption status based on user id


  // get menu list
  getMenuList(): Observable<MenuItem[]> {
    const url = IAceeptMessageConstant.EMPLOYERMENU;
    return this.http.get(url).pipe(
      catchError(handleErrorObservable<any>('getMenuList')));
  }

  // get Subscrption list
  getSubscrptionList(): Observable<SubscrptionList[]> {
    const url = IAceeptMessageConstant.SUBSCRIPTIONLIST;
    return this.http.get(url).pipe(
      catchError(handleErrorObservable<any>('getSubscrptionList')));
  }

  // get Subscrption list
  getSubscrptionPackageList(): Observable<SubscrptionPackageList[]> {
    const url = IAceeptMessageConstant.SUBSCRIPTIONPACKAGELIST;
    return this.http.get(url).pipe(
      catchError(handleErrorObservable<any>('getSubscrptionPackageList')));
  }

  // log out from application and firebase
  logout() {
    this.setCurrentUser(null);
    this.setEmployeeAttendance(null);
    return this.fireAuth.auth.signOut();
  }

  getAllEmployeesOfClient(clientId: string, fromDate: Date, toDate: Date): Observable<User[]> {
    return this.angularFirestoreDb.collection<User>(IAceeptMessageConstant.COLLECTION_USERS,
      ref => ref.where('clientId', '==', clientId).
        where('role', '==', 'employee')).get().pipe(map(res => res.docs.map(doc => doc.data() as User)));
  }

  getAllUsersBasedOnEmployeeRoles(clientId) {
    return this.angularFirestoreDb.collection<User>(IAceeptMessageConstant.COLLECTION_USERS,
      ref => ref.where('clientId', '==', clientId).
        where('role', '==', 'employee').
        where('isActive', '==', true)).get().pipe(map(res => res.docs.map(doc => doc.data() as User)));
  }

  getEmployeesOfClient(clientId: string): Observable<User[]> {
    return this.angularFirestoreDb.collection<User>(IAceeptMessageConstant.COLLECTION_USERS,
      ref => ref
        .where('clientId', '==', clientId)
        .where('registrationType', 'in', ['halt', 'patrol', 'custom', 'Team Member', 'general']))
      .get().pipe(map(res => res.docs.map(doc => doc.data() as User)));
  }

  getEmployeesOfClientBasedOnSubscription(clientId: string, subscription: string): Observable<User[]> {
    return this.angularFirestoreDb.collection<User>(IAceeptMessageConstant.COLLECTION_USERS,
      ref => ref
        .where('clientId', '==', clientId)
        .where('registrationType', '==', subscription))
        // .where('isActive', '==', true))
      .get().pipe(map(res => res.docs.map(doc => doc.data() as User)));
  }


  // If error, console log and notify user

  inviteEmployee(invite: Invite): Promise<any> {
    const inviteCreation = this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_INVITES).add({ ...invite });
    inviteCreation.then(ref => {
      ref.update({
        id: ref.id
      });
    });
    return inviteCreation;
  }

  remapInviteEmployee(invite: Invite, remapField:string): Promise<any> {
    const inviteCreation = this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_INVITES).add({ ...invite });
    inviteCreation.then(ref => {
      ref.set( { 
        id: this.angularFirestoreDb.createId(),
        clientId: invite.clientId,
        contactNumber: invite.contactNumber,
        createdDate: firestore.Timestamp,
        emailAddress: invite.emailAddress,
        employeeName: invite.employeeName,
        employeeType: invite.employeeType, 
        remap: remapField } );
      });
    return inviteCreation;
  }

  getInvitesSentByClient(clientId: string): Observable<Invite[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_INVITES,
      query => query.where('clientId', '==', clientId)).get()
      .pipe(map(res => res.docs.map(invite => invite.data() as Invite)));
  }

  getInviteForContactNumber(contactNumber: string): Observable<Invite[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_INVITES,
      query => query.where('contactNumber', '==', contactNumber)).get()
      .pipe(map(res => res.docs.map(invite => invite.data() as Invite)));
  }

  getInvitesSentByClientForTeamMember(emailAddress: string): Observable<Invite[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_INVITES,
      query => query.where('emailAddress', '==', emailAddress)).get()
      .pipe(map(res => res.docs.map(invite => invite.data() as Invite)));
  }

  deleteInvite(inviteId: string) {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_INVITES).doc(inviteId).delete();
  }

  deleteAttendance(attendanceId) {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_ATTENDANCE).doc(attendanceId).delete().then(() => {
      if (attendanceId === this.currentEmployeeAttendance.id) {
        this.setEmployeeAttendance(null);
        this.saveLoggedInUserAttendanceData(this.currentUser.clientId, this.currentUser.id);
      }
      //console.log('Document successfully deleted!');
    }).catch((error) => {
      //console.error('Error removing document: ', error);
    });
  }

  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: alertData,
      buttons: ['OK'],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  checkUserExistsStatus(phoneNumber: string, isActive: boolean): Observable<User[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_USERS,
      query => query.where('phonenumber', '==', phoneNumber).where('isActive', '==', isActive)).get()
      .pipe(map(res => res.docs.map(user => user.data() as User)));
  }

  getClientDataBasedOnClientId(clientId: string): Observable<Clients[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_CLIENTS,
      query => query.where('id', '==', clientId)).get()
      .pipe(map(res => res.docs.map(client => client.data() as Clients)));
  }

  // create attendance
  createAttendance(attendance: Attendance): Promise<any> {
    attendance.startTimeDeviceId = this.device.uuid;
    attendance.id = this.angularFirestoreDb.createId();
    if (attendance.id) {
      const templateAttendance = this.attendanceCollections.doc(attendance.id).set({ ...attendance });
      templateAttendance.then(() => {
        this.attendanceCollections.doc(attendance.id).get().subscribe(att => {
          const attData = att.data();
          // console.log(UserService.name, 'att data', attData);
          this.setEmployeeAttendance(attData as any);
        });
      });
      return templateAttendance;
    }
  }

  updateAttendance(id: string, attendance: Attendance): Promise<any> {
    attendance.endTimeDeviceId = this.device.uuid;
    const templateAttendance = this.attendanceCollections.doc(id).update({ ...attendance });
    return templateAttendance;
  }

  // getAllEmployeeAttendance(clientId: string): Observable<Attendance[]> {
  //   return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_ATTENDANCE,
  //     query => query.where('clientId', '==', clientId).orderBy('startTime', 'desc')).get()
  //     .pipe(map(res => res.docs.map(user => user.data() as Attendance)));
  // }

  getAllEmployeeAttendance(clientId: string, fromDate: Date, toDate: Date): Observable<Attendance[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_ATTENDANCE,
      query => {
        let ref = query.where('clientId', '==', clientId).
        where('startTime', '>=', fromDate).
        where('startTime', '<=', toDate);
        ref = ref.orderBy('startTime', 'desc');
        return ref; 
      } ).get()
      .pipe(map(res => res.docs.map(user => user.data() as Attendance)));
  }

  getCurrentEmployeeAttendance(clientId: string, id: string): Observable<Attendance[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_ATTENDANCE,
      query => query.where('clientId', '==', clientId).where('createdByUser', '==', id).where('endTime', '==', null)).get()
      .pipe(map(res => res.docs.map(user => user.data() as Attendance)));
  }

  getCurrentEmployeeAttendanceHistory(clientId: string, id: string): Observable<Attendance[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_ATTENDANCE,
      query => query.where('clientId', '==', clientId).where('createdByUser', '==', id).orderBy('startTime', 'desc').limit(50)).get()
      .pipe(map(res => res.docs.map(user => user.data() as Attendance)));
  }

  getEmployeeAttendanceByDate(clientId: string, createdByUserId: string, startTime: Date): Observable<Attendance[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_ATTENDANCE,
      query => query
        .where('clientId', '==', clientId)
        .where('createdByUser', '==', createdByUserId)
        .where('startTime', '<', startTime).orderBy('startTime', 'desc').limit(1)).get()
      .pipe(map(res => res.docs.map(user => user.data() as Attendance)));
  }

  getAttendance() {
    
  }
  // to deactivate employee
  deActivateEmployee(userId: string, isActive: boolean): Promise<any> {
    return this.userCollections.doc(userId).update({
      isActive
    });
  }

  // to get deactivate employee list
  getdeactivatedEmployeeList(clientId: string): Observable<User[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_USERS,
      query => query.where('clientId', '==', clientId).where('isActive', '==', false)).get()
      .pipe(map(res => res.docs.map(user => user.data() as User)));
  }

  // To count number of empoyee for particular client

  getNumberOfActiveEmployeeOfClient(clientId: string): Observable<number> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_USERS,
      query => query.where('clientId', '==', clientId).where('isActive', '==', true).where('role', '==', 'employee')).get()
      .pipe(map(res => res.docs.map(user => user.data() as User).length));
  }

  // to get user based on client id for employee-info

  getAllEmployesBasedOnClientid(clientId: string) {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_USERS,
      query => query
        .where('clientId', '==', clientId)
        .where('isActive', '==', true)
        .where('registrationType', 'in', ['patrol', 'custom', 'halt', 'general'])
        .where('role', '==', 'employee'))
      .get().pipe(map(res => res.docs.map(doc => doc.data())));
  }

  getAllHaltEmployesBasedOnClientid(clientId: string) {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_USERS,
      query => query
        .where('clientId', '==', clientId)
        .where('isActive', '==', true)
        .where('registrationType', 'in', ['halt', 'custom',])
        .where('role', '==', 'employee'))
      .get().pipe(map(res => res.docs.map(doc => doc.data())));
  }

  getAppVersion(platform: string): Observable<string> {
    return this.utilsCollections.doc('app-version').get().pipe(map(res => {
      const record = res.data();
      if (platform === 'android') {
        return record.android;
      } else if (platform === 'ios') {
        return record.ios;
      }
    }));
  }

  getEmployeeByEmployeeId(employeeId: Observable<User>) {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_USERS,
      //future release feature
      query => query.where('id', '==', employeeId).where('isActive', '==', true).where('role', 'in', ['employee', 'client'])).get()
      .pipe(map(res => res.docs.map(user => user.data() as User)));
  }

  updateEmployee(userId: string, employeeName: string, type: string, email: string) {
    // console.log(UserService.name, 'user service', userId, employeeName, type, email);
    return this.userCollections.doc(userId).update({ name: employeeName, registrationType: type, email: email });
  }

  getTravelHistoryOfAllEmployees(clientId: string, fromDate: Date, toDate: Date): Observable<TravelHistory[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_TRAVEL_HISTORY,
      query => query.where('clientId', '==', clientId).
        where('createdDate', '>=', fromDate).
        where('createdDate', '<=', toDate).
        orderBy('createdDate', 'desc')).get()
      .pipe(map(res => res.docs.map(user => user.data() as TravelHistory)));
  }

  getTravelHistoryOfEmployeeById(clientId: string, employeeId: string, fromDate: Date, toDate: Date): Observable<TravelHistory[]> {
    return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_TRAVEL_HISTORY,
      query => query.where('clientId', '==', clientId).
        where('employeeId', '==', employeeId).
        where('createdDate', '>=', fromDate).
        where('createdDate', '<=', toDate).
        orderBy('createdDate', 'desc')).get()
      .pipe(map(res => res.docs.map(user => user.data() as TravelHistory)));
  }

  getUserSignUpStatus() {
    return localStorage.getItem('userCreation');
  }

  checkIfEmailIsVerified(email, password) {
    //  return this.firebase.auth().signInWithEmailAndPassword(email, password)
    //    .then(function (result) {
    //       console.log('true')
    //     }).catch(function (error) {
    //       console.log('false');
    //     });

    // firebase.auth().signInWithEmailAndPassword(email, password).then(authUser => {

    //   if (authUser.user.emailVerified) { //This will return true or false
    //     console.log('email is verified')
    //   } else {
    //     console.log('email not verified')
    //   }
    // }).catch(function (error) {

    // });

  }
}
