import { SubscrptionList } from './../../entity/menu';
import { User } from './../../entity/user';
import { Component, OnInit, OnChanges } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MenuItem } from 'src/app/entity/menu';
import { Router } from '@angular/router';
import { NavController, MenuController, AlertController } from '@ionic/angular';
import { UserSubscription } from 'src/app/entity/subscription';
import { ClientService } from 'src/app/services/client.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
@Component({
  selector: 'app-employer-main',
  templateUrl: './employer-main.component.html',
  styleUrls: ['./employer-main.component.scss'],
})
export class EmployerMainComponent implements OnInit {
  menuList: MenuItem[] = [];
  currentUser: User;
  client: any;
  currentSubscrption: UserSubscription;
  currentAppVersion: string;
  dashboardMenu: MenuItem[];
  reportsMenu: MenuItem[];
  adminMenu: MenuItem[];
  statsMenu: MenuItem[];

  constructor(private userService: UserService, private router: NavController, private clientService: ClientService,
    private menuCtrl: MenuController, public alertController: AlertController, private appVersion: AppVersion) { }

  ngOnInit() {
    this.getCurrentUser();
    this.getMenuList();
    this.getAppVersion();
  }

  async getAppVersion() {
    this.currentAppVersion = await this.appVersion.getVersionNumber();
  }

  onMenuOpen() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      console.log("🚀 ~ file: employer-main.component.ts ~ line 33 ~ EmployerMainComponent ~ this.userService.getCurrentUser ~ user", user)

      this.currentUser = user;
      this.getCurrentSubscrption();
      this.getCurrentClientDetails();
    });
  }

  async getCurrentClientDetails() {
    this.client = await this.clientService.getClientData(this.currentUser.clientId);
    console.log("getCurrentClientDetails -> client", this.client);
  }

  getCurrentSubscrption() {
    this.userService.getCurrentSubscrption().subscribe((sub) => {
      if (sub) {
        // console.log(EmployerMainComponent.name, sub, 'currentSubscrption-employee');
        this.currentSubscrption = sub;
        this.getMenuList();
      }
    });
  }

  getMenuList() {
    this.userService.getMenuList().subscribe((menu) => {
      if (menu && this.currentSubscrption) {
        // console.log("current subscription ->", this.currentSubscrption);
        this.menuList = menu.map((item) => {
          if (item.title === 'Dashboard') {
            if ((this.currentSubscrption.type === 'halt' || this.currentSubscrption.type === 'custom')) {
              item.url = 'employer/dashboard/halts';
              return item;
            }
            if ((this.currentSubscrption.type === 'patrol')) {
              item.url = 'employer/dashboard/patrols';
              return item;
            }
            if ((this.currentSubscrption.type === 'general')) {
              item.url = 'employer/dashboard/employee-list';
              return item;
            }
          }
          if (item.title === 'Manage Patrols') {
            if ((this.currentSubscrption.type === 'halt')) {
              item.isAccess = false;
              return item;
            }
            if ((this.currentSubscrption.type === 'general')) {
              item.isAccess = false;
              return item;
            }
          }

          if (item.title === 'Reports' || item.title === 'Manage Task Templates') {
            if ((this.currentSubscrption.type === 'general')) {
              item.isAccess = false;
              return item;
            }
          }
          
          return item;
        });

        this.dashboardMenu = this.menuList.slice(0,1);
        this.reportsMenu = this.menuList.slice(1,5);
        this.adminMenu = this.menuList.slice(5,9);
        this.statsMenu = this.menuList.slice(9);

        // console.log(EmployerMainComponent.name, 'menu list', this.menuList);
      }
    });
  }

  logOut() {
    this.userService.logout().then(() => {
      this.router.navigateRoot(['/home']);
    });
  }

  onMenuItemClick(menu: MenuItem) {
    this.menuCtrl.close();
    this.router.navigateRoot(menu.url);
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.logOut();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

}
