import { EmployeeListComponent } from './employee-list/employee-list.component';
import { CustomersComponent } from './customers/customers.component';
import { CreatePatrolComponent } from './create-patrol/create-patrol.component';
import { SettingsComponent } from './settings/settings.component';
import { PatrolsListComponent } from './patrols-list/patrols-list.component';
import { ReportsComponent } from './reports/reports.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AddNewCustomerComponent } from './add-new-customer/add-new-customer.component';
import { AuthGuard } from '../core/auth.guard';
import { AddNewStopComponent } from './add-new-stop/add-new-stop.component';
import { PitstopListComponent } from './pitstop-list/pitstop-list.component';
import { EmployerMainComponent } from './employer-main/employer-main.component';
import { PatrolsComponent } from './patrols/patrols.component';
import { HaltsComponent } from './halts/halts.component';
import { InviteEmployeeComponent } from './invite-employee/invite-employee.component';
import { DeactivatedEmployeeListComponent } from './deactivated-employee-list/deactivated-employee-list.component';
import { AttendanceReportComponent } from './attendance-report/attendance-report.component';
import { EmployerCustomerInfoComponent } from './employer-customer-info/employer-customer-info.component';
import { EmployeeUpdateComponent } from './employee-update/employee-update.component';
import { DistanceTrackerComponent } from './distance-tracker/distance-tracker.component';
import { ContactComponent } from '../shared/contact/contact.component';
import { ViewPatrolDetailsComponent } from './view-patrol-details/view-patrol-details.component';
import { CustomerAccessComponent } from './customer-access/customer-access.component';
import { IncidentsAccessComponent } from './incidents-access/incidents-access.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskInputComponent } from './task-input/task-input.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { FireAlertsComponent } from './fire-alerts/fire-alerts.component';
import { AlertDateInfoComponent } from './alert-date-info/alert-date-info.component';
import { AlertMapComponent } from './alert-map/alert-map.component';
const employerRoutes: Routes = [
    {
        path: 'employer',
        canActivate: [AuthGuard],
        component: EmployerMainComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                children: [
                    {
                        path: 'patrols',
                        component: PatrolsComponent
                    }, {
                        path: 'halts',
                        component: HaltsComponent
                    }, {
                        path: 'employee-list',
                        component: EmployeeListComponent
                    },
                    {
                        path: 'employee-deactivated-list',
                        component: DeactivatedEmployeeListComponent
                    }, {
                        path: 'customers',
                        component: CustomersComponent
                    }
                ]
            },
            {
                path: 'reports',
                component: ReportsComponent
            },
            {
                path: 'distance-tracker',
                component: DistanceTrackerComponent
            },
            {
                path: 'patrol-list',
                component: PatrolsListComponent
            },
            {
                path: 'settings',
                component: SettingsComponent
            },
            {
                path: 'customer-info',
                component: EmployerCustomerInfoComponent
            },
            {
                path: 'subscrption',
                component: SubscriptionComponent
            },
            {
                path: 'create-patrol',
                component: CreatePatrolComponent
            },
            {
                path: 'create-customer',
                component: AddNewCustomerComponent
            },
            {
                path: 'add-pitstop',
                component: AddNewStopComponent
            },
            {
                path: 'pitstop-list',
                component: PitstopListComponent
            },
            {
                path: 'invite-employee',
                component: InviteEmployeeComponent
            },
            {
                path: 'attendance-report',
                component: AttendanceReportComponent
            },
            {
                path: 'employee-update',
                component: EmployeeUpdateComponent
            },
            {
                path: 'contact',
                component: ContactComponent
            },
            {
                path: 'customer-access',
                component: CustomerAccessComponent
            },
            {
                path: 'view-patrol-details',
                component: ViewPatrolDetailsComponent
            },
            {
                path: 'incidents-access',
                component: IncidentsAccessComponent
            },
            {
                path: 'task-list',
                component: TaskListComponent
            },
            {
                path: 'task-input',
                component: TaskInputComponent
            },
            {
                path: 'bar-Chart',
                component: BarChartComponent
            },
            {
                path: 'fire-alerts',
                component: FireAlertsComponent
            },
            {
                path: 'alert-date',
                component: AlertDateInfoComponent
            },
            {
                path: 'alert-map',
                component: AlertMapComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(employerRoutes)
    ],
    exports: [RouterModule]
})
export class EmployerRoutingModule { }
