import { Event } from '../../../app/entity/event';
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-halt-details-modal',
  templateUrl: './halt-details-modal.component.html',
  styleUrls: ['./halt-details-modal.component.scss'],
})
export class HaltDetailsModalComponent implements OnInit {

  @Input() halt: any;
  slideOpts = {
    // initialSlide: 1, 
    speed: 100,
  };

  constructor(public modalController: ModalController, private userService: UserService) { }


  deviceAssesment: any;

  ngOnInit() {
    if (this.halt) {
      console.log('HaltDetailsModalComponent -> halt', this.halt)
    }
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  getTimeinHHMMFormat(mins) {
    if (mins >= 0) {
      const num = mins;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.floor(minutes);
      const seconds = (minutes - rminutes) * 60;
      const rseconds = Math.round(seconds);
      return rhours + ' ' + 'h' + ' ' + ':' + ' ' + rminutes + ' ' + 'm' + ' ' + ':' + ' ' + rseconds + ' ' + 's';
    } else {
      return 0 + ' ' + 'hours' + ' ' + ':' + ' ' + 0 + ' ' + 'mins';
    }
  }
}
