import { SnackBarService } from './../../services/snackbar.service';
import { UserSubscription } from './../../entity/subscription';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { IonTabs, ModalController } from '@ionic/angular';
import { TabsComponent } from 'src/app/shared/tabs/tabs.page';
import { MenuItem } from 'src/app/entity/menu';
import { OnBoardingModalComponent } from 'src/app/on-boarding-modal/on-boarding-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  currentUser;
  currentClient: User;
  isEmaileVerified;
  menuList: MenuItem[] = [];
  tabLinks = [];
  selectedTab: string;
  currentSubscrption: UserSubscription;
  constructor(private router: Router, private userService: UserService, private snackBarService: SnackBarService,
              public modalController: ModalController) { }

  ngOnInit() {
    this.onBoarding();
    this.getCurrentUser();
  }

  async onBoarding() {
    // console.log(DashboardComponent.name, 'local storage', localStorage.getItem('onEmployerBoarding'));
    if (localStorage.getItem('onEmployerBoarding') === 'shown') {
      return;
    }
    const modal = await this.modalController.create({
      component: OnBoardingModalComponent,
      componentProps: {
        type: 'employer',
      }
    });
    localStorage.setItem('onEmployerBoarding', 'shown');
    return await modal.present();
  }

  getCurrentSubscrption() {
    this.userService.getCurrentSubscrption().subscribe((sub) => {
      if (sub) {
        // console.log(DashboardComponent.name, sub, 'currentSubscrption');
        this.currentSubscrption = sub;
        this.getTabLinks();
      }
    });
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        // console.log(DashboardComponent.name, user, 'user');
        this.getCurrentSubscrption();
      }
    });
  }

  reDirectionBaseonSubscrption() {
    if (this.currentSubscrption) {
      if (this.currentSubscrption.type === 'halt' || this.currentSubscrption.type === 'custom') {
        this.selectedTab = 'halt';
      }
      if (this.currentSubscrption.type === 'patrol') {
        this.selectedTab = 'patrol';
      }
      // check this
      if (this.currentSubscrption.type === 'general') {
        this.selectedTab = 'employees';
      }
    }
  }

  getTabLinks() {
    this.reDirectionBaseonSubscrption();
    if (this.currentSubscrption) {
      this.tabLinks = [
        {
          title: 'Halts',
          tab: 'halts',
          icon: 'stopwatch',
          isAccess: this.currentSubscrption.type === 'halt' || this.currentSubscrption.type === 'custom' ? true : false

        },
        {
          title: 'Patrols',
          tab: 'patrols',
          icon: 'refresh',
          isAccess: this.currentSubscrption.type === 'patrol' || this.currentSubscrption.type === 'custom' ? true : false
        },
        {
          title: 'Employees',
          tab: 'employee-list',
          icon: 'people',
          isAccess: true
        },
        {
          title: 'Customers',
          tab: 'customers',
          icon: 'list',
          isAccess: true
        }
      ];
    } else {
      this.tabLinks = [];
    }
  }
}
