import { take } from 'rxjs/operators';
import { PitstopListComponent } from './../pitstop-list/pitstop-list.component';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PitStop } from 'src/app/entity/event.template';
import { EventService } from 'src/app/services/event.services';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { Subscription } from 'rxjs';
import { GoogleMap, GoogleMaps, GoogleMapsEvent, Environment } from '@ionic-native/google-maps/ngx';
import { AppLocationService } from 'src/app/services/app-location.service';
import { firestore } from 'firebase';
import { LoaderService } from 'src/app/services/loader.service';
import { AlertController, Platform } from '@ionic/angular';
import { PatrolService } from 'src/app/services/patrol.service';
import { promise } from 'protractor';

@Component({
  selector: 'app-add-new-stop',
  templateUrl: './add-new-stop.component.html',
  styleUrls: ['./add-new-stop.component.scss'],
})
export class AddNewStopComponent implements OnInit, OnDestroy {
  pitstopForm: FormGroup;
  patrolId: string;
  pitstopId: string;
  currentUser: User;
  locationCoords: any;
  locationSubscription: Subscription;
  map: GoogleMap;
  marker: any;
  markerSetOnMap = false;
  pitstops: PitStop[];
  multiplePitStopData: any[] = [];
  editPitstop = false; // for editing pitstop
  stepper1 = true;
  stepper2 = false;
  title = 'Add Pitstop';
  locationDetails: any;
  btnLoading = false;
  manualLocationPostioning = false;
  setLocationBtns = true;
  inputLat: number;
  inputLng: number;

  constructor(
    private formBuilder: FormBuilder, private eventService: EventService, private route: ActivatedRoute,
    private loaderService: LoaderService, private location: Location, private snackBarService: SnackBarService,
    private userService: UserService, private locationService: AppLocationService, public alertController: AlertController,
    private patrolService: PatrolService, private locationAccuracy: LocationAccuracy, private platform: Platform) { }


  ngOnInit() {
    this.createPitstopForm();
    this.route.queryParams.subscribe(params => {
      this.patrolId = params['patrol'],
        this.pitstopId = params['pitstop'];
      if (this.patrolId && this.pitstopId) {
        this.editPitstop = true;
        this.title = 'Update Pitstop';
        this.getPitstopsOfPatrol(this.patrolId);
      }
    });
    this.userService.getCurrentUser().subscribe(user => this.currentUser = user);
    this.checkLocationPermissionsAndTrack();
  }

  createPitstopForm() {
    this.pitstopForm = this.formBuilder.group({
      name: ['', Validators.required],
      shortCode: ['', Validators.required]
    });
  }

  getPitstopsOfPatrol(patrolId: string) {
    this.eventService.getPitstopsOfPatrol(patrolId).pipe(take(1)).subscribe(pitstops => {
      this.pitstops = pitstops;
      if (this.pitstopId) {
        this.getPitstop(this.pitstopId);
        // console.log(AddNewStopComponent.name, 'pitstops', this.pitstops);
      }
    });
  }

  getPitstop(pitstopId: string): Event {
    if (this.pitstops) {
      const pitstop = this.pitstops.find(item => item.id === pitstopId);
      // console.log(AddNewStopComponent.name, 'pitstop to be edited', pitstop);
      this.pitstopForm.patchValue({ ...pitstop });
      setTimeout(() => {
        this.addMarkerForPreviousPosition(pitstop.location['latitude'], pitstop.location['longitude']);
      }
        , 1000);
    } else {
      return null;
    }
  }

  addMarkerForPreviousPosition(lat, lng) {
    this.map.addMarker({
      title: 'Calibrated pitstop location',
      label: 'Calibrated pitstop location',
      icon: '../../../assets/images/concentric.png',
      animation: 'DROP',
      position: {
        lat,
        lng
      },
    });
  }

  checkLocationPermissionsAndTrack() {
    if (this.platform.is('desktop')) {
      this.startTrackingLocation();
      return;
    }
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => {
            this.startTrackingLocation();
          },
          error => {
            // console.log(AddNewStopComponent.name, 'Error requesting location permissions', error);
          }
        );
      } else {
        // console.log(AddNewStopComponent.name, 'location permissions already granted');
        this.startTrackingLocation();
      }
    }, error => {
      // console.log(AddNewStopComponent.name, 'error requesting location access' + error);
    });
  }

  async startTrackingLocation() {
    try {
      // this.locationCoords = await this.locationService.getCurrentLocation();
      this.locationSubscription = this.locationService.startLocation().subscribe(loc => {
        console.log("logging locationCoords ", loc);
        this.locationCoords = loc;

        if (this.locationCoords && this.map && !this.manualLocationPostioning) {
          this.markerSetOnMap = true;
          if (this.inputLat && this.inputLng) {
            this.setupLocationOnMap(this.inputLat, this.inputLng);
          } else {
            this.setupLocationOnMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
          }
        }

      });
    } catch (err) {
      this.snackBarService.showToaster(err);
    }
  }

  setupLocationOnMap(lat: number, lng: number) {
    this.addMarkerToMap(lat, lng).then(marker => {
      this.marker = marker;
      this.map.setCameraTarget({ lat, lng });
      this.map.setCameraZoom(18);
      this.map.addEventListener(GoogleMapsEvent.CAMERA_MOVE).subscribe(data => {
        if (this.manualLocationPostioning) {
          this.marker.setPosition(data[0].target);
        }
      });
      this.map.addEventListener(GoogleMapsEvent.CAMERA_MOVE_END).subscribe(data => {
        // console.log(AddNewStopComponent.name, 'camera move end data', data);
        if (this.manualLocationPostioning) {
          this.marker.setPosition(data[0].target);
        }
      });
    }, () => {
      // console.log('adding marker: map not loaded');
    });
  }

  addMarkerToMap(lat: number, long: number): Promise<any> {
    if (this.map) {
      if (this.marker) {
        this.map.clear();
      }
      return this.map.addMarker({
        title: 'You are here',
        icon: 'green',
        animation: 'DROP',
        position: { lat, lng: long },
      });
    } else {
      return Promise.reject('map not loaded');
    }
  }

  stopTrackingLocation() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
      this.locationSubscription = null;
    }
  }

  ngOnDestroy() {
    this.stopTrackingLocation();
  }

  ionViewDidEnter() {
    this.loadMap();
  }

  loadMap() {
    // console.log(AddNewStopComponent.name, 'loading map...');
    Environment.setEnv({
      API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI',
      API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI'
    });
    this.map = GoogleMaps.create('map_canvas');
    // console.log('load map: marker status', this.markerSetOnMap);
    if (!this.markerSetOnMap && this.locationCoords) {
      this.markerSetOnMap = true;
      this.setupLocationOnMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
    }
    // console.log(AddNewStopComponent.name, 'map created', this.map);
  }

  async onAddPitstop() {
    this.btnLoading = true;
    const pitstopValues = this.pitstopForm.value;
    const markerPosition = this.locationDetails;
    const pitstop = new PitStop();
    if (!this.editPitstop) {
      pitstop.name = pitstopValues.name;
      pitstop.shortCode = pitstopValues.shortCode;
      pitstop.clientId = this.currentUser.clientId;
      pitstop.location = new firestore.GeoPoint(markerPosition.lat, markerPosition.lng);
      pitstop.isActive = true;
      if (pitstop.shortCode) {
        if (this.checkForDuplicateShortCodeinSamePatrol(pitstop)) {
          this.presentAlert('Duplicate entry with the same shortcode found, please add a new shortcode');
          this.btnLoading = false;
          return;
        }
        await this.checkForDuplicateShortCodeinOtherPatrols(pitstop);
      }
    } else {
      // console.log(AddNewStopComponent.name, 'pitstop update', this.pitstopForm.value);
      pitstop.name = this.pitstopForm.get('name').value;
      if (this.locationDetails) {
        pitstop.location = new firestore.GeoPoint(markerPosition.lat, markerPosition.lng);
        // console.log(AddNewStopComponent.name, 'storing updated pitstop template ', pitstop);
      }
      await this.updatePitstop(this.pitstopId, pitstop.name, pitstop.location);
    }
  }

  checkForDuplicateShortCodeinSamePatrol(pitstop: PitStop): boolean {
    if (this.pitstops) {
      const res = this.pitstops.find(item => pitstop.shortCode === item.shortCode);
      // console.log(AddNewStopComponent.name, 'checkForDuplicateShortCodeinSamePatrol', res);
      return res ? true : false;
    } else {
      return false;
    }
  }

  async checkForDuplicateShortCodeinOtherPatrols(pitstop: PitStop) {
    this.multiplePitStopData = await this.patrolService.getPatrolsForPitstopByShortcode(pitstop.shortCode, this.currentUser.clientId)
    if (this.multiplePitStopData && this.multiplePitStopData.length > 0) {
      // console.log(AddNewStopComponent.name, this.multiplePitStopData[0], 'multiplePitStopData[0]');
      pitstop.location = this.multiplePitStopData[0].pitstop.location;
      pitstop.name = this.multiplePitStopData[0].pitstop.name;
      pitstop.isActive = true;
      this.presentConfirm('Duplicate entry with the same shortcode exists! Would you like to reuse the same information', pitstop);
      this.btnLoading = false;
    } else {
      await this.createPitstop(pitstop);
    }
  }

  async createPitstop(pitstop: PitStop) {
    this.loaderService.presentLoading('Adding pitstop details');
    await this.eventService.addPitstopToPatrol(this.patrolId, pitstop)
    this.loaderService.dismissLoading();
    this.btnLoading = false;
    this.snackBarService.showToaster('Pitstop added successfully');
    // console.log(AddNewStopComponent.name, 'pitstop added successfully');
    this.location.back();
  }

  async updatePitstop(pitstopId: string, pitstopName: string, pitstopLocation: any) {
    // console.log(AddNewStopComponent.name, 'pitstop update values', pitstopId, pitstopName, pitstopLocation);
    this.loaderService.presentLoading('Updating pitstop details');
    await this.eventService.updatePitstopToPatrol(this.patrolId, pitstopId, pitstopName, pitstopLocation)
    this.loaderService.dismissLoading();
    this.editPitstop = false;
    this.btnLoading = false;
    this.title = 'Add Pitstop';
    this.snackBarService.showToaster('Pitstop updated successfully');
    this.location.back();
  }

  async confirmUpdatePitsop() {
    this.btnLoading = true;
    if (this.editPitstop) {
      const alert = await this.alertController.create({
        header: 'Alert',
        message: 'Would you like to update your pitstop location',
        buttons: [
          {
            text: 'Skip',
            role: 'cancel',
            handler: () => {
              alert.dismiss();
              this.moveToStepper2();
            }
          }, {
            text: 'Yes',
            cssClass: 'primary',
            handler: () => {
              alert.dismiss();
              this.locationDetails = this.marker.getPosition();
              this.moveToStepper2();
            }
          }
        ],
        cssClass: 'p-3',
      });

      await alert.present();
    } else {
      this.locationDetails = this.marker.getPosition();
      this.moveToStepper2();
    }
  }

  moveToStepper2() {
    this.btnLoading = false;
    this.locationCoords = null;
    this.markerSetOnMap = false;
    this.stepper1 = false;
    this.stepper2 = true;
    this.map.destroy();
    this.setLocationBtns = false;
  }

  moveToStepper1() {
    this.loadMap();
    this.stepper1 = true;
    this.stepper2 = false;
    this.setLocationBtns = true;
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.pitstopForm.reset();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  async presentConfirm(data, pitstop: PitStop) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'COPY',
          handler: async () => {
            await this.createPitstop(pitstop);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  onGPSLocation() {
    this.manualLocationPostioning = false;
    this.inputLat = null;
    this.inputLng = null;
    if (this.map) {
      if (this.marker) {
        this.map.clear();
      }
      this.setupLocationOnMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
    }
  }

  onRemoteCalibration() {
    this.inputLat = null;
    this.inputLng = null;
    this.manualLocationPostioning = true;
  }

  async onInputLocation() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: `Are you sure that you want set locations to ${this.inputLat + ' and ' + this.inputLng}  ?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.onSetLocation();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  onSetLocation() {

    if (this.inputLat && this.inputLng) {
      this.manualLocationPostioning = false;
      if (this.map) {
        if (this.marker) {
          this.map.clear();
        }
        this.setupLocationOnMap(this.inputLat, this.inputLng);
      }
    }
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
