import { SnackBarService } from './../../services/snackbar.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Invite } from 'src/app/entity/invite';
import { Location } from '@angular/common';

@Component({
  selector: 'app-employee-update',
  templateUrl: './employee-update.component.html',
  styleUrls: ['./employee-update.component.scss'],
})
export class EmployeeUpdateComponent implements OnInit {

  employeeId: string;
  editEmployee = false;
  updateEmployeeForm: FormGroup;
  btnLoading = false;
  constructor(private route: ActivatedRoute, private location: Location, private snackBarService: SnackBarService,
              private formBuilder: FormBuilder, private userService: UserService) { }

  ngOnInit() {
    this.employeeForm();
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.getEmployeeData(params.id);
        let currentUserRole = params.role;
        console.log("role ", currentUserRole);
        if(currentUserRole === "client") {
          this.editEmployee = false;
        } else {
          this.editEmployee = true;
        }
        this.employeeId = params.id;
      } else {
        this.editEmployee = false;
      }
    });
  }

  employeeForm() {
    this.updateEmployeeForm = this.formBuilder.group({
      name: ['', Validators.required],
      phonenumber: [''],
      email: [''],
      registrationType: [''],
    });
  }

  getEmployeeData(employeeId) {
    this.userService.getEmployeeByEmployeeId(employeeId).subscribe((empData) => {
      // console.log(EmployeeUpdateComponent.name, 'empl data', empData);
      this.updateEmployeeForm.patchValue(empData[0]);
    });
  }

  updateEmployee() {
    this.btnLoading = true;
    console.log(EmployeeUpdateComponent.name, 'form values', this.updateEmployeeForm.value);
    const employee = new Invite();
    employee.employeeName = this.updateEmployeeForm.value.name;
    employee.id = this.employeeId;
    employee.emailAddress = this.updateEmployeeForm.value.email;
    // console.log(EmployeeUpdateComponent.name, 'form values', employee.id, employee.employeeName );
    this.userService.updateEmployee(employee.id, employee.employeeName, this.updateEmployeeForm.value.registrationType, employee.emailAddress).then(() => {
      this.btnLoading = false;
      this.snackBarService.showToaster('Employee details updated successfully');
      this.location.back();
    });
  }
} 
