import { TravelHistory } from './../../entity/travelHistory';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { HaltService } from 'src/app/services/halt.services';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { PatrolService } from 'src/app/services/patrol.service';
import { Event, EventInfo } from 'src/app/entity/event';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-distance-tracker',
  templateUrl: './distance-tracker.component.html',
  styleUrls: ['./distance-tracker.component.scss'],
})
export class DistanceTrackerComponent implements OnInit {
  currentUser: User;
  travelDistanceList: TravelHistory[] = [];
  travelDistanceDisplayList: TravelHistory[] = [];
  employees: User[] = [];
  searchString: string;
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  noDataFound = false;
  hideFilter: boolean;
  showFilteredReports: boolean;
  btnLoading = false;
  totalDisatnce: number = 0;

  constructor(
    private loaderService: LoaderService,
    private userService: UserService,
    private haltService: HaltService,
    private patrolService: PatrolService,
    private snackBarService: SnackBarService) {
    this.filterFromDate = new Date();
    this.filterFromDate.setMonth(this.filterFromDate.getMonth() - 1);
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      // console.log(DistanceTrackerComponent.name, 'current user', this.currentUser);
    });
  }

  onShowFilter() {
    this.hideFilter = !this.hideFilter;
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    this.filterFromDate = new Date(date);
  }

  dateChange(event) {
    // console.log(event, 'event');
    this.travelDistanceDisplayList = [];
    this.hideFilter = false;
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    this.filterToDateRawValue = date;
    this.filterToDate = new Date(date);
  }

  onFilter() {
    const formDate = new Date(this.filterFromDate).setHours(0, 0, 0, 0);
    const toDate = new Date(this.filterToDate).setHours(23, 59, 59, 59);

    // console.log(DistanceTrackerComponent.name, 'from: ', new Date(formDate), ' to: ', new Date(toDate));
    this.hideFilter = !this.hideFilter;
    this.btnLoading = true;
    this.showFilteredReports = !this.showFilteredReports;
    this.loaderService.presentLoading('Loading distance covered logs');
    this.getTravelHistory(this.currentUser.clientId, new Date(formDate), new Date(toDate));
  }


  getTravelHistory(clientId, fromDate, toDate) {
    this.userService.getTravelHistoryOfAllEmployees(clientId, fromDate, toDate).subscribe((data) => {
      // console.log(data, 'getTravelHistory');
      this.btnLoading = false;
      this.loaderService.dismissLoading();
      if (data && data.length > 0) {
        this.travelDistanceList = data;
        this.travelDistanceDisplayList = this.travelDistanceList;
        // console.log(DistanceTrackerComponent.name, 'travelDistanceList', this.travelDistanceList);
        this.getEmployeesOfClient(clientId);
        const totalResult = this.travelDistanceDisplayList.reduce((sum, { distanceTravelled }) => sum + +(distanceTravelled), 0);
        this.totalDisatnce = totalResult;
        this.noDataFound = false;
      } else if (data.length <= 0) {
        this.noDataFound = true;
      }
    });
  }

  getEmployeesOfClient(clientId: string) {
    this.userService.getEmployeesOfClient(clientId).subscribe(employees => {
      this.employees = employees;
      // console.log(DistanceTrackerComponent.name, 'employees', this.employees);
    });
  }

  showFilteredResults() {
    this.showFilteredReports = !this.showFilteredReports;
    this.hideFilter = !this.hideFilter;
  }

  onChange(searchString) {
    const lowercasedValue = searchString.toLowerCase().trim();
    const filteredEmployees = this.employees.filter((item) => item.name.toString().toLowerCase().includes(lowercasedValue));
    // console.log(filteredEmployees, 'filteredEmployees');
    const filtred = this.travelDistanceList.filter((distance) => {
      return filteredEmployees.find((employee) => employee.id === distance.employeeId) ? true : false;
    });
    this.travelDistanceDisplayList = filtred;
    // console.log(filtred, 'filtred');
    const totalResult = filtred.reduce((sum, { distanceTravelled }) => sum + +(distanceTravelled), 0);
    this.totalDisatnce = totalResult;
    if (this.travelDistanceDisplayList.length <= 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

  getEmployeeName(id: string) {
    const employee = this.employees.find((item => item.id === id));
    return employee ? employee.name : 'unknown';
  }

  getEventType(id: string) {
    const eventType = this.employees.find((item => item.id === id));
    return eventType ? eventType.registrationType : 'unknown';
  }

  getDistanceCoveredinKms(distanceTravelled) {
    const distanceinMtrs = parseInt(distanceTravelled, 10);
    return (distanceinMtrs / 1000).toFixed(1) + ' ' + 'kms';
  }


}
