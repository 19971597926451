export class Event {
    clientId: string;
    createdByUser: string;
    createdByUserName: string;
    endTime?: any;
    files: string[];
    id?: string;
    images: string[];
    startLocation?: any;
    startLocationAccuracy: number;
    endLocation?: any;
    endLocationAccuracy: number;
    startTime?: any;
    templateId: string;
    templateItemId: string;
    type: string;
    data: EventInfo;
    description: string[];
    constructor() {
        this.startTime = null;
        this.endTime = null;
        this.startLocation = null;
        this.endLocation = null;
    }
}

export class EventInfo {
    contactNumber?: string;
    companyName?: string;
    customerName?: string;
    contactPersonName?: string;
    customerId?: string;
    followupDate?: string;
    notes?: string;
    errorPitstops?: string[];
    missedPitstops?: string[];
    id?: string;
    deviceId: string;
    startTimeDeviceId: string;
    endTimeDeviceId: string;
    totalDistanceCovered?: number;
    deviceAssesment: string;
    taskListId: any ;
    taskName: any;
    description?:string;
}
