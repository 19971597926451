import { Attendance } from './../../entity/attendance';
import { LoaderService } from 'src/app/services/loader.service';
import { HaltService } from 'src/app/services/halt.services';
import { FireDrillCallService } from 'src/app/services/fire-drill-call.service';
import { Event } from './../../entity/event';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { PopoverController, AlertController, NavController, ModalController } from '@ionic/angular';
import { AccountMenuComponent } from 'src/app/shared/account-menu/account-menu.component';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { OnBoardingModalComponent } from 'src/app/on-boarding-modal/on-boarding-modal.component';
import { getPreciseDistance, getPathLength } from 'geolib';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { firestore } from 'firebase';
import { ClientService } from 'src/app/services/client.service';
import { AppLocationService } from "src/app/services/app-location.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: 'app-halt-dashboard',
  templateUrl: './halt-dashboard.component.html',
  styleUrls: ['./halt-dashboard.component.scss'],
})

export class HaltDashboardComponent implements OnInit {
  tabLink = [];
  haltList = [];
  currentUser: User;
  filterDate: Date;
  filterFromDateRawValue: any;
  searchString: string;
  currentEmployeeAttendance: Attendance;
  yesterdaysTravelDistance: any;
  distanceInKms: any;
  fromDate: Date;
  toDate: Date;
  employees: User[] = [];
  employerName: string;
  disableBtn = false;
  activeHalt: any;
  showHistory = false;
  showRecentActivity = false;
  spentTime: any = 0;
  headerNav:boolean;
  totalDistanceCovered: any = 0;
  distanceRange = 0;
  client: any;
  filterTasks : any[];
  selectedTasks: any[];
  Task: any[];
  activeFireDrills: boolean = true;
  showDrill: boolean = false;
  fireDrillAlerts = [];
  constructor(
    public fireAuth: AngularFireAuth, private route: ActivatedRoute, private snackBarService: SnackBarService,
    public alertController: AlertController, private haltService: HaltService, public modalController: ModalController,
    private userService: UserService, public popoverController: PopoverController, private clientService: ClientService,
    private loaderService: LoaderService, private navCtrl: NavController, private router: Router,
    private fireDrillCallService: FireDrillCallService, private locationService: AppLocationService, private afs: AngularFirestore,
  ) {
    this.headerNav = true;
    this.filterDate = new Date();
    this.filterDate.setHours(0);
    this.filterDate.setMinutes(0);
    this.filterDate.setSeconds(0);
    this.filterFromDateRawValue = this.filterDate.toISOString();
  }

  ionViewDidEnter() {
    this.currentEmployeeAttendance = this.userService.getEmployeeAttendance();
    //console.log('ionViewDidEnter currentEmployeeAttendance', this.currentEmployeeAttendance);
    if (this.currentEmployeeAttendance) {
      this.calcLoginTime();
    }
  }

  ngOnInit() {
    this.onBoarding();
    // this.snackBarService.showFireDrillToaster("Fire Drill Alert", null, null, null);
    this.getCurrentUser();
    this.userService.listenToAttendanceChange().subscribe((attendance) => {
      if (attendance) {
        this.currentEmployeeAttendance = attendance;
        this.calcLoginTime();
        //console.log('ngonit currentEmployeeAttendance', this.currentEmployeeAttendance);
      }
    });
    this.haltService.listenToActiveHaltChange().subscribe((data) => {
      console.log(HaltDashboardComponent.name, 'current Active Halt', data);
      this.activeHalt = data;
      this.getHaltList(this.currentUser.id, this.currentUser.clientId);
    });
    // if(localStorage.getItem("responseStatus") === "pending") {
    //   this.showDrill = true;
    //   console.log("key exists ");
    //   this.locationService.getCurrentLocation().then((loc) => {
    //     console.log(" alert locationCoords ", loc);
    //     this.snackBarService.showFireDrillToaster("Fire Drill Alert", loc, localStorage.getItem("fireDrillDocId"), localStorage.getItem("responseDocId"));
    //   });
    // }
  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.navCtrl.navigateRoot(['/home']);
    }
  }

  async onBoarding() {
    // console.log(HaltDashboardComponent.name, 'local storage', localStorage.getItem('onHaltBoarding'));
    if (localStorage.getItem('onHaltBoarding') === 'shown') {
      return;
    }
    const modal = await this.modalController.create({
      component: OnBoardingModalComponent,
      componentProps: {
        type: 'halt',
      }
    });
    localStorage.setItem('onHaltBoarding', 'shown');
    return await modal.present();
  }

  async getCurrentUser() {
    const subEndDateValue = sessionStorage.getItem("subscription");
    console.log("subscription value from session storage", subEndDateValue);
    this.subEndDateCheck(subEndDateValue);
    const user = await this.userService.getCurrentUser().toPromise();
    if (user) {
      this.currentUser = user;
      await this.getHaltList(user.id, user.clientId);
      this.checkActiveHalt();
      this.getLocationTravelData(this.currentUser.clientId, this.currentUser.id);
      this.getEmployerName(this.currentUser.clientId);
      this.getCurrentClientDetails();
      // if(this.showDrill === false) {
        console.log("active drill check call start");
        this.activeDrillCallCheck();
    }
  }

  async getCurrentClientDetails() {
    this.client = await this.clientService.getClientData(this.currentUser.clientId);
    //console.log("getCurrentClientDetails -> client", this.client);
  }

  checkActiveHalt() {
    //console.log('checking active halt', this.haltList);
    this.activeHalt = this.haltList.find((halt) => halt.endTime === null);
    if (this.activeHalt) {
      //console.log(HaltDashboardComponent.name, 'active halt', this.activeHalt);
      this.haltService.setActiveHalt(this.activeHalt);
      this.disableBtn = true;
    } else {
      this.disableBtn = false;
    }
  }

  navigateToIncidents() {
    this.navCtrl.navigateForward(['/incidents']);
  }

  async getHaltList(userId, clientId) {
    // console.log(HaltDashboardComponent.name, 'fetching halts for ', this.filterDate);
    const loader = await this.loaderService.newLoader("Loading halts list");
    loader.present();
    const eventsList = await this.haltService.getAllHaltBasedOnUserId(userId, clientId, this.filterDate).toPromise()
    //console.log(HaltDashboardComponent.name + "halts list ", eventsList);
    
    if (eventsList && eventsList.length > 0) {
      eventsList.map((item: Event, index) => {
        // console.log(HaltDashboardComponent.name, item, 'item');
        if (item.endTime !== null && item.endTime !== undefined) {
          item['endTime'] = new Date((item.endTime).seconds * 1000);
        }
        if (item.startTime !== null && item.startTime !== undefined) {
          item['startTime'] = new Date((item.startTime).seconds * 1000);
        }
        this.haltList = eventsList.filter((event) => event.id && event.id !== '');
        this.getTotalDistanceCovered(this.haltList);
      });
      // console.log(HaltDashboardComponent.name, this.haltList, 'haltlist');
    } else {
      this.haltList = [];
      this.disableBtn = false;
    }
    loader.dismiss();
  }

  getHalt(halt: Event) {
    // console.log(HaltDashboardComponent.name, halt, 'halt');
    this.navCtrl.navigateForward(['/halt/create-halt'], {
      queryParams: {
        haltId: halt.id,
      }
    });
  }

  logout() {
    this.userService.logout().then(() => {
      this.navCtrl.navigateBack(['home']);
    });
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: AccountMenuComponent,
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then(res => {
      if (res.data === 'logout') {
        this.presentAlert('Are you sure that you want to logout?');
        // this.userService.logout().then(() => {
        //   this.navCtrl.navigate(['home']);
        // });
      }
    });
    return await popover.present();
  }

  async onFilterByDate() {
    await this.getHaltList(this.currentUser.id, this.currentUser.clientId);
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    // console.log(HaltDashboardComponent.name, 'from date val : ', new Date(date));
    this.filterDate = new Date(date);
    // console.log(HaltDashboardComponent.name, 'setting from date', this.filterDate, date);
  }


  createAttendance() {
    this.navCtrl.navigateForward(['attendance']);
  }

  routeToTravelHistory() {
    this.router.navigate(['travel-history']);
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.logout();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }


  onCreateHalt() {
    if (this.haltList.find((item) => item.endTime === null)) {
      this.presentAlertForEndHalt('Kindly end the currently running HALT to proceed');
    } else {
      this.navCtrl.navigateForward(['/halt/create-halt'], { queryParams: { newHalt: 'true', id: null } });
    }
  }
  
  onUpdateHalt(event: any, halt: Event) {
    // console.log('onUpdateHalt halt', halt);
    event.stopPropagation();
    this.navCtrl.navigateForward(['/halt/create-halt'], { queryParams: { updateHalt: 'true', id: halt.id } });
  }

  // onVisitHalt(event:any, halt: Event) {
  //   event.stopPropagation();
  //   this.navCtrl.navigateForward(['halt/create-halt'], { queryParams: { visitedHalt: 'true', id: halt.id } });
  // }

  onEndHalt(event: any, halt: Event) {
    // console.log('onEndHalt halt', halt);
    event.stopPropagation();
    // console.log(halt.id);
    this.haltService.updateHalt(halt.id, halt.data);
    this.navCtrl.navigateForward(['/halt/create-halt'], { queryParams: { endHalt: 'true', id: halt.id } });
  }

  async presentAlertForEndHalt(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            alert.dismiss();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  getLocationTravelData(clientId, employeeId) {
    this.fromDate = new Date();
    this.fromDate.setDate(this.fromDate.getDate() - 1);
    this.fromDate.setHours(0, 0, 0, 0);
    // console.log(HaltDashboardComponent.name, 'fromDate', this.fromDate);
    this.fromDate.toISOString();
    this.toDate = new Date();
    this.toDate.setHours(0, 0, 0, 0);
    // this.toDate.setDate(this.toDate.getDate() );
    // console.log(HaltDashboardComponent.name, 'toDate', this.toDate);
    this.toDate.toISOString();
    this.userService.getTravelHistoryOfEmployeeById(clientId, employeeId, this.fromDate, this.toDate).subscribe((data) => {
      // console.log(HaltDashboardComponent.name, 'travel history', data);
      if (data && data.length > 0) {
        this.yesterdaysTravelDistance = data[0].distanceTravelled;
        this.getDistanceCoveredinKms(this.yesterdaysTravelDistance);
      }
    });
  }

  getDistanceCoveredinKms(distanceTravelled) {
    this.distanceInKms = parseInt(distanceTravelled, 10);
    this.distanceInKms = (this.distanceInKms / 1000).toFixed(1) + ' ' + 'kms';
  }

  getEmployerName(clientId: string) {
    this.userService.getClientDataBasedOnClientId(clientId).subscribe((employer) => {
      this.employerName = employer[0].companyName;
    });
  }

  async confirmEndHalt(event: any, haltId) {
     // console.log('halt ID', haltId);
    event.stopPropagation();
    const alert = await this.alertController.create({
      header: 'Alert',
      message: 'Are you sure that you want to end current Halt',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.onAbortHalt(haltId);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  onAbortHalt(haltId: string) {
     // console.log('halt ID', haltId);
    this.disableBtn = true;
    this.haltService.deleteHalt(haltId);
    this.snackBarService.showToaster('Halt has been force ended');
  }

  toggleContainer(event: any) {
    event.stopPropagation();
    this.showRecentActivity = !this.showRecentActivity;
    if (this.showRecentActivity === true) {
      this.hideNavBar();
    } else {
      this.showNavBar();
    }
  }


  showNavBar() {
    this.headerNav = true;
  }
  hideNavBar() {
    this.headerNav = false;
  }

  calcLoginTime() {
    const currentTime = new Date();
    if (this.currentEmployeeAttendance) {
      const diffTime = Math.abs(currentTime.getTime() - this.currentEmployeeAttendance.startTime.toMillis());
      this.spentTime = this.validateDuePunchOut(diffTime);
      if (this.spentTime > 23) {
        this.currentEmployeeAttendance.endTime = firestore.FieldValue.serverTimestamp();
        // revised code
        this.userService.updateAttendance(this.currentEmployeeAttendance.id, this.currentEmployeeAttendance).then(() => {
          this.userService.setEmployeeAttendance(null);
          // this.haltService.deleteHalt(this.activeHalt.id).then(() => {
            // console.log('delete halt current user', this.currentUser);
            // this.getHaltList(this.currentUser.id, this.currentUser.clientId);
          // });
        });
      }
    } else {
      this.spentTime = 0;
    }
  }


  validateDuePunchOut(mins) {
    if (mins <= 0) {
      return 'Incomplete';
    }
    mins = mins / 60000;
    const num = mins;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    return rhours;
  }


  getTimeinHHMMFormat(mins) {
    if (mins <= 0) {
      return 'Incomplete';
    }
    mins = mins / 60000;
    const num = mins;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = Math.round(seconds);
    return rhours + ':' + rminutes + ':' + rseconds;
  }

  onDeletePopOver(event: any, halt: Event) {
    event.preventDefault();
    event.stopPropagation();
    //console.log('delete halt', halt);
    this.confirmDelete('Are you sure to delete this entry', halt);
  }

  deleteHalt(halt: Event) {
    //console.log('delete halt', halt);
    if (this.activeHalt) {
      if (this.activeHalt.id === halt.id) {
        this.activeHalt = null;
        this.haltService.setActiveHalt(null);
      }
    }
    this.haltService.deleteHalt(halt.id).then(() => {
      // console.log('delete halt current user', this.currentUser);
      this.getHaltList(this.currentUser.id, this.currentUser.clientId);
      this.snackBarService.showToaster('Visit has been deleted');
    });
  }

  async confirmDelete(data, halt) {
    const alert = await this.alertController.create({
      header: 'Alert',
      backdropDismiss: false,
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        },
        {
          text: 'Delete',
          handler: () => {
            this.deleteHalt(halt);
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  checkForLocationRange(startLocation, endLocation) {
    this.distanceRange = getPreciseDistance(
      { latitude: startLocation.latitude, longitude: startLocation.longitude },
      { latitude: endLocation.latitude, longitude: endLocation.longitude }
    );
    if (this.distanceRange && this.distanceRange > IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE) {
      return `End location appears to be ${this.distanceRange} mtrs away`;
    } else {
      return ''
    }
  }

  getTotalDistanceCovered(halts) {
    let locationArray = [];
    //console.log('Halts done', halts);
    halts.map(({ startLocation }) => {
      // console.log('startLocation', startLocation)
      locationArray.push({ latitude: startLocation.latitude, longitude: startLocation.longitude });
    });
    // console.log('locationArray', locationArray)
    if (locationArray.length > 0) {
      this.totalDistanceCovered = getPathLength(locationArray);
      // console.log('getPathLength -> res', this.totalDistanceCovered);
    }
  }

  LocationAccess() {
    this.navCtrl.navigateForward(['location-access']);
  }

  onTaskStatus(event:any, task){
    // console.log(event);
    // console.log('before check', task);
    task.status = event.detail.checked;
    // console.log('after check', task);
    
  }

  // navigateToFireDrillResponses() {
  //   this.navCtrl.navigateForward(['employee-alert'])
  // }

  async activeDrillCallCheck() {
    console.log("active drill call checks ");
    console.log("fire drill service", this.fireDrillCallService);
    this.fireDrillCallService.activeDrillCallCheck(this.currentUser.id).subscribe((drills) => {
      console.log("drills subscribe ", drills);
      this.fireDrillAlerts = drills;
      this.locationService.getCurrentLocationPromise().then((loc) => {
        console.log("alert locationCoords ", loc);
        console.log("fire drill alerts array ", this.fireDrillAlerts);
        if(this.fireDrillAlerts.length === 1) {
          console.log("only one active drill call raised ");
          if(this.fireDrillAlerts[0]) {
            // localStorage.setItem("responseStatus", "pending");
            // localStorage.setItem("fireDrillDocId", this.fireDrillAlerts[0].docId);
            // localStorage.setItem("responseDocId", this.fireDrillAlerts[0].id);
            this.afs.collection('fireAlerts').ref.where('clientId','==' , this.currentUser.clientId).get().then((listr) => {
              listr.forEach((documentSnapshot) => {
                const drillDocument = documentSnapshot.data();
                this.afs.collection('fireAlerts').doc(drillDocument.id).collection('responses').ref.where('id', '==', this.fireDrillAlerts[0].id).where('responded', '==', false).get().then((data) => {
                  // console.log("data ", data);
                  data.forEach((dat) => {
                    // console.log("dat ", dat.ref.path);
                    if(this.fireDrillCallService.toasterShown === false) {
                      this.snackBarService.showFireDrillToaster("Fire Drill Alert", loc, drillDocument.id, dat.data().id);  
                      this.fireDrillCallService.toasterShown = true;
                    }
                  })
                })
              })
            });
          }
        }
         if(this.fireDrillAlerts.length >1) {
            console.log("multiple drill calls raised ");
            this.presentMultipleDrillCallsAlert();
        }
      }).catch((err) => {
        console.error("ERROR ", err);
      });
    })
  }

  async presentMultipleDrillCallsAlert() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: "Multiple drill calls have been raised. Please respond!",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'Respond',
          handler: () => {
            this.navCtrl.navigateForward(['/employee-alert']);
          }
        }
      ],
      cssClass: 'p-3',
    });
    await alert.present();
  }

}
