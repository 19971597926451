import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Customer } from 'src/app/entity/clients';
import { CustomerAccess } from 'src/app/entity/customerAccess';
import { User } from 'src/app/entity/user';
import { ClientService } from 'src/app/services/client.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-customer-access',
  templateUrl: './customer-access.component.html',
  styleUrls: ['./customer-access.component.scss'],
})
export class CustomerAccessComponent implements OnInit {

  currentUser: User;
  customers: Customer[] = [];
  selectedCustomers: any = [];
  customerAccessForm: FormGroup;
  customerAccess: any;
  customerAccessList: CustomerAccess[] = [];
  phoneNumber: string;

  constructor(
    public fireAuth: AngularFireAuth,
    private formBuilder: FormBuilder, public modalController: ModalController, private alertController: AlertController, private clientService: ClientService,
    private router: Router, private snackBarService: SnackBarService, private userService: UserService, private loaderService: LoaderService) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }
  }

  ngOnInit() {
    this.initCustomerForm();
    this.getCurrentuser();
    this.getCustomers();
    this.getCustomerAccessList();
  }

  initCustomerForm() {
    this.customerAccessForm = this.formBuilder.group({
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      selectedCustomers: [null, Validators.required],
    });
  }

  getCurrentuser() {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  getCustomers() {
    this.loaderService.presentLoading('Loading customers list');
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.clientService.getCustomersOfClient(currentUser.clientId).subscribe(customers => {
        this.loaderService.dismissLoading();
        this.customers = customers;
        this.customers.sort((customerA: Customer, customerB: Customer) => {
          if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
          return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
        });
      });
    });
  }

  getCustomerAccessList() {
    this.loaderService.presentLoading('Loading customer access list');
    this.clientService.fetchCustomerAccessListByClientId(this.currentUser.clientId).subscribe(customers => {
      this.loaderService.dismissLoading();
      this.customerAccessList = customers;
      // console.log('customer access list', this.customerAccessList);
    });
  }

  onSelectCustomers(event) {
    // console.log('selected items', event.value);
    event.value.map((item) => {
      if (item !== null && item !== undefined && item !== '') {
        this.selectedCustomers.push(item.id);
      }
      // console.log('selectedCustomers', this.selectedCustomers);
    })
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onVerifyCustomer() {
    this.customerAccess = new CustomerAccess();
    this.customerAccess.addedDate = new Date();
    this.customerAccess.clientId = this.currentUser.clientId;
    this.customerAccess.name = this.customerAccessForm.value.name;
    this.customerAccess.phoneNumber = this.customerAccessForm.value.phoneNumber;
    this.customerAccess.email = this.customerAccessForm.value.email;
    this.customerAccess.sites = this.selectedCustomers;
    if (this.customerAccess.email) {
      this.userService.verifyIfUserExists(this.customerAccess.email).subscribe((existingCustomer) => {
        // console.log('Existing customer', existingCustomer);
        if (existingCustomer && existingCustomer.length > 0) {
          if (existingCustomer[0].clientId === this.currentUser.clientId) {
            this.updateCustomer(existingCustomer[0])
          } else {
            this.presentAlert('Email has already been registered with different client, Kindly use any other email id');
          }
        }
        this.addCustomer(this.customerAccess);
      })
    }
  }

  updateCustomer(existingCustomer) {
    const customerSites = [...existingCustomer.sites];
    this.customerAccess.sites.map((selectedCustomerId, index) => {
      // console.log('selectedCustomerId', selectedCustomerId, index);
      const siteId = existingCustomer.sites.find((id) => selectedCustomerId === id);
      // console.log('site id -> customerAccess.sites', siteId);
      if (!siteId) {
        // console.log('selectedCustomers -> before', customerSites);
        customerSites.push(selectedCustomerId);
        // console.log('selectedCustomers -> after', customerSites);
      }
    })
    this.clientService.updateInviteAccessToCustomer(existingCustomer.id, customerSites).then(() => {
      this.getCustomerAccessList();
    });
  }

  addCustomer(customer) {
    // console.log('customer', customer);
    this.userService.sendEmailVerificationToCustomer(customer.email, "ivisit123").subscribe((res) => {
      this.snackBarService.showToaster('Successfully created, Kindly inform customer to verify email id');
      // console.log('email verification', res);
      if (res.error) {
        this.snackBarService.showToaster('Error:' + res.error);
      } else {
        this.clientService.inviteAccessToCustomer(customer).then(() => {
          this.getCustomerAccessList();
        });
      }
    })

  }


  getCustomerLocationName(sites) {
    const locationName = sites.map((id) => {
      const res = this.customers.find(customer => customer.id === id);
      if (res) {
        return res.companyName;
      }
    })
    return locationName.join();
  }

  test(userEmail, password) {

  }
  handleError(error) {
    if (error.code === 'auth/popup-blocked') {
      window.alert('Your browser has disabled Popups. Please Try again');
    } else if (error.code === 'auth/popup-closed-by-user') {
      window.alert('Please reload and try again');
    } else {
      this.snackBarService.showToaster(error.message);
    }
    return error.message;
  }

  onDeletePopOver(id: any) {
    this.confirmDelete('Are you sure to delete this entry', id);
  }

  async confirmDelete(data, id) {
    const alert = await this.alertController.create({
      header: 'Alert',
      backdropDismiss: false,
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        },
        {
          text: 'Delete',
          handler: () => {
            this.clientService.deleteInviteAccessToCustomer(id).then(() => {
              this.getCustomerAccessList();
            });
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [{
        text: 'OK',
        handler: () => {
          alert.dismiss();
        }
      }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }


}
