export class Invite {
    id: string;
    employeeName: string;
    employeeType: string;
    contactNumber: string;
    createdDate: any;
    clientId: string;
    emailAddress: string;

    constructor() {
        this.emailAddress = null;
        this.employeeType = null;
        this.clientId = null;
        this.emailAddress = null;
    }
}