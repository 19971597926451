import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Customer } from 'src/app/entity/clients';
import { Event } from 'src/app/entity/event';
import { EventTemplate, PitStop } from 'src/app/entity/event.template';
import { User } from 'src/app/entity/user';
import { ClientService } from 'src/app/services/client.service';
import { EventService } from 'src/app/services/event.services';
import { ExcelService } from 'src/app/services/excel.service';
import { HaltService } from 'src/app/services/halt.services';
import { LoaderService } from 'src/app/services/loader.service';
import { MapService } from 'src/app/services/map-data';
import { PatrolService } from 'src/app/services/patrol.service';
import { UserService } from 'src/app/services/user.service';
import { HaltDetailsModalComponent } from 'src/app/shared/halt-details-modal/halt-details-modal.component';
import { SnackBarService } from './../../services/snackbar.service';
import { getDistance, getPreciseDistance } from 'geolib';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  currentUser: User;
  customers: Customer[] = [];
  patrolsOfCustomer: Event[] = [];
  reportType: string;
  selectedCustomerId: string;
  selectedEmployeeId: string;
  selectedPatrolIds = [];
  reportFilter: string;
  reportLevel: string;
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  halts: Event[] = [];
  groupedHalts: any;
  employeesList: User[] = [];
  patrols: Event[] = [];
  groupedPatrols: any;
  groupedPatrolsByEmployee: any;
  patrolTemplates: EventTemplate[] = [];
  pitstops: PitStop[] = [];
  hideFilter: boolean;
  hideReports = true;
  isLoading: boolean;
  showLoader: Subscription;
  PitstopDistanceErrorLog: Event[] = [];
  locationAccuracy: number;
  eventTemplateItemList: PitStop[] = [];
  isMobile: boolean;
  accessToHaltOnly = false;
  accessToPatrolOnly = false;
  pitstopss: Event[];
  pitstopsList: PitStop[];
  groupedPitstopsOfPatrolArray: any;
  pitstopsArray: Event[];
  formatedStartTime: Date;
  formatedEndTime: Date;
  patrolImagesArray: any[] = [];
  patrolNotesArray: any[] = [];
  pitstopsArrayList = [];
  distanceRange = 0;
  constructor(
    private clientService: ClientService, private haltService: HaltService, private userService: UserService,
    private mapService: MapService,
    private snackBarService: SnackBarService, private platform: Platform, private navCtrl: NavController,
    private patrolService: PatrolService, private eventService: EventService, private loaderService: LoaderService,
    public loadingController: LoadingController, public modalController: ModalController, private excelService: ExcelService) {
    this.filterFromDate = new Date();
    //this.filterFromDate.setMonth(this.filterFromDate.getMonth() - 1);
    this.filterFromDate.setDate(this.filterFromDate.getDate());
    console.log('current from date', this.filterFromDate);
    this.filterFromDate.setHours(this.filterFromDate.getHours() - 12);
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
    this.reportFilter = 'none';
    this.reportLevel = 'aggregate';
    this.isMobile = !this.platform.is('desktop');
    // console.log(this.isMobile, 'cordova');
  }

  ngOnInit() {
    this.getCustomersList();
    this.getEmployeesList();
    this.getPatrolTemplatesOfClient();
    this.getAllPitstopsOfClient();
    this.getCurrentSubscription();
  }

  getCurrentSubscription() {
    this.userService.getCurrentSubscrption().subscribe((subscription) => {
      if (subscription) {
        // console.log(subscription, 'currentSubscrption');
        if (subscription.type === 'halt' || subscription.type === 'custom') {
          this.accessToHaltOnly = true;
        }
        if (subscription.type === 'patrol' || subscription.type === 'custom') {
          this.accessToPatrolOnly = true;
        }
      }
    });
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    console.log('from date val : ', new Date(date));
    this.filterFromDateRawValue = date;
    this.filterFromDate = new Date(date);
    //console.log('setting from date', this.filterFromDate, date);
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    console.log('to date val : ', date);
    this.filterToDateRawValue = date;
    this.filterToDate = new Date(date);
    //console.log('setting to date', this.filterToDate, date);
  }

  getCustomersList() {
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.clientService.getCustomersOfClient(currentUser.clientId).subscribe(customers => {
        this.customers = customers;
        this.customers.sort((customerA: Customer, customerB: Customer) => {
          if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
          return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
        });
        console.log(ReportsComponent.name, 'getCustomersList() -> fetched customers', customers);
      });
    });
  }

  getPatrolsOfCustomer(clientId, customerId) {
    this.loaderService.presentLoading('Loading patrols list');
    this.eventService.getAllPatrolTemplatesOfCustomer(clientId, customerId).subscribe((patrol) => {
      this.loaderService.dismissLoading();
      this.patrolsOfCustomer = patrol;
    });
    //console.log('patrolsOfCustomer', this.patrolsOfCustomer);
  }

  getEmployeesList() {
    this.loaderService.presentLoading('Loading employees list');
    this.userService.getEmployeesOfClient(this.currentUser.clientId).subscribe(employees => {
      this.employeesList = employees;
      this.employeesList.sort((employeeA: User, employeeB: User) => {
        if (employeeA.name.toLowerCase() === employeeB.name.toLowerCase()) { return 0; }
        return (employeeA.name.toLowerCase() > employeeB.name.toLowerCase()) ? 1 : -1;
      });
      console.log(ReportsComponent.name, 'getEmployeesList() -> fetched employeesList', employees);
      // this.loaderService.dismissLoading();
    });
  }

  onShowFilter() {
    this.hideFilter = !this.hideFilter;
    if (!this.hideFilter) {
      this.groupedHalts = [];
      this.groupedPatrols = [];
      this.halts = [];
      this.groupedPatrolsByEmployee = [];
      this.selectedCustomerId = null;
      this.selectedEmployeeId = null;
      this.selectedPatrolIds = [];
      this.reportFilter = null;
      this.reportLevel = null;
    }
  }

  onClickFilter() {
    console.log(ReportsComponent.name, 'onClickFilter() -> ');
    console.log(ReportsComponent.name, 'onClickFilter() -> filterFromDate: ', this.filterFromDate);
    console.log(ReportsComponent.name, 'onClickFilter() -> filterToDate: ', this.filterToDate);
    console.log(ReportsComponent.name, 'onClickFilter() -> reportType: ', this.reportType);
    console.log(ReportsComponent.name, 'onClickFilter() -> reportLevel: ', this.reportLevel);
    if (this.reportType) {
      if (this.reportType === 'halts') {
        // this.hideFilter = !this.hideFilter;
        this.getHaltsByFilter();
      } else if (this.reportType === 'patrols') {
        if (this.reportLevel) {
          this.getPatrolsByFilter();
        } else {
          this.snackBarService.showToaster('Please Select The Report Level');
        }
      } else {
        this.snackBarService.showToaster('No records found');
      }
    } else {
      this.snackBarService.showToaster('Please Select The Report Type');
    }
  }

  resetDependentFields(changedField: string) {
    //console.log('resetting dependent fields of ', changedField);
    if (changedField === 'reportType') {
      this.selectedCustomerId = null;
      this.selectedEmployeeId = null;
      this.selectedPatrolIds = [];
      this.reportFilter = null;
      this.reportLevel = null;
    } else if (changedField === 'reportFilter') {
      this.reportLevel = null;
      this.selectedCustomerId = null;
      this.selectedEmployeeId = null;
      this.selectedPatrolIds = [];
    }
  }

  onSelectCustomer(customer: {
    component: IonicSelectableComponent,
    value: any
  }) {
    //console.log('selected customer', customer);
    this.selectedCustomerId = customer.value.id;
    this.getPatrolsOfCustomer(this.currentUser.clientId, this.selectedCustomerId);
  }

  onSelectPatrol(patrols: {
    component: IonicSelectableComponent,
    value: any
  }) {
    //console.log('selected patrols', patrols);
    patrols.value.map((patrol) => {
      //console.log('selected patrol', patrol);
      this.selectedPatrolIds.push('' + patrol.id);
    })
  }

  onSelectEmployee(employee: {
    component: IonicSelectableComponent,
    value: any
  }) {
    // console.log('selected employee', employee);
    this.selectedEmployeeId = employee.value.id;
  }

  getHaltsByFilter() {
    this.loaderService.presentLoading('Loading halts list');
    // console.log('fetching halts by ', this.selectedCustomerId, this.selectedEmployeeId);
    this.filterFromDate.setHours(0, 0, 0, 0);
    this.filterToDate.setHours(23, 59, 59, 0);
    this.haltService.getHaltsOfClientByFilter(this.currentUser.clientId, this.filterFromDate, this.filterToDate,
      this.selectedCustomerId, this.selectedEmployeeId).subscribe(halts => {
        this.hideFilter = true;
        this.loaderService.dismissLoading();
        this.halts = halts;
        // console.log('fetched halts', halts);
        this.groupedHalts = this.groupEventsByCustomer(halts);
        // console.log('getHaltsByFilter() : grouped halts', this.groupedHalts);
      });
  }

  get groupedHaltsKeys() {
    if (!this.groupedHalts) { return []; }
    return Object.keys(this.groupedHalts);
  }

  get groupedPatrolsKeys() {
    if (!this.groupedPatrols) { return []; }
    const res = Object.keys(this.groupedPatrols);
    console.log('groupedPatrolsKeys -> res', res);
    return res;

  }

  get groupedPatrolKeysOfEmployee() {
    //console.log('groupedPatrolKeysOfEmployee', this.groupedPatrolsByEmployee);
    if (!this.groupedPatrolsByEmployee) { return []; }
    const res = Object.keys(this.groupedPatrolsByEmployee);
    //console.log('groupedPatrolKeysOfEmployee -> res', res);
    return res;

  }

  getCustomerById(customerId: string): Customer {
    return this.customers.find(customer => customer.id === customerId);
  }

  getPitstopsOfPatrol(patrol) {
    this.patrolService.getPitStopsForPatrol(patrol.templateId, patrol.startTime, patrol.endTime, patrol.createdByUser)
      .subscribe((pitstopsList) => {
        this.pitstopss = pitstopsList;
        //console.log('filteredPatrolOfCustomer-> pitstoplist', pitstopsList);
        console.log(ReportsComponent.name, 'getPitstopsOfPatrol() -> filteredPatrolOfCustomer-> pitstoplist', pitstopsList);
        pitstopsList.map((pitstop) => {
          if (!this.patrolImagesArray[patrol.id]) {
            this.patrolImagesArray[patrol.id] = [];
          }
          if (pitstop.images && pitstop.images.length) {
            this.patrolImagesArray[patrol.id].push(...pitstop.images);
            console.log('ReportsComponent.name, filteredPatrolOfCustomer-> patrol array images', this.patrolImagesArray);
          }
          if (!this.patrolNotesArray[patrol.id]) {
            this.patrolNotesArray[patrol.id] = [];
          }
          if (pitstop.data.notes && pitstop.data.notes.length) {
            this.patrolNotesArray[patrol.id].push(...pitstop.data.notes);
            //console.log('filteredPatrolOfCustomer-> patrol array notes', this.patrolNotesArray);
          }
        });
      });
  }

  getPatrolsByFilter() {
    // console.log(ReportsComponent.name,'getPatrolsByFilter() ->');
    this.loaderService.presentLoading('Loading patrols list');
    //this.filterFromDate.setHours(0, 0, 0, 0);
    //this.filterToDate.setHours(23, 59, 59, 0);
    // tslint:disable-next-line: max-line-length
    // console.log(ReportsComponent.name,'getPatrolsByFilter() -> fetching patrols this.currentUser.clientId', this.currentUser.clientId);
    // console.log(ReportsComponent.name,'getPatrolsByFilter() -> fetching patrols this.selectedCustomerId', this.selectedCustomerId);
    // console.log(ReportsComponent.name,'getPatrolsByFilter() -> fetching patrols this.selectedEmployeeId', this.selectedEmployeeId);
    // console.log(ReportsComponent.name,'getPatrolsByFilter() -> fetching patrols this.filterFromDate', this.filterFromDate);
    // console.log(ReportsComponent.name,'getPatrolsByFilter() -> fetching patrols this.filterToDate', this.filterToDate);
    // tslint:disable-next-line: max-line-length
    this.patrolService.getPatrolsOfClientByFilter(this.currentUser.clientId, this.selectedCustomerId, this.selectedEmployeeId, this.filterFromDate, this.filterToDate)
      .subscribe(patrols => {
        this.loaderService.dismissLoading();
        this.hideFilter = true;
        console.log(ReportsComponent.name, 'All patrols of client', patrols);
        this.patrols = patrols;
        const filteredPatrolsOfCustomer = [];
        patrols.map((patrol) => {
          //console.log(ReportsComponent.name,'getPatrolsByFilter() -> patrol', patrol);
          //console.log(ReportsComponent.name,'getPatrolsByFilter() -> selectedPatrolIds', this.selectedPatrolIds);
          if (this.selectedPatrolIds.length > 0) {
            this.selectedPatrolIds.map((selectedPatrolId) => {
              if (selectedPatrolId === patrol.templateId) {
                filteredPatrolsOfCustomer.push(patrol);
                //console.log(ReportsComponent.name,'getPatrolsByFilter() -> filteredPatrols', filteredPatrolsOfCustomer);
                this.getPitstopsOfPatrol(patrol);
              }
            })
          } else {
            filteredPatrolsOfCustomer.push(patrol);
            this.getPitstopsOfPatrol(patrol);
          }
        });

        this.groupedPatrols = this.groupEventsByCustomer(filteredPatrolsOfCustomer);
        this.groupedPatrolsByEmployee = this.groupEventsByEmployee(filteredPatrolsOfCustomer);
        console.log(ReportsComponent.name, 'grouped patrols', this.groupedPatrols);
        console.log(ReportsComponent.name, 'groupedPatrolsByEmployee', this.groupedPatrolsByEmployee);
        this.loaderService.dismissLoading();
      });
  }

  get groupedPatrolKeys() {
    if (!this.groupedPitstopsOfPatrolArray) { return []; }
    return Object.keys(this.groupedPitstopsOfPatrolArray);
  }

  getPitstopsList(patrolId: string) {
    this.eventService.getPitstopsOfPatrol(patrolId).subscribe(pitstops => {
      this.pitstopsList = pitstops;
      this.pitstopsList = this.pitstopsList.filter(pitstop => pitstop.isActive);
      console.log(ReportsComponent.name, 'pitstopsList', this.pitstopsList);
    });
  }

  groupEventsByCustomer(halts: Event[]) {
    // console.log('halts', halts);
    return halts.reduce((values, halt) => {
      this.haltService.getHalt(halt.id).subscribe(doc => {
        console.log( 'taskListId Tasks' , doc.data.taskListId);
        if(doc.data.taskListId){
          const totalTasks = doc.data.taskListId.Tasks.length;
          let doneTasks = 0, pendingTasks = 0;
          doc.data.taskListId.Tasks.forEach((ele) => ele.status === true ? doneTasks++ : pendingTasks++);
          // console.log('doneTasks and pendingTasks' , doneTasks, pendingTasks);
          return Object.assign(halt,  { 'taskCount' : { 'total': totalTasks , 'done': doneTasks, 'pending' : pendingTasks }}); 
        }
        else {
          return halt;
        }});
      halt.data['customerName'] ?
        (values[halt.data['customerName']] = values[halt.data['customerName']] || []).push(halt) :
        (values[halt.data['companyName']] = values[halt.data['companyName']] || []).push(halt);
      return values;
    }, {});
  }

  groupEventsByEmployee(patrols: Event[]) {
    //console.log('groupEventsByEmployee -> patrols', patrols);
    return patrols.reduce((values, patrol) => {
      //console.log('groupEventsByEmployee -> patrol', patrol);
      if (patrol['createdByUserName']) {
        (values[patrol['createdByUserName']] = values[patrol['createdByUserName']] || []).push(patrol)
        return values;
      }

    }, {});
  }

  getIncompletePatrolsOfCustomer(patrolList: Event[]): Event[] {
    return patrolList.filter(patrol => patrol.data['missedPitstops'] ? patrol.data['missedPitstops'].length > 0 : false);
  }

  getTotalDistanceCovered(patrol: Event[]) {
    console.log('getTotalDistanceCovered - > patrol', patrol);
    return 0;
    // return patrolList.filter(patrol => patrol.data['totalDistanceCovered'] ? patrol.data['totalDistanceCovered'] : '0');
  }

  getErrorDetailsOfPitstops(patrolList: Event[]): Event[] {
    return patrolList.filter(patrol => patrol.data['errorPitstops'] ? patrol.data['errorPitstops'].length > 0 : false);
  }

  getPatrolTemplatesOfClient() {
    this.eventService.getAllPatrolTemplatesOfClient(this.currentUser.clientId)
      .subscribe((templates) => {
        this.patrolTemplates = templates
        console.log(ReportsComponent.name, 'getPatrolTemplatesOfClient() -> fetched templates of clients', templates);
      });
  }

  getAllPitstopsOfClient() {
    this.patrolService.getAllPitstopsOfClient(this.currentUser.clientId).subscribe((pitstops) => {
      this.pitstops = pitstops;
      console.log(ReportsComponent.name, 'getAllPitstopsOfClient() -> pitstops', pitstops);
    });
  }

  getPatrolName(patrolTemplateId: string): string {
    const patrolTemplate = this.patrolTemplates.find(template => template.id === patrolTemplateId);
    return patrolTemplate ? patrolTemplate.name : 'Unknown';
  }

  getPitstopName(pitstopTemplateId: string): string {
    // //console.log('fetching pitstop name ', pitstopTemplateId, this.pitstops);
    const pitstop = this.pitstops.find(pit => pit.id === pitstopTemplateId);
    // //console.log('fetching pitstop ', pitstopTemplateId, pitstop);
    return pitstop ? pitstop.name : 'Unknown';
  }

  onViewDetails(reportType) {
    if (reportType.type === 'patrol') {
      const patrolTemplate = this.patrolTemplates.find(template => template.id === reportType.templateId);
      this.showPatrolDetails(reportType, patrolTemplate);
    } else if (reportType.type === 'halt') {
      this.showHaltDetails(reportType);
    }
  }

  groupEventsByCustomerForReports(halts: Event[]) {
    return halts.reduce((values, halt) => {
      (values[halt['customerName']] = values[halt['customerName']] || []).push(halt);
      return values;
    }, {});
  }

  groupEventsByDateForReports(halts: Event[]) {
    return halts.reduce((values, halt) => {
      (values[halt['startTime']] = values[halt['startTime']] || []).push(halt);
      return values;
    }, {});
  }

  exportToExcel() {
    this.hideFilter = false;
    const reportsArray = [];
    let duration: any = '0';
    if (this.reportType === 'halts') {
      let haltsArray = [...this.halts];
      console.log(haltsArray, 'haltsArray');
      haltsArray = haltsArray.sort((haltA: Event, haltB: Event) => {
        if (haltA.startTime === haltB.startTime) { return 0; }
        return (haltA.startTime > haltB.startTime) ? 1 : -1;
      });
      haltsArray.map((halt) => {
        // console.log(item.images, 'images');
        duration = '0';
        let locationMismatch;
        locationMismatch = this.verifyLocationMisMatch(halt);
        // let deviceAssesment;
        // deviceAssesment = this.verifyDeviceMisMatch(halt);
        if (halt.endTime !== null && halt.endTime !== undefined) {
          halt['endTime'] = new Date((halt.endTime).seconds * 1000);
          this.formatedEndTime = halt['endTime'].toLocaleString('en-GB', { timeZone: 'IST' });
        }
        if (halt.startTime !== null && halt.startTime !== undefined) {
          halt['startTime'] = new Date((halt.startTime).seconds * 1000);
          this.formatedStartTime = halt['startTime'].toLocaleString('en-GB', { timeZone: 'IST' });
        }

        if (halt.endTime !== null && halt.endTime !== undefined && halt.startTime !== null && halt.startTime !== undefined) {
          duration = this.getTimeinHHMMFormat(Math.abs(halt.endTime - halt.startTime) / 60000);
        }

        console.log("duration ", duration);
        return reportsArray.push({
          customerName: halt.data.customerName || halt.data.companyName,
          createdBy: halt.createdByUserName,
          startTime: this.formatedStartTime,
          endTime: this.formatedEndTime,
          duration: duration || 0,
          TotalHalts: this.groupedHalts[halt.data.customerName] ?
            this.groupedHalts[halt.data.customerName].length : this.groupedHalts[halt.data.companyName].length,
          mapUrl: halt.endLocation ? this.getMapUrl(halt.endLocation.latitude, halt.endLocation.longitude) :
            this.getMapUrl(halt.startLocation.latitude, halt.startLocation.longitude),
          imageUrl: halt.images ? halt.images.toString() : 'No Images',
          locationMismatch: locationMismatch,
          deviceAssesment: halt.data.deviceAssesment,
          notes: halt.data.notes
        });
      });
      // const groupedHalts = this.groupEventsByDateForReports(reportsArray);
      this.excelService.exportDateWiseHaltReportAsExcelFile(reportsArray, this.reportType, this.reportType, this.isMobile);
      this.pitstopsArrayList = [];
      // console.log(reportsArray, 'reportsArray');
    }

    if (this.reportType === 'patrols') {
      const patrolArray = [...this.patrols];
      console.log('patrol array', patrolArray);
      patrolArray.map((item) => {
        duration = '0';
        console.log(item, 'item');
        if (item.endTime !== null && item.endTime !== undefined) {
          item['endTime'] = new Date((item.endTime).seconds * 1000);
        }
        if (item.startTime !== null && item.startTime !== undefined) {
          item['startTime'] = new Date((item.startTime).seconds * 1000);
        }
        if (item.endTime !== null && item.endTime !== undefined && item.startTime !== null && item.startTime !== undefined) {
          duration = this.getTimeinHHMMFormat(Math.abs(item.endTime - item.startTime) / 60000);
        }
        console.log("duration : ", duration);
        
        reportsArray.push({
          patrolName: this.getPatrolName(item.templateId),
          createdBy: item.createdByUserName,
          startTime: item.startTime,
          endTime: item.endTime,
          duration: duration || 0,
          TotalPatrols: item.data.customerName !== undefined ? (this.groupedPatrols[item.data.customerName].length || 0) : 0,
          ErrorPitStops: item.data.errorPitstops ? this.getErroPiststop(item.data.errorPitstops) : '',
          MissedPitstops: item.data.missedPitstops ? this.getErroPiststop(item.data.missedPitstops) : '',
          customerName: item.data.customerName,
          totalDistanceCovered: item.data.totalDistanceCovered,
          mapUrl: item.endLocation ? this.getMapUrl(item.endLocation.latitude, item.endLocation.longitude) :
            this.getMapUrl(item.startLocation.latitude, item.startLocation.longitude),
          imageUrl: (this.patrolImagesArray[item.id] ? (this.patrolImagesArray[item.id]).toString() : 'No Images'),
          //notes: (this.patrolNotesArray[item.id] ? (this.patrolNotesArray[item.id]).join("") : 'No Notes'),
        });

      });
      const groupedPatrols = this.groupEventsByCustomerForReports(reportsArray);
      this.excelService.exportAsExcelFile(groupedPatrols, this.reportType, this.reportType, this.isMobile);
      this.pitstopsArrayList = [];
    }

    // const groupedHalts = this.groupEventsByCustomerForReports(reportsArray);
    // this.excelService.exportAsExcelFile(groupedHalts, this.reportType, this.reportType, this.isMobile);
    // this.pitstopsArrayList = [];
  }

  getImagesList(createdByUser) {
    // console.log('createdByUser', createdByUser);
    let currentImages = [];
    const imagesArrray = this.pitstopsArrayList.filter((patrol) => patrol.createdByUser === createdByUser);
    if (imagesArrray) {
      imagesArrray.map((item) => {
        if (item.images && item.images.length) {
          // console.log('item.images', item.images);
          currentImages = [...item.images, ...currentImages];
        }
      });
      return currentImages.toString();
    }
  }

  getErroPiststop(errorPitstops: string[]): string {
    let errorList = '';
    errorPitstops.map((templateId) => {
      errorList = errorList + this.getPitstopName(templateId) + ', ';
    });
    return errorList.substr(0, errorList.length - 2);
  }

  getPitstopNotes(pitstopId) {
    console.log('pitstopss', this.pitstopss);
    if (this.pitstopss) {
      console.log('pitstops', this.pitstopss);
      const pitStop = this.pitstopss.find((item) => item.templateItemId === pitstopId);
      console.log('pitstop notes', pitStop);
      if (pitStop) {
        return pitStop ? pitStop.data.notes : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  async showPatrolDetails(patrol: Event, template: EventTemplate) {
    this.mapService.setPatrol(patrol);
    this.mapService.setPatrolTemplate(template);
    this.navCtrl.navigateRoot(['/employer/view-patrol-details']);
  }

  async showHaltDetails(halt) {
    const modal = await this.modalController.create({
      component: HaltDetailsModalComponent,
      componentProps: {
        halt,
      }
    });
    return await modal.present();
  }

  getTimeinHHMMFormat(mins) {
    console.log("mins : ", mins);
    if (mins >= 0) {
      const num = mins;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.floor(minutes);
      const seconds = (minutes - rminutes) * 60;
      const rseconds = Math.round(seconds);
      return rhours + ' ' + 'h' + ' ' + ':' + ' ' + rminutes + ' ' + 'm' + ' ' + ':' + ' ' + rseconds + ' ' + 's';
    } else {
        return 0 + ' ' + 'h' + ' ' + ':' + ' ' + 0 + ' ' + 'm' + ' ' + ':' + ' ' + 0 + ' ' + 's';
    }
  }

  getMapUrl(lat, lag) {
    const mapUrl = `https://maps.google.com/?q=${lat},${lag}&amp;z=8`;
    return mapUrl;
  }

  displayImages(imagesList) {
    if (imagesList.length) {
      // //console.log('imageList');
      return imagesList.map((item, index) => {
        return `<a href="${item}"> Image ${index + 1}</a>`;
      });
    } else {
      return 'No Images';
    }
  }

  verifyLocationMisMatch(halt) {
    let customerLocation: any;
    const customer = this.getCustomerById(halt.data.customerId);
    if (customer) {
      if (customer.approx_location) {
        customerLocation = customer.approx_location;
      } else {
        customerLocation = customer.location;
      }
    }
    if (halt.endTime !== null && halt.endTime !== undefined && customerLocation !== null && customerLocation !== undefined) {
      this.distanceRange = getPreciseDistance(
        { latitude: halt.startLocation.latitude, longitude: halt.startLocation.longitude },
        { latitude: customerLocation.latitude, longitude: customerLocation.longitude }
      );
      if (this.distanceRange && this.distanceRange > IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE) {
        return `Start location appears to be ${this.distanceRange} mtrs away`;
      } else {
        this.distanceRange = getPreciseDistance(
          { latitude: halt.endLocation.latitude, longitude: halt.endLocation.longitude },
          { latitude: customerLocation.latitude, longitude: customerLocation.longitude }
        );
        if (this.distanceRange && this.distanceRange > IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE) {
          return `End location appears to be ${this.distanceRange} mtrs away`;
        }
      }
    }
  }

  // verifyDeviceMisMatch(halt) {
  //   let res;
  //   this.userService.getEmployeeAttendanceByDate(halt.clientId, halt.createdByUser, halt.startTime.toDate()).
  //     subscribe((attendance) => {
  //       if(attendance) {
  //         if(attendance[0].startTimeDeviceId === halt.data.deviceId) {
  //           res = 'Same device'
  //         } else {
  //           res = 'Device mismatch'
  //         }
  //       } return res;
  //     });
  // }

  calculateDistance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'M') {
        dist = dist * 0.8684;
      }
      // // console.log(PatrolDashboardComponent.name, 'dist', dist);
      return dist;
    }
  }
}