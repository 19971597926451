import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { SharedModule } from '../shared/shared.module';
import { CustomerRoutingModule } from './customer.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouteMapComponent } from './route-map/route-map.component';
import { ViewPatrolDetailsComponent } from './view-patrol-details/view-patrol-details.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    CustomerRoutingModule,
    IonicModule.forRoot(),
    IonicSelectableModule
  ],
  declarations: [
    DashboardComponent,
    ViewPatrolDetailsComponent,
    RouteMapComponent
  ]

})
export class CustomerModule {
  constructor() {
    //console.log('Customer module loaded.');
  }
}
