import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.services';
import { PitStop, EventTemplate } from 'src/app/entity/event.template';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { AlertController, NavController } from '@ionic/angular';
import { PatrolService } from 'src/app/services/patrol.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-pitstop-list',
  templateUrl: './pitstop-list.component.html',
  styleUrls: ['./pitstop-list.component.scss'],
})
export class PitstopListComponent implements OnInit {
  patrolId: string;
  pitstops: PitStop[];
  patrolTemplate: EventTemplate;
  currentUser: User;
  searchString: string;

  constructor(
    private alertController: AlertController, private route: ActivatedRoute, private router: NavController,
    private eventService: EventService, private snackBarService: SnackBarService, private clientService: ClientService,
    private loaderService: LoaderService, private patrolService: PatrolService, private userService: UserService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.patrolId = params['patrol'];
      // console.log(PitstopListComponent.name, 'showing pitstops for patrol ', this.patrolId);
      this.getPitstopsOfPatrol(this.patrolId);
      // this.fetchPatrolTemplatesOfClient();
      this.getPatrolById(this.patrolId);
    });
  }

  async getPatrolById(patrolId: string) {
    this.patrolTemplate = await this.patrolService.getEventTemplateById(patrolId);
  }

  async getPitstopsOfPatrol(patrolId: string) {
    this.loaderService.presentLoading('Loading pitstops list');
    const pitstops = await this.eventService.getPitstopsOfPatrol(patrolId).toPromise();
    this.loaderService.dismissLoading();
    this.pitstops = pitstops;
    this.pitstops.sort((pitstopA: PitStop, pitstopB: PitStop) => {
      if (pitstopA.name.toLowerCase() === pitstopB.name.toLowerCase()) { return 0; }
      return (pitstopA.name.toLowerCase() > pitstopB.name.toLowerCase()) ? 1 : -1;
    });
    console.log(PitstopListComponent.name, 'pitstops', this.pitstops);
    this.loaderService.dismissLoading();
  }

  onPatrolRemove(patrolId: string, isActivateState) {
    if (!isActivateState) {
      this.eventService.deactivatePatrol(patrolId);
    } else {
      this.eventService.activatePatrol(patrolId);
    }
  }



  onChangeState(pit, isActiveState) {
    if (!isActiveState) {
      this.presentAlert('Are you sure that you want to deactivate', pit, isActiveState);
    } else {
      this.presentAlert('Are you sure that you want to activate', pit, isActiveState);
    }
  }

  async onPitstopRemove(pitstop: PitStop, isActiveState) {
    if (!isActiveState) {
      await this.eventService.deactivatePitstopFromPatrol(this.patrolId, pitstop.id);
    } else {
      await this.eventService.activatePitstopFromPatrol(this.patrolId, pitstop.id);
    }
    await this.getPitstopsOfPatrol(this.patrolId);
  }

  async presentAlert(data, pit, isActiveState) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.onPitstopRemove(pit, isActiveState);
          }
        }
      ],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  editPitstop(event, pitstopId) {
    event.stopPropagation();
    this.router.navigateForward(['/employer/add-pitstop'], {
      queryParams: {
        pitstop: pitstopId,
        patrol: this.patrolId
      }
    });
  }
}
