import { SnackBarService } from './../../services/snackbar.service';
import { PatrolService } from 'src/app/services/patrol.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-custom-form-modal',
  templateUrl: './custom-form-modal.component.html',
  styleUrls: ['./custom-form-modal.component.scss'],
})
export class CustomFormModalComponent implements OnInit {
  @Input() pitStopId: string;
  notes: string;
  constructor(public modalController: ModalController, public patrolService: PatrolService,
              private snackbarService: SnackBarService) { }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  addNotes() {
    const pitStopNotes = this.notes;
    this.snackbarService.showToaster('Notes added successfully');
    this.patrolService.addPitstopNotes(this.pitStopId, pitStopNotes);
    this.dismiss();
  }

}
