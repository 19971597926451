import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take, filter } from 'rxjs/operators';
import { Observable, concat, Subject, of } from 'rxjs';
import { Event, EventInfo } from '../entity/event';
import { EventTemplate, PitStop } from '../entity/event.template';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { firestore } from 'firebase';
import { Device } from '@ionic-native/device/ngx';

@Injectable({
    providedIn: 'root'
})
export class PatrolService {
    currentPatrol: Event = null;
    currentActivePitStopList: Event[] = []; // active pitstop evnent;
    currentPitStopList: PitStop[] = []; // event template itemlist  or pitStopList
    private isPatrolStartedChangedSource = new Subject<any>();
    isPatrolStartedChangedObservable = this.isPatrolStartedChangedSource.asObservable();
    eventsCollections: AngularFirestoreCollection<Event>;
    eventTemplateCollection: AngularFirestoreCollection<EventTemplate>;
    eventsInfoCollections: AngularFirestoreCollection<EventInfo>;

    constructor(
        public fireAuth: AngularFireAuth, private device: Device,
        private angularFirestoreDb: AngularFirestore) {
        this.eventsCollections = this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS);
        this.eventTemplateCollection = this.angularFirestoreDb.collection<EventTemplate>(IAceeptMessageConstant.COLLECTION_EVENT_TEMPLATE);
    }

    /* create  patrol */
    async createPatrol(event: Event): Promise<any> {
        event.data.deviceId = this.device.uuid;
        event.id = this.angularFirestoreDb.createId();
        // console.log(PatrolService.name, this.currentPatrol, 'currentPatrol');
        if (this.currentPatrol == null) {
            const eventObj = { ...event };
            eventObj.data = { ...event.data };
            // console.log(PatrolService.name, 'creating new patrol', eventObj);
            const patrolCreated = await this.eventsCollections.doc(event.id).set(eventObj);
            const patrolDataDoc = await this.eventsCollections.doc(event.id).get().toPromise();
            const patrol = patrolDataDoc.data() as Event;
            this.setCurrentPatrol(patrol);
            return patrol;
        } else {
            this.currentPatrol = null;
            return null;
        }
    }

    /* create  pitstop for patrol */
    async createPitstopForPatrol(event: Event): Promise<any> {
        //this.savePatrolDistance(event.templateId, event.startLocation);
        event.data.deviceId = this.device.uuid;
        event.id = this.angularFirestoreDb.createId();
        try {
            const eventObj = { ...event };
            eventObj.data = { ...eventObj.data };
            console.log(PatrolService.name, 'creating new pitstop', eventObj);
            const pitstop = await this.eventsCollections.doc(event.id).set(eventObj);
            const pitstopDataDoc = await this.eventsCollections.doc(event.id).get().toPromise();
            const pitstopData = pitstopDataDoc.data() as Event;
            this.currentActivePitStopList.push(pitstopData);
            return pitstopData;
        } catch (err) {
            console.log('error creating pitstop data');
            return null;
        }
    }


    /* start  pitstop for patrol */
    startPitstop(docId: string) {
        return this.eventsCollections.doc(docId).update({
            endTime: new Date()
        });
    }

    /* end  pitstop for patrol */
    async endPitstop(pitstopId: string, endLocation: Geoposition) {
        await this.eventsCollections.doc(pitstopId).update({
            endTime: firestore.FieldValue.serverTimestamp(),
            endLocation: new firestore.GeoPoint(endLocation.coords.latitude, endLocation.coords.longitude),
            endLocationAccuracy: endLocation.coords.accuracy
        });
        const pitstop = this.currentActivePitStopList.find(event => event.id === pitstopId);
        pitstop.endTime = firestore.FieldValue.serverTimestamp();
        pitstop.endLocation = new firestore.GeoPoint(endLocation.coords.latitude, endLocation.coords.longitude);
    }

    updatePitStopImages(pitstopId: string, imageUrl: string) {
        return this.eventsCollections.doc(pitstopId).update({
            images: firestore.FieldValue.arrayUnion(imageUrl)
        });
    }

    removePitStopImages(pitstopId: string, imageUrl: string) {
        return this.eventsCollections.doc(pitstopId).update({
            images: firestore.FieldValue.arrayRemove(imageUrl)
        });
    }

    addPitstopNotes(pitstopId: string, notes: string) {
        return this.eventsCollections.doc(pitstopId).update({
            'data.notes': notes
        });
    }

    /* end  overall  patrol */
    endPatrol(patrolTemplateId: string, missedPitstops?: string[], errorPitstops?: string[]) {
        const endPatrolTask = this.eventsCollections.doc(patrolTemplateId).update({
            endTime: new Date(),
            data: {
                customerId: this.currentPatrol.data.customerId,
                customerName: this.currentPatrol.data.customerName,
                missedPitstops: missedPitstops || null,
                errorPitstops: errorPitstops || null,
                totalDistanceCovered: this.currentPatrol.data.totalDistanceCovered
            }
        });
        this.currentPatrol = null;
        return endPatrolTask;
    }

    // delete unresponsive patrols
    deletePatrol(patrolId: string) {
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS)
            .doc(patrolId).delete().then(() => this.setCurrentPatrol(null)
            );
    }

    // set current pitStop
    setCurrentPatrol(patrol: Event) {
        this.currentPatrol = patrol;
        this.isPatrolStartedChangedSource.next(patrol);
    }

    // set current pitStop
    getCurrentPatrol() {
        // if (this.currentPatrol) {
        //     return of(this.currentPatrol);
        // } else {
        //     return this.isPatrolStartedChangedObservable;
        // }
        return this.currentPatrol;
    }

    /* get all patrols of client*/
    getAllPatrolsOfClient(clientId: string, filterDate: Date = null): Observable<Event[]> {
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let endDate: Date = null;
            if (filterDate) {
                endDate = new Date(filterDate.getTime());
                endDate.setHours(23);
                endDate.setMinutes(59);
                endDate.setSeconds(59);
            }
            let query = ref.where('clientId', '==', clientId).where('type', '==', 'patrol');
            if (filterDate) {
                query = query.where('startTime', '>=', filterDate);
                query = query.where('startTime', '<=', endDate);
            }
            query = query.orderBy('startTime', 'desc');
            return query;
        }).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    /* get all patrols of client in a given date range*/
    getAllPatrolsOfClientDateRange(clientId: string, fromDate: Date, endDate: Date): Observable<Event[]> {
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('clientId', '==', clientId).where('type', '==', 'patrol').where('startTime', '>=', fromDate).where('startTime', '<=', endDate).orderBy('startTime', 'desc');
            return query;
        }).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    /* get Active Patrol for client*/
    getActivePatrol(clientId: string, currentUserId: string): Observable<Event[]> {
        const date15daysback = new Date();
        date15daysback.setDate(date15daysback.getDate() - 15);
        date15daysback.setHours(0, 0, 0, 0);
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref =>
            ref.where('clientId', '==', clientId)
                .where('type', '==', IAceeptMessageConstant.TEMPLATE_TYPE_PATROL)
                .where('endTime', '==', null)
                .where('startTime', '>', date15daysback)
                .where('createdByUser', '==', currentUserId)
        ).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    /* get Pit Stop For Active Patrol*/
    getPitStopsForPatrol(templateId: string, startTime: Date, endTime?: Date, currentUserId?: string): Observable<Event[]> {
        console.log(PatrolService.name, 'PitStopsForPatrol values', startTime, endTime);
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('type', '==', IAceeptMessageConstant.TEMPLATE_TYPE_PATROL_PITSTOP)
                .where('templateId', '==', templateId)
                .where('startTime', '>', startTime);
            if (endTime) {
                query = query.where('startTime', '<', endTime);
            }
            if (currentUserId) {
                query = query.where('createdByUser', '==', currentUserId);
            }
            // console.log(PatrolService.name, 'PitStopsForPatrol query', query);
            return query;
        }).get().pipe(map(res => {
            const events = res.docs.map(doc => doc.data() as Event)
            if (events) {
                this.currentActivePitStopList = [...events];
            }
            return events;
        }))
    }

    getAllPitstopsOfClient(clientId: string): Observable<PitStop[]> {
        return this.angularFirestoreDb.collectionGroup<PitStop>('items', ref =>
            ref.where('clientId', '==', clientId)).get().pipe(map(res => res.docs.map(doc => doc.data() as PitStop)));
    }

    // tslint:disable-next-line: max-line-length
    getPatrolsOfClientByFilter(clientId: string, customerId: string, employeeId: string, startDate: Date, endDate: Date): Observable<Event[]> {
        // console.log(PatrolService.name, "filter from ", startDate, " to ", endDate);
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('clientId', '==', clientId).where('type', '==', 'patrol').orderBy('startTime', 'desc');
            if (customerId) {
                query = query.where('data.customerId', '==', customerId);
            }
            if (employeeId) {
                query = query.where('createdByUser', '==', employeeId);
            }
            if (startDate) {
                query = query.where('startTime', '>=', startDate);
            }
            if (endDate) {
                query = query.where('startTime', '<=', endDate);
            }
            //console.log('query', query);
            return query;
        }).get().pipe(take(1), map(res => {
            //console.log('got results ', res);
            return res.docs.map(patrol => patrol.data() as Event);
        }));
    }

    getPatrolsOfEmployeeByDate(clientId: string, employeeId: string, filterDate: Date): Observable<Event[]> {
        let endDate: Date = null;
        if (filterDate) {
            endDate = new Date(filterDate.getTime());
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
        }
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('clientId', '==', clientId).where('type', '==', 'patrol');
            if (filterDate) {
                query = query.where('startTime', '>=', filterDate);
                query = query.where('startTime', '<=', endDate);
            }
            if (employeeId) {
                query = query.where('createdByUser', '==', employeeId);
            }
            return query;
        }).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    getAllPitstopsOfEmployeeByDate(clientId: string, employeeId: string, filterDate: Date): Observable<Event[]> {
        let endDate: Date = null;
        if (filterDate) {
            endDate = new Date(filterDate.getTime());
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
        }
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('clientId', '==', clientId).where('type', '==', 'patrol-pitstop');
            if (filterDate) {
                query = query.where('startTime', '>=', filterDate);
                query = query.where('startTime', '<=', endDate);
            }
            if (employeeId) {
                query = query.where('createdByUser', '==', employeeId);
            }
            return query;
        }).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    /* get all patrols of pitstop By Shortcode*/
    // getPatrolsForPitstopByShortcode(shortCode: string, clientId: string): Observable<any> {
    //     const patrolForPitStopObservable = this.angularFirestoreDb.collectionGroup(IAceeptMessageConstant.COLLECTION_EVENT_ITEMS, ref =>
    //         ref.where('shortCode', '==', shortCode).where('isActive', '==', true).where('clientId', '==', clientId)).get();
    //     return new Observable<any>((observer) => {
    //         const patrolPitStopO = [];
    //         patrolForPitStopObservable.subscribe(((querySnapshot) => {
    //             const pitstopDocs = [];
    //             // console.log(PatrolService.name, 'pitstops found ', querySnapshot.docs.length);
    //             querySnapshot.forEach((doc) => {
    //                 const patrolId = doc.ref.parent.parent.id;
    //                 // console.log(PatrolService.name, patrolId, 'patrolId');
    //                 pitstopDocs.push(doc);
    //                 patrolPitStopO.push(this.getEventTemplateById(patrolId));
    //             });
    //             // console.log(PatrolService.name, patrolPitStopO, 'patrolPitStopO');
    //             concat(...patrolPitStopO).pipe(take(querySnapshot.size)).subscribe((patrol: EventTemplate) => {
    //                 // console.log(PatrolService.name, patrol, 'events');
    //                 if (patrol && patrol.isActive) {
    //                     observer.next({ pitstop: pitstopDocs.find(doc => doc.ref.parent.parent.id === patrol.id).data(), patrol });
    //                 }
    //             }, (err) => {
    //                 // console.log(PatrolService.name, err, 'something-went-wrong');
    //             }, () => {
    //                 observer.complete();
    //             });
    //         }));
    //     });
    // }

    async getPatrolsForPitstopByShortcode(shortCode: string, clientId: string) {
        const pitstopSnapshot = await this.angularFirestoreDb.collectionGroup(IAceeptMessageConstant.COLLECTION_EVENT_ITEMS, ref =>
            ref.where('shortCode', '==', shortCode).where('isActive', '==', true).where('clientId', '==', clientId)).get().toPromise();

        const response = [];
        const pitstopDocs = [];

        pitstopSnapshot.forEach((doc) => {
            const patrolId = doc.ref.parent.parent.id;
            pitstopDocs.push(doc.data());
            response.push({ pitstop: doc.data(), patrol: patrolId });
        });

        await Promise.all(response.map(async (res, index) => {
            const patrol = await this.getEventTemplateById(res.patrol);
            response[index].patrol = patrol;
        }));
        console.log(PatrolService.name, 'all patrols for short code', response);
        return response;
    }

    // get event template by templateId in eventtemplates
    async getEventTemplateById(docId: string) {
        return this.eventTemplateCollection.doc<EventTemplate>(docId).get()
            .pipe(map(snapshot => snapshot.data() as EventTemplate)).toPromise();
    }

    // TODO: call a cloud function to delete patrols recursively
    removePatrolsOfCustomer(clientId: string, customerId: string) {
        this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENT_TEMPLATE,
            ref => ref.where('customerId', '==', customerId).where('clientId', '==', clientId)).get().subscribe((querySnapshot) => {
                const batch = this.angularFirestoreDb.firestore.batch();

                querySnapshot.forEach(function (doc) {
                    batch.delete(doc.ref);
                });

                // Commit the batch
                return batch.commit();
            });
    }

    // get event template by templateId in eventtemplates
    getAllEventTemplateItems(patrolTemplateId: string): Observable<any> {
        return this.eventTemplateCollection.doc(patrolTemplateId)
            .collection(IAceeptMessageConstant.COLLECTION_EVENT_ITEMS)
            .get().pipe(map(res => {
                let pitStopList = res.docs.map(doc => doc.data() as PitStop)
                if (pitStopList) {
                    pitStopList = pitStopList.filter(pitstop => pitstop.isActive);
                    this.currentPitStopList = [...pitStopList];
                }
                return pitStopList;
            }));
    }

    async savePatrolDistance(patrol, totalDistanceCovered) {
        console.log('patrol & totalDistanceCovered', patrol,)
        await this.eventsCollections.doc(patrol.id).update({
            data: {
                ...patrol.data,
                totalDistanceCovered: totalDistanceCovered
            }
        });
    }
}
