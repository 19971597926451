import { NavController, AlertController } from '@ionic/angular';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { LoaderService } from 'src/app/services/loader.service';
import { Router } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/services/excel.service';
import { Platform } from '@ionic/angular';

export interface Data {
  movies: string;
}

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeListComponent implements OnInit {
  currentUser: User;
  employees: User[] = [];
  searchString: string;
  public data: Data;
  public columns: any;
  private topLimit: number = 100;
  dataSource: any = [];
  isMobile: boolean;

  constructor(private http: HttpClient, private userService: UserService, private loaderService: LoaderService,
    private snackBar: SnackBarService, private router: NavController, private alertController: AlertController,
    private excelService: ExcelService, private platform: Platform) { }

  ngOnInit() {
    this.loaderService.presentLoading('Loading employees list');
    this.isMobile = !this.platform.is('desktop');

    const subEndDateValue = sessionStorage.getItem("subscription");
    console.log("subscription value from session storage", subEndDateValue);
    this.subEndDateCheck(subEndDateValue);

    this.userService.getCurrentUser().subscribe(user => {
      // this.loaderService.dismissLoading();
      this.currentUser = user;
      this.getEmployeesOfClient(this.currentUser.clientId);
    });
    // console.log('test');
  }

  //future release feature
  searchStringFilter(event) {
    this.searchString = event.target.value;
    this.searchNameAndPhone();
  }

  searchNameAndPhone() {
    console.log("search string ", this.searchString);
    if(this.searchString) {
      const regex = new RegExp(/^[a-zA-Z ]+$/);
      // check for +91 in the regex
      const found = regex.test(this.searchString);
      console.log("found this ", found);
      if(found === true) {
        // has only names
        const nameRegex = RegExp(`${this.searchString.toLowerCase()}`);
        this.dataSource = this.employees.filter(employee => 
          nameRegex.test(employee.name.toLowerCase()) === true);
        console.log("datasource ", this.dataSource);
      } else {
        //has phone number
        const phoneRegex = RegExp(`${this.searchString}`);
        this.dataSource = this.employees.filter(employee => 
          phoneRegex.test(employee.phonenumber) === true);
        console.log("datasource ", this.dataSource);
      }
    } else {
      console.log("no search string, slicing to top limit");
      this.dataSource = this.employees.slice(0, this.topLimit);
    }
  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.router.navigateRoot(['/home']);
    }
  }

  loadData(event) {
    this.topLimit += 100;
    this.dataSource = this.employees.slice(0, this.topLimit);
    event.target.complete();
    if (this.dataSource.length === this.employees.length) {
      event.target.disabled = true;
    }
  }

  getEmployeesOfClient(clientId: string) {
    this.userService.getEmployeesOfClient(clientId).subscribe(employees => {
      this.loaderService.dismissLoading();
      this.employees = employees.filter(employee => employee.isActive);
      this.employees.sort((employeeA: User, employeeB: User) => {
        if (employeeA.name.toLowerCase() === employeeB.name.toLowerCase()) { return 0; }
        return (employeeA.name.toLowerCase() > employeeB.name.toLowerCase()) ? 1 : -1;
      });
      this.dataSource = this.employees.slice(0, this.topLimit);
      // console.log("🚀  -> file: employee-list.component.ts -> line 62 -> EmployeeListComponent -> this.userService.getEmployeesOfClient -> dataSource 087", this.dataSource)
    });
  }

  deactivateEmploayee(employee: User) {
    // console.log(EmployeeListComponent.name, employee, 'emp');
    this.loaderService.presentLoading('Deactivating......');
    this.userService.deActivateEmployee(employee.id, false).then(() => {
      this.snackBar.showToaster('Sucessfully Deactivated');
      this.loaderService.dismissLoading();
    }).catch((err) => {
      this.snackBar.showToaster(err);
      this.loaderService.dismissLoading();
    });
  }

  getEmployeeRole(role) {
    if (role === 'patrol') return 'Patrol';
    else if (role === 'halt') return 'Halt';
    else if (role === 'custom') return 'Halt & Patrol';
    else if (role === 'Team Member') return 'Team Member';
    else if (role === 'general') return 'General';
    else return ''
  }

  viewDeactivateEmploayee() {
    this.router.navigateForward(['employer/dashboard/employee-deactivated-list']);
  }

  onSelectRed(row) {
    // console.log(row);
  }

  onSelectBlue(value) {
    // console.log(value);
  }

  async presentAlert(data, employee) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.deactivateEmploayee(employee);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  editEmployee(employeeId) {
    console.log("current user ", this.currentUser);
    this.router.navigateForward(['/employer/employee-update'], { queryParams: { id: employeeId, role: this.currentUser.role } });
  }

  onExportEmloyees() {
    const employeesArray = [];
    this.employees.map(employee => {
      employeesArray.push({
        name: employee.name,
        contactNumber: employee.phonenumber,
        type: employee.registrationType
      });
    });
    // console.log('onExportEmloyees');
    this.excelService.exportEmployeesToExcel(employeesArray, 'EmployeesList', this.isMobile);
  }

}
