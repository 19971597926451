import { CustomerSignupComponent } from './customer-signup/customer-signup.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendanceComponent } from './attendance/attendance.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { EmployeeDistanceTrackerComponent } from './employee-distance-tracker/employee-distance-tracker.component';
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { EmployeeSignupComponent } from './employee-signup/employee-signup.component';
import { EmployerLoginComponent } from './employer-login/employer-login.component';
import { EmployerSignupComponent } from './employer-signup/employer-signup.component';
import { ErrorComponent } from './error/error.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomePage } from './home/home.page';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { ContactComponent } from './shared/contact/contact.component';
import { SubscriptionHandlingComponent } from './subscription-handling/subscription-handling.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TeamMemberSignupComponent } from './team-member-signup/team-member-signup.component';
import { CustomerLoginComponent } from './customer-login/customer-login.component';
import { PatrolIncidentComponent } from './incident-reporting/patrol-incident/patrol-incident.component';
import { ReportIncidentComponent } from './incident-reporting/report-incident/report-incident.component';
import { HaltDashboardComponent } from './employee-halt/halt-dashboard/halt-dashboard.component';
import { EmployeeAlertComponent } from './employee-alert/employee-alert.component';

const routes: Routes = [
  { path: 'home', component: HomePage },
  { path: 'employee-login', component: EmployeeLoginComponent },
  { path: 'employee-signup', component: EmployeeSignupComponent },
  { path: 'customer-login', component: CustomerLoginComponent },
  { path: 'customer-signup', component: CustomerSignupComponent },
  { path: 'employer-signup', component: EmployerSignupComponent },
  { path: 'team-member-signup', component: TeamMemberSignupComponent },
  { path: 'employer-login', component: EmployerLoginComponent },
  { path: 'set-password', component: SetpasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'emailverification', component: EmailNotVerifiedComponent },
  { path: 'subscription-payment', component: SubscriptionPaymentComponent },
  { path: 'subscription-handling', component: SubscriptionHandlingComponent },
  { path: 'attendance', component: AttendanceComponent },
  { path: 'travel-history', component: EmployeeDistanceTrackerComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'incidents', component: PatrolIncidentComponent },
  { path: 'incidents/create', component: ReportIncidentComponent },
  { path: 'halt-dashboard' , component: HaltDashboardComponent},
  { path: 'employee-alert' , component: EmployeeAlertComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
