export class Incident {
    id: string;
    content: string;
    createdTime: any;
    createdByUser: string;
    createdByUserName: string;
    clientId: string;
    customerId: string;
    customerName: string;
    imageAttachments: string[];
    audioAttachments: string[];
    videoAttachments: string[];
  static createdByUser: string;
}