export class Attendance {
    id: string;
    startTime?: any;
    endTime?: any;
    startLocationAccuracy?: number;
    endLocationAccuracy?: number;
    startLocation?: any;
    endLocation?: any;
    clientId: string;
    createdByUser: string; // login user id;
    startTimeDeviceId: string;
    endTimeDeviceId: string;
    punchInImage?: string;
    punchOutImage?: string;
    constructor() {
        this.id = null;
        this.startTime = null;
        this.startLocation = null;
        this.startLocationAccuracy = null;
        this.endTime = null;
        this.endLocation = null;
        this.endLocationAccuracy = null;
    }
}

export class GeoLocation {
    Latitude: number;
    Longitude: number;
}
