
export class UserSubscription {
    amountPaid: number;
    clientId: string;
    id: string;
    userId: string;
    type: string;
    subscribedDate: Date;
    subscriptionEndDate: Date;
    users: number;
}