import { PitStop } from './../entity/event.template';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { SnackBarService } from './snackbar.service';
import { map, tap, concatMap, concatAll, take } from 'rxjs/operators';
import { Observable, forkJoin, concat, merge, Subject, of } from 'rxjs';
import { Router } from '@angular/router';
import { Event, EventInfo } from '../entity/event';
import { EventTemplate, PitStop as Pitstop } from '../entity/event.template';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    eventsCollections: AngularFirestoreCollection<Event>;
    eventTemplateCollection: AngularFirestoreCollection<EventTemplate>;
    eventsInfoCollections: AngularFirestoreCollection<EventInfo>;
    constructor(
        public fireAuth: AngularFireAuth,
        private angularFirestoreDb: AngularFirestore) {
        this.eventsCollections = this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS);
        this.eventTemplateCollection = this.angularFirestoreDb.collection<EventTemplate>(IAceeptMessageConstant.COLLECTION_EVENT_TEMPLATE);
        this.eventsInfoCollections = this.angularFirestoreDb.collection<EventInfo>(IAceeptMessageConstant.COLLECTION_EVENTINFO);
    }


    createEventTemplate(template: EventTemplate): Promise<any> {
        const templateAddition = this.eventTemplateCollection.add({ ...template });
        templateAddition.then(ref => {
            ref.update({
                id: ref.id
            });
        });
        return templateAddition;
    }

    updateEventTemplate(template: EventTemplate): Promise<any> {
        if (!template) {
            return Promise.reject('Cannot update template without data');
        }
        return this.eventTemplateCollection.doc(template.id).update(template);
    }

    getAllPatrolTemplatesOfClient(clientId: string): Observable<any> {
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENT_TEMPLATE, ref =>
            ref.where('clientId', '==', clientId)).get().pipe(map(res => res.docs.map(doc => doc.data())));
    }

    getAllPatrolTemplatesOfCustomer(clientId: string, customerId: string): Observable<any> {
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENT_TEMPLATE, ref =>
            ref.where('clientId', '==', clientId)
                .where('customerId', '==', customerId))
            .get().pipe(map(res => res.docs.map(doc => doc.data())));
    }

    getAllCustomersForAClient(clientId: any):Observable<any> {
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_CUSTOMERS, ref =>
            ref.where('clientId', '==', clientId))
            .get().pipe(map(res => res.docs.map(doc => doc.data())));
    }

    getPitstopsOfPatrol(patrolId: string): Observable<Pitstop[]> {
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENT_TEMPLATE)
            .doc(patrolId).collection<Pitstop>('items')
            .get().pipe(map(res => res.docs.map(doc => doc.data() as PitStop)));
    }

    addPitstopToPatrol(patrolId: string, pitstop: Pitstop): Promise<any> {
        const pitstopAddition = this.eventTemplateCollection.doc(patrolId).collection('items').add({ ...pitstop });
        pitstopAddition.then(ref => {
            ref.update({ id: ref.id });
        });
        return pitstopAddition;
    }

    deactivatePitstopFromPatrol(patrolId: string, pitstopId: string): Promise<any> {
        return this.eventTemplateCollection.doc(patrolId).collection('items').doc(pitstopId).update({ isActive: false });
    }

    activatePitstopFromPatrol(patrolId: string, pitstopId: string): Promise<any> {
        return this.eventTemplateCollection.doc(patrolId).collection('items').doc(pitstopId).update({ isActive: true });
    }

    deactivatePatrol(patrolId: string): Promise<any> {
        return this.eventTemplateCollection.doc(patrolId).update({ isActive: false });
    }

    activatePatrol(patrolId: string): Promise<any> {
        return this.eventTemplateCollection.doc(patrolId).update({ isActive: true });
    }

    updatePitstopToPatrol(patrolId: string, pitstopId: string, pitstopName: string, pitstopLocation: any): Promise<any> {
        if (!pitstopId) {
            return Promise.reject('Cannot update pitstop without data');
        }
        // tslint:disable-next-line: max-line-length
        console.log(EventService.name, 'updating pitstop', patrolId, pitstopId, pitstopName, pitstopLocation);
        const docRef = this.eventTemplateCollection.doc(patrolId).collection('items').doc(pitstopId);
        // console.log(EventService.name, "doc ref", docRef);
        if (pitstopLocation) {
            return docRef.update({
                name: pitstopName,
                location: pitstopLocation
            });
        }
        if (!pitstopLocation) {
            return docRef.update({
                name: pitstopName,
            });
        }
    }

    getAllEventsBasedOnUserId(id: string) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate());
        startDate.setHours(0, 0, 0, 0);
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENTS, ref =>
            ref.where('createdByUser', '==', id)
                .where('type', 'in', ['patrol', 'halt'])
                .where('startTime', '>', startDate)
                .orderBy('startTime', 'desc')
                .orderBy('endTime', 'desc')
                .limit(1)
        ).get().pipe(map(res => res.docs.map(doc => doc.data())));
    }

    getAllPatrolsInLast24Hrs(clientId: string){
        const startDateTime = new Date();
        startDateTime.setDate(startDateTime.getDate());
        startDateTime.setHours(0, 0, 0, 0);
        console.log("startDate is ", startDateTime);
        
        const endDateTime = new Date();
        endDateTime.setHours(23, 59, 59, 59);
        console.log("endDate is ", endDateTime);

        // test id - RqU492p4NQXPpxPFYynh Force18 client
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENTS , ref =>       
              ref.where('clientId' , '==', clientId)
              .where('type', '==', 'patrol')
              .where('startTime', '>', startDateTime)
              .where('startTime', '<', endDateTime)
              .orderBy('startTime', 'desc'))
              .get()
              .pipe(map(res => res.docs.map(doc => doc.data() as Event)));

    }

    getAllPatrolsForADateRange(clientId: string, startDateTime: Date, endDateTime: Date) {
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENTS , ref =>       
            ref.where('clientId' , '==', clientId)
            .where('type', '==', 'patrol')
            .where('startTime', '>', startDateTime)
            .where('startTime', '<', endDateTime)
            .orderBy('startTime', 'desc'))
            .get()
            .pipe(map(res => res.docs.map(doc => doc.data())));
    }
}
