import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertController } from '@ionic/angular';
import { Form } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email: string;
  forgotPasswordOTPForm: Form;
  constructor(public location: Location, private router: Router, public alertController: AlertController,
              private userService: UserService) { }

  ngOnInit() {}


  onGoBack() {
    this.location.back();
  }

  onResetPassword() {
    this.userService.sendPasswordResetLink(this.email).then(() => {
      // tslint:disable-next-line: max-line-length
      this.router.navigate(['/emailverification'], {
        queryParams: {
          feature: 'reset-password',
          userType: 'employer'
        }
      });
    }, (error) => {
        this.presentAlert(error);
        this.email = '';
    });
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: ['OK'],
      cssClass: 'p-3',
    });

    await alert.present();
  }
}
