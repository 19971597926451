import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-location-access',
  templateUrl: './location-access.component.html',
  styleUrls: ['./location-access.component.scss'],
})
export class LocationAccessComponent implements OnInit {

  constructor(private location: Location, private androidPermissions: AndroidPermissions, private modalController: ModalController) { }

  ngOnInit() { }

  async closeModal() {
    return await this.modalController.dismiss({ granted: false });
  }

  async requestPermissions() {
    const resp = await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION);
    return await this.modalController.dismiss({ granted: resp.hasPermission });
  }

}
