import { LoaderService } from './../../services/loader.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { User } from 'src/app/entity/user';
import { Attendance } from 'src/app/entity/attendance';
import { ExcelService } from 'src/app/services/excel.service';
import { Platform } from '@ionic/angular';
import { ClientService } from 'src/app/services/client.service';
import { Customer } from 'src/app/entity/clients';
import { Incident } from 'src/app/entity/incident';
import { IncidentService } from 'src/app/services/incident.service';
import { ActivatedRoute } from '@angular/router';

@Component({ 
  selector: 'app-incidents-access',
  templateUrl: './incidents-access.component.html',
  styleUrls: ['./incidents-access.component.scss'],
})
export class IncidentsAccessComponent implements OnInit {
  currentUser: User;
  employees: User[] = [];
  searchString: string;
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  noDataFound = false;
  hideFilter: boolean;
  showFilteredReports: boolean;
  btnLoading = false;
  isMobile: boolean;
  customersList = [];
  incidents: Incident[] = [];
  incidentsDisplayList: Incident[] = [];
  selectedCustomer: any;
  selectedEmployee: any;


  constructor(
    private location: Location,
    private loaderService: LoaderService, private userService: UserService,
    private excelService: ExcelService, private platform: Platform,
    private clientService: ClientService,
    private incidentService: IncidentService,
    private route: ActivatedRoute) {
    this.filterFromDate = new Date();
    this.filterFromDate.setMonth(this.filterFromDate.getMonth() - 1);
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
    this.isMobile = !this.platform.is('desktop');

    // console.log(this.isMobile, 'cordova');
  }
  ngOnInit() {
    this.loadData();
  }

  getRoutingParams() {
    this.route.queryParams.subscribe(async(params) => {
      if(params.customer && params.employee && params.date){
        // console.log("incident access - > customer -> ", params.customer);
        // console.log("incident access - > employee -> ", params.employee);

        // have to set selectedEmployee and selectedCustomer based on params and filter
        let findEmp = this.employees.find((employee) => employee.id === params.employee);
        console.log("logging findEmp -> ", findEmp);
        this.selectedEmployee = findEmp;

        let findCust = this.customersList.find((customer) => customer.id === params.customer);
        console.log("logging findCust -> ", findCust);
        this.selectedCustomer = findCust;
        
        console.log("incident access - > date -> ", params.date);
        console.log("parsed epoch data -> ", parseInt(params.date));

        let fromDate = new Date(parseInt(params.date));
        console.log("logging from date received in params", fromDate);

        this.filterFromDate = fromDate;
        this.filterFromDateRawValue = this.filterFromDate.toISOString();

        // let toDate = new Date(parseInt(params.date));
        // toDate.setMinutes(toDate.getMinutes() + 10);
        // console.log("logging toDate based on date received in params", toDate);
        this.filterToDate = fromDate;
        this.filterToDateRawValue = this.filterToDate.toISOString();
        await this.onFilter();
      }
    });
  }

  async loadData() {
    this.loaderService.presentLoading('Loading incidents..');
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    await this.getCustomersList();
    await this.getEmployeesOfClient(this.currentUser.clientId);
    this.getRoutingParams();
    // this.loaderService.dismissLoading();
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    this.filterFromDate = new Date(date);
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    this.filterToDateRawValue = date;
    this.filterToDate = new Date(date);
  }

  async onFilter() {
    const fromDate = new Date(this.filterFromDate).setHours(0, 0, 0, 0);
    const toDate = new Date(this.filterToDate).setHours(23, 59, 59, 59);
    //console.log("search criteria", fromDate, toDate, this.selectedCustomer, this.selectedEmployee);
    this.hideFilter = !this.hideFilter;
    this.btnLoading = true;
    this.showFilteredReports = !this.showFilteredReports;
    this.loaderService.presentLoading('Loading incidents');
    try {
      await this.getIncidentsByFilter(this.currentUser.clientId, new Date(fromDate), new Date(toDate),
        this.selectedCustomer ? this.selectedCustomer.id : null, this.selectedEmployee ? this.selectedEmployee.id : null);
    } catch (error) {
      //console.log("error fetching incidents", error);
    }
    this.btnLoading = false;
    // this.loaderService.dismissLoading();
    this.noDataFound = !(this.incidents && this.incidents.length > 0);
    // console.log(this.filterFromDateRawValue);
    // console.log(this.filterToDateRawValue);
  }

  onFilterClear() {
    this.selectedCustomer = null;
    this.selectedEmployee = null;
    this.filterFromDate = new Date();
    this.filterFromDate.setMonth(this.filterFromDate.getMonth() - 1);
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
  }

  async getIncidentsByFilter(clientId: string, fromDate: Date, toDate: Date, customerId: string, employeeId: string) {
    this.incidents = await this.incidentService.getAllIncidentsOfClient(clientId, fromDate, toDate, customerId, employeeId);
    this.incidentsDisplayList = [...this.incidents];
  }

  async getEmployeesOfClient(clientId: string) {
    this.employees = await this.userService.getEmployeesOfClient(clientId).toPromise();
  }

  getEmployeeName(id: string) {
    const employee = this.employees.find((item => item.id === id));
    return employee ? employee.name : 'unknown';
  }

  getEmployeeStatus(id: string) {
    const employee = this.employees.find((item => item.id === id));
    if (employee) {
      return employee.isActive ? 'ACTIVE' : 'INACTIVE';
    }
  }

  getTimeinHHMMFormat(mins) {
    if (mins >= 0) {
      const num = mins;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.floor(minutes);
      const seconds = (minutes - rminutes) * 60;
      const rseconds = Math.round(seconds);
      return rhours + 'h' + ':' + rminutes + 'm' + ':' + rseconds + 's';
    } else {
      return 0 + 'hours' + ':' + 0 + 'mins';
    }
  }

  dateChange(event) {
    // console.log(event, 'event');
    this.incidentsDisplayList = [];
    this.hideFilter = false;
  }

  onChange(searchString) {
    // console.log(AttendanceReportComponent.name, 'search value', searchString);
    const lowercasedValue = searchString.toLowerCase().trim();
    this.incidentsDisplayList = this.incidents.filter((incident) => incident.content.toLowerCase().indexOf(lowercasedValue) !== -1)

    const filteredEmployees = this.employees.filter((item) => item.name.toString().toLowerCase().includes(lowercasedValue));
    // console.log(AttendanceReportComponent.name, 'filteredEmployees', filteredEmployees);
    //this.incidentsDisplayList = this.incidents.filter((currentUser) => {
    //return filteredEmployees.find((employee) => employee.id === currentUser.createdByUser) ? true : false;
    //});
    //console.log(IncidentsAccessComponent.name, 'attendanceDisplayList', this.attendanceDisplayList);
    // // console.log(AttendanceReportComponent.name, 'attendanceList', this.attendanceList);
    // if (this.attendanceDisplayList.length <= 0) {
    //   this.noDataFound = true;
    // } else {
    //   this.noDataFound = false;
    // }
  }

  onShowFilter() {
    this.hideFilter = !this.hideFilter;
    this.showFilteredReports = !this.showFilteredReports;
  }

  showFilteredResults() {
    this.showFilteredReports = !this.showFilteredReports;
    this.hideFilter = !this.hideFilter;
  }

  onExportIncident() {
    //console.log('onExportIncident', this.incidentsDisplayList);
    const incidentArray = [];

    this.incidentsDisplayList.map(incident => {
      incident.imageAttachments = incident.imageAttachments ? incident.imageAttachments : []
      incident.audioAttachments = incident.audioAttachments ? incident.audioAttachments : []
      incident.videoAttachments = incident.videoAttachments ? incident.videoAttachments : []
      //console.log('attachments', incident.imageAttachments);
      incidentArray.push({
        reportedTime: incident.createdTime,
        incidentDetails: incident.content,
        customer: incident.customerName,
        nameOfEmployee: incident.createdByUserName,
        attachments: [...incident.imageAttachments, ...incident.audioAttachments, ...incident.videoAttachments]
      });
    })

    this.excelService.exportIncidentsToExcel(incidentArray, 'incidentReport', this.isMobile);
  }

  async getCustomersList() {
    this.customersList = await this.clientService.getCustomersOfClient(this.currentUser.clientId).toPromise();
    //console.log('customers list', this.customersList);
    this.customersList.sort((customerA: Customer, customerB: Customer) => {
      if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
      return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
    });
  }

  onNavigateBack() {
    this.location.back();
  }

}