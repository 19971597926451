import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { File } from '@ionic-native/file/ngx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
    providedIn: 'root'
})
export class ExcelService {
    exportIncident: any;

    constructor(private file: File) {
    }

    async exportEmployeesToExcel(data, filename, isMobile) {
        {
            console.log("exporting data...", data);
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { header: ['Employees List'] });
            XLSX.utils.sheet_add_json(
                worksheet, data,
                {
                    skipHeader: false,
                    origin: { r: data.length, c: 0 },
                    header: ["Employees"]
                }
            );

            const workbook: XLSX.WorkBook = {
                Sheets: { Export: worksheet },
                SheetNames: ['Export']
            };

            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            if (!isMobile) {
                this.saveAsExcelFileDesktop(excelBuffer, filename);
            } else {
                this.saveAsExcelFile(excelBuffer, filename);
            }

            // const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, filename);
            // XLSX.writeFile(workbook, filename + '.xlsx');
        }
    }

    async exportCustomersToExcel(data, filename, isMobile) {
        {
            console.log("exporting data...", data);
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { header: ['Customers List'] });
            XLSX.utils.sheet_add_json(
                worksheet, data,
                {
                    skipHeader: false,
                    origin: { r: data.length, c: 0 },
                    header: ["Customers"]
                }
            );

            const workbook: XLSX.WorkBook = {
                Sheets: { Export: worksheet },
                SheetNames: ['Export']
            };

            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            if (!isMobile) {
                this.saveAsExcelFileDesktop(excelBuffer, filename);
            } else {
                this.saveAsExcelFile(excelBuffer, filename);
            }

            // const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, filename);
            // XLSX.writeFile(workbook, filename + '.xlsx');
        }
    }

    async exportDeactivatedEmployeesToExcel(data, filename, isMobile) {
        {
            console.log("exporting data...", data);
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { header: ['Deactivated Employees List'] });
            XLSX.utils.sheet_add_json(
                worksheet, data,
                {
                    skipHeader: false,
                    origin: { r: data.length, c: 0 },
                    header: ["Deactivated Employees"]
                }
            );

            const workbook: XLSX.WorkBook = {
                Sheets: { Export: worksheet },
                SheetNames: ['Export']
            };

            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            if (!isMobile) {
                this.saveAsExcelFileDesktop(excelBuffer, filename);
            } else {
                this.saveAsExcelFile(excelBuffer, filename);
            }

            // const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, filename);
            // XLSX.writeFile(workbook, filename + '.xlsx');
        }
    }

    async exportIncidentsToExcel(data, filename, isMobile) {
        {
            console.log("exporting data...", data, filename, isMobile);
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { header: ['Incidents Report'] });
            XLSX.utils.sheet_add_json(
                worksheet, data,
                {
                    skipHeader: false,
                    origin: { r: data.length, c: 0 },
                    header: ["Incidents"]
                }
            );

            const workbook: XLSX.WorkBook = {
                Sheets: { Export: worksheet },
                SheetNames: ['Export']
            };

            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            if (!isMobile) {
                this.saveAsExcelFileDesktop(excelBuffer, filename);
            } else {
                this.saveAsExcelFile(excelBuffer, filename);
            }

            // const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, filename);
            // XLSX.writeFile(workbook, filename + '.xlsx');
        }
    }

    async exportAttendanceToExcel(data, filename, isMobile) {
        {
            console.log("exporting data...");
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { header: ['Attendance Report'] });
            const keys = Object.keys(data);
            console.log('keys', keys);
            if (keys.length) {
                let rowsLenth = 2;
                for (let i = 0; i < keys.length; i++) {
                    console.log("export data", i, keys[i], data[keys[i]])
                    XLSX.utils.sheet_add_json(
                        worksheet, data[keys[i]],
                        {
                            skipHeader: false,
                            origin: { r: rowsLenth, c: 0 },
                            header: [keys[i]]
                        }
                    );
                    rowsLenth += data[keys[i]].length + 3;
                }
            }

            const workbook: XLSX.WorkBook = {
                Sheets: { Export: worksheet },
                SheetNames: ['Export']
            };

            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            if (!isMobile) {
                this.saveAsExcelFileDesktop(excelBuffer, filename);
            } else {
                this.saveAsExcelFile(excelBuffer, filename);
            }

            // const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, filename);
            // XLSX.writeFile(workbook, filename + '.xlsx');
        }
    }

    exportAsExcelFile(data: any, excelFileName: string, reportType: string = 'Detail', isMobile): void {
        // console.log(ExcelService.name, data, 'data');
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { header: [reportType + ' ' + 'Reports'] });

        const keys = Object.keys(data);
        if (keys.length) {
            let rowsLenth = 2;
            let totalpatrol = '';
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < keys.length; i++) {
                if (reportType === 'halts') {
                    totalpatrol = data[keys[i]][0] !== undefined ? data[keys[i]][0].TotalHalts : 0;
                } else {
                    totalpatrol = data[keys[i]][0] !== undefined ? data[keys[i]][0].TotalHalts : 0;
                }
                // console.log(ExcelService.name, totalpatrol);
                XLSX.utils.sheet_add_json(
                    worksheet, data[keys[i]],
                    {
                        skipHeader: false,
                        origin: { r: rowsLenth, c: 0 },
                        header: [keys[i] + ' ' + 'Total' + reportType + ' ' + totalpatrol]
                    }
                );
                rowsLenth += data[keys[i]].length + 3;
            }
        }
        // to set a coulmn width
        const wscols = [
            { wch: 25 },
            { wch: 15 },
            { wch: 13 },
            { wch: 13 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
            { wch: 65 },
            { wch: 120 }
        ];
        worksheet['!cols'] = wscols;

        // to hide a particular coulmn
        if (reportType === 'halts') {
            worksheet['!cols'][5] = { hidden: true };
            worksheet['!cols'][6] = { hidden: true };

        } else {
            worksheet['!cols'][6] = { hidden: true };
            worksheet['!cols'][9] = { hidden: true };
        }
        const workbook: XLSX.WorkBook = {
            Sheets: { Export: worksheet },
            SheetNames: ['Export']
        };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        if (!isMobile) {
            this.saveAsExcelFileDesktop(excelBuffer, excelFileName);
        } else {
            this.saveAsExcelFile(excelBuffer, excelFileName);
        }
    }

    exportDateWiseHaltReportAsExcelFile(data: any, excelFileName: string, reportType: string = 'Detail', isMobile): void {
        // console.log(ExcelService.name, data, 'data');
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { header: [reportType + ' ' + 'Reports'] });
        XLSX.utils.sheet_add_json(
            worksheet, data,
            {
                skipHeader: false,
                origin: { r: 3, c: 0 },
            }
        );
        // to set a coulmn width
        const wscols = [
            { wch: 25 },
            { wch: 15 },
            { wch: 13 },
            { wch: 13 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 65 },
            { wch: 120 }
        ];
        worksheet['!cols'] = wscols;

        // to hide a particular coulmn
        if (reportType === 'halts') {
            // worksheet['!cols'][1] = { hidden: true };
            worksheet['!cols'][5] = { hidden: true };
            worksheet['!cols'][7] = { hidden: true };

        } else {
            worksheet['!cols'][6] = { hidden: true };
            worksheet['!cols'][9] = { hidden: true };
        }
        const workbook: XLSX.WorkBook = {
            Sheets: { Export: worksheet },
            SheetNames: ['Export']
        };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        if (!isMobile) {
            this.saveAsExcelFileDesktop(excelBuffer, excelFileName);
        } else {
            this.saveAsExcelFile(excelBuffer, excelFileName);
        }
    }


    private saveAsExcelFile(buffer: any, fileName: string): void {
        console.log("save excel to file", fileName)
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        const self = this;
        this.getStoragePath(fileName).then((url) => {
            const name = `${fileName}export${this.getRandomNumber()}.xlsx`;
            console.log("saving file", url, name)
            self.file.writeFile(url, name, data).then(() => {
                alert('file saved at: ' + url);
            }).catch((err) => {
                console.log("error creating file", err);
                alert('error creating file at :' + url);
            });
        });
        //FileSaver.saveAs(data, fileName + 'Export' + EXCEL_EXTENSION);
    }

    private getRandomNumber() {
        return Math.round(Math.random() * 100000);
    }

    private saveAsExcelFileDesktop(buffer: any, fileName: string): void {
        console.log("saving file desktop", fileName)
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + 'Export' + EXCEL_EXTENSION);
    }


    private async getStoragePath(fileName?) {
        console.log("getStoragePath -> filename", fileName);
        const file = this.file;
        const directoryEntry = await this.file.resolveDirectoryUrl(this.file.externalRootDirectory);
        console.log("directory url ", directoryEntry);
        try {
            await this.file.getDirectory(directoryEntry, `${fileName}Export`, {
                create: true,
                exclusive: false
            })
        } catch (err) {
            console.log("error creating directory", err);
        }
        return directoryEntry.nativeURL + `${fileName}Export`;
    }

}