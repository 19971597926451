import { PatrolService } from './../../services/patrol.service';
import { Event } from './../../entity/event';
import { UserService } from 'src/app/services/user.service';
import { Attendance } from './../../entity/attendance';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { RoundProgressEase } from 'angular-svg-round-progressbar';
import { User } from 'src/app/entity/user';
import { HaltService } from 'src/app/services/halt.services';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.scss'],
})
export class CircularProgressBarComponent implements OnInit, OnDestroy {
  @Input() activeHalt;
  @Input() activePatrol;

  currentUser: User;
  current = 5;
  max = 100;
  stroke = 6;
  radius = 60;
  color = '#d3d3d3';
  semicircle = false;
  rounded = true;
  responsive = false;
  clockwise = true;
  background = '#eaeaea';
  duration = 0;
  animation = 'easeOutCubic';
  animationDelay = 0;
  animations: string[] = [];
  gradient = true;
  realCurrent = 0;
  halt: Event;
  patrol: Event;
  startTime: Date;
  spentTime: any;
  intervalSub: any;

  constructor(private haltService: HaltService, private userService: UserService, private patrolService: PatrolService) {
  }

  ionViewDidEnter() {
    this.halt = this.haltService.getActiveHalt();
    console.log("progress bar: ionVieDidEnter halt", this.halt)
    if (this.halt) {
      this.setStartTime(this.halt);
    } else if (!this.halt && this.halt != null) {
      this.duration = 0;
    }
  }

  ngOnInit() {
    console.log('progress bar: active halt', this.activeHalt);
    // console.log('active patrol', this.activePatrol);
    this.haltService.listenToActiveHaltChange().subscribe((data) => {
      // console.log(CircularProgressBarComponent.name, 'current Active Halt', data);
      if (data) {
        this.setStartTime(data);
      } else if (!this.halt && this.halt != null) {
        this.duration = 0;
      }
    });
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  setStartTime(activeHalt: Event) {
    console.log("progress bar: setting start time", activeHalt.startTime);
    if (typeof activeHalt.startTime.getTime !== "function") {
      console.log("firebase time found ", activeHalt.startTime);
      this.startTime = new Date()
    } else {
      this.startTime = activeHalt.startTime;
    }
    this.stopTimer();
    this.showDiffTime();
  }

  stopTimer() {
    if (this.intervalSub) {
      clearInterval(this.intervalSub)
    }
  }

  showDiffTime() {
    if (this.startTime) {
      this.intervalSub = setInterval(() => {
        console.log("tick", this.startTime);
        if (this.startTime) {
          const currentTime = new Date();
          const diffTime = Math.abs(currentTime.getTime() - this.startTime.getTime());
          this.getTimeinHHMMFormat(diffTime);
        }
      }, 1000);
    }
  }

  getTimeinHHMMFormat(mins) {
    if (mins <= 0) {
      return 'Incomplete';
    }
    mins = mins / 60000;
    const num = mins;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = Math.round(seconds);
    this.spentTime = rhours + ':' + rminutes + ':' + rseconds;
  }

  getOverlayStyle() {
    const isSemi = this.semicircle;
    //const transform = (isSemi ? '' : 'translateY(-50%) ') + 'translateX(-50%)';

    return {
      // top: isSemi ? 'auto' : '50%',
      // bottom: isSemi ? '5%' : 'auto',
      // left: '50%',
      // transform,
      // '-moz-transform': transform,
      // '-webkit-transform': transform,
      'font-size': this.radius / 3.5 + 'px'
    };
  }

  doSomethingWithCurrentValue(event) {
    // // console.log('event', event);
  }

}
