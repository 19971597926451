import { SnackBarService } from './../../services/snackbar.service';
import { Location } from '@angular/common';
import { EventService } from 'src/app/services/event.services';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entity/user';
import { EventInfo, Event } from './../../entity/event';
import { GoogleMapOptions, CameraPosition } from '@ionic-native/google-maps';

import {
  GoogleMap,
  GoogleMaps,
  MarkerCluster,
  GoogleMapsEvent,
  Marker,
  LatLng,
  Environment,
  Polyline,
} from '@ionic-native/google-maps/ngx';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { IonicSelectableComponent } from 'ionic-selectable';
import { HaltService } from 'src/app/services/halt.services';
import { PatrolService } from 'src/app/services/patrol.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/services/map-data';

@Component({
  selector: 'app-employer-customer-info',
  templateUrl: './employer-customer-info.component.html',
  styleUrls: ['./employer-customer-info.component.scss'],
})
export class EmployerCustomerInfoComponent implements OnInit {
  mapUserData = [];
  currentUser: User;
  map: GoogleMap;
  marker: any;
  employees = [];
  selectedEmployee: User;
  selectedEmployeeId: string;
  filterDate: Date;
  filterDateRawValue: any;
  haltList = [];
  showLocation = false;
  markerCluster: MarkerCluster;
  polyline: Polyline;
  patrols: Event[] = [];
  hideFilter = false;
  constructor(
    private userService: UserService,
    private eventService: EventService,
    private location: Location,
    private snackBarService: SnackBarService,
    private haltService: HaltService,
    private loaderService: LoaderService,
    private patrolService: PatrolService,
    private route: ActivatedRoute,
    private mapService: MapService
  ) {
    this.filterDate = new Date();
    this.filterDate.setHours(0);
    this.filterDate.setMinutes(0);
    this.filterDate.setSeconds(0);
    this.filterDateRawValue = this.filterDate.toISOString();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.view === 'pitstop-details') {
        this.mapService.getPitstops().subscribe((events: Event[]) => {
          // console.log('pitstop templates', events);
          this.patrols = events;
          this.hideFilter = true;
        });
      } else {
        this.hideFilter = false;
      }
    });
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
      // console.log('currentUser', this.currentUser);
      this.getAllEmployees();
    });
  }

  getAllEmployees() {
    this.userService
      .getAllHaltEmployesBasedOnClientid(this.currentUser.clientId)
      .subscribe((employees) => {
        this.employees = employees;
        this.employees.sort((employeeA: User, employeeB: User) => {
          if (employeeA.name.toLowerCase() === employeeB.name.toLowerCase()) { return 0; }
          return (employeeA.name.toLowerCase() > employeeB.name.toLowerCase()) ? 1 : -1;
        });
        // // console.log('employee list', this.employees);
      });
  }

  ionViewDidEnter() {
    this.loadMap();
  }

  get formattedDate() {
    return this.filterDateRawValue;
  }

  set formattedDate(date) {
    this.filterDateRawValue = date;
    this.filterDate = new Date(date);
  }

  onLocateAllUsers() {
    this.loaderService.presentLoading(`Loading locations...`);
    return new Promise((resolve, reject) => {
      let count = 0;
      if (this.currentUser && this.employees) {
        this.employees.map((employee: User) => {
          this.eventService
            .getAllEventsBasedOnUserId(employee.id)
            .subscribe((events: Event[]) => {
              // console.log(EmployerCustomerInfoComponent.name, events, 'events');
              if (events.length) {
                count = count + 1;
                this.mapUserData = this.mapUserData.concat(this.getMarkersOfEvents(events));
              } else {
                count = count + 1;
              }
              // console.log(EmployerCustomerInfoComponent.name, this.employees.length, count, 'count');
              if (this.employees.length === count) {
                this.loaderService.dismissLoading();
                // console.log(EmployerCustomerInfoComponent.name, this.mapUserData, 'final maps data');
                if (this.mapUserData.length) {
                  resolve(this.mapUserData);
                } else {
                  this.loaderService.dismissLoading();
                  reject(new Error('Whoops! No Active Employee Found'));
                }
              }
            });
        });
      }
    });
  }

  getMarkersOfEvents(events) {
    return events.map((item) => {
      return {
        name: item['createdByUserName'] + ' - ' + this.getEventTime(item),
        address: item.type,
        datetime: item['startTime'] ? item['startTime'].toDate() : null,
        label: item.type === 'halt' ? 'H' : 'P',
        position: {
          lat: (item['endLocation'] ? item['endLocation'] : item['startLocation']).latitude,
          lng: (item['endLocation'] ? item['endLocation'] : item['startLocation']).longitude,
        },
        icon: item.type === 'halt' ? 'blue' : 'green',
      }
    });
  }

  onShowAllEmployees() {
    this.onLocateAllUsers().then(
      (mapDataList) => {
        // console.log(EmployerCustomerInfoComponent.name, mapDataList, 'mapDataList show all events');
        if (mapDataList) {
          this.addCluster(mapDataList);
        }
      },
      (err) => {
        // console.log(EmployerCustomerInfoComponent.name, err, 'err');
        this.snackBarService.showToaster(
          'No active employees found during this time'
        );
        this.location.back();
      }
    );
  }

  loadMap() {
    Environment.setEnv({
      API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI',
      API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI'
    });
    // console.log(EmployerCustomerInfoComponent.name, 'loading map...');
    this.map = GoogleMaps.create('map_canvas', {
      preferences: {
        zoom: {
          minZoom: 3,
          maxZoom: 18,
        },
      },
    });
    // console.log(EmployerCustomerInfoComponent.name, 'map created', this.map);
    // this.patrols.map(() => {
      // });
      this.markLocationOnMap(this.patrols);
  }

  addCluster(data) {
    // console.log(EmployerCustomerInfoComponent.name, 'location data', data);
    this.markerCluster = this.map.addMarkerClusterSync({
      markers: data,
      icons: [
        {
          min: 3,
          max: 9,
          url: '../../../assets/images/logo32x32.png',
          label: {
            color: 'white',
          },
        },
        {
          min: 10,
          url: '../../../assets/images/logo32x32.png',
          label: {
            color: 'white',
          },
        },
      ],
    });
    this.map.setCameraTarget({
      lat: data[0].position.lat,
      lng: data[0].position.lng,
    });
    this.map.setCameraZoom(15);
    this.markerCluster.on(GoogleMapsEvent.MARKER_CLICK).subscribe((params) => {
      const marker: Marker = params[1];
      // console.log(EmployerCustomerInfoComponent.name, marker, 'marker');
      marker.setTitle(marker.get('name'));
      marker.setSnippet(marker.get('address'));
      marker.showInfoWindow();
    });
  }

  getCurrentTime() {
    const currentTime = new Date();
    // currentTime.setMinutes(currentTime.getMinutes() - 120); // Enable for employees who are active in past two hours
    currentTime.setMinutes(currentTime.getMinutes());
    return currentTime.getTime();
  }

  getEventTime(event) {
    // console.log(EmployerCustomerInfoComponent.name, 'event', event);
    if (event.endTime) {
      return event.endTime.toDate().toLocaleTimeString();
    } else if (event.startTime) {
      return event.startTime.toDate().toLocaleTimeString();
    }
  }

  onSelectEmployee(employee: {
    component: IonicSelectableComponent;
    value: any;
  }) {
    this.selectedEmployee = employee.value;
    // console.log('selected employee', employee);
    if (this.map) {
      this.removeMarker();
    }
  }

  removeMarker() {
    if (this.markerCluster) {
      this.markerCluster.remove();
    }
    if (this.polyline) {
      this.polyline.remove();
      this.polyline.empty();
    }
  }

  markLocationOnMap(events) {
    // console.log('mapUserData', events);
    const coordinates = events.map((event) => {
      return {
        lat: (event.endLocation ? event.endLocation : event.startLocation).latitude,
        lng: (event.endLocation ? event.endLocation : event.startLocation).longitude
      };
    });
    this.map.addPolyline({
      points: coordinates,
      color: '#0000ff',
      width: 4,
      geodesic: true
    }).then((polyline) => {
      this.polyline = polyline;
      // console.log('polyline', polyline);
    });

    const markers = this.getMarkersOfEvents(events);
    // console.log('markers', markers);
    this.addCluster(markers);
  }


  onClickFilter() {
    this.getAllHalts();
    // else if (this.selectedEmployee.registrationType === 'patrol' || this.selectedEmployee.registrationType === 'custom') {
    //   this.getAllPatrols();
    // }
  }

  getAllPatrols(selectedEmployeeId) {
    this.filterDate.setHours(0);
    this.filterDate.setMinutes(0);
    this.filterDate.setSeconds(0);
    this.filterDateRawValue = this.filterDate.toISOString();
    this.patrolService.getAllPitstopsOfEmployeeByDate(this.currentUser.clientId, selectedEmployeeId, this.filterDate)
      .subscribe((patrols) => {
        this.patrols = patrols;
        this.patrols.map(() => {
          this.markLocationOnMap(this.patrols);
        });
        // console.log(EmployerCustomerInfoComponent.name, 'patrol History', this.patrols);
      });
  }

  getAllHalts() {
    this.filterDate.setHours(0);
    this.filterDate.setMinutes(0);
    this.filterDate.setSeconds(0);
    this.filterDateRawValue = this.filterDate.toISOString();
    this.haltService.getAllHaltBasedOnUserId(this.selectedEmployee.id, this.currentUser.clientId, this.filterDate)
      .subscribe((eventsList: Event[]) => {
        if (eventsList && eventsList.length > 0) {
          this.haltList = eventsList.filter((event) => event.id && event.id !== '');
          this.haltList.map(() => {
            this.markLocationOnMap(this.haltList);
          });
        } else {
          this.haltList = [];
        }
      });
  }
}
