import { LoaderComponent } from './loader/loader.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { TabsComponent } from './tabs/tabs.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from '../error/error.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { TableFilterPipe } from './pipes/filter.pipe';
import { PatrolDetailsModalComponent } from './patrol-details-modal/patrol-details-modal.component';
import { HaltDetailsModalComponent } from './halt-details-modal/halt-details-modal.component';
import { CustomFormModalComponent } from './custom-form-modal/custom-form-modal.component';
import { DisableAfterClickDirective } from '../utilities/disable-after-click.directive';
import { ContactComponent } from './contact/contact.component';
import { CircularProgressBarComponent } from './circular-progress-bar/circular-progress-bar.component';
import { RoundProgressModule, ROUND_PROGRESS_DEFAULTS } from 'angular-svg-round-progressbar';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';

@NgModule({
  declarations: [
    AccountMenuComponent,
    CommonHeaderComponent,
    TabsComponent,
    ErrorComponent,
    ImageGalleryComponent,
    HaltDetailsModalComponent,
    PatrolDetailsModalComponent,
    CustomFormModalComponent,
    ContactComponent,
    TableFilterPipe,
    DisableAfterClickDirective,
    CircularProgressBarComponent,
    LoaderComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule.forRoot(),
    RoundProgressModule,
    NgxIonicImageViewerModule
  ],
  exports: [
    AccountMenuComponent,
    CommonHeaderComponent,
    TabsComponent,
    RouterModule,
    HaltDetailsModalComponent,
    PatrolDetailsModalComponent,
    ErrorComponent,
    ImageGalleryComponent,
    ContactComponent,
    TableFilterPipe,
    DisableAfterClickDirective,
    CircularProgressBarComponent,
    LoaderComponent
  ],
  entryComponents: [PatrolDetailsModalComponent,
    HaltDetailsModalComponent,
    CustomFormModalComponent,
    CircularProgressBarComponent,
    LoaderComponent]
})
export class SharedModule { }
