import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-on-boarding-modal',
  templateUrl: './on-boarding-modal.component.html',
  styleUrls: ['./on-boarding-modal.component.scss'],
})

export class OnBoardingModalComponent implements OnInit {
  @ViewChild(IonSlides, { static: false }) slides: IonSlides;
  @Input() type: string;
  haltOnBoarding = false;
  patrolOnBoarding = false;
  employerOnBoarding = false;
  ivisitOnBoarding = false;
  generalOnBoarding = false;
  slideOpts = {
    initialSlide: 0,
  };
  constructor(public modalCtrl: ModalController) { }

  public ionViewWillEnter() {
    this.slides.update();
  }

  ngOnInit() {
    if (this.type === 'ivisit') {
      this.haltOnBoarding = false;
      this.patrolOnBoarding = false;
      this.employerOnBoarding = false;
      this.ivisitOnBoarding = true;
      this.generalOnBoarding = false;
    }
    if (this.type === 'halt') {
      this.haltOnBoarding = true;
      this.patrolOnBoarding = false;
      this.employerOnBoarding = false;
      this.ivisitOnBoarding = false;
      this.generalOnBoarding = false;
    }
    if (this.type === 'patrol') {
      this.haltOnBoarding = false;
      this.patrolOnBoarding = true;
      this.employerOnBoarding = false;
      this.ivisitOnBoarding = false;
      this.generalOnBoarding = false;
    }
    if (this.type === 'employer') {
      this.haltOnBoarding = false;
      this.patrolOnBoarding = false;
      this.employerOnBoarding = true;
      this.ivisitOnBoarding = false;
      this.generalOnBoarding = false;
    }
    if (this.type === 'general') {
      this.haltOnBoarding = false;
      this.patrolOnBoarding = false;
      this.employerOnBoarding = false;
      this.ivisitOnBoarding = false;
      this.generalOnBoarding = true;
    }
  }

  onPrevious() {
    this.slides.slidePrev();

  }
  onNext() {
    this.slides.slideNext();
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }
}
