import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarService } from '../services/snackbar.service';
import { User } from '../entity/user';
import { IAceeptMessageConstant } from '../core/constants/i-accept-msg.constant';
import { LoaderService } from '../services/loader.service';
import { AlertController, NavController } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-employee-login',
  templateUrl: './employee-login.component.html',
  styleUrls: ['./employee-login.component.scss'],
})
export class EmployeeLoginComponent implements OnInit {
  phonenumber: string;
  password: string;
  btnLoading = false;
  constructor(
    private userService: UserService, private router: Router, public alertController: AlertController,
    private snackBarService: SnackBarService, private loaderService: LoaderService, private navCtrl: NavController,
    private location: Location) { }

  ngOnInit() {
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onEmployeeLogin() {
    this.btnLoading = true;
    const phoneToEmail = '+91' + this.phonenumber + '@ivist.com';
    const phone = '+91' + this.phonenumber;
    this.userService.checkUserExistsStatus(phone, true).subscribe((user) => {
      this.btnLoading = false;
      //// console.log(EmployeeLoginComponent.name, user, 'user');
      if (user.length) {
        this.userService.emailLogin(phoneToEmail, this.password).then((userData) => {
          //// console.log(EmployeeLoginComponent.name, 'user data after login', userData);
        }, () => {
          this.presentAlert('Invalid user id or password');
        });
      } else {
        this.snackBarService.showToaster('No user record found');
      }
    });
  }

  

  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: alertData,
      buttons: ['OK'],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  onForgotPassword() {
    this.navCtrl.navigateForward(['/employee-signup'], {
      queryParams: {
        feature: 'reset-password',
        userType: 'employee',
      }
    });
  }

  onGoBack() {
    this.navCtrl.navigateBack(['/home']);
  }

  onSignUp() {
    this.navCtrl.navigateForward(['/employee-signup']);
  }
}
