import { Invite } from 'src/app/entity/invite';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ClientService } from '../services/client.service';
import { SnackBarService } from '../services/snackbar.service';
import { EmployerLogin } from '../entity/employer';
import { User } from '../entity/user';
import { UserSubscription } from '../entity/subscription';
import { IAceeptMessageConstant } from '../core/constants/i-accept-msg.constant';
import { LoaderService } from '../services/loader.service';
import { NavController, AlertController, IonSlides } from '@ionic/angular';
import { MustMatch } from '../utilities/must-match.validator';



@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})


export class HomePage implements OnInit {
  action = true;
  actionType = '';
  loginType = false;
  signUpForm = false;
  isEmailVerified = true;
  employerLoginForm: FormGroup;
  employerSignUpForm: FormGroup;
  teamMemberSignUpForm: FormGroup;
  title = 'Login';
  phonenumber: string;
  password: string;
  isTeamMemberInvited = false;
  isTeamMemberSignUpForm = false;
  currentEmployerInvite: Invite;
  btnLoading = false;
  sigin = false;
  slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  signin = false;
  slides: IonSlides;

  constructor(
    public location: Location, private formBuilder: FormBuilder, private router: Router,
    private userService: UserService, private clientService: ClientService,
    private snackBarService: SnackBarService, private loaderService: LoaderService,
    private ionRouter: NavController, private alertController: AlertController,
    private nav: NavController) { }

  ngOnInit() {

    //  this.verifyEmployerLogin();
    //  this.verifyEmployerSignUp();
    //  this.verifyTeamMemberSignUp();
  }

  onAction(action) {
    this.actionType = action;
    this.action = false;
  }

  onClickBack() {
    this.action = true;
  }

  onRoleSelection(role) {
    if (role === 'employer') {
      this.onEmployerSelection();
    }
    if (role === 'teammember') {
      this.onTeamMemberSelection();
    }
    if (role === 'employee') {
      this.onEmployeeSelection();
    }
    if (role === 'customer') {
      this.onCustomerSelection();
    }
  }

  onEmployerSelection() {
    if (this.actionType === 'login') {
      this.ionRouter.navigateForward(['/employer-login'], {
        queryParams: {
          userType: 'employer',
        }
      });
    } else {
      this.ionRouter.navigateForward(['/employer-signup'], {
        queryParams: {
          userType: 'employer',
        }
      });
    }
  }

  onTeamMemberSelection() {
    if (this.actionType === 'login') {
      this.ionRouter.navigateForward(['/employer-login'], {
        queryParams: {
          userType: 'team-member',
        }
      });
    } else {
      this.ionRouter.navigateForward(['/team-member-signup']);
    }
  }

  onEmployeeSelection() {
    if (this.actionType === 'login') {
      this.ionRouter.navigateForward(['/employee-login']);
    } else {
      this.ionRouter.navigateForward(['/employee-signup']);
    }
  }

  onCustomerSelection() {
      this.ionRouter.navigateForward(['/customer-login']);
  }


  // verifyEmployerLogin() {
  //   this.employerLoginForm = this.formBuilder.group({
  //     email: ['', Validators.compose([Validators.required, Validators.email])],
  //     password: ['', Validators.required],
  //   });
  //   // // console.log(HomePage.name, 'employer login form values:', this.employerLoginForm);
  // }

  // verifyEmployerSignUp() {
  //   this.employerSignUpForm = this.formBuilder.group({
  //     email: ['', Validators.compose([Validators.required,
  //     Validators.pattern(/^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/)])],
  //     password: ['', [Validators.required, Validators.pattern(/^(?=.*\d).{4,30}$/)]],
  //     confirmPassword: ['', Validators.required],
  //     companyName: ['', Validators.required],
  //     name: ['', Validators.required],
  //     phoneNumber: ['', Validators.required],
  //     city: ['', Validators.required],
  //     gstNumber: [''],
  //     // panNumber: [''],
  //     country: ['', Validators.required],
  //     line1: ['', Validators.required],
  //     // line2: ['', Validators.required],
  //     province: ['', Validators.required],
  //     zipcode: ['', Validators.required],
  //   },
  //     {
  //       validator: MustMatch('password', 'confirmPassword')
  //     });
  //   // console.log(HomePage.name, 'employer sign up form values:', this.employerSignUpForm);
  // }

  // verifyTeamMemberSignUp() {
  //   this.teamMemberSignUpForm = this.formBuilder.group({
  //     name: ['', Validators.required],
  //     // tslint:disable-next-line: max-line-length
  //     email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/)])],
  //     password: ['', [Validators.required, Validators.pattern(/^(?=.*\d).{4,30}$/)]],
  //     confirmPassword: ['', Validators.required],
  //   },
  //     {
  //       validator: MustMatch('password', 'confirmPassword')
  //     });
  // }

  // employerLogin(employerLogin: EmployerLogin) {
  //   this.btnLoading = true;
  //   // console.log(HomePage.name, 'employer login credentials:', employerLogin);
  //   this.loaderService.presentLoading('Verifying user');
  //   this.userService.emailLogin(employerLogin.email, employerLogin.password).then(() => {
  //     this.btnLoading = false;
  //     this.loaderService.dismissLoading();
  //   }).catch(error => {
  //     this.btnLoading = false;
  //     this.handleError(error);
  //     this.loaderService.dismissLoading();
  //   });
  // }

  // employeeLogin() {
  //   const phoneToEmail = '+91' + this.phonenumber + '@ivist.com';
  //   const phone = '+91' + this.phonenumber;
  //   this.btnLoading = true;
  //   this.loaderService.presentLoading('Verifying user');
  //   this.userService.checkUserExistsStatus(phone, true).subscribe((user) => {
  //     // console.log(HomePage.name, user, 'user');
  //     if (user.length) {
  //       this.btnLoading = false;
  //       this.userService.emailLogin(phoneToEmail, this.password).then(() => {
  //         this.btnLoading = false;
  //         this.loaderService.dismissLoading();
  //       }, () => {
  //         this.btnLoading = false;
  //         this.loaderService.dismissLoading();
  //         this.presentAlert('Invalid user id or password');
  //       });
  //     } else {
  //       this.btnLoading = false;
  //       this.snackBarService.showToaster('User is not active please contact employer');
  //       this.loaderService.dismissLoading();
  //     }
  //   });
  // }

  // onEmployeeSignUp() {
  //   this.ionRouter.navigateForward('employee-signup');
  // }


  // checkUserAsVerifiedHisEmail(clientId) {
  //   this.userService.checkUserAsVerifiedHisEmail().subscribe((isEmailVerifed) => {
  //     // console.log(HomePage.name, isEmailVerifed, 'is-Email-verfied');
  //     if (isEmailVerifed) {
  //       this.isEmailVerified = isEmailVerifed;
  //       this.checkCurrentUserSubscrption(clientId);
  //     } else {
  //       this.title = 'Please Verfiy Your Email And Login';
  //       this.isEmailVerified = false;
  //       return;
  //     }
  //   });
  // }

  // checkCurrentUserSubscrption(clientId) {
  //   this.userService.fetchCurrentSubscrption(clientId).subscribe((sub: UserSubscription[]) => {
  //     // console.log(HomePage.name, sub, 'subscription');
  //     if (sub) {
  //       this.nav.navigateRoot(['/employer/dashboard']);
  //     } else {
  //       // console.log(HomePage.name, 'subscription-not-done');
  //       this.router.navigate(['/subscription']);
  //       this.presentAlert('Kindly confirm your subscription');
  //     }
  //   });
  // }

  // redirectionToLogin() {
  //   this.router.navigate(['/employer-login']);
  //   this.isEmailVerified = true;
  //   this.loginType = true;
  // }

  // home() {
  //   this.location.back();
  // }

  // onChangeLoginType(value: string) {
  //   if (value === 'employer') {
  //     this.loginType = true;
  //   } else {
  //     this.loginType = false;
  //   }
  // }

  // onSignUp(value) {
  //   if (value === 'signup') {
  //     this.signUpForm = true;
  //     this.isTeamMemberSignUpForm = false;
  //   } else if (value === 'team-member-signup') {
  //     this.isTeamMemberSignUpForm = true;
  //     this.signUpForm = true;
  //     this.loginType = true;
  //   } else if (value === 'home') {
  //     this.teamMemberSignUpForm.reset();
  //     this.isTeamMemberSignUpForm = false;
  //     this.signUpForm = false;
  //     this.loginType = true;
  //   } else {
  //     this.employerSignUpForm.reset();
  //     this.signUpForm = false;
  //     this.isTeamMemberSignUpForm = false;
  //   }
  // }

  // employerInviteCheck(event) {
  //   // console.log(HomePage.name, event.srcElement.value, 'event');
  //   const emailAdress = event.srcElement.value;
  //   this.userService.getInvitesSentByClientForTeamMember(emailAdress).subscribe((invite) => {
  //     // console.log(HomePage.name, invite, 'invite');
  //     if (invite.length) {
  //       this.currentEmployerInvite = invite[0];
  //       this.isTeamMemberInvited = true;
  //     }
  //   });
  // }


  // onForgotPassword(userData) {
  //   if (userData === 'employee-password-reset') {
  //     this.router.navigate(['/employee-signup'], {
  //       queryParams: {
  //         feature: 'reset-password',
  //         userType: 'employee',
  //       }
  //     });
  //   } else if (userData === 'employer-password-reset') {
  //     this.router.navigate(['/forgot-password']);
  //   }
  // }

  // createEmployer(employerLogin: EmployerLogin) {
  //   this.btnLoading = true;
  //   // console.log(HomePage.name, employerLogin, 'employee');
  //   this.loaderService.presentLoading('Verifying user details');
  //   this.userService.checkUserAlreadySignUp(employerLogin.email)
  //     .then((userexists) => {
  //       // console.log(HomePage.name, userexists.length, 'data');
  //       if (!userexists.length) {
  //         this.btnLoading = false;
  //         this.loaderService.dismissLoading();
  //         if (employerLogin) {
  //           const clientAddress = {
  //             city: employerLogin.city,
  //             country: employerLogin.country,
  //             line1: employerLogin.line1,
  //             // line2: employerLogin.line2,
  //             province: employerLogin.province,
  //             zipcode: employerLogin.zipcode
  //           };
  //           // console.log(HomePage.name, clientAddress, 'clientAddress');
  //           this.userService.emailSignUp(employerLogin.email, employerLogin.password).then((user) => {
  //             // console.log(HomePage.name, 'authenticated user', user);
  //             this.userService.createUserInDB(user, 'client', clientAddress, employerLogin.companyName,
  //               employerLogin.name, employerLogin.phoneNumber, false, null, null, employerLogin.gstNumber);
  //             this.presentAlert('Successfully created, Kindly verfiy your email to login');
  //             this.userService.sendEmailVerification().then((a) => {
  //               this.signUpForm = false;
  //             });
  //           }).catch(error => {
  //             this.loaderService.dismissLoading();
  //             this.handleError(error);
  //           });
  //         }
  //       } else {
  //         this.btnLoading = false;
  //         this.loaderService.dismissLoading();
  //         this.presentAlert('User Already Registered With This Email ');
  //       }
  //     });
  // }


  // createTeamEmployer(teamMemberLogin) {
  //   this.btnLoading = true;
  //   // console.log(HomePage.name, teamMemberLogin, 'team');
  //   this.loaderService.presentLoading('Verifying user details');
  //   this.userService.checkUserAlreadySignUp(teamMemberLogin.email)
  //     .then((userexists) => {
  //       // console.log(HomePage.name, userexists.length, 'userexists');
  //       this.loaderService.dismissLoading();
  //       if (!userexists.length && this.currentEmployerInvite) {
  //         this.btnLoading = false;
  //         this.userService.emailSignUp(teamMemberLogin.email, teamMemberLogin.password).then((user) => {
  //           // console.log(HomePage.name, 'authenticated user', user);
  //           this.userService.createUserInDB(user, 'client', null, null,
  //             teamMemberLogin.name, this.currentEmployerInvite.contactNumber, true, this.currentEmployerInvite.clientId,
  //             this.currentEmployerInvite.employeeType);
  //           this.presentAlert('Successfully created, Kindly verfiy your email to login');
  //           this.userService.deleteInvite(this.currentEmployerInvite.id);
  //           this.userService.sendEmailVerification().then((a) => {
  //             this.signUpForm = false;
  //             this.isTeamMemberSignUpForm = false;
  //             this.userService.logout();
  //           });
  //         }).catch(error => {
  //           this.btnLoading = false;
  //           this.handleError(error);
  //           this.loaderService.dismissLoading();
  //         });
  //       } else {
  //         this.btnLoading = false;
  //         this.loaderService.dismissLoading();
  //         this.presentAlert('User already registered with this email ');
  //       }
  //     });
  // }

  // handleError(error) {
  //   if (error.code === 'auth/popup-blocked') {
  //     this.presentAlert('Your browser has disabled Popups. Please Try again');
  //   } else if (error.code === 'auth/popup-closed-by-user') {
  //     this.presentAlert('Please reload and try again');
  //   } else {
  //     this.snackBarService.showToaster(error.message);
  //   }
  //   return error.message;
  // }


  // async presentAlert(alertData) {
  //   const alert = await this.alertController.create({
  //     backdropDismiss: false,
  //     header: 'Alert',
  //     message: alertData,
  //     buttons: [
  //       {
  //         text: 'OK',
  //         handler: () => {
  //           alert.dismiss();
  //         }
  //       }],
  //     cssClass: 'p-3',
  //   });
  //   await alert.present();
  // }

  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }

  onLogin(userType) {
    if (userType === 'employer') {
      const employerSignUp = this.userService.getUserSignUpStatus();
      if (employerSignUp === 'complete') {
        this.ionRouter.navigateForward(['/employer-login'], {
          queryParams: {
            userType: 'employer',
          }
        });
      } else {
        this.ionRouter.navigateForward(['/employer-signup'], {
          queryParams: {
            userType: 'employer',
          }
        });
      }
    }

    if (userType === 'team-member') {
      const teamMemberSignUp = this.userService.getUserSignUpStatus();
      if (teamMemberSignUp === 'complete') {
        this.ionRouter.navigateForward(['/employer-login'], {
          queryParams: {
            userType: 'team-member',
          }
        });
      } else {
        this.ionRouter.navigateForward(['/team-member-signup']);
      }
    }

    if (userType === 'employee') {
      const employeeSignUp = this.userService.getUserSignUpStatus();
      if (employeeSignUp === 'complete') {
        this.ionRouter.navigateForward(['/employee-login']);
      } else {
        this.ionRouter.navigateForward(['/employee-signup']);
      }
    }
  }
}
