import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { HaltDashboardComponent } from './halt-dashboard/halt-dashboard.component';
import { CreateHaltComponent } from './create-halt/create-halt.component';
import { ContactComponent } from '../shared/contact/contact.component';

const haltRoutes: Routes = [
    {
        path: 'halt',
        children: [
            {
                path: 'dashboard',
                component: HaltDashboardComponent,
            },
            {
                path: 'create-halt',
                component: CreateHaltComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            }
        ]
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(haltRoutes)
    ],
    exports: [RouterModule]
})
export class EmployeeHaltRoutingModule { }