import { ListPage } from './../../employer-module/list/list.page';
import { ModalController, AlertController, Platform, NavController } from '@ionic/angular';
import { UploadService } from './../../services/upload.service';
import { Upload } from './../../entity/upload';
import { HaltService } from 'src/app/services/halt.services';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EventInfo, Event } from 'src/app/entity/event';
import { User } from 'src/app/entity/user';
import { ActivatedRoute, Router } from '@angular/router';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { Subscription, Observable } from 'rxjs';
import { AppLocationService } from 'src/app/services/app-location.service';
import { firestore } from 'firebase';
import { Location } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { File } from '@ionic-native/file/ngx';
import { Contacts, Contact, ContactField, ContactName } from '@ionic-native/contacts/ngx';
import { GoogleMap, GoogleMaps, Environment } from '@ionic-native/google-maps/ngx';
import { LoaderService } from 'src/app/services/loader.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { ClientService } from 'src/app/services/client.service';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Customer } from 'src/app/entity/clients';
import { IonicSelectableComponent } from 'ionic-selectable';

interface taskList {
  listName: String;
  taskId: Number;
  description: String;
  EmpName: String;
  CreatedDate: String;
}

@Component({
  selector: 'app-create-halt',
  templateUrl: './create-halt.component.html',
  styleUrls: ['./create-halt.component.scss'],
  providers: [UploadService]
})

export class CreateHaltComponent implements OnInit, OnDestroy {
  createHalt: FormGroup;
  searchString: string;
  currentUser: User;
  newHalt = true;
  haltId: string;
  isEventCompleted = false;
  title = 'Create Visit';
  locationCoords: any;
  locationSubscription: Subscription;
  map: GoogleMap;
  marker: any;
  isUploadProgress: Observable<number>;
  uploadedImageUrlList = [];
  currentHalt: Event;
  selectedCustomerId = '';
  customersList = [];
  custom: Event;
  filterDate: Date;
  filterFromDateRawValue: any;
  clicked = false;
  reenableButton = new EventEmitter<boolean>(false);
  showEndBtn = false;
  showSaveBtn = false;
  enableSelection = true;
  showLocationErrorAlert = false;
  isLocationFound = false;
  tasks: taskList[];
  selectTasks: any[];
  filteredListName: any[];
  selectListName: any;
  tasklistItems: any;
  subCollectionsArray: any;
  tasksSubCollection = [];
  imageEle: any;
  imageWidth: any;
  imageHeight: any;

  constructor(
    private formBuilder: FormBuilder, private userService: UserService, private uploadService: UploadService,
    private route: ActivatedRoute, private location: Location,
    private angularFirestore: AngularFirestore, private camera: Camera, private webview: WebView,
    private haltService: HaltService, private connectionService: ConnectionService, private locationService: AppLocationService,
    private file: File, private locationAccuracy: LocationAccuracy, private contacts: Contacts,
    private loaderService: LoaderService, private clientService: ClientService,
    private modalController: ModalController, private alertController: AlertController,
    private snackBarService: SnackBarService, private platform: Platform, private navCtrl: NavController,
    private router: Router) {
    this.filterDate = new Date();
    this.filterDate.setHours(0);
    this.filterDate.setMinutes(0);
    this.filterDate.setSeconds(0);
    this.filterFromDateRawValue = this.filterDate.toISOString();
  }


  onSelectCustomer(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this.onCustomSelection(event);
    // console.log('selected customer:', event);
  }

  ngOnInit() {
    this.createHaltForm();
    this.getRoutingParams();
    this.getCurrentuser();
    this.getTasklistItems(this.currentUser.clientId);
    this.checkLocationPermissionsAndTrack();
    // this.onClickSelectTask();
  }


  ngOnDestroy() {
    //this.stopTrackingLocation();
  }

  ionViewDidEnter() {
    this.loadMap();
  }


  onClickBackBtn() {
    this.location.back();
  }

  loadMap() {
    // console.log(CreateHaltComponent.name, 'loading map...');
    Environment.setEnv({
      'API_KEY_FOR_BROWSER_RELEASE': 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI',
      'API_KEY_FOR_BROWSER_DEBUG': 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI'
    });
    this.map = GoogleMaps.create('map_canvas', {
      preferences: {
        zoom: {
          minZoom: 15,
          maxZoom: 18
        }
      }
    });
    if (!this.isEventCompleted && this.locationCoords) {
      this.addMarkerToMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
    }
    if (this.isEventCompleted && this.currentHalt) {
      this.addMarkerToMap(this.currentHalt.endLocation.latitude, this.currentHalt.endLocation.longitude);
    }
    //// console.log(CreateHaltComponent.name, 'map created', this.map);
  }

  addMarkerToMap(lat: number, long: number) {
    if (this.map) {
      if (this.marker) {
        this.map.clear();
      }
      this.map.addMarker({
        title: 'you are here',
        icon: 'orange',
        animation: 'DROP',
        position: {
          lat,
          lng: long
        }
      }).then(marker => {
        this.marker = marker;
        this.map.setCameraTarget({ lat, lng: long });
      });
    }
  }

  async startTrackingLocation() {
    try {
      // this.locationCoords = await this.locationService.getCurrentLocation();
      this.locationSubscription = this.locationService.startLocation().subscribe(loc => {
        console.log("logging locationCoords ", loc);
        this.locationCoords = loc;
        // if (loc.coords.accuracy < 100) {
        // this.locationAccuracy = true;
        // } else {
        // this.locationAccuracy = false;
        // }
        this.showLocationErrorAlert = false;
        this.addMarkerToMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
      });
    } catch (err) {
      this.snackBarService.showToaster("Failed to fetch location", 2000);
    }
  }

  // stopTrackingLocation() {
  //   if (this.locationSubscription) {
  //     this.locationSubscription.unsubscribe();
  //   }
  // }

  getCurrentuser() {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
        // console.log(CreateHaltComponent.name, 'currentUser', this.currentUser);
        this.getCustomersList();
      }
    });
  }

  getCustomersList() {
    this.clientService.getCustomersOfClient(this.currentUser.clientId).subscribe((customers) => {
      // console.log(CreateHaltComponent.name, 'user list', customers);
      this.customersList = customers;
      this.customersList.sort((customerA: Customer, customerB: Customer) => {
        if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
        return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
      });
      // console.log(CreateHaltComponent.name, this.customersList, 'this.customersList');
    });
  }

  getRoutingParams() {
    this.route.queryParams.subscribe((halt) => {
      console.log('halt', halt);
      if (halt) {
        this.newHalt = halt.newHalt;
        this.haltId = halt.id;
        if (this.haltId) {
          if (halt.newHalt) {
            this.title = 'Create Visit';
            this.enableSelection = true;
          }
          if (halt.updateHalt) {
            this.title = 'Update Visit';
            this.showSaveBtn = true;
            this.enableSelection = true;
          }
          if (halt.endHalt) {
            this.title = 'End Visit';
            this.showEndBtn = true;
            this.enableSelection = false;
          }

          this.haltService.getHalt(this.haltId).subscribe((event: Event) => {
            // console.log(CreateHaltComponent.name, event, 'event-data-for-edit');
            if (event) {
              this.currentHalt = event;
              this.isEventCompleted = event.endTime !== null && event.endTime !== undefined ? true : false;
              if (this.isEventCompleted) {
                  this.title = 'Visit Completed';
                  this.tasksSubCollection = event.data.taskListId.Tasks;
                  //this.stopTrackingLocation();
                  if (event.endLocation) {
                    // console.log(CreateHaltComponent.name, 'halt is complete, setting end location', event.endLocation);
                    this.addMarkerToMap(event.endLocation.latitude, event.endLocation.longitude);
                  }
                }
              // setTimeout(() => {
                this.createHalt.patchValue(event.data);
              // } , 6000);
              this.uploadedImageUrlList = event.images ? event.images : [];
              // console.log(CreateHaltComponent.name, this.uploadedImageUrlList, 'this.uploadedImageUrlList');
            }
          });
        }
      }
    });
  }

  imageUpload(event) {
    const reader = new FileReader();
    if (event.target.files &&
      event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      // this.startUpload(file);
    }
  }

  startUpload(filePath: any, fileName: string) {
    // console.log(CreateHaltComponent.name, 'new file name ', fileName);
    // storage path
    const imagePath = `events/${this.currentUser.id}/${fileName}`;
    const uplaod = new Upload();
    uplaod.imagePath = imagePath;
    uplaod.filePath = filePath;
    uplaod.name = fileName;
    this.loaderService.presentLoading('Uploading image');
    this.uploadService.fileUpload(uplaod).subscribe((imageData) => {
      console.log(CreateHaltComponent.name, imageData, 'imagedata');
      this.loaderService.dismissLoading();
      if (imageData) {
        this.snackBarService.showToaster('Image uploaded successfully!');
        this.uploadedImageUrlList.push(imageData);
        this.updateHaltImages();
        // console.log(CreateHaltComponent.name, this.uploadedImageUrlList);
      }
    });
  }

  deleteImage(url) {
    const index = this.uploadedImageUrlList.indexOf(url);
    // to remove locally
    this.uploadedImageUrlList.splice(index, 1);
    this.title = 'Create Halt';
    this.updateHaltImages();
    // delete in firebase
    this.uploadService.deleteFileStorage(url).then(() => {
      // console.log(CreateHaltComponent.name, 'deleted');
    });
  }

  updateHaltImages() {
    this.haltService.updateHaltImages(this.haltId, this.uploadedImageUrlList).then(() => {
      // console.log(CreateHaltComponent.name, 'uploaded successfully');
    });
  }

  pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      const converted = this.webview.convertFileSrc(img);
      return converted;
    }
  }

  copyFileToLocalDir(namePath, currentName, newFileName): Promise<string> {
    // console.log(CreateHaltComponent.name, 'copy from ', namePath, currentName, ' to ', this.file.dataDirectory);
    return new Promise((resolve, reject) => {
      this.file.copyFile(namePath, currentName, this.file.dataDirectory, newFileName).then(success => {
        let filePath = this.file.dataDirectory + newFileName;
        resolve(filePath);
      }, error => {
        // console.log(CreateHaltComponent.name, 'Error loading image', error);
        reject('error loading image');
      });
    });
  }

  onAddImageClick() {
    const options: CameraOptions = {
      quality: 20,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true
    };
    //datetime stamp
    this.camera.getPicture(options).then((imagePath) => {
      // console.log(CreateHaltComponent.name, 'captured image', imagePath);

      const currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
      const correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
      const fileName = this.angularFirestore.createId() + '.jpg';
      this.copyFileToLocalDir(correctPath, currentName, fileName).then(filePath => {
        this.uploadService.getBlob(filePath).then(blob => {
          this.createCanvas(blob, fileName);
        });
      });
    }, (err) => {
      console.error(err);
    });
  }

  createCanvas(imageBlob, fileName) {
    let loadingObject;
    const url = URL.createObjectURL(imageBlob);
    console.log("src attribute url is", url);
    this.imageEle = new Image();
    this.imageEle.src = url;
    this.imageEle.addEventListener("load" , async () => {
      loadingObject = await this.loaderService.newLoader("Please wait while the Image is being uploaded");
      loadingObject.present();
      console.log("intrinsic width", this.imageEle.naturalWidth);
      console.log("intrinsic height", this.imageEle.naturalHeight);
      this.imageWidth = this.imageEle.naturalWidth;
      this.imageHeight = this.imageEle.naturalHeight;
      let canvas  = document.createElement('canvas');
      canvas.width = this.imageWidth;
      canvas.height = this.imageHeight + 500;
      let ctx = canvas.getContext('2d');
      ctx.font = '32px "Sans"';
      ctx.drawImage(this.imageEle, 0, 0, this.imageWidth, this.imageHeight);
      ctx.fillStyle = '#FFFFFF';
      await this.drawOnCanvas(canvas, ctx, fileName);
      URL.revokeObjectURL(url);
      loadingObject.dismiss();
    });
  }

  async drawOnCanvas(canvas, ctx, fileName) {
    const dateTimeObj = new Date();

    ctx.fillText("Start Latitude: " + `${this.locationCoords.coords.latitude.toFixed(2).toString()}` , this.imageWidth/10 , this.imageHeight+ 100);
    ctx.fillText("Start Longitude: " + `${this.locationCoords.coords.longitude.toFixed(2).toString()}`, this.imageWidth/10 , this.imageHeight+ 200);
    ctx.fillText("Start Date: " + `${this.currentHalt.startTime.toDate().toDateString()}`, this.imageWidth/10 , this.imageHeight+ 300);
    ctx.fillText("Start Time: " + `${this.currentHalt.startTime.toDate().toLocaleTimeString()}`, this.imageWidth/10, this.imageHeight+ 400);

    ctx.fillText("Upload Latitude: " + `${this.locationCoords.coords.latitude.toFixed(2).toString()}` , this.imageWidth/2 , this.imageHeight+ 100);
    ctx.fillText("Upload Longitude: " + `${this.locationCoords.coords.longitude.toFixed(2).toString()}`, this.imageWidth/2 , this.imageHeight+ 200);
    ctx.fillText("Uploaded Date: " + `${dateTimeObj.toDateString()}`, this.imageWidth/2 , this.imageHeight+ 300);
    ctx.fillText("Uploaded Time: " + `${dateTimeObj.toLocaleTimeString()}`, this.imageWidth/2 , this.imageHeight+ 400);

    await this.canvasToBlobAndUpload(canvas, fileName);
  }

  async canvasToBlobAndUpload(canvas, fileName) {
    let uploadedImageUrl;
    await canvas.toBlob(async (blob) => {
      console.log("blob inside the toBlob()", blob);
      uploadedImageUrl = await this.uploadService.uploadBlobForImage(blob, fileName, this.currentUser);
      console.log("upload", uploadedImageUrl);

      this.uploadedImageUrlList.push(uploadedImageUrl);
      this.updateHaltImages();
    },'image/jpeg', 1.0);
  }

  createHaltForm() {
    this.createHalt = this.formBuilder.group({
      customerId: [''],
      companyName: ['', Validators.required],
      contactPerson: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      taskListId: [''],
      notes: ['']
    });
  }

  async createHaltForEmployee(eventInfo: EventInfo) {
    if (!this.locationCoords) {
      this.showLocationErrorAlert = true;
      this.loaderService.dismissLoading();
      this.confirmLocationAlert('Could not fetch location, please enable location from settings');
      return;
    }
    // console.log(CreateHaltComponent.name, 'event info', eventInfo);
    const halt = new Event();
    halt.type = IAceeptMessageConstant.EVENT_TYPE_HALT;
    halt.clientId = this.currentUser.clientId;
    halt.createdByUser = this.currentUser.id;
    halt.createdByUserName = this.currentUser.name;
    if (eventInfo.customerId) {
      const customerObj = this.customersList.find(customer => customer.id === eventInfo.customerId);
      if (customerObj) {
        eventInfo.customerName = customerObj.companyName;
      }
    }
    halt.data = eventInfo;
    if (!this.haltId) {
      halt.startLocation = new firestore.GeoPoint(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
      halt.startLocationAccuracy = this.locationCoords.coords.accuracy;
      if (this.connectionService.isConnected()) {
        halt.startTime = firestore.FieldValue.serverTimestamp();
      } else {
        halt.startTime = new Date();
      }
      console.log('halt data', halt);
      const created = await this.haltService.createHalt(halt);
      //console.log('created event ', created)
      this.haltService.setActiveHalt(created);
      //console.log(CreateHaltComponent.name, 'saved new halt', created);
      //this.haltService.getHalt(created.id).subscribe(doc => console.log(CreateHaltComponent.name, 'got offline data', doc));
      this.snackBarService.showToaster('Successfully created');
      this.location.back();
    } else {
      //console.log('ending halt')
      this.title = 'End halt';
      this.haltService.getHalt(this.haltId).subscribe(async (currentHalt: any) => {
        //console.log('currentHalt', currentHalt)
        if (currentHalt) {
          await this.haltService.endHalt(this.haltId, currentHalt, this.locationCoords);
          this.haltService.setActiveHalt(null);
          console.log(CreateHaltComponent.name, 'ended halt info');
          this.snackBarService.showToaster('Successfully ended');
          this.showSaveBtn = false;
          this.showEndBtn = false;
          this.newHalt = false;
          this.location.back();
        }
      });
    }
  }

  onCustomSelection(event) {
    console.log(CreateHaltComponent.name, 'selection event', event);
    if (event.value) {
      const customValue = this.customersList.find((item) => item.id === event.value.id);
      console.log(CreateHaltComponent.name, 'selected value ', customValue);
      this.createHalt.get('companyName').setValue(customValue.companyName);
      this.createHalt.get('contactPerson').setValue(customValue.contactName);
      this.createHalt.get('mobileNumber').setValue(customValue.contactNumber);
      this.createHalt.get('customerId').setValue(customValue.id);
      this.createHalt.get('taskListId').setValue(customValue.listName);

      this.getTasklistItems(this.currentUser.clientId, customValue.companyName);
    } else {
      this.createHalt.get('companyName').setValue('');
      this.createHalt.get('contactPerson').setValue('');
      this.createHalt.get('mobileNumber').setValue('');
      this.createHalt.get('customerId').setValue('');
      this.createHalt.get('taskListId').setValue('');
    }
    console.log('here is CustomSelection form values', this.createHalt);
  }

  // searchTasks(event: { component: IonicSelectableComponent; text: string }){
  //   let text = event.text.trim().toLowerCase();
  //   event.component.startSearch();

  //   if (!text) {
  //     event.component.items = [];
  //     event.component.endSearch();
  //     return;
  //   }

  //   //     event.component.items = this.filterPorts(ports, text);
  //   //     event.component.endSearch();
  //   //   });
  // // }
  // }

  checkLocationPermissionsAndTrack() {
    if (this.platform.is('desktop')) {
      this.startTrackingLocation();
      return;
    }
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        // console.log(CreateHaltComponent.name, 'location services must be requested');
        // the accuracy option will be ignored by iOS
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => {
            // console.log(CreateHaltComponent.name, 'Location permission request successful');
            this.startTrackingLocation();
          },
          error => {
            // console.log(CreateHaltComponent.name, 'Error requesting location permissions', error);
          }
        );
      } else {
        // console.log(CreateHaltComponent.name, 'location permissions already granted');
        this.startTrackingLocation();
      }
    }, error => {
      // console.log(CreateHaltComponent.name, 'error requesting location access' + error);
    });
  }

  addContactFromDevice() {
    this.contacts.pickContact().then(
      (contact) => {
        if (contact.phoneNumbers[0].value.indexOf('+91') > -1) {
          contact.phoneNumbers[0].value = contact.phoneNumbers[0].value.substring(3);
          // console.log(CreateHaltComponent.name, 'Access to device Contacts!', contact);
          this.createHalt.patchValue({ contactPerson: contact.displayName, mobileNumber: contact.phoneNumbers[0].value });
        } else {
          this.createHalt.patchValue({ contactPerson: contact.displayName, mobileNumber: contact.phoneNumbers[0].value });
        }
      },
      (error: any) => { console.error(CreateHaltComponent.name, 'Error saving contact.', error); }
    );
  }

  onNavigateBack() {
    this.location.back();
  }

  onUpdateHalt(eventInfo: EventInfo) {
    if (!this.locationCoords) {
      this.showLocationErrorAlert = true;
      this.loaderService.dismissLoading();
      this.confirmLocationAlert('Could not fetch location, please enable location from settings');
      return;
    }
    if (eventInfo.customerId) {
      const customerObj = this.customersList.find(customer => customer.id === eventInfo.customerId);
      if (customerObj) {
        eventInfo.customerName = customerObj.companyName;
      }
    }


    this.showLocationErrorAlert = false;
    this.haltService.updateHalt(this.haltId, eventInfo);
    // console.log(CreateHaltComponent.name, 'updated halt info');
    this.snackBarService.showToaster('Successfully updated');
    this.location.back();
  }

  async presentAlert(data, image) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.deleteImage(image);
          }
        }
      ],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  async onConfirmForceKillHalt(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.onForceKill();
          }
        }
      ],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  onForceKill() {
    this.haltService.deleteHalt(this.haltId);
    this.snackBarService.showToaster('Visit has been force ended');
    this.location.back();
  }

  async confirmLocationAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      backdropDismiss: false,
      message: data,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  // onClickSelectTask() {
  //   this.selectTasks = this.tasks.filter((list) =>
  //     list.EmpName == this.currentUser.name
  //   );
  //   this.filteredListName = [...this.selectTasks];
  //   console.log("🚀 ~ file: create-halt.component.ts ~ line 580 ~ CreateHaltComponent ~ onClickSelectTask ~ this.filteredListName", this.filteredListName)
  // }

  // onSelectTask(event: {
  //   component: IonicSelectableComponent,
  //   value: any
  // }) {
    // this.onCustomSelection(event);
    // console.log("🚀 ~ file: create-halt.component.ts ~ line 585 ~ CreateHaltComponent ~ event", event)
  // }

  getTasklistItems(clientId: string, companyName?: string) {
    if(companyName){
      const result = this.haltService.getTaskList(clientId, companyName);
      result.subscribe(res => this.tasklistItems = res);
    }
    else{
      const result = this.haltService.getTaskList(clientId);
      result.subscribe(res => this.tasklistItems = res);
    }
  }

    async onSelectTaskList(event: {
      component: IonicSelectableComponent,
      value: any
    }) {
      console.log("🚀 ~ file: create-halt.component.ts ~ line 637 ~ CreateHaltComponent ~ event", event);
      this.subCollectionsArray = await this.haltService.getTaskListSubcollection(event.value.id);
      console.log("🚀 ~ file: create-halt.component.ts ~ line 639 ~ CreateHaltComponent ~ subCollecionsArray", this.subCollectionsArray);
      this.onCustomSelectionTestForTaskList(event);
  }

  onCustomSelectionTestForTaskList(event) {
    console.log(CreateHaltComponent.name, 'selection event', event);
    if (event.value) {
      const customValue = this.customersList.find((item) => item.id === event.value.customerId);
      console.log(CreateHaltComponent.name, 'selected value ', customValue);
      this.createHalt.get('companyName').setValue(customValue.companyName);
      this.createHalt.get('contactPerson').setValue(customValue.contactName);
      this.createHalt.get('mobileNumber').setValue(customValue.contactNumber);
      this.createHalt.get('customerId').setValue(customValue.id);
      this.createHalt.get('taskListId').setValue({ taskListId: event.value.id, listName : event.value.listName, Tasks : this.subCollectionsArray });
    } else {
      this.createHalt.get('companyName').setValue('');
      this.createHalt.get('contactPerson').setValue('');
      this.createHalt.get('mobileNumber').setValue('');
      this.createHalt.get('customerId').setValue('');
      this.createHalt.get('taskListId').setValue('');
    }
    console.log('here is CustomSelection form values', this.createHalt);
  }
}

