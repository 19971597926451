import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LoadingController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    constructor(public loadingController: LoadingController) { }

    private readonly isLoadingSrc = new Subject<boolean>();

    isLoading() {
        return this.isLoadingSrc.asObservable();
    }

    showLoader() {
        this.isLoadingSrc.next(true);
    }

    hideLoader() {
        this.isLoadingSrc.next(false);
    }

    presentLoading(msg) {
        this.loadingController.create({
            spinner: 'crescent',
            duration: 300,
            message: msg,
            translucent: true,
        }).then(loading => {
            loading.present();
        });
    }

    async newLoader(msg: string){
        return await this.loadingController.create({
            spinner: 'crescent',
            duration: 600000,
            message: msg,
            translucent: true,
        });
    }
    

    dismissLoading() {
        //  this.loadingController.dismiss();
    }
}
