import { UserSubscription } from './../../entity/subscription';
import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsComponent implements OnInit {
  @Input() tabLinks = [];
  @Input() selectedTab: any;

  constructor() {

  }

  ngOnInit() {
    // console.log(TabsComponent.name, this.tabLinks, 'tabLinks');
  }

}
