import { AlertController } from '@ionic/angular';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { database } from 'firebase';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  @Input() imageList = [];
  @Output() deleteImageO = new EventEmitter();
  constructor(
    private alertController: AlertController,
    private location: Location,
    private router: Router) { }

  ngOnInit() {
    // console.log(ImageGalleryComponent.name, 'image list', this.imageList);
  }

  deleteImage(image) {
    this.deleteImageO.emit(image);
  }

  async presentAlert(data, image) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.deleteImage(image);
          }
        }
      ],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  onNavigateBack() {
    this.router.navigate(['/']);
  }
}
