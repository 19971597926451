import { LoaderService } from 'src/app/services/loader.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ClientService } from 'src/app/services/client.service';
import { Customer } from 'src/app/entity/clients';
import { EventTemplate } from 'src/app/entity/event.template';
import { EventService } from 'src/app/services/event.services';
import { Location } from '@angular/common';
import { User } from 'src/app/entity/user';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { PatrolService } from 'src/app/services/patrol.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-create-patrol',
  templateUrl: './create-patrol.component.html',
  styleUrls: ['./create-patrol.component.scss'],
})
export class CreatePatrolComponent implements OnInit {
  createPatrolForm: FormGroup;
  currentUser: User;
  customers: Customer[] = [];
  patrolId: string;
  patrolTemplate: EventTemplate;
  editPatrol = false; // for editing patrol
  customerSelect = '';
  title = 'Create Patrol';
  btnLoading = false;
  disableTeam  = false;

  constructor(
    private formBuilder: FormBuilder, private router: Router, private clientService: ClientService,
    private eventService: EventService, private userService: UserService, private location: Location,
    private loaderService: LoaderService, private route: ActivatedRoute, private patrolService: PatrolService,
    private snackBarService: SnackBarService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['patrol']) {
        this.editPatrol = true;
        this.title = 'Update Patrol';
        this.getPatrolById(params['patrol']);
        // console.log("user param => ", params.user);
        if(params.user === "Team Member") {
          // console.log("team member so disable");
          //disable editing for team members, just viewing
          this.disableTeam = true; 
        }
      }
    });
    this.getCustomersList();
    this.createCustomerForm();
  }

  async getPatrolById(patrolId: string) {
    const eventTemplate: EventTemplate = await this.patrolService.getEventTemplateById(patrolId);
    if (eventTemplate) {
      this.patrolTemplate = eventTemplate;
      this.customerSelect = eventTemplate.customerId;
      // console.log(CreatePatrolComponent.name, this.patrolTemplate, 'patrolTemplate received');
      // console.log(CreatePatrolComponent.name, 'fetched patrol', this.patrolTemplate);
      this.createPatrolForm.patchValue({ name: eventTemplate.name, customer: eventTemplate.customerName});
    }
  }

  async getCustomersList() {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    this.loaderService.presentLoading('Loading customers list');
    this.customers = await this.clientService.getCustomersOfClient(this.currentUser.clientId).toPromise();
    // this.loaderService.dismissLoading();
    this.customers.sort((customerA: Customer, customerB: Customer) => {
      if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
      return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
    });
    // console.log(CreatePatrolComponent.name, CreatePatrolComponent.name, 'fetched customers', customers);
    // this.loaderService.dismissLoading();
  }

  createCustomerForm() {
    this.createPatrolForm = this.formBuilder.group({
      name: ['', Validators.required],
      customer: ['', Validators.required],
    });
  }

  onCreatePatrol() {
    this.btnLoading = true;
    // console.log('form values', this.createPatrolForm.value);
    const patrolData = this.createPatrolForm.value;
    const template = new EventTemplate();
    if (!this.editPatrol) {
      template.name = patrolData.name;
      template.customerId = patrolData.customer.id;
      template.customerName = this.customers.find(customer => customer.id === patrolData.customer.id).companyName;
      template.createdByUser = this.currentUser.id;
      template.clientId = this.currentUser.clientId;
      template.type = IAceeptMessageConstant.TEMPLATE_TYPE_PATROL;
      template.isActive = true;
      this.loaderService.presentLoading('Adding patrol details');
      this.eventService.createEventTemplate(template).then(() => {
        this.loaderService.dismissLoading();
        this.btnLoading = false;
        this.snackBarService.showToaster('Patrol created successfully');
        // console.log(CreatePatrolComponent.name, 'Patrol created successfully');
        this.location.back();
      });
    } else {
      // console.log(CreatePatrolComponent.name, 'patrol update', this.patrolTemplate);
      this.patrolTemplate.name = this.createPatrolForm.get('name').value;
      this.loaderService.presentLoading('Updating patrol info');
      this.eventService.updateEventTemplate(this.patrolTemplate).then(() => {
        this.loaderService.dismissLoading();
        this.editPatrol = false;
        this.btnLoading = false;
        this.snackBarService.showToaster('Patrol updated successfully');
        // console.log(CreatePatrolComponent.name, 'Patrol updated successfully');
        this.location.back();
      }, (err) => {
        this.loaderService.dismissLoading();
        this.btnLoading = false;
        // console.log(CreatePatrolComponent.name, err, 'err-updating-patrol');
      });
    }
  }

  onSelectCustomer(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    // console.log('selected customer:', event);
  }
}
