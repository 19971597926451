// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDslNy9oN4wq3nQYup5KLtYLpLVfmYVB0Y",
    authDomain: "ivisit-f2ce6.firebaseapp.com",
    databaseURL: "https://ivisit-f2ce6.firebaseio.com",
    projectId: "ivisit-f2ce6",
    storageBucket: "ivisit-f2ce6.appspot.com",
    messagingSenderId: "601553586109",
    appId: "1:601553586109:web:9e4c4936c96c27b6d434d4",
    measurementId: "G-SWE4H49ZH4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
