import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import taskData from "../../../assets/json/task.json";
import { LoaderService } from './../../services/loader.service';
import { User } from 'src/app/entity/user';
import { map } from "rxjs/operators";
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from 'src/app/services/user.service';
import { TaskService } from 'src/app/services/task.service';
import { ClientService } from 'src/app/services/client.service';
import { Customer } from 'src/app/entity/clients';
import { NavController } from '@ionic/angular';

//interfaces

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {

  tasks: any ; //need to fetch this from firestore
  employees: any; //list of employees
  taskSubColl: any;
  hideTaskFilter: boolean;
  showFilteredTask: boolean;
  filterFromEmployeesList: String;
  searchString: string;
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  noDataFound = false;
  btnLoading = false;
  isMobile: boolean;
  currentUser: User;
  filterData: any;
  filteredTasks: any;
  filterEmployee: any;
  selectedCustomer: any;
  createdDate: Date;
  customersList:any = [];
  subDocs:any;
  taskCollection: any;
  filteredTaskListName: any = [];
  constructor(
    private location: Location,
    private loaderService: LoaderService,
    private userService: UserService,
    private clientService: ClientService,
    private taskService: TaskService,
    private navCtrl: NavController,
    private angularFirestore: AngularFirestore,
  ) {
    this.filterFromDate = new Date();
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    let testArray = [];
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    await this.getEmployeesOfClient(this.currentUser.clientId);
    await this.getCustomersList();
    this.taskCollection = this.angularFirestore.collection('taskList', 
    ref => ref
      .where('clientId', '==', this.currentUser.clientId)
      .orderBy('listName')
      .orderBy('companyName'));
    const descriptions = this.taskCollection.snapshotChanges().pipe(map((actions:any) => actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return { ...data, id }
    })));
    descriptions.subscribe(dataSource => { 
        console.log("here is the filtered tasks list ->", dataSource);
        dataSource.map((ele) => {
          if(testArray && testArray.includes(ele.listName)) {
            // console.log(ele.listName , "listName already exists in the filter array");
          }
          else {
            const listName = ele.listName;
            const listId = ele.id;
            testArray.push(listName);
            this.filteredTaskListName.push({ open: false , listName, listId });
          }
        });
        console.log("filtered taskListName ", this.filteredTaskListName);
        return this.filteredTasks = dataSource;
    });
  }

  getTasks(id:string){
    const listItems = this.taskService.getTaskSubCollection(id);
    return listItems;
  }

  async getCustomersList() {
    this.customersList = await this.clientService.getCustomersOfClient(this.currentUser.clientId).toPromise();
    console.log('customers list', this.customersList);
    this.customersList.sort((customerA: Customer, customerB: Customer) => {
      if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
      return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
    });
  }

  async getEmployeesOfClient(clientId: string) {
    this.employees = await this.userService.getEmployeesOfClient(clientId).toPromise();
    // console.log(this.employees);
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    this.filterFromDate = new Date(date);
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    this.filterToDateRawValue = date;
    this.filterToDate = new Date(date);
  }

  async onTaskInputFilter() {
    const fromDate = new Date(this.filterFromDate).setHours(0, 0, 0, 0);
    const toDate = new Date(this.filterToDate).setHours(23, 59, 59, 59);
    console.log("current start date ", new Date(fromDate));
    console.log("current end date ", new Date(toDate));
    console.log("selected customer is ",this.selectedCustomer);
    this.hideTaskFilter = !this.hideTaskFilter;
    this.btnLoading = true;
    this.showFilteredTask = !this.showFilteredTask;
    // this.loaderService.presentLoading('Loading tasks');
    try { 
      await this.getTasksByFilter(this.currentUser.clientId ,new Date(fromDate), new Date(toDate), this.selectedCustomer);
      this.btnLoading = false;
      // this.loaderService.dismissLoading();
    } catch (error) {
      console.error("error fetching tasks", error);
    }
    // this.filteredTasks = this.tasks.filter((task) => task.EmpName === this.selectedEmployee
    //   && this.stringToDateConversion(task.CreatedDate) >= fromDate &&
    //   this.stringToDateConversion(task.CreatedDate) <= toDate);
  }

  async getTasksByFilter(clientId: string, fromDate: Date, toDate: Date, customerName: string) {
    console.log("fetching the query results from getTasksByFilter");
    this.filterData = await this.taskService.getAllTaskLists(clientId, fromDate, toDate, customerName);
    console.log("new data ", this.filterData);
    this.filteredTasks = [...this.filterData];
  }

  onFilterTaskClear() {
    this.filterFromDate = new Date();
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
    // this.selectedCustomer = null;
  }

  removeDuplicateEmp(EmpName: any[]){
    var uniqueEmp = [];
    EmpName.forEach(element => {
      if (!uniqueEmp.includes(element)){
        uniqueEmp.push(element);
      }
    });
    return uniqueEmp;
  }

  stringToDateConversion(datestring) {
    const [day, month, year] = datestring.split('/');
    const date = new Date(+year, month - 1, +day);
    return date;
  }

  onSelectCustomer(event) {
    if (event && event.value) {
      console.log(event);
      this.selectedCustomer = event.value.companyName;
      console.log(this.selectedCustomer);
    }
  }

  onNavigateBack() {
    this.location.back();
  };

  onShowTaskList() {
    this.hideTaskFilter = !this.hideTaskFilter;
    this.showFilteredTask = !this.showFilteredTask;
  }

  showFilteredResults() {
    this.showFilteredTask = !this.showFilteredTask;
    this.hideTaskFilter = !this.hideTaskFilter;
  }

  toggleSection(index, id){
    if(!this.filteredTaskListName[index].open) {
      // const collectionDocs = this.getTasks(id);
      Object.assign(this.filteredTaskListName[index]);
      console.log("toggle Section collectionDocs", id, this.filteredTaskListName);
    }
    this.filteredTaskListName[index].open = !this.filteredTaskListName[index].open;
  }

  editTaskList(event, task){
    event.stopPropagation();
    // console.log("~ log at 196", task);
    this.navCtrl.navigateForward(['/employer/task-input'], { queryParams: { edit: 'true', id: task.id } });
  }
}