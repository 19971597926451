import { HaltService } from 'src/app/services/halt.services';
import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/entity/event';
import { EventService } from 'src/app/services/event.services';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { LoaderService } from 'src/app/services/loader.service';
import { Router } from '@angular/router';
import { EventTemplate } from 'src/app/entity/event.template';
import { ModalController, NavController } from '@ionic/angular';
import { HaltDetailsModalComponent } from 'src/app/shared/halt-details-modal/halt-details-modal.component';
import { getDistance, getPreciseDistance, getPathLength } from 'geolib';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
//import { MessagingService } from 'src/app/services/message.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-halts',
  templateUrl: './halts.component.html',
  styleUrls: ['./halts.component.scss'],
})
export class HaltsComponent implements OnInit {
  halts: Event[] = [];
  filteredActiveHalts: Event[] = [];
  currentUser: User;
  endDate: Date;
  haltTemplates: EventTemplate[] = [];
  searchString: string;
  showFilteredHalts = true;
  showFilteredCustomers = false;
  showFilteredEmployees = false;
  filteredActiveCustomers = {};
  filteredActiveEmployees = {};
  message: any;
  distanceRange = 0;
  private topLimit: number = 30;
  dataSource: any = [];

  constructor(private haltService: HaltService, private userService: UserService,
    private router: Router, private loaderService: LoaderService,
    public modalController: ModalController, private navCtrl: NavController) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.getHaltsOfClient(this.currentUser.clientId);
      // this.messagingService.requestPermission(this.currentUser.id);
      // this.message = this.messagingService.currentMessage;
      // this.messagingService.receiveMessage();
      // console.log(this.message, 'message');
    });
    // firebase.initializeApp({
    //   'messagingSenderId': '1030285670157' // Keep same
    // });
    // const messaging = firebase.messaging();
    // console.log(messaging, 'messaging');
    // messaging
    //   .requestPermission()
    //   .then(() => {
    //     console.log("Notification permission granted.");
    //     // get the token in the form of promise
    //     return messaging.getToken();
    //   })
    //   .then((token) => {
    //     // print the token on the HTML page
    //     console.log("token is : " + token);
    //   })
    //   .catch((err) => {
    //     console.log("Unable to get permission to notify.", err);
    //   });
  }

  loadData(event) {
    setTimeout(() => {
      this.topLimit += 30;
      this.dataSource = this.halts.slice(0, this.topLimit);
      event.target.complete();
      if (this.dataSource.length === this.halts.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  routeChart(){
    // this.navCtrl.navigateRoot('bar-Chart');
    this.router.navigate(['/employer/bar-Chart'], { queryParams: { haltsLastDay: true } });
  }

  routeRangeChart() {
    this.router.navigate(['/employer/bar-Chart'], { queryParams: { haltRange: true } });
  }

  getHaltsOfClient(clientId: string) {
    // // console.log(HaltsComponent.name, 'fetching halts of client', clientId, this.filterDate, this.endDate);

    const subEndDateValue = sessionStorage.getItem("subscription");
    console.log("subscription value from session storage", subEndDateValue);
    this.subEndDateCheck(subEndDateValue);

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    this.loaderService.presentLoading('Loading halts list');
    this.haltService.getAllHaltsOfClient(clientId, today).subscribe(halts => {
      // this.loaderService.dismissLoading();
      this.halts = halts;
      // console.log(HaltsComponent.name, 'Fetch halts of client', this.halts);
      this.filteredActiveHalts = this.removeDuplicates(this.halts, 'id');
      // console.log(HaltsComponent.name, 'Filtered halts', this.filteredActiveHalts);
      this.groupEventsByCustomer(this.halts);
      this.groupEventsByEmployees(this.halts);
      this.dataSource = halts.slice(0, this.topLimit);
    });
    // this.loaderService.dismissLoading();
  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.navCtrl.navigateRoot(['/home']);
    }
  }

  groupEventsByCustomer(halts: Event[]) {
    this.filteredActiveCustomers = halts.reduce((values, halt) => {
      (values[halt.data['customerName']] = values[halt.data['customerName']] || []).push(halt);
      return values;
    }, {});
    // console.log(HaltsComponent.name, 'Filtered customers', this.filteredActiveCustomers);
  }

  groupEventsByEmployees(halts: Event[]) {
    this.filteredActiveEmployees = halts.reduce((values, halt) => {
      (values[halt['createdByUserName']] = values[halt['createdByUserName']] || []).push(halt);
      return values;
    }, {});
    // console.log(HaltsComponent.name, 'Filtered employees', this.filteredActiveEmployees);
  }

  getActiveCustomersLength() {
    return Object.keys(this.filteredActiveCustomers).length;
  }

  getActiveEmployeesLength() {
    return Object.keys(this.filteredActiveEmployees).length;
  }

  getHalt(halt: Event) {
    // console.log(HaltsComponent.name, halt, 'halt');
    this.router.navigate(['/halt/create-halt'], {
      queryParams: {
        haltId: halt.id,
      }
    });
  }

  checkIfStartTimeIsLessthanEndTime(startTime, endTime) {
    if (startTime && startTime.seconds && endTime && endTime.seconds) {
      const startTimeDate = new Date(startTime.seconds * 1000);
      const endTimeDate = new Date(endTime.seconds * 1000);
      // // console.log(startTimeDate, 'startTimeDate');
      // // console.log(endTimeDate, 'endtime');
      if (endTimeDate > startTimeDate) {
        return 'Completed';
      } else {
        return '';
        //return 'Invalid Data';
      }
    } else {
      return 'Running';
    }
  }

  removeDuplicates(originalArray, prop) {
    const newArray = [];
    const lookupObject = {};

    for (var i in originalArray) { lookupObject[originalArray[i][prop]] = originalArray[i]; }

    for (i in lookupObject) { newArray.push(lookupObject[i]); }
    return newArray;
  }

  onViewDetails(halt) {
    // console.log(HaltsComponent.name, 'onViewDetails', halt);
    this.showHaltDetails(halt);
  }

  async showHaltDetails(halt) {
    const modal = await this.modalController.create({
      component: HaltDetailsModalComponent,
      componentProps: {
        'halt': halt,
      }
    });
    return await modal.present();
  }

  getEmployeeName() {

  }

  viewDetails(type) {
    if (type === 'filteredPatrols') {
      this.showFilteredHalts = true;
      this.showFilteredCustomers = false;
      this.showFilteredEmployees = false;
    }
    if (type === 'filteredCustomers') {
      this.showFilteredHalts = false;
      this.showFilteredCustomers = true;
      this.showFilteredEmployees = false;
    }
    if (type === 'filteredEmployees') {
      this.showFilteredHalts = false;
      this.showFilteredCustomers = false;
      this.showFilteredEmployees = true;
    }
  }

  checkForLocationRange(startLocation, endLocation) {
    if (startLocation && endLocation) {
      this.distanceRange = getPreciseDistance(
        { latitude: startLocation.latitude, longitude: startLocation.longitude },
        { latitude: endLocation.latitude, longitude: endLocation.longitude }
      );
      //console.log('distanceRange', distanceRange);
      if (this.distanceRange && this.distanceRange > IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE) {
        return `End location appears to be ${this.distanceRange} mtrs away`;
      } else {
        return ''
      }
    }
    else return ''
  }
  // sendPushNotification() {
  //   const userId = '2222';
  //   this.messagingService.requestPermission(userId);
  //   this.messagingService.receiveMessage();
  //   this.message = this.messagingService.currentMessage;

  //   this.messagingService.sendPushMessage("Web push notification", "HI, Halt Firebase test messsage");
  // }

}
