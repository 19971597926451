import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class TaskService {
    constructor(private angularFirestoreDb: AngularFirestore) {

    }

    getTaskCollection(id: string){
        return this.angularFirestoreDb.collection('taskList').doc(id).valueChanges();
    }

    getTaskSubCollection(id: string) {
        return this.angularFirestoreDb.collection('taskList').doc(id).collection('task').get().pipe(map(res => res.docs.map(d => d.data())));        
    }

    async getAllTaskListsOfTheSameName( clientId: string, listName: string) {
        const res = await this.angularFirestoreDb.collection('taskList',
            ref => ref
            .where('clientId', '==', clientId)
            .where('listName', '==', listName)
            .orderBy('createdDate', 'desc'))
            .get().pipe(map(res => res.docs.map(doc => {
                const docsData = doc.data();
                return {...docsData};
            }))).toPromise();
        // console.log('all tasks with same listnames ----', res);
        return res;
    }
    
    async getAllTaskLists(clientId: string, fromDate: Date, toDate: Date, customerName: any) {
        const res = await this.angularFirestoreDb.collection('taskList',
            ref => ref
            .where('clientId', '==', clientId)
            .where('createdDate', '>=' , fromDate)
            .where('createdDate', '<=', toDate)
            .where('companyName', '==', customerName)
            .orderBy('createdDate', 'desc'))
            .get().pipe(map(res => res.docs.map(doc => {
                const docsData = doc.data(); 
                const collectionDocs = this.angularFirestoreDb.collection(`${doc.ref.path}/task`).valueChanges();
                return {...docsData, collectionDocs};
            }))).toPromise();
        console.log('filtering tasks here ----', res);
        return res;
    }

    deleteTaskDoc(taskListId, id){
        return this.angularFirestoreDb.collection('taskList').doc(taskListId).collection('task').doc(id).delete();
    }
}

