import { AlertController } from '@ionic/angular';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Location } from "@angular/common";
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from 'src/app/services/user.service';
import { ClientService } from 'src/app/services/client.service';
import { Customer } from 'src/app/entity/clients';
import { TaskService } from 'src/app/services/task.service';
import { ActivatedRoute } from '@angular/router';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-task-input',
  templateUrl: './task-input.component.html',
  styleUrls: ['./task-input.component.scss'],
})
export class TaskInputComponent implements OnInit {

  @ViewChild('customerComponent', { static:false }) customerComponent: IonicSelectableComponent;
  currentUser: any;
  taskInputForm: FormGroup;
  taskCollection: any;
  selectedCustomer: any;
  customersList:any = [];
  editMode: boolean;
  taskListId: any;
  subTasks: any;

  originalEditedDoc: any;
  sameListName: any = [];
  sameListNameDocs: any = [];
  otherTaskListsisOpen: boolean = false;
  // customers: any;
  // employees: any;
  // selectedEmployee: any;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private userService: UserService,
    private clientService: ClientService,
    private alertController: AlertController,
    private route: ActivatedRoute,
    private taskService: TaskService,
    private angularFirestore: AngularFirestore
  ) {
    this.taskCollection = angularFirestore.firestore.collection("taskList");
  }

  ngOnInit() { 
    this.loadData();
    this.taskInputForm = this.fb.group({
      taskname: '',
      task: this.fb.array([]),
      selectedCustomer: [''],
    });
    this.getRoutingParams();
  }

  async loadData() {
    // this.currentUser = await this.userService.getCurrentUser().toPromise();
    this.userService.getCurrentUser().subscribe(doc => this.currentUser = doc);
    console.log(this.currentUser);
    await this.getCustomersList();
  }

  getRoutingParams(){
    this.route.queryParams.subscribe((taskInput) => {
      if (taskInput.edit){
        this.editMode = true;
        this.taskListId = taskInput.id;
        let currentCompanyName= [];
        this.taskService.getTaskCollection(taskInput.id).subscribe((doc:any) => {
          const listNames = this.taskService.getAllTaskListsOfTheSameName(doc.clientId, doc.listName);
          listNames.then((res) => {
            console.log("listNames are ", res);
            this.sameListName = res;
            this.sameListName.map((ele) => {
              // console.log(ele.companyName);
              if(ele.companyName !== doc.companyName) {
                this.sameListNameDocs.push(ele);
                this.sameListNameDocs.sort((a, b) => a.companyName.localeCompare(b.companyName));
                console.log("sameListNameDocs", this.sameListNameDocs);
              }
            })
          });
          setTimeout(() => {
            this.originalEditedDoc = doc;
            this.customersList.forEach((ele) => {
              // console.log("ele", ele);
              if(doc.companyName === ele.companyName){
                currentCompanyName.push(ele);
                // console.log(currentCompanyName);
              }
            });
            this.taskInputForm.get('taskname').setValue(doc.listName);
            this.taskInputForm.get('selectedCustomer').setValue(currentCompanyName);
            // console.log(this.taskInputForm.controls['selectedCustomer'].setValue(doc.companyName));
            // this.taskInputForm.patchValue( { taskname: doc.listName , selectedCustomer: doc.companyName } );
            this.taskService.getTaskSubCollection(taskInput.id).subscribe(doc => this.subTasks=doc);
          }, 1000);
          // console.log("~ line 88 taskInputForm ", this.taskInputForm.value);
          
          // console.log(this.subTasks);
        });        
      }
    })
  }

  formatCustomerValues(val : any[]) {
    return val.map((value) => value.companyName).join(', ');
  }

  toggleItems() {
    this.customerComponent.toggleItems(
      this.customerComponent.itemsToConfirm.length ? false : true
    );

    // Confirm items and close Select page
    // without having the user to click Confirm button.
    // this.confirm();
  }

  confirm() {
    this.customerComponent.confirm();
    this.customerComponent.close();
  }

  async getCustomersList() {
    this.customersList = await this.clientService.getCustomersOfClient(this.currentUser.clientId).toPromise();
    console.log('customers list', this.customersList);
    this.customersList.sort((customerA: Customer, customerB: Customer) => {
      if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
      return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
    });
  }

  task(): FormArray {
    return this.taskInputForm.get("task") as FormArray
  }

  newTaskInput(): FormGroup {
    return this.fb.group({
      inputtask: '',
    })
  }

  addTask() {
    this.task().push(this.newTaskInput());
  }

  removeTask(i: number) {
    this.task().removeAt(i);
  }

  onUpdateTask() {
    const fieldsTask={};
    console.log("taskInputForm -> " , this.taskInputForm.value);
    const updateBatchTask = this.angularFirestore.firestore.batch();

    this.taskInputForm.value.selectedCustomer.forEach((customer, i) => {
      console.log("iterating thru every selected customer")
      console.log("current customer", customer.companyName);
      console.log("initial/original customer", this.originalEditedDoc.companyName);
      // console.log("length of selected customers is", this.taskInputForm.value.selectedCustomer.length);

      if( this.taskInputForm.value.selectedCustomer.length === 1 ) {
        if( this.originalEditedDoc.companyName === customer.companyName ){
          console.log("this is an existing record for the current customer", this.originalEditedDoc.companyName);
          const collectionRef = this.taskCollection.doc(this.originalEditedDoc.id);
          const fieldsTaskList = this.taskListFields(this.originalEditedDoc.id, this.taskInputForm.value.taskname, this.currentUser.clientId, customer.id, customer.companyName);
          updateBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
          this.taskFields(collectionRef, updateBatchTask, fieldsTask);
        }
        else {
          console.log("remapping the customer field for the exisiting record");
          const collectionRef = this.taskCollection.doc(this.originalEditedDoc.id);
          const fieldsTaskList = this.taskListFields(this.originalEditedDoc.id, this.taskInputForm.value.taskname, this.currentUser.clientId, customer.id, customer.companyName);
          updateBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
          this.taskFields(collectionRef, updateBatchTask, fieldsTask);
        }
      }

      else {
        console.log(" more than one customer selected in the form ");
        if( this.originalEditedDoc.companyName === customer.companyName ){
          console.log("updating existing records for the exisiting customer", customer.companyName);
          const collectionRef = this.taskCollection.doc(this.originalEditedDoc.id);
          const fieldsTaskList = this.taskListFields(this.originalEditedDoc.id, this.taskInputForm.value.taskname, this.currentUser.clientId, customer.id, customer.companyName);
          updateBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
          this.taskFields(collectionRef, updateBatchTask, fieldsTask);
        }
        else if (this.sameListNameDocs.length < 1 && this.originalEditedDoc.companyName !== customer.companyName) {
          console.log("creating new record for the new customer and length of sameListNameDocs is less than 1", customer.companyName);
          const id = this.angularFirestore.createId();
          const collectionRef = this.taskCollection.doc(id);
          const fieldsTaskList = this.taskListFields(id, this.taskInputForm.value.taskname, this.currentUser.clientId, customer.id, customer.companyName);
          updateBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
          this.taskFields(collectionRef, updateBatchTask, fieldsTask);
          this.subTasksFields(collectionRef, updateBatchTask, fieldsTask);
        }

        else if( this.sameListNameDocs.length >= 1 && this.originalEditedDoc.companyName !== customer.companyName ) {
          let match = false;
          this.sameListNameDocs.forEach((list) => {
            console.log("checking other customers with the same taskList - ", list);
            if(list.companyName === customer.companyName) {
              // console.log("setting the match variable to true since a matching customer name is found ");
              match = true;
              console.log("updating the records with same listname but for different customer", customer.companyName);
              const collectionRef = this.taskCollection.doc(list.id);
              const fieldsTaskList = this.taskListFields(list.id, this.taskInputForm.value.taskname, this.currentUser.clientId, customer.id, customer.companyName);
              updateBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
              return this.taskFields(collectionRef, updateBatchTask, fieldsTask);
            };
          });
          // match === false condition is to create task list for new customers after checking all the sameListName docs
          if(match === false) {
            console.log("no matches found at all");
            console.log("creating for the new customer ", customer.companyName , " after checking all the same list name docs array ");
            const id = this.angularFirestore.createId();
            const collectionRef = this.taskCollection.doc(id);
            const fieldsTaskList = this.taskListFields(id, this.taskInputForm.value.taskname, this.currentUser.clientId, customer.id, customer.companyName);
            updateBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
            this.taskFields(collectionRef, updateBatchTask, fieldsTask);
            this.subTasksFields(collectionRef, updateBatchTask, fieldsTask);
          }

        }
        else {
          console.log("creating for the new customer", customer.companyName);
          const id = this.angularFirestore.createId();
          const collectionRef = this.taskCollection.doc(id);
          const fieldsTaskList = this.taskListFields(id, this.taskInputForm.value.taskname, this.currentUser.clientId, customer.id, customer.companyName);
          updateBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
          this.taskFields(collectionRef, updateBatchTask, fieldsTask);
          this.subTasksFields(collectionRef, updateBatchTask, fieldsTask);
        }
      };
    });
    updateBatchTask.commit().then(() => console.log("commit of update batch tasks"));

    this.presentAlert(this.editMode);
  }

  taskListFields(docId, taskName, clientId, customerId, companyName) {
    const taskList = {
      id: docId,
      listName: taskName,
      description: 'new description',
      createdDate: new Date(),
      clientId: clientId,
      customerId: customerId,
      companyName: companyName
    };
    return taskList;
  };

  taskFields(collectionRef, batch, fieldsTask) {
    this.taskInputForm.value.task.forEach((ele, i) => {
      const subId = this.angularFirestore.createId();
      fieldsTask[i] = {
        id: subId,
        description: ele.inputtask
      }
      console.log('line 267 ~ Task sub collection', fieldsTask[i]);
      batch.set(collectionRef.collection('task').doc(subId) , fieldsTask[i], { merge: true });
    });
  }

  subTasksFields(collectionRef, updateBatchTask, fieldsTask) {
    this.subTasks.forEach((ele, i) => {
      console.log("each task of subTasks", ele);
      const subId = this.angularFirestore.createId();
      fieldsTask[i] = {
        id: subId,
        description: ele.description
      }
      console.log('line 280 ~ Task sub collection', fieldsTask[i]);
      updateBatchTask.set(collectionRef.collection('task').doc(subId) , fieldsTask[i] , { merge: true });
    });
  }

  onSaveTask() {
    const writeBatchTask = this.angularFirestore.firestore.batch();
    // console.log(writeBatchTask);
    this.taskInputForm.value.selectedCustomer.forEach((ele,i) => {
      console.log("selected customer", ele.companyName);
      const id = this.angularFirestore.createId();
      const collectionRef = this.taskCollection.doc(id);
      const fieldsTaskList = this.taskListFields(id, this.taskInputForm.value.taskname, this.currentUser.clientId, ele.id, ele.companyName);
      writeBatchTask.set(collectionRef, fieldsTaskList, { merge : true });
      console.log('line 294 ~ TaskList collection', fieldsTaskList);
      const fieldsTask={};
      this.taskFields(collectionRef, writeBatchTask, fieldsTask);
    });
    writeBatchTask.commit().then(() => console.log("commit of write batch tasks"));
    this.presentAlert();
  }

  async presentAlert(mode?){
    if(mode){
      const alert = await this.alertController.create({
        header: 'Task Updated',
        message: 'The task has been updated',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.onNavigateBack();
            }
          }
        ]
      });
      await alert.present();
    }
    else {
      const alert = await this.alertController.create({
        header: 'Task Submitted',
        message: 'The task has been submitted',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.onNavigateBack();
            }
          }
        ]
      });
      console.log("done submitting");
      await alert.present();
    }
  }

  onSelectCustomer(event){
      // console.log(event);
      this.selectedCustomer = event.value;
      // console.log(this.selectedCustomer);
  }

  removeTaskId(task) {
    console.log(task);
    this.taskCollection.doc(this.taskListId).collection('task').doc(task.id).delete();    
  }

  toggleSection() {
    this.otherTaskListsisOpen = !this.otherTaskListsisOpen;
  }

  onNavigateBack() {
    this.location.back();
  }

}


