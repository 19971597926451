import { Component, OnInit } from '@angular/core';
import { Attendance } from '../../entity/attendance';
import { LoaderService } from 'src/app/services/loader.service';
// import { HaltService } from 'src/app/services/halt.services';
import { Event } from '../../entity/event';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { PopoverController, AlertController, NavController, ModalController } from '@ionic/angular';
import { AccountMenuComponent } from 'src/app/shared/account-menu/account-menu.component';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { OnBoardingModalComponent } from 'src/app/on-boarding-modal/on-boarding-modal.component';
import { getPreciseDistance, getPathLength } from 'geolib';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { firestore } from 'firebase';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-general-dashboard',
  templateUrl: './general-dashboard.component.html',
  styleUrls: ['./general-dashboard.component.scss'],
})
export class GeneralDashboardComponent implements OnInit {

  tabLink = [];
  haltList = [];
  currentUser: User;
  filterDate: Date;
  filterFromDateRawValue: any;
  searchString: string;
  currentEmployeeAttendance: Attendance;
  yesterdaysTravelDistance: any;
  distanceInKms: any;
  fromDate: Date;
  toDate: Date;
  employees: User[] = [];
  employerName: string;
  disableBtn = false;
  activeHalt: any;
  showHistory = false;
  showRecentActivity = false;
  spentTime: any = 0;
  headerNav: boolean;
  totalDistanceCovered: any = 0;
  distanceRange = 0;
  client: any;
  filterTasks : any[];
  selectedTasks: any[];
  Task: any[];

  constructor(
    public fireAuth: AngularFireAuth, private route: ActivatedRoute, private snackBarService: SnackBarService,
    public alertController: AlertController, public modalController: ModalController,
    private userService: UserService, public popoverController: PopoverController, private clientService: ClientService,
    private loaderService: LoaderService, private navCtrl: NavController, private router: Router
  ) {
    this.headerNav = true;
    this.filterDate = new Date();
    this.filterDate.setHours(0);
    this.filterDate.setMinutes(0);
    this.filterDate.setSeconds(0);
    this.filterFromDateRawValue = this.filterDate.toISOString();
  }

  ionViewDidEnter() {
    this.currentEmployeeAttendance = this.userService.getEmployeeAttendance();
    //console.log('ionViewDidEnter currentEmployeeAttendance', this.currentEmployeeAttendance);
    if (this.currentEmployeeAttendance) {
      this.calcLoginTime();
    }
  }

  ngOnInit() {
    this.onBoarding();
    this.getCurrentUser();
    this.userService.listenToAttendanceChange().subscribe((attendance) => {
      if (attendance) {
        this.currentEmployeeAttendance = attendance;
        this.calcLoginTime();
        //console.log('ngonit currentEmployeeAttendance', this.currentEmployeeAttendance);
      }
    });
  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.navCtrl.navigateRoot(['/home']);
    }
  }

  async onBoarding() {
    // console.log(HaltDashboardComponent.name, 'local storage', localStorage.getItem('onHaltBoarding'));
    if (localStorage.getItem('onHaltBoarding') === 'shown') { //change to general boarding
      return;
    }
    const modal = await this.modalController.create({
      component: OnBoardingModalComponent,
      componentProps: {
        type: 'halt', // general
      }
    });
    localStorage.setItem('onHaltBoarding', 'shown');
    return await modal.present();
  }

  async getCurrentUser() {
    const subEndDateValue = sessionStorage.getItem("subscription");
    console.log("subscription value from session storage", subEndDateValue);
    this.subEndDateCheck(subEndDateValue);
    const user = await this.userService.getCurrentUser().toPromise();
    if (user) {
      this.currentUser = user;
      //  check Active Attendance();
      this.getEmployerName(this.currentUser.clientId);
      this.getCurrentClientDetails();
    }
  }

  async getCurrentClientDetails() {
    this.client = await this.clientService.getClientData(this.currentUser.clientId);
    //console.log("getCurrentClientDetails -> client", this.client);
  }

  navigateToIncidents() {
    this.navCtrl.navigateForward(['/incidents']);
  }

  logout() {
    this.userService.logout().then(() => {
      this.navCtrl.navigateBack(['home']);
    });
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: AccountMenuComponent,
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then(res => {
      if (res.data === 'logout') {
        this.presentAlert('Are you sure that you want to logout?');
        // this.userService.logout().then(() => {
        //   this.navCtrl.navigate(['home']);
        // });
      }
    });
    return await popover.present();
  }


  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    // console.log(GeneralDashboardComponent.name, 'from date val : ', new Date(date));
    this.filterDate = new Date(date);
    // console.log(GeneralDashboardComponent.name, 'setting from date', this.filterDate, date);
  }


  createAttendance() {
    this.navCtrl.navigateForward(['attendance']);
  }

  // routeToTravelHistory() {
  //   this.router.navigate(['travel-history']);
  // }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.logout();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  // getDistanceCoveredinKms(distanceTravelled) {
  //   this.distanceInKms = parseInt(distanceTravelled, 10);
  //   this.distanceInKms = (this.distanceInKms / 1000).toFixed(1) + ' ' + 'kms';
  // }

  getEmployerName(clientId: string) {
    this.userService.getClientDataBasedOnClientId(clientId).subscribe((employer) => {
      this.employerName = employer[0].companyName;
    });
  }

  // toggleContainer(event: any) {
  //   event.stopPropagation();
  //   this.showRecentActivity = !this.showRecentActivity;
  //   if (this.showRecentActivity === true) {
  //     this.hideNavBar();
  //   } else {
  //     this.showNavBar();
  //   }
  // }

  // showNavBar() {
  //   this.headerNav = true;
  // }
  // hideNavBar() {
  //   this.headerNav = false;
  // }

  calcLoginTime() {
    const currentTime = new Date();
    if (this.currentEmployeeAttendance) {
      const diffTime = Math.abs(currentTime.getTime() - this.currentEmployeeAttendance.startTime.toMillis());
      this.spentTime = this.validateDuePunchOut(diffTime);
      if (this.spentTime > 23) {
        this.currentEmployeeAttendance.endTime = firestore.FieldValue.serverTimestamp();
        this.userService.updateAttendance(this.currentEmployeeAttendance.id, this.currentEmployeeAttendance).then(() => {
          this.userService.setEmployeeAttendance(null);
        });
      }
    } else {
      this.spentTime = 0;
    }
  }


  validateDuePunchOut(mins) {
    if (mins <= 0) {
      return 'Incomplete';
    }
    mins = mins / 60000;
    const num = mins;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    return rhours;
  }


  // getTimeinHHMMFormat(mins) {
  //   if (mins <= 0) {
  //     return 'Incomplete';
  //   }
  //   mins = mins / 60000;
  //   const num = mins;
  //   const hours = (num / 60);
  //   const rhours = Math.floor(hours);
  //   const minutes = (hours - rhours) * 60;
  //   const rminutes = Math.floor(minutes);
  //   const seconds = (minutes - rminutes) * 60;
  //   const rseconds = Math.round(seconds);
  //   return rhours + ':' + rminutes + ':' + rseconds;
  // }

  // onDeletePopOver(event: any, halt: Event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   //console.log('delete halt', halt);
  //   this.confirmDelete('Are you sure to delete this entry', halt);
  // }

  // async confirmDelete(data, halt) {
  //   const alert = await this.alertController.create({
  //     header: 'Alert',
  //     backdropDismiss: false,
  //     message: data,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         handler: () => {
  //           alert.dismiss();
  //         }
  //       },
  //       {
  //         text: 'Delete',
  //         handler: () => {
  //           // this.deleteHalt(halt);
  //           alert.dismiss();
  //         }
  //       }],
  //     cssClass: 'p-3',
  //   });

  //   await alert.present();
  // }

  // checkForLocationRange(startLocation, endLocation) {
  //   this.distanceRange = getPreciseDistance(
  //     { latitude: startLocation.latitude, longitude: startLocation.longitude },
  //     { latitude: endLocation.latitude, longitude: endLocation.longitude }
  //   );
  //   if (this.distanceRange && this.distanceRange > IAceeptMessageConstant.PITSTOP_DIFFERENCE_RANGE) {
  //     return `End location appears to be ${this.distanceRange} mtrs away`;
  //   } else {
  //     return ''
  //   }
  // }

  // getTotalDistanceCovered(halts) {
  //   let locationArray = [];
  //   //console.log('Halts done', halts);
  //   halts.map(({ startLocation }) => {
  //     // console.log('startLocation', startLocation)
  //     locationArray.push({ latitude: startLocation.latitude, longitude: startLocation.longitude });
  //   });
  //   // console.log('locationArray', locationArray)
  //   if (locationArray.length > 0) {
  //     this.totalDistanceCovered = getPathLength(locationArray);
  //     // console.log('getPathLength -> res', this.totalDistanceCovered);
  //   }
  // }

  // LocationAccess() {
  //   this.navCtrl.navigateForward(['location-access']);
  // }

}
