import { ClientService } from './../../services/client.service';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.services';
import { User } from 'src/app/entity/user';
import { Event } from '../../entity/event';
import { PatrolService } from 'src/app/services/patrol.service';
import { EventTemplate, PitStop } from 'src/app/entity/event.template';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { PatrolDetailsModalComponent } from 'src/app/shared/patrol-details-modal/patrol-details-modal.component';
import { Customer } from 'src/app/entity/clients';
import { MapService } from 'src/app/services/map-data';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patrols',
  templateUrl: './patrols.component.html',
  styleUrls: ['./patrols.component.scss'],
})
export class PatrolsComponent implements OnInit {
  currentUser: User;
  patrols: Event[] = [];
  filteredActivePatrols: Event[] = [];
  filteredPatrols: EventTemplate[] = [];
  patrolTemplates: EventTemplate[] = [];
  filteredActiveCustomers = {};
  filteredActiveEmployees = {};
  pitstops: PitStop[];
  searchString: string;
  customers: Customer[] = [];
  employees: User[] = [];
  pieChartData;
  missedStops: Event[] = [];
  locationErrorStops: Event[] = [];
  patroledEmployee: EventTemplate[] = [];
  showFilteredPatrols = true;
  showFilteredCustomers = false;
  showFilteredEmployees = false;
  private topLimit: number = 50
  dataSource: any = [];
  uniqueSites = [];
  unvisitedSites = [];
  activePatrolSites: EventTemplate[] = [];
  isMobile:boolean;
  constructor(private patrolService: PatrolService, private userService: UserService,
    private eventService: EventService, private loaderService: LoaderService,
    public modalController: ModalController, private clientService: ClientService,
    private router: Router,
    private navCtrl: NavController, private mapService: MapService, private platform: Platform) {
      this.isMobile = !this.platform.is('desktop');
     }

  ngOnInit() {
    this.getCurrentUser();
    this.getPatrolsOfClient();
  }

  loadData(event) {
    setTimeout(() => {
      this.topLimit += 50;
      this.dataSource = this.patrols.slice(0, this.topLimit);
      event.target.complete();
      if (this.dataSource.length === this.patrols.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  getActiveCustomers() {
    console.log("customers", this.customers);
  }

  getPatrolsOfClient() {
    this.loaderService.presentLoading('Loading patrols list');
    this.userService.getCurrentUser().subscribe(currentUser => {
      // this.loaderService.dismissLoading();
      this.currentUser = currentUser;
      // console.log(PatrolsComponent.name, 'Current user', this.currentUser);
      this.getPatrolTemplatesOfClient();

      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      this.patrolService.getAllPatrolsOfClient(this.currentUser.clientId, today).subscribe(patrols => {
        // this.loaderService.dismissLoading();
        this.patrols = patrols;
        this.dataSource = patrols.slice(0, this.topLimit);
        // console.log(PatrolsComponent.name, 'Fetched patrols', this.patrols);
        this.filteredActivePatrols = this.removeDuplicates(this.patrols, 'templateId');
        // console.log(PatrolsComponent.name, 'Filtered patrols', this.filteredActivePatrols);
        this.groupEventsByCustomer(this.patrols);
        this.groupEventsByEmployees(this.patrols);
        // this.getMissedStops();
        // this.getLocationErrorStops();
      });

    });
    // this.loaderService.dismissLoading();
  }

  groupEventsByCustomer(patrols: Event[]) {
    this.filteredActiveCustomers = patrols.reduce((values, patrol) => {
      (values[patrol.data['customerName']] = values[patrol.data['customerName']] || []).push(patrol);
      return values;
    }, {});
    console.log(PatrolsComponent.name, 'Filtered customers', this.filteredActiveCustomers);
  }

  groupEventsByEmployees(patrols: Event[]) {
    this.filteredActiveEmployees = patrols.reduce((values, patrol) => {
      (values[patrol['createdByUserName']] = values[patrol['createdByUserName']] || []).push(patrol);
      return values;
    }, {});
    // console.log(PatrolsComponent.name, 'Filtered employees', this.filteredActiveEmployees);
  }

  getActiveCustomersLength() {
    return Object.keys(this.filteredActiveCustomers).length;
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user: User) => this.currentUser = user);
    this.getCustomersOfClient(this.currentUser.clientId);

    const subEndDateValue = sessionStorage.getItem("subscription");
    console.log("subscription value from session storage", subEndDateValue);
    this.subEndDateCheck(subEndDateValue);

  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.navCtrl.navigateRoot(['/home']);
    }
  }

  getCustomersOfClient(clientId) {
    this.clientService.getCustomersOfClient(clientId).subscribe((customers: Customer[]) => {
      this.customers = customers
    });
    this.getActiveCustomers();
  }

  getActiveEmployeesLength() {
    return Object.keys(this.filteredActiveEmployees).length;
  }

  // getCustomersList() {
  //   this.clientService.getCustomersOfClient(this.currentUser.clientId).subscribe((customers) => {
  //     this.customers = customers;
  //     this.customers.sort((customerA: Customer, customerB: Customer) => {
  //       if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
  //       return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
  //     });
  //     // console.log(PatrolsComponent.name, 'Fetched customers', customers);
  //     this.filteredActiveCustomers = this.removeDuplicates(this.patrols, 'customerName');
  //     // console.log(PatrolsComponent.name, 'Filtered customers', this.filteredActiveCustomers);
  //   });
  // }

  // getEmployeesList() {
  //   this.userService.getEmployeesOfClient(this.currentUser.clientId).subscribe((employees) => {
  //     this.employees = employees;
  //     this.employees.sort((employeeA: User, employeeB: User) => {
  //       if (employeeA.name.toLowerCase() === employeeB.name.toLowerCase()) { return 0; }
  //       return (employeeA.name.toLowerCase() > employeeB.name.toLowerCase()) ? 1 : -1;
  //     });
  //     // console.log(PatrolsComponent.name, 'Fetched employees', employees);
  //     this.filteredActiveEmployees = this.removeDuplicates(this.patrols, 'createdByUserName');
  //     // console.log(PatrolsComponent.name, 'Filtered employees', this.filteredActiveEmployees);
  //   });
  // }

  getPatrolName(patrolTemplateId: string): string {
    const patrolTemplate = this.patrolTemplates.find(template => template.id === patrolTemplateId);
    return patrolTemplate ? patrolTemplate.name : 'Unknown';
  }


  getPatrolTemplatesOfClient() {
    this.eventService.getAllPatrolTemplatesOfClient(this.currentUser.clientId)
      .subscribe(templates => {
        templates.map((template) => {
          if (template.isActive === true) {
            if (!this.uniqueSites.find((item) => item === template.customerName)) {
              this.uniqueSites.push(template.customerName);
              //console.log("🚀  -> file: patrols.component.ts -> line 174 -> PatrolsComponent -> templates.map -> uniqueSites", this.uniqueSites)
            }
          }
        })
        this.patrolTemplates = templates;
        this.activePatrolSites = templates.filter((template) => template.isActive === true);
      });
  }


  getMissedStops() {
    this.missedStops = this.patrols.filter(missedStop => missedStop.data.missedPitstops);
    // console.log(PatrolsComponent.name, 'missed stops', this.missedStops);
  }

  getLocationErrorStops() {
    this.locationErrorStops = this.patrols.filter(locationErrorStop => locationErrorStop.data.errorPitstops);
    // console.log('location error stops', this.locationErrorStops);
  }

  removeDuplicates(originalArray, prop) {
    const newArray = [];
    const lookupObject = {};

    for (var i in originalArray) { lookupObject[originalArray[i][prop]] = originalArray[i]; }

    for (i in lookupObject) { newArray.push(lookupObject[i]); }
    return newArray;
  }

  viewDetails(type) {
    if (type === 'filteredPatrols') {
      this.showFilteredPatrols = true;
      this.showFilteredCustomers = false;
      this.showFilteredEmployees = false;
    }
    if (type === 'filteredCustomers') {
      this.showFilteredPatrols = false;
      this.showFilteredCustomers = true;
      this.showFilteredEmployees = false;
    }
    if (type === 'filteredEmployees') {
      this.showFilteredPatrols = false;
      this.showFilteredCustomers = false;
      this.showFilteredEmployees = true;
    }
  }

  onViewDetails(patrol) {
    // console.log(PatrolsComponent.name, 'onViewDetails', patrol);
    const patrolTemplate = this.patrolTemplates.find(template => template.id === patrol.templateId);
    // console.log(PatrolsComponent.name, 'patrolTemplate', patrolTemplate);
    this.showPatrolDetails(patrol, patrolTemplate);
  }

  routeChart(){
    // this.navCtrl.navigateRoot('bar-Chart');
    this.router.navigate(['/employer/bar-Chart'], { queryParams: { patrolsLastDay: true } });
  }

  routeRangeChart() {
    this.router.navigate(['/employer/bar-Chart'], { queryParams: { patrolRange: true } });
  }

  async showPatrolDetails(patrol: Event, template: EventTemplate) {
    this.mapService.setPatrol(patrol);
    this.mapService.setPatrolTemplate(template);
    this.navCtrl.navigateRoot(['/employer/view-patrol-details']);
    // const modal = await this.modalController.create({
    //   component: PatrolDetailsModalComponent,
    //   componentProps: {
    //     'patrol': patrol,
    //     'template': template
    //   }
    // });
    // return await modal.present();
  }

  getTimeinHHMMFormat(mins) {
    if (mins >= 0) {
      const num = mins;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.floor(minutes);
      const seconds = (minutes - rminutes) * 60;
      const rseconds = Math.round(seconds);
      return rhours + 'h' + ' ' + ':' + rminutes + 'm' + ':' + rseconds + 's';
    } else {
      return 0 + 'hours' + ':' + 0 + 'mins';
    }
  }

  getDistanceCovered(patrolTemplateId) {
    let pitstopsList;
    console.log('patrolTemplateId', patrolTemplateId);
    this.eventService.getPitstopsOfPatrol(patrolTemplateId).subscribe(pitstops => {
      if (pitstops.length) {
        pitstopsList = pitstops;
        console.log('pitstopsList', pitstopsList);
      }
    });
  }

  getUnVisitedSiteDetails() {
    const temp = Object.keys(this.filteredActiveCustomers);
    this.unvisitedSites = this.uniqueSites.filter((item) => {
      return !temp.find((filteredItem) => filteredItem === item)
    })
  }
}
