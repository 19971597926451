import { AsyncPipe, CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
// import { checkAvailability } from '@ionic-native/core';
// Firebase Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GoogleMaps } from '@ionic-native/google-maps/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Network } from '@ionic-native/network/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CustomerLoginComponent } from './customer-login/customer-login.component';
import { CustomerModule } from './customer-module/customer.module';
import { CustomerSignupComponent } from './customer-signup/customer-signup.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { EmployeeDistanceTrackerComponent } from './employee-distance-tracker/employee-distance-tracker.component';
import { EmployeeHaltModule } from './employee-halt/employee.halt.module';
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { EmployeePatrolModule } from './employee-patrol/employee-patrol.module';
import { EmployeeSignupComponent } from './employee-signup/employee-signup.component';
import { EmployerLoginComponent } from './employer-login/employer-login.component';
import { CheckoutComponent } from './employer-module/checkout/checkout.component';
import { EmployerModule } from './employer-module/employer.module';
import { ListPage } from './employer-module/list/list.page';
import { EmployerSignupComponent } from './employer-signup/employer-signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomePage } from './home/home.page';
import { OnBoardingModalComponent } from './on-boarding-modal/on-boarding-modal.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppLocationService } from './services/app-location.service';
import { ConnectionService } from './services/connection.service';
import { LoaderInterceptorService } from './services/loader.interceptor';
import { UserService } from './services/user.service';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { SharedModule } from './shared/shared.module';
import { SubscriptionHandlingComponent } from './subscription-handling/subscription-handling.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TeamMemberSignupComponent } from './team-member-signup/team-member-signup.component';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { PatrolIncidentComponent } from './incident-reporting/patrol-incident/patrol-incident.component';
import { ReportIncidentComponent } from './incident-reporting/report-incident/report-incident.component';
import { EmployeeAlertComponent } from './employee-alert/employee-alert.component';
import { FireAlertsComponent } from './employer-module/fire-alerts/fire-alerts.component';
import { AlertDateInfoComponent } from './employer-module/alert-date-info/alert-date-info.component';
import { AlertMapComponent } from './employer-module/alert-map/alert-map.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { LocationAccessComponent } from './location-access/location-access.component';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { StreamingMedia } from "@awesome-cordova-plugins/streaming-media/ngx";
import { EmployeeGeneralModule } from './employee-general/employee.general.module';
// import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    PageNotFoundComponent,
    EmployeeLoginComponent,
    EmployeeSignupComponent,
    CustomerLoginComponent,
    CustomerSignupComponent,
    EmployerLoginComponent,
    EmployerSignupComponent,
    SetpasswordComponent,
    SubscriptionComponent,
    SubscriptionPaymentComponent,
    EmailNotVerifiedComponent,
    SubscriptionHandlingComponent,
    ForgotPasswordComponent,
    AttendanceComponent,
    PatrolIncidentComponent,
    ReportIncidentComponent,
    CheckoutComponent,
    ListPage,
    EmployeeDistanceTrackerComponent,
    TeamMemberSignupComponent,
    OnBoardingModalComponent,
    LocationAccessComponent,
    EmployeeAlertComponent,
    FireAlertsComponent,
    AlertDateInfoComponent,
    AlertMapComponent
  ],
  entryComponents: [OnBoardingModalComponent, LocationAccessComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    AppRoutingModule,
    EmployeePatrolModule,
    EmployeeHaltModule,
    EmployerModule,
    CustomerModule,
    EmployeeGeneralModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig), // Main Angular fire module;
    AngularFireStorageModule, // for storage module;
    AngularFirestoreModule.enablePersistence(), // for offline supports;
    AngularFireAuthModule,
    IonicSelectableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    AngularFirestore,
    UserService,
    ConnectionService,
    LocationAccuracy,
    Geolocation,
    Device,
    Contacts,
    AppVersion,
    AppLocationService,
    GoogleMaps,
    File,
    Network,
    WebView,
    Camera,
    PhotoViewer,
    ImagePicker,
    AndroidPermissions,
    AsyncPipe,
    QRScanner,
    StreamingMedia,
    FirebaseX,
    // AngularFireMessaging,
    FCM,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    //// console.log('AppModule loaded.');
    // , - imports
    // FCM, - providers
  }
}
