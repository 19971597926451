import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { forkJoin } from 'rxjs';
import { Event } from 'src/app/entity/event';
import { EventTemplate, PitStop } from 'src/app/entity/event.template';
import { User } from 'src/app/entity/user';
import { ClientService } from 'src/app/services/client.service';
import { EventService } from 'src/app/services/event.services';
import { ExcelService } from 'src/app/services/excel.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MapService } from 'src/app/services/map-data';
import { PatrolService } from 'src/app/services/patrol.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  hideFilter: boolean;
  currentUser: User;
  selectedCustomerId = "";
  sites = [];
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  patrols: Event[] = [];
  groupedPatrols: any;
  patrolTemplates: EventTemplate[] = [];
  pitstops: PitStop[] = [];
  pitstopss: Event[];
  pitstopsList: PitStop[];
  groupedPitstopsOfPatrolArray: any;
  pitstopsArray: Event[];
  patrolImagesArray: any[] = [];
  hideReports = true;
  reportLevel: string;
  reportType = 'patrol';
  pitstopsArrayList = [];
  isMobile: boolean;
  customersList = [];


  constructor(
    private clientService: ClientService, private platform: Platform, private userService: UserService, private loaderService: LoaderService,
    private navCtrl: NavController, private patrolService: PatrolService, private excelService: ExcelService, private eventService: EventService, private mapService: MapService,) {
    this.filterFromDate = new Date();
    this.filterFromDate.setMonth(this.filterFromDate.getMonth() - 1);
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
    this.reportLevel = 'aggregate';
    this.isMobile = !this.platform.is('desktop');
  }

  ngOnInit() {
    this.getAllData();
  }

  async getAllData() {

    const subEndDateValue = sessionStorage.getItem("subscription");
    console.log("subscription value from session storage", subEndDateValue);
    this.subEndDateCheck(subEndDateValue);

    console.log("getAllData -> fetching all data");
    const loader = await this.loaderService.newLoader("Loading ...");
    loader.present();
    this.userService.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
      console.log("getAllData -> currentUser", this.currentUser);
      //console.log('customer dashboard -> current user', this.currentUser);
      forkJoin({
        customers: this.getCustomersList(this.currentUser.clientId),
        customerSites: this.getSitesOfCustomer(this.currentUser.clientId, this.currentUser.email),
        patrolTemplates: this.getPatrolTemplatesOfClient(this.currentUser.clientId),
        pitstops: this.getAllPitstopsOfClient(this.currentUser.clientId)
      }).subscribe(data => {
        console.log("getAllData -> data", data);

        this.customersList = data.customers;
        this.patrolTemplates = data.patrolTemplates;
        this.pitstops = data.pitstops;

        data.customerSites[0].sites.map((site) => {
          this.sites.push({ id: site, name: this.getSiteNameById(site) ? this.getSiteNameById(site) : "" });
        })

        this.selectedCustomerId = (this.sites && this.sites[0]) ? this.sites[0].id : "";
        // this.loaderService.dismissLoading();
        loader.dismiss();
      }, error => console.log("getAllData -> error", error), () => console.log("getAllData -> completed"));
    });
  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.navCtrl.navigateRoot(['/home']);
    }
  }

  getCustomersList(clientId) {
    return this.clientService.getCustomersOfClient(clientId);
  }

  getSitesOfCustomer(clientId, emailId) {
    return this.clientService.fetchCustomerAccessListByEmailId(clientId, emailId);
  }

  getPatrolTemplatesOfClient(clientId) {
    return this.eventService.getAllPatrolTemplatesOfClient(clientId);
  }

  getAllPitstopsOfClient(clientId) {
    return this.patrolService.getAllPitstopsOfClient(clientId);
  }

  getSiteNameById(siteId) {
    if (this.customersList) {
      const site = this.customersList.find((site) => site.id === siteId);
      return site.companyName;
    }
  }

  onShowFilter() {
    this.hideFilter = !this.hideFilter;
  }

  onSelectLocation(event) {
    this.selectedCustomerId = event.value.id;
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    this.filterFromDate = new Date(date);
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    this.filterToDateRawValue = date;
    this.filterToDate = new Date(date);
  }

  get groupedPatrolsKeys() {
    if (!this.groupedPatrols) { return []; }
    return Object.keys(this.groupedPatrols);
  }

  getIncompletePatrolsOfCustomer(patrolList: Event[]): Event[] {
    return patrolList.filter(patrol => patrol.data['missedPitstops'] ? patrol.data['missedPitstops'].length > 0 : false);
  }

  onViewDetails(reportType) {
    //console.log('reportType', reportType)
    const patrolTemplate = this.patrolTemplates.find(template => template.id === reportType.templateId);
    //
    //console.log('patrolTemplate', patrolTemplate);
    this.showPatrolDetails(reportType, patrolTemplate);
  }

  async showPatrolDetails(patrol: Event, template: EventTemplate) {

    this.mapService.setPatrol(patrol);
    this.mapService.setPatrolTemplate(template);
    this.navCtrl.navigateRoot(['/customer-view/view-patrol-details']);

    // const modal = await this.modalController.create({
    //   component: PatrolDetailsModalComponent,
    //   componentProps: {
    //     patrol,
    //     template
    //   }
    // });
    // return await modal.present();
  }

  getPatrolName(patrolTemplateId: string): string {
    const patrolTemplate = this.patrolTemplates.find(template => template.id === patrolTemplateId);
    return patrolTemplate ? patrolTemplate.name : 'Unknown';
  }

  getErrorDetailsOfPitstops(patrolList: Event[]): Event[] {
    return patrolList.filter(patrol => patrol.data['errorPitstops'] ? patrol.data['errorPitstops'].length > 0 : false);
  }

  getPitstopName(pitstopTemplateId: string): string {
    // //console.log('fetching pitstop name ', pitstopTemplateId, this.pitstops);
    const pitstop = this.pitstops.find(pit => pit.id === pitstopTemplateId);
    // //console.log('fetching pitstop ', pitstopTemplateId, pitstop);
    return pitstop ? pitstop.name : 'Unknown';
  }

  getTimeinHHMMFormat(mins) {
    if (mins >= 0) {
      const num = mins;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      return rhours + ' ' + 'hours' + ' ' + ':' + ' ' + rminutes + ' ' + 'mins';
    } else {
      return 0 + ' ' + 'hours' + ' ' + ':' + ' ' + 0 + ' ' + 'mins';
    }
  }

  getPatrolsByFilter() {
    this.filterFromDate.setHours(0, 0, 0, 0);
    this.filterToDate.setHours(23, 59, 59, 0);
    // console.log('client id', this.currentUser.clientId);
    // console.log('customer id', this.selectedCustomerId);
    // console.log('filter from date', this.filterFromDate);
    // console.log('filter to date', this.filterToDate);
    this.loaderService.presentLoading('Loading patrols');
    this.patrolService.getPatrolsOfClientByFilter(this.currentUser.clientId, this.selectedCustomerId, null, this.filterFromDate, this.filterToDate)
      .subscribe(patrols => {
        this.hideFilter = true;
        console.log('fetched patrols', patrols);
        this.patrols = patrols;
        // this.patrols.map((patrol) => {
        //   console.log('patrol details', patrol.templateId, patrol.startTime, patrol.endTime, patrol.createdByUser);
        //   this.patrolService.getPitStopsForPatrol(patrol.templateId, patrol.startTime, patrol.endTime, patrol.createdByUser)
        //     .subscribe((pitstopsList) => {
        //       console.log('pitstoplist', pitstopsList);
        //       pitstopsList.map((pitstop) => {
        //         this.hideFilter = true;
        //         this.loaderService.dismissLoading();
        //         if (!this.patrolImagesArray[patrol.id]) {
        //           this.patrolImagesArray[patrol.id] = [];
        //         }
        //         if (pitstop.images && pitstop.images.length) {
        //           this.patrolImagesArray[patrol.id].push(...pitstop.images);
        //           // console.log('patrol array images', this.patrolImagesArray);
        //         }
        //       });

        //     }, (error) => { console.log('error', error) });
        // });
        this.groupedPatrols = this.groupEventsByCustomer(patrols);
        console.log("grouped patrols", this.groupedPatrols);
        this.loaderService.dismissLoading();
        // console.log('grouped patrols', this.groupedPatrols);
      });
  }

  groupEventsByCustomer(patrols: Event[]) {
    // console.log('patrols', patrols);
    return patrols.reduce((values, patrol) => {
      patrol.data['customerName'] ?
        (values[patrol.data['customerName']] = values[patrol.data['customerName']] || []).push(patrol) :
        (values[patrol.data['companyName']] = values[patrol.data['companyName']] || []).push(patrol);
      return values;
    }, {});
  }

  exportToExcel() {
    this.hideFilter = false;
    const reportsArray = [];
    const patrolArray = [...this.patrols];
    // console.log('patrol array', patrolArray);
    patrolArray.map((item) => {
      // console.log(item, 'item');
      let duration: any;
      if (item.endTime !== null && item.endTime !== undefined) {
        item['endTime'] = new Date((item.endTime).seconds * 1000);
      }
      if (item.startTime !== null && item.startTime !== undefined) {
        item['startTime'] = new Date((item.startTime).seconds * 1000);
      }
      if (item.endTime !== null && item.endTime !== undefined && item.startTime !== null && item.startTime !== undefined) {
        duration = ((item.endTime - item.startTime) / 1000) / 3600;
      }
      reportsArray.push({
        patrolName: this.getPatrolName(item.templateId),
        createdBy: item.createdByUserName,
        startTime: item.startTime,
        endTime: item.endTime,
        duration: duration || 0,
        TotalPatrols: item.data.customerName !== undefined ? (this.groupedPatrols[item.data.customerName].length || 0) : 0,
        ErrorPitStops: item.data.errorPitstops ? this.getErroPiststop(item.data.errorPitstops) : '',
        MissedPitstops: item.data.missedPitstops ? this.getErroPiststop(item.data.missedPitstops) : '',
        customerName: item.data.customerName,
        mapUrl: item.endLocation ? this.getMapUrl(item.endLocation.latitude, item.endLocation.longitude) :
          this.getMapUrl(item.startLocation.latitude, item.startLocation.longitude),
        imageUrl: (this.patrolImagesArray[item.id] ? (this.patrolImagesArray[item.id]).toString() : 'No Images')
      });

    });
    const groupedPatrols = this.groupEventsByCustomerForReports(reportsArray);
    this.excelService.exportAsExcelFile(groupedPatrols, this.reportType, this.reportType, this.isMobile);
    this.pitstopsArrayList = [];

    // const groupedHalts = this.groupEventsByCustomerForReports(reportsArray);
    // this.excelService.exportAsExcelFile(groupedHalts, this.reportType, this.reportType, this.isMobile);
    // this.pitstopsArrayList = [];
  }

  getMapUrl(lat, lag) {
    const mapUrl = `https://maps.google.com/?q=${lat},${lag}&amp;z=8`;
    return mapUrl;
  }

  getErroPiststop(errorPitstops: string[]): string {
    let errorList = '';
    errorPitstops.map((templateId) => {
      errorList = errorList + this.getPitstopName(templateId) + ', ';
    });
    return errorList.substr(0, errorList.length - 2);
  }

  groupEventsByCustomerForReports(patrols: Event[]) {
    return patrols.reduce((values, patrol) => {
      (values[patrol['customerName']] = values[patrol['customerName']] || []).push(patrol);
      return values;
    }, {});
  }

}
