import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';

@Injectable()
export class ConnectionService {
    private connectionMonitor: Observable<boolean>;

    constructor(private network: Network) {
        this.connectionMonitor = new Observable((observer) => {

            this.network.onDisconnect().subscribe(() => {
                // console.log(ConnectionService.name, 'network was disconnected :-(');
                observer.next(false);
            });

            this.network.onConnect().subscribe(() => {
                // console.log(ConnectionService.name, 'network was connected :-)');
                observer.next(true);
            });
        });
    }

    isConnected(): boolean {
        let conntype = this.network.type;
        return conntype && conntype !== 'unknown' && conntype !== 'none';
    }

    monitor(): Observable<boolean> {
        return this.connectionMonitor;
    }

    isOnline(): boolean {
        // // console.log(ConnectionService.name, 'Network type', this.network.type);
        // if (this.network.type) {
        //     return this.network.type !== 'none';
        // } else {
        //     return navigator.onLine;
        // }
        return window.navigator.onLine;
    }
}