import { Geoposition } from '@ionic-native/geolocation/ngx';
import { PatrolService } from './../../services/patrol.service';
import { EventService } from './../../services/event.services';
import { UserService } from './../../services/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppLocationService } from 'src/app/services/app-location.service';
import { User } from 'src/app/entity/user';
import { PitStop, EventTemplate } from 'src/app/entity/event.template';
import { Event, EventInfo } from 'src/app/entity/event';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

// Init Firebase
import * as firebase from 'firebase';

// Init GeoFireX
import * as geofirex from 'geofirex';
import { NavController, AlertController } from '@ionic/angular';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
const geo = geofirex.init(firebase);

@Component({
  selector: 'app-select-patrol',
  templateUrl: './select-patrol.component.html',
  styleUrls: ['./select-patrol.component.scss'],
})

export class SelectPatrolComponent implements OnInit, OnDestroy {
  backBtnposition = 'start';
  isActivePatrolExists = false;
  currentUser: User;
  patrolTemplates: EventTemplate[] = [];
  locationCoords: any;
  locationSubscription: Subscription;
  eventTemplateItemList: PitStop[] = [];
  eventList: Event[] = [];
  nearByEvents: Event[] = [];
  searchString: string;
  title = 'Pitstops';
  shortCode: string;

  constructor(
    private locationService: AppLocationService, private loaderService: LoaderService,
    private userService: UserService, public alertController: AlertController, private snackBarService: SnackBarService,
    private eventService: EventService,
    private patrolService: PatrolService,
    private location: Location, private router: NavController) { }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
      if (user) {
        this.getCurrentLocation();
      }
    });
  }

  async getCurrentLocation() {
    this.loaderService.presentLoading('Fetching location, please wait...');
    try {
      // this.locationCoords = await this.locationService.getCurrentLocation();
      this.locationSubscription = this.locationService.startLocation().subscribe(loc => {
        console.log("logging locationCoords ", loc);
        this.snackBarService.showToaster("Location coords fetched");
        this.locationCoords = loc;
        this.locationService.getPatrolGeoLocation(this.currentUser.clientId, this.locationCoords.coords.latitude, this.locationCoords.coords.longitude)
          .subscribe((nearByEvents) => {
            this.nearByEvents = nearByEvents;
            //// console.log('query', this.nearByEvents);
          });

      });
    } catch (err) {
      this.snackBarService.showToaster("Failed to fetch location", 2000);
    }
  }

  onSelectPitstop(event: any, patrol) {
    event.stopPropagation();
    this.shortCode = patrol.shortCode;
    this.createPatrolForEmployee();
  }

  onClickBackBtn() {
    this.location.back();
  }

  createPatrolForEmployee() {
    if (this.shortCode) {
      this.navigateToValidateLocation(this.shortCode);
    } else {
      this.presentAlert('Please enter short code');
    }
  }

  navigateToValidateLocation(shortCode) {
    this.router.navigateForward(['/patrol/validate-location'], {
      queryParams: {
        shortcode: shortCode,
      }
    });
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      backdropDismiss: false,
      message: data,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
            this.location.back();
          }
        }],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  stopTrackingLocation(){
    this.locationSubscription.unsubscribe();
    this.locationSubscription = null;
  }
  
  ngOnDestroy(){
    this.stopTrackingLocation();
  }

}
