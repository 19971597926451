import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IAceeptMessageConstant } from "../core/constants/i-accept-msg.constant";
import { Incident } from "../entity/incident";
import { ConnectionService } from "./connection.service";

@Injectable({
    providedIn: 'root'
})
export class IncidentService {
    incidentCollection: AngularFirestoreCollection<Incident>;

    constructor(private angularFirestoreDb: AngularFirestore, private connectionService: ConnectionService) {
        this.incidentCollection = this.angularFirestoreDb.collection<Incident>(IAceeptMessageConstant.COLLECTION_INCIDENTS);
    }

    async createIncident(incident: Incident) {
        console.log('createIncident -> creating incident', incident);
        const docRef = this.incidentCollection.doc(this.angularFirestoreDb.createId());
        incident.id = docRef.ref.id;
        await docRef.set({ ...incident });
        return incident;
    }

    async deleteIncident(incidentId: string) {
        console.log('createIncident -> deleting incident', incidentId);
        const docRef = this.incidentCollection.doc(incidentId);
        await docRef.delete();
    }

    async updateIncident(incident: Incident) {
        console.log('createIncident -> updating incident', incident);
        const docRef = this.incidentCollection.doc(incident.id);
        await docRef.update(incident);
        return incident;
    }

    async getAllIncidentsOfClient(clientId: string, fromDate: Date = null, toDate: Date = null, customerId: string = null, employeeId: string = null): Promise<Incident[]> {
        console.log('createIncident -> getting all incidents of client', clientId, fromDate, toDate, customerId, employeeId);
        const incidents = await this.angularFirestoreDb.collection<Incident[]>(IAceeptMessageConstant.COLLECTION_INCIDENTS,
            ref => {
                let query = ref.where('clientId', '==', clientId)
                if (fromDate) query = query.where("createdTime", ">=", fromDate);
                if (toDate) query = query.where("createdTime", "<=", toDate);
                if (customerId) query = query.where("customerId", "==", customerId);
                if (employeeId) query = query.where("createdByUser", "==", employeeId);
                query = query.orderBy("createdTime", 'desc');
                return query;
            })
            .get().pipe(map(res => res.docs.map(doc => doc.data() as Incident))).toPromise();
        console.log("incidents of client ", incidents);
        return incidents;
    }

    async getAllIncidentsReportedByEmployee(employeeId: string): Promise<Incident[]> {
        console.log('createIncident -> getting all incidents of employee', employeeId);
        const incidents = await this.angularFirestoreDb.collection<Incident[]>(IAceeptMessageConstant.COLLECTION_INCIDENTS,
            ref => ref.where('createdByUser', '==', employeeId))
            .get().pipe(map(res => res.docs.map(doc => doc.data() as Incident))).toPromise();
        console.log("incidents of employee ", incidents);
        return incidents;
    }


}