import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, tap, concatMap, concatAll, take, filter } from 'rxjs/operators';
import { Observable, forkJoin, concat, merge, Subject, of } from 'rxjs';
import { Router } from '@angular/router';
import { Event, EventInfo } from '../entity/event';
import { EventTemplate, PitStop } from '../entity/event.template';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { firestore } from 'firebase';
import { ConnectionService } from './connection.service';
import { Device } from '@ionic-native/device/ngx';
import { Attendance } from '../entity/attendance';

@Injectable({
    providedIn: 'root'
})
export class HaltService {
    activeHalt: Event = null;
    eventsCollections: AngularFirestoreCollection<Event>;
    eventTemplateCollection: AngularFirestoreCollection<EventTemplate>;
    eventsInfoCollections: AngularFirestoreCollection<EventInfo>;

    private isHaltStartedChangedSource = new Subject<any>();
    isHaltStartedChangedObservable = this.isHaltStartedChangedSource.asObservable();

    constructor(
        public fireAuth: AngularFireAuth, private device: Device,
        private angularFirestoreDb: AngularFirestore, private connectionService: ConnectionService) {
        this.eventsCollections = this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS);
    }


    getEmployeeAttendanceByDate(clientId, user, attendanceTime): Promise<Attendance[]> {

        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_ATTENDANCE,
            query => query
                .where('clientId', '==', clientId)
                .where('createdByUser', '==', user)
                .where('startTime', '<', attendanceTime).orderBy('startTime', 'desc').limit(1)).get()
            .pipe(map(res => res.docs.map(user => user.data() as Attendance))).toPromise();
    }


    /* create   createHalt */
    async createHalt(event: Event) {
        console.log('createHalt -> event', event);
        const docRef = this.eventsCollections.doc(this.angularFirestoreDb.createId());
        if (docRef.ref.id) {
            event.id = docRef.ref.id;
            event.data.startTimeDeviceId = this.device.uuid;
            const currentTime = new Date();
            if (event.data.startTimeDeviceId) {
                const attendanceList = await this.getEmployeeAttendanceByDate(event.clientId, event.createdByUser, currentTime);
                if (attendanceList[0].startTimeDeviceId === event.data.startTimeDeviceId) {
                    event.data.deviceAssesment = 'Same device'
                } else {
                    event.data.deviceAssesment = 'Device mismatch'
                }
                console.log("event.data.taskListId in createHalt is", event.data.taskListId);
                
                if(event.data.taskListId === undefined) {
                    console.log("quotes");
                    Object.assign(event.data, { ...event.data, taskListId: null });
                    console.log(event.data.taskListId);
                }

                console.log("event.data.taskListId in createHalt is", event.data.taskListId);

                await docRef.set({ ...event });
                return event;
            }
        } else {
            //window.alert('Failed to create visit, please try again');
            return Promise.reject("createHalt -> firestore id creation failed");
        }
    }

    // update Halt
    updateHalt(haltId: string, eventData: EventInfo) {
        //eventData.deviceId = this.device.uuid;
        console.log("eventData,taskListId in updateHalt is", eventData.taskListId);

        if(eventData.taskListId === undefined){
            console.log("quotes");
            Object.assign(eventData, { ...eventData, taskListId: null });
            eventData.taskListId == "";
        }
        console.log("eventData.taskListId in updateHalt is", eventData.taskListId);
        return this.eventsCollections.doc(haltId).update({
            data: { ...eventData }
        });
    }

    // update Halt Images
    updateHaltImages(haltId: string, images: string[]) {
        return this.eventsCollections.doc(haltId).update({
            images
        });
    }

    // end Halt
    async endHalt(haltId: string, event: Event, endLocation: Geoposition) {
        console.log("halt-service: ending halt", haltId)
        event.data.endTimeDeviceId = this.device.uuid;
        if (event.data.endTimeDeviceId) {
            const attendanceList = await this.getEmployeeAttendanceByDate(event.clientId, event.createdByUser, event.startTime);
            if (attendanceList[0].startTimeDeviceId === event.data.endTimeDeviceId) {
                event.data.deviceAssesment = 'Same device'
            } else {
                event.data.deviceAssesment = 'Device mismatch'
            }
            await this.eventsCollections.doc(haltId).update({
                data: { ...event.data },
                endLocation: new firestore.GeoPoint(endLocation.coords.latitude, endLocation.coords.longitude),
                endAccuracy: endLocation.coords.accuracy,
                endTime: this.connectionService.isConnected() ? firestore.FieldValue.serverTimestamp() : new Date()
            });
            console.log("halt-service: halt ended", haltId)
            return event;
        } else return Promise.reject("endHalt -> firestore end halt failed");
    }



    /* get all halts of client*/
    getAllHaltsOfClient(clientId: string, filterDate: Date): Observable<Event[]> {
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('clientId', '==', clientId).where('type', '==', IAceeptMessageConstant.EVENT_TYPE_HALT);
            if (filterDate) {
                query = query.where('startTime', '>=', filterDate);
            }
            query = query.orderBy('startTime', 'desc');
            return query;
        }).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    /* get halts of employee */
    getAllHaltBasedOnUserId(userId: string, clientId: string, filterDate: Date = null) {
        let endDate: Date = null;
        if (filterDate) {
            endDate = new Date(filterDate.getTime());
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
        }

        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('createdByUser', '==', userId)
                .where('clientId', '==', clientId)
                .where('type', '==', IAceeptMessageConstant.EVENT_TYPE_HALT);
            if (filterDate) {
                query = query.where('startTime', '>=', filterDate);
                query = query.where('startTime', '<=', endDate);
            }
            query = query.orderBy('startTime', 'desc');
            return query;
        }).get().pipe(map(res => res.docs.map(doc => doc.data())));
    }

    // get Single Halt
    getHalt(id: string) {
        return this.eventsCollections.doc(id).get().pipe(map(res => res.data()));
    }

    getHaltsOfClientByFilter(
        clientId: string, fromDate: Date, toDate: Date, customerId: string = null,
        employeeId: string = null): Observable<Event[]> {
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENTS, ref => {
            let query = ref.where('clientId', '==', clientId).where('type', '==', IAceeptMessageConstant.EVENT_TYPE_HALT)
                .where('startTime', '>=', fromDate).where('startTime', '<=', toDate);
            if (customerId && customerId !== '') {
                query = query.where('data.customerId', '==', customerId);
            }
            if (employeeId && employeeId !== '') {
                query = query.where('createdByUser', '==', employeeId);
            }
            // console.log(HaltService.name, query);
            return query;
        }).get()
            .pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    // delete unresponsive halt
    deleteHalt(haltId: string) {
        console.log('halt service delete halt id', haltId);
        if (this.activeHalt) {
            if (haltId === this.activeHalt.id) {
                console.log('halt service delete halt id', haltId);
                console.log('halt service active halt id', this.activeHalt.id);
                this.setActiveHalt(null);
                this.isHaltStartedChangedSource.next(null);
            }
        }
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS)
            .doc(haltId).delete().then(() => {
                console.log('Document successfully deleted!');
            }).catch((error) => {
                console.error('Error removing document: ', error);
            });
    }


    setActiveHalt(halt: Event) {
        this.activeHalt = halt;
        // console.log(HaltService.name, 'active halt', this.activeHalt);
        this.isHaltStartedChangedSource.next(halt);
    }

    getActiveHalt() {
        return this.activeHalt;
    }

    listenToActiveHaltChange(): Observable<Event> {
        return this.isHaltStartedChangedObservable;
    }

    // createHalt(event: Event): Event {
    //     const docRef = this.eventsCollections.doc(this.angularFirestoreDb.createId());
    //     event.id = docRef.ref.id;
    //     event.data.deviceId = this.device.uuid;
    //     // console.log(HaltService.name, this.currentHalt, 'currentHalt');
    //     if (this.currentHalt == null) {
    //         docRef.set({ ...event });
    //         docRef.get().subscribe((createdEvent) => {
    //             if (createdEvent) {
    //                 this.setCurrentHalt(createdEvent.data() as Event);
    //             }
    //         });
    //         return event;
    //     } else {
    //         this.currentHalt = null;
    //     }
    // }

    fetchActiveHalt(currentUserId: string, clientId: string): Observable<Event[]> {
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref =>
            ref.where('clientId', '==', clientId)
                .where('type', '==', IAceeptMessageConstant.TEMPLATE_TYPE_PATROL)
                .where('endTime', '==', null)
                .where('createdByUser', '==', currentUserId)
        ).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }

    getTaskList(clientId: string, companyName?: string){
        if(companyName){
            return this.angularFirestoreDb.collection('taskList', ref => 
                ref.where('clientId', '==', clientId)
                    .where('companyName', '==', companyName)
            ).get().pipe(map(res => res.docs.map(doc => doc.data())));
        }
        else {
            return this.angularFirestoreDb.collection('taskList', ref => 
                ref.where('clientId', '==', clientId))
            .get().pipe(map(res => res.docs.map(doc => doc.data())));
        }
    }

    getTaskListSubcollection(id: string){
        return this.angularFirestoreDb.collection('taskList').doc(id).collection('task').get().pipe(map(res => res.docs.map( d =>  { 
          const newData = Object.assign(d.data(), { status: false });
          // console.log(newData);
          return newData;
        }))).toPromise();
    }

    getAllHaltsInLast24Hrs(clientId: string): Observable<Event[]>{
        const startDateTime = new Date();
        startDateTime.setDate(startDateTime.getDate());
        startDateTime.setHours(0, 0, 0, 0);
        console.log("startDate is ", startDateTime);
        
        const endDateTime = new Date();
        endDateTime.setHours(23, 59, 59, 59);
        console.log("endDate is ", endDateTime);

        // test id - RqU492p4NQXPpxPFYynh Force18 client
        return this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_EVENTS , ref =>       
              ref.where('clientId' , '==', clientId)
              .where('type', '==', IAceeptMessageConstant.EVENT_TYPE_HALT)
              .where('startTime', '>=', startDateTime)
              .where('startTime', '<=', endDateTime)
              .orderBy('startTime', 'desc'))
              .get()
              .pipe(map(res => res.docs.map(doc => doc.data() as Event)));

    }

    getAllHaltsOfClientRanged(clientId: string, startDate: Date, endDate: Date): Observable<Event[]> {
        return this.angularFirestoreDb.collection<Event>(IAceeptMessageConstant.COLLECTION_EVENTS, ref => ref.where('clientId', '==', clientId).where('type', '==', IAceeptMessageConstant.EVENT_TYPE_HALT)
                .where('startTime', '>=', startDate)
                .where('startTime', '<=', endDate)
                .orderBy('startTime', 'desc')).get().pipe(map(res => res.docs.map(doc => doc.data() as Event)));
    }
}

