import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EventInfo, Event } from 'src/app/entity/event';
import { User } from 'src/app/entity/user';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Location } from '@angular/common';
import { PatrolService } from 'src/app/services/patrol.service';
import { PitStop } from 'src/app/entity/event.template';
import { AppLocationService } from 'src/app/services/app-location.service';
import { Subscription } from 'rxjs';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { firestore } from 'firebase';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { LoaderService } from 'src/app/services/loader.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

@Component({
  selector: 'app-create-patrol',
  templateUrl: './create-patrol.component.html',
  styleUrls: ['./create-patrol.component.scss'],
})
export class CreatePatrolComponent implements OnInit, OnDestroy {
  shortCode: string; 
  currentUser: User;
  multipleEventData: any[] = [];
  templateId = [];
  templateItemId = [];
  isMultipleEventFound = false;
  currentPatrolRunning = false;
  eventTemplateItemList: PitStop[] = [];
  currentPatrol: Event;
  currentActivePitStopList: Event[] = [];
  locationCoords: Geoposition | PositionError
  locationSubscription: Subscription;
  clicked = false;
  reenableButton = new EventEmitter<boolean>(false);

  constructor(
    private userService: UserService, private route: ActivatedRoute,
    private location: Location, private router: NavController, private loaderService: LoaderService,
    private snackService: SnackBarService, private patrolService: PatrolService, private connectionService: ConnectionService,
    private locationService: AppLocationService, private locationAccuracy: LocationAccuracy, private qrScanner: QRScanner,
    public alertController: AlertController, public modalController: ModalController) { }


  ngOnDestroy() {
    if (this.qrScanner) {
      this.qrScanner.destroy();
    }
  }

  ngOnInit() {
    this.getShortCode();
    this.startQrScanner();
  }

  getShortCode() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.shortCode = params.shortCode;
        console.log(params.patrol);
        params.patrol === undefined ? this.currentPatrolRunning = false : this.currentPatrolRunning = true;
        console.log("currentPatrolRunning => ", this.currentPatrolRunning);
        this.currentPatrol = params.patrol;
        console.log("currentPatrol templateId ", this.currentPatrol.templateId);
      }
    });
  }


  startQrScanner() {
    this.qrScanner.prepare()
      .then((status: QRScannerStatus) => {
        if (status.authorized) {
          const scanSub = this.qrScanner.scan().subscribe((text: string) => {
            //// console.log(CreatePatrolComponent.name, 'Scanned something', text);
            this.shortCode = text;
            // alert("scanned text " + text);
            this.qrScanner.hide(); // hide camera preview
            scanSub.unsubscribe(); // stop scanning
            this.navigateToValidateLocation(this.shortCode);
            // this.createPatrolForEmployee();
          });

        } else if (status.denied) {
          // camera permission was permanently denied
          // you must use QRScanner.openSettings() method to guide the user to the settings page
          // then they can grant the permission from there
          this.presentAlert('Please allow camera access to scan QR code of a pitstop');
          this.qrScanner.openSettings();
        } else {
          this.presentAlert('Please allow camera access to scan QR code of a pitstop');
        }
      })
      .catch((e: any) => console.log(CreatePatrolComponent.name, 'Error:', e));
  }


  navigateToValidateLocation(shortCode) {
    this.qrScanner.destroy();
    this.router.navigateForward(['/patrol/validate-location'], {
      queryParams: {
        shortcode: shortCode,
      }
    });
  }

  createPatrolForEmployee() {
    if (this.shortCode) {
      this.navigateToValidateLocation(this.shortCode);
    } else {
      this.presentAlert('Please enter short code');
    }
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      backdropDismiss: false,
      message: data,
      buttons: [
        {
        text: 'OK',
        handler: () => {
          alert.dismiss();
          this.location.back();
        }
      }],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  onClickBackBtn() {
    this.location.back();
  }
}
