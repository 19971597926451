import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from '../shared/contact/contact.component';
import { CreatePatrolComponent } from './create-patrol/create-patrol.component';
import { PatrolDashboardComponent } from './patrol-dashboard/patrol-dashboard.component';
import { SelectPatrolComponent } from './select-patrol/select-patrol.component';
import { ValidateLocationComponent } from './validate-location/validate-location.component';

const patrolRoutes: Routes = [
    {
        path: 'patrol',
        children: [
            {
                path: 'dashboard',
                component: PatrolDashboardComponent,
            },
            {
                path: 'validate-location',
                component: ValidateLocationComponent,
            },
            {
                path: 'create-patrol',
                component: CreatePatrolComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            },
            {
                path: 'select-patrol',
                component: SelectPatrolComponent,
            },
        ]

    }
];
@NgModule({
    imports: [
        RouterModule.forChild(patrolRoutes)
    ],
    exports: [RouterModule]
})
export class EmployeePatrolRoutingModule { }
