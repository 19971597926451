import { PitStop } from 'src/app/entity/event.template';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Platform, AlertController, NavController, ModalController } from '@ionic/angular';
import { PatrolService } from 'src/app/services/patrol.service';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Subscription } from 'rxjs';
import { AppLocationService } from 'src/app/services/app-location.service';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { GoogleMap, GoogleMapsEvent, Environment, GoogleMaps, MarkerCluster, Marker, } from '@ionic-native/google-maps/ngx';
import { User } from 'src/app/entity/user';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { Location } from '@angular/common';
import { ConnectionService } from 'src/app/services/connection.service';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { firestore } from 'firebase';
import { EventInfo, Event } from 'src/app/entity/event';
import { CustomFormModalComponent } from 'src/app/shared/custom-form-modal/custom-form-modal.component';
import { getDistance, getPreciseDistance } from 'geolib';


@Component({
  selector: 'app-validate-location',
  templateUrl: './validate-location.component.html',
  styleUrls: ['./validate-location.component.scss'],
})
export class ValidateLocationComponent implements OnInit, OnDestroy {
  map: GoogleMap;
  marker: any;
  calibratedMarker: any;
  mapPistopData = [];
  locationCoords: any;
  masterlocationCoords: any;
  locationSubscription: Subscription;
  customerLocationSet = false;
  inPitstopRange = false;
  currentUser: User;
  currentPatrol: Event;
  currentPatrolRunning = false;
  shortCode: string;
  pitstops: PitStop[];
  templateId = [];
  templateItemId = [];
  eventTemplateItemList: PitStop[] = [];
  currentActivePitStopList: Event[] = [];
  multipleEventData: any[] = [];
  isMultipleEventFound = false;
  currentPitstopDetails: any;
  currentPitStopLocationValue = 0;
  totalDistanceCovered = 0;
  locationZeroMeters = false;
  constructor(
    public patrolService: PatrolService, private platform: Platform, private userService: UserService,
    private locationAccuracy: LocationAccuracy, private locationService: AppLocationService,
    private route: ActivatedRoute, private loaderService: LoaderService, public alertController: AlertController,
    private location: Location, private connectionService: ConnectionService, private snackService: SnackBarService,
    private router: NavController, public modalController: ModalController, private snackBarService: SnackBarService) { }


  ionViewDidEnter() { // Fired when the component routing to has finished animating.
    this.loadMap(); // Load map
  }

  ngOnDestroy() { // Custom cleanup that needs to occur when the instance is destroyed.
    this.stopTrackingLocation();
  }

  async loadMap() {
    //// console.log(ValidateLocationComponent.name, 'Loading map...');
    // Specifies environment variables. The specified variable effects for all maps in application.
    Environment.setEnv({
      'API_KEY_FOR_BROWSER_RELEASE': 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI',
      'API_KEY_FOR_BROWSER_DEBUG': 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI'
    });
    // Create a map view
    this.map = GoogleMaps.create('map_canvas', {
      preferences: {
        zoom: {
          minZoom: 15,
          maxZoom: 18
        }
      }
    });
    await this.map.one(GoogleMapsEvent.MAP_READY)
    console.log('loadmap -> map', this.map);
    //// console.log(ValidateLocationComponent.name, 'Map created', this.map);
  }

  addMarkerToMap(latitude: number, longitude: number) {
    console.log(ValidateLocationComponent.name, 'addMarkerToMap', latitude, longitude, this.map);
    if (this.map) {
      if (this.marker) {
        this.map.clear();
      }
      this.marker = this.map.addMarkerSync({
        title: 'you are here',
        icon: 'orange',
        animation: 'DROP',
        position: {
          lat: latitude,
          lng: longitude
        }
      });
      this.map.setCameraTarget({ lat: latitude, lng: longitude });
    }
  }

  ngOnInit() {
    this.checkCurrentPatrolRunning();
    this.loadData();
  }


  async loadData() {
    // Capture Shotcode from URL
    console.log('loading data...');
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.shortCode = params.shortcode;
        this.getCurrentUser();         // Get current user details
      }
    })
  }

  async getCurrentUser() {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    this.checkLocationPermissionsAndTrack();
  }

  async fetchEventTemplate(clientId, shortCode) {
    console.log('fetchEventTemplate', shortCode);
    // console.log('current Patrol Running ', this.currentPatrolRunning);
    if (this.currentPatrolRunning) {
      const pitstop: PitStop = this.checkIfShortCodeExists(this.shortCode);
      // console.log('pitstop ',pitstop);
      if (pitstop) {
        this.addCalibratedLocationMarker(pitstop.location.latitude, pitstop.location.longitude);
        this.masterlocationCoords = pitstop.location;
      } else {
        //this.loaderService.dismissLoading();
        this.presentAlert(`Pitstop with shortcode ${this.shortCode} is not available in current patrol, please check the shortcode`);
      }
      console.log('fetchEventTemplate -> coords', this.locationCoords);
      if (this.locationCoords) {
        this.addMarkerToMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
        this.calcDistanceRangeError();
      }
    } else {
      this.multipleEventData = await this.patrolService.getPatrolsForPitstopByShortcode(shortCode, clientId);
      // console.log('multiple event data ', this.multipleEventData);
      this.saveMultipleEventData();
    }
  }

  checkIfShortCodeExists(shortCode: string) {
    return this.eventTemplateItemList.find((item) => item.shortCode === shortCode);
  }

  async saveMultipleEventData() {
    console.log(ValidateLocationComponent.name, this.multipleEventData, 'multipleEventData');
    if (!this.multipleEventData || this.multipleEventData.length === 0) {
      //this.loaderService.dismissLoading();
      this.presentAlert(`Pitstop with shortcode ${this.shortCode} not found, please check the short code`);
      return;
    }
    this.isMultipleEventFound = this.multipleEventData.length > 1 ? true : false;
    if (!this.isMultipleEventFound) {
      this.currentPitstopDetails = this.multipleEventData[0];
      //// console.log(ValidateLocationComponent.name, 'saveMultipleEventData currentPitstopDetails', this.currentPitstopDetails);
      if (this.currentPitstopDetails) {
        this.masterlocationCoords = this.currentPitstopDetails.pitstop.location;
        //// console.log(ValidateLocationComponent.name, 'saveMultipleEventData currentPitstopLoc', location);
        this.addCalibratedLocationMarker(this.masterlocationCoords.latitude, this.masterlocationCoords.longitude);
        if (this.locationCoords) {
          this.addMarkerToMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
          this.calcDistanceRangeError();
        }
        //// console.log(ValidateLocationComponent.name, this.multipleEventData, 'Multiple Event Data');
      }
    }
  }

  addCalibratedLocationMarker(lat: number, lng: number) {
    console.log("addCalibratedLocationMarker", lat, lng, this.map);
    try {
      this.marker = this.map.addMarkerSync({
        title: 'Calibrated pitstop location',
        icon: '../../../assets/images/concentric.png',
        animation: 'BOUNCE',
        position: {
          lat,
          lng
        }
      });
      console.log('calibrated marker added');
      this.map.setCameraTarget({ lat, lng });
      this.map.setCameraZoom(11);
    }
    catch (error) {
      console.log('error adding marker', error);
    }
  }

  calculateDistanceCovered() {
    // console.log('currentActivePitStopList', this.currentActivePitStopList);
    this.currentActivePitStopList.map((activePitstop, index) => {
      // console.log('activePitstop', activePitstop, index);
      if (index > 0) {
        this.totalDistanceCovered = this.totalDistanceCovered +
          getPreciseDistance(
            {
              latitude: activePitstop.startLocation.latitude,
              longitude: activePitstop.startLocation.longitude
            },
            {
              latitude: this.currentActivePitStopList[index - 1].startLocation.latitude,
              longitude: this.currentActivePitStopList[index - 1].startLocation.longitude
            }
          );
      }
    })
    const startLocation = new firestore.GeoPoint(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
    // console.log('start location', startLocation);
    this.totalDistanceCovered = this.totalDistanceCovered +
      getPreciseDistance(
        {
          latitude: startLocation.latitude,
          longitude: startLocation.longitude
        },
        {
          latitude: this.currentActivePitStopList[this.currentActivePitStopList.length - 1].startLocation.latitude,
          longitude: this.currentActivePitStopList[this.currentActivePitStopList.length - 1].startLocation.longitude
        });
    // console.log('totalDistanceCovered', this.totalDistanceCovered);
    return this.totalDistanceCovered;
  }

  async createPatrol() {
    if (this.currentPatrolRunning) {
      if (!this.locationCoords) {
        //this.loaderService.dismissLoading();
        this.presentAlert('Could not fetch location, please enable location from settings');
        return;
      }
      const currentPitStop = this.checkCurrentPitStopByTemplateId(this.shortCode);
      if (currentPitStop) {
        if (currentPitStop.endTime == null) {
            await this.patrolService.endPitstop(currentPitStop.id, this.locationCoords)
            this.snackService.showToaster(`Completed pitstop ${this.shortCode}`);
            this.reDirectToHome();
            return;
          } else {
          //this.loaderService.dismissLoading();
          this.presentAlert('This pitstop is already completed');
          return;
        }
      } else {
        //// console.log(ValidateLocationComponent.name, 'continue');
        const pitStop = this.checkIfShortCodeExists(this.shortCode);
        //// console.log(ValidateLocationComponent.name, pitStop, 'pitstop');
        if (pitStop) {
          const savePitStopParams = this.setPatrolAndPitStopParmameter(
            this.currentPatrol.templateId,
            pitStop.id,
            IAceeptMessageConstant.TEMPLATE_TYPE_PATROL_PITSTOP,
            new Date(),
            null,
            this.currentPatrol.data.customerId,
            this.currentPatrol.data.customerName);
          //// console.log(ValidateLocationComponent.name, savePitStopParams, 'res-savePitStopParams');

          await this.savePitstopForPatrol(savePitStopParams);
          const savedPatrolDistance = this.calculateDistanceCovered();
          console.log( "savedPatrolDistance", savedPatrolDistance);
          // console.log('savedPatrolDistance', savedPatrolDistance);
          await this.patrolService.savePatrolDistance(this.currentPatrol, savedPatrolDistance);
          //this.loaderService.dismissLoading();
        } else {
          //this.loaderService.dismissLoading();
          this.presentAlert(`Could not find a pitstop with this ${this.shortCode}`);
        }
      }
    } else {
      if (this.currentPitstopDetails) {
        const savePatrolParams = this.setPatrolAndPitStopParmameter(
          this.currentPitstopDetails.patrol.id,
          null, IAceeptMessageConstant.TEMPLATE_TYPE_PATROL,
          new Date(), null,
          this.currentPitstopDetails.patrol.customerId,
          this.currentPitstopDetails.patrol.customerName);
        //// console.log(ValidateLocationComponent.name, savePatrolParams, 're-set Patrol and PitStop Parmameter');

        const patrolCreated = await this.savePatrol(savePatrolParams);
        const savePitStopParams = this.setPatrolAndPitStopParmameter(
          this.currentPitstopDetails.patrol.id,
          this.currentPitstopDetails.pitstop.id,
          IAceeptMessageConstant.TEMPLATE_TYPE_PATROL_PITSTOP,
          new Date(), null,
          this.currentPitstopDetails.patrol.customerId,
          this.currentPitstopDetails.patrol.customerName);
        //this.loaderService.dismissLoading();
        await this.savePitstopForPatrol(savePitStopParams);
      }
    }
    this.snackService.showToaster(IAceeptMessageConstant.SUCESSFULLYSAVE);
    this.reDirectToHome();
  }

  checkCurrentPatrolRunning() {
    const currentPatrol: Event = this.patrolService.getCurrentPatrol();
    console.log(ValidateLocationComponent.name, currentPatrol, 'currentPatrol');
    if (currentPatrol) {
      this.currentPatrolRunning = true;
      this.currentPatrol = currentPatrol;
      console.log(ValidateLocationComponent.name, 'currentpatrol', this.currentPatrolRunning);
      this.eventTemplateItemList = this.patrolService.currentPitStopList;
      console.log(ValidateLocationComponent.name, 'eventTemplateItemList', this.eventTemplateItemList);
      this.currentActivePitStopList = this.patrolService.currentActivePitStopList;
      console.log(ValidateLocationComponent.name, 'currentActivePitStopList', this.currentActivePitStopList);
    }
  }

  onSelectPatrol(event) {
    this.isMultipleEventFound = false;
    this.currentPitstopDetails = event;
    this.masterlocationCoords = this.currentPitstopDetails.pitstop.location;
    this.addCalibratedLocationMarker(this.masterlocationCoords.latitude, this.masterlocationCoords.longitude);
    if (this.locationCoords) {
      this.addMarkerToMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
      this.calcDistanceRangeError();
    }
  }

  setPatrolAndPitStopParmameter(
    templateId: string, templateItemId: string,
    type: string, startTime: Date, endTime: Date, customerId: string, customerName: string): Event {
    const event = new Event();
    event.clientId = this.currentUser.clientId;
    event.createdByUser = this.currentUser.id;
    event.createdByUserName = this.currentUser.name;
    if (startTime) {
      event.startTime = this.connectionService.isConnected() ? firestore.FieldValue.serverTimestamp() : new Date();
      if (this.locationCoords) {
        event.startLocation = new firestore.GeoPoint(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
        event.startLocationAccuracy = this.locationCoords.coords.accuracy;
      }
    }
    if (endTime) {
      event.endTime = this.connectionService.isConnected() ? firestore.FieldValue.serverTimestamp() : new Date();
      if (this.locationCoords) {
        //// console.log(ValidateLocationComponent.name, 'location coordinates before', this.locationCoords);
        event.endLocation = new firestore.GeoPoint(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
        //// console.log(ValidateLocationComponent.name, 'event end location', event.endLocation);
        event.endLocationAccuracy = this.locationCoords.coords.accuracy;
      }
    }
    event.templateId = templateId;
    event.templateItemId = templateItemId;
    event.type = type;
    event.data = new EventInfo();
    event.data.customerId = customerId;
    event.data.customerName = customerName;
    event.data.totalDistanceCovered = 0;
    //// console.log(ValidateLocationComponent.name, 'event', event);
    return event;
  }

  async savePitstopForPatrol(eventInfoObj: Event) {
    return await this.patrolService.createPitstopForPatrol(eventInfoObj);
  }

  async savePatrol(eventInfoObj: Event) {
    const patrolCreated = await this.patrolService.createPatrol(eventInfoObj);
    return patrolCreated;
  }

  checkCurrentPitStopByTemplateId(shortCode: string) {
    const pitStopByShortCode = this.eventTemplateItemList.find(pitstop => pitstop.shortCode === shortCode);
    if (pitStopByShortCode) {
      return this.currentActivePitStopList.find((item) => item.templateItemId === pitStopByShortCode.id);
    }
  }

  reDirectToHome() {
    this.router.navigateBack(['/patrol/dashboard']);
  }

  async checkLocationPermissionsAndTrack() {
    if (this.platform.is('desktop')) {
      this.loaderService.presentLoading('Fetching location, please wait...');
      await this.getLocation();
      return;
    }
    try {
      const canRequest: boolean = await this.locationAccuracy.canRequest();
      if (canRequest) {
        console.log(ValidateLocationComponent.name, 'Location services must be requested');
        // the accuracy option will be ignored by iOS
        await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
        console.log(ValidateLocationComponent.name, 'Location permission request successful');
        this.loaderService.presentLoading('Fetching location, please wait...');
        await this.getLocation();
      } else {
        console.log(ValidateLocationComponent.name, 'Location permissions already granted');
        this.loaderService.presentLoading('Fetching location, please wait...');
        await this.getLocation();
      }
    } catch (err) {
      this.presentAlert(`Error fetching device location, please try again..`);
      console.log("Validate Location: error fetching location ", err);
    }
  }

  async getLocation() {
    try {
      this.locationSubscription = this.locationService.startLocation().subscribe(async loc => {
        console.log("locationCoords ", loc);
        this.locationCoords = loc;
        this.snackBarService.showToaster("Location coords fetched");
        console.log(ValidateLocationComponent.name, 'Got new location coords', this.locationCoords);
        this.calcDistanceRangeError();
        this.addMarkerToMap(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
        const pitStopLength = this.currentActivePitStopList.length;
        if(pitStopLength){
          const PrevPistop = this.currentActivePitStopList[pitStopLength-1].endLocation;
          if(PrevPistop && this.calculatePitstopDistance(PrevPistop.latitude, PrevPistop.longitude, this.locationCoords.coords.latitude, this.locationCoords.coords.longitude, 'K') === 0){
            this.locationZeroMeters = true;
            const alert = await this.alertController.create({
              cssClass: "my-custom-class",
              header: "Alert",
              //subHeader: 'Subtitle',
              message: "The distance covered from last pitstop is 0 mts. Please check if GPS is enabled and you have moved!",
              buttons: [
                {
                  text: "Cancel",
                  role: "NO",
                  handler: () => {
                    this.reDirectToHome();
                  },
                },
              ],
            });
        
            await alert.present();
            return;
          }
          else {
            // PrevPiStop is "null" i.e. the last recorded pitstop patrol has not ended yet 
            this.fetchEventTemplate(this.currentUser.clientId, this.shortCode); // Get Event template (patrol and pitstop)
          }
        }
        // not required 
        else {
          console.log("only len of 0");
          this.fetchEventTemplate(this.currentUser.clientId, this.shortCode); // Get Event template (patrol and pitstop)
        }
      }
      );
    } catch (err) {
      this.snackBarService.showToaster("Failed to fetch location", 2000);
    }
  }

  stopTrackingLocation() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
      this.locationSubscription = null;
    }
  }

  calcDistanceRangeError() {
    console.log('calcDistanceRangeError -> values', this.locationCoords, this.masterlocationCoords);
    if (this.locationCoords && this.masterlocationCoords) {
      this.currentPitStopLocationValue = getPreciseDistance(
        { latitude: this.locationCoords.coords.latitude, longitude: this.locationCoords.coords.longitude },
        { latitude: this.masterlocationCoords.latitude, longitude: this.masterlocationCoords.longitude });
      console.log('calcDistanceRangeError -> distance', this.currentPitStopLocationValue);
      if (this.currentPitStopLocationValue < 75) {
        this.inPitstopRange = true;
      } else {
        this.inPitstopRange = false;
      }
    }
  }

  calculatePitstopDistance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'M') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      backdropDismiss: false,
      message: data,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
            this.router.navigateBack(['/patrol/dashboard']);
          }
        }],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  onClickBackBtn() {
    this.router.navigateBack(['/patrol/dashboard']);
  }

  rad(x) {
    return x * Math.PI / 180;
  };

  getDistance = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = this.rad(p2.latitude - p1.latitude);
    var dLong = this.rad(p2.longitude - p1.longitude);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.latitude)) * Math.cos(this.rad(p2.latitude)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  }
}
