import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { IAceeptMessageConstant } from '../core/constants/i-accept-msg.constant';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FireDrillCallService {
  toasterShown: boolean = false;
  constructor(private angularFirestoreDb: AngularFirestore,) { 

  }

  public activeDrillCallCheck(userId) {
    return this.angularFirestoreDb.collectionGroup(IAceeptMessageConstant.COLLECTION_FIRE_DRILL_RESPONSES,
      ref => ref.where('employeeId', '==', userId).where('responded', '==', false))
       .get().pipe(map(res => res.docs.map(doc => doc.data())));
  }
}
