import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralDashboardComponent } from './general-dashboard/general-dashboard.component';
const generalRoutes: Routes = [
    {
        path: 'general',
        children: [
            {
                path: 'dashboard',
                component: GeneralDashboardComponent,
            }
        ]
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(generalRoutes)
    ],
    exports: [RouterModule]
})
export class EmployeeGeneralRoutingModule { }