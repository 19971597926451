export class EventTemplate {
    clientId: string;
    createdByUser: string;
    customerId: string;
    customerName: string;
    id: string;
    type: string;
    name: string;
    items: PitStop;
    isActive: boolean;
    taskName: string;

}

export class PitStop {
    id: string;
    name: string;
    shortCode: string;
    clientId: string;
    location: any;
    isActive: boolean;
}
