import { Component, OnInit } from '@angular/core';
import { Geoposition } from "@ionic-native/geolocation/ngx";
import { AppLocationService } from "src/app/services/app-location.service";
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from "@angular/fire/firestore";
import { UserService } from 'src/app/services/user.service';
import { AlertController, NavController } from '@ionic/angular';
import { LoaderService } from 'src/app/services/loader.service';
import { firestore } from "firebase";

@Component({
  selector: 'app-employee-alert',
  templateUrl: './employee-alert.component.html',
  styleUrls: ['./employee-alert.component.scss'],
})
export class EmployeeAlertComponent implements OnInit {
  currentUser: any;
  currentTime: Date;
  locationCoords: Geoposition | PositionError
  docId: any;
  subCollectionId: any;
  fireDrillAlerts: any[]= [];

  constructor(
    private locationService: AppLocationService,
    public alertController: AlertController,
    private userService: UserService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private loaderService: LoaderService,
    private afs: AngularFirestore
  ) { 
    this.currentTime = new Date;
  }

  ngOnInit() {
    this.loaderService.presentLoading('Loading fire drill list');
    this.getRoutingParams();
    this.userService.getCurrentUser().subscribe(user => {
      // this.loaderService.dismissLoading();
      this.currentUser = user;
      // if(this.docId && this.subCollectionId) {
      //   this.notificationPayload();
      // } else {
        this.activeDrillCallCheck();
      // }
    });
  }

  getRoutingParams() {
    this.route.queryParams.subscribe((params) => {
      if(params) {
        console.log("params ", params);
        this.docId = params.docId;
        this.subCollectionId = params.subCollectionId;
      }
    })
  }

  notificationPayload() {
    this.afs.collection('fireAlerts').doc(this.docId).get().subscribe(documentSnapshot => {
      console.log("data ", documentSnapshot.data());
      this.fireDrillAlerts = [];
      this.fireDrillAlerts.push({ ...documentSnapshot.data(), docId: this.docId, id: this.subCollectionId });
      console.log("firedrillalerts ", this.fireDrillAlerts);
    })
  }

  sendAlertResponse() {
    this.locationService.getCurrentLocation().then((loc) => {
      this.locationCoords = loc;
      console.log("employee alert locationCoords ", this.locationCoords);
      if(this.docId && this.subCollectionId) {
        this.firestoreWrite(this.docId, this.subCollectionId, this.locationCoords);
        this.presentAlert("Response has been recorded!!")
      }
      //navigate to dashboard
      console.log("navigating back to dashboard");
      if (this.currentUser.registrationType === 'halt' || this.currentUser.registrationType === 'custom') {
        this.navCtrl.navigateRoot(['/halt/dashboard']);
      }
      if (this.currentUser.registrationType === 'patrol') {
        this.navCtrl.navigateRoot(['/patrol/dashboard']);
      }
    }).catch((err) => {
      console.error("ERROR ", err);
    })
  }

  firestoreWrite(docId, subcollectionId, locCoords) {
    let respRef = this.afs.collection('fireAlerts').doc(docId).collection('responses').doc(subcollectionId);
    console.log("responses collection reference ", respRef);
    respRef.update({
      data: {
        "coords": new firestore.GeoPoint(locCoords.coords.latitude, locCoords.coords.longitude),
        "time": firestore.FieldValue.serverTimestamp()
      },
      responded: true,
    });
  }

  async activeDrillCallCheck() {
    console.log("active drill check");
    const drillsList = await this.afs.collection('fireAlerts').ref
      .where('clientId', '==', this.currentUser.clientId)
      .orderBy('alarmRaisedAt', 'asc')
      .get();
    drillsList.forEach(async (documentSnapshot) => {
      const alertDocument = documentSnapshot.data();
      // console.log("drills ", documentSnapshot.data());
      const responsesList =  await this.afs.collection('fireAlerts').doc(documentSnapshot.data().id).collection('responses').ref
        .where('employeeId', '==', this.currentUser.id)
        .where('responded', '==', false).get();
      responsesList.forEach((documentSnapshot) => {
        console.log("pending responses ", documentSnapshot.data());
        this.fireDrillAlerts.push({ ...documentSnapshot.data(), docId: alertDocument.id, employerName: alertDocument.employerName, alarmRaisedAt: alertDocument.alarmRaisedAt });
        console.log("firedrill ", this.fireDrillAlerts);
      });
    });

  }

  sendResponseForACall(alertDocId, responseDocId) {
    console.log("alert document id ", alertDocId);
    console.log("response document id ", responseDocId);
    this.docId = alertDocId;
    this.subCollectionId = responseDocId;
    this.sendAlertResponse();
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            alert.dismiss();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

}
