import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-handling',
  templateUrl: './subscription-handling.component.html',
  styleUrls: ['./subscription-handling.component.scss'],
})
export class SubscriptionHandlingComponent implements OnInit {
  userRole: string;
  constructor(private router: ActivatedRoute) { }

  ngOnInit() {
    this.getRouteParams();
  }

  getRouteParams() {
    this.router.queryParams.subscribe((params) => {
      // console.log(SubscriptionHandlingComponent.name, params, 'parmas');
      if (params) {
        this.userRole = params.userRole;
      }
    });
  }

}
