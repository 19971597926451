import { Injectable } from '@angular/core';
import { Observable, concat, Subject, of } from 'rxjs';
import { Event, EventInfo } from '../entity/event';
import { EventTemplate, PitStop } from '../entity/event.template';

@Injectable({
    providedIn: 'root'
})
export class MapService {
    patrol: Event = null;
    patrolTemplate: EventTemplate = null;
    pitstops: Event[] = [];
    pitstopTemplates: PitStop[] = [];

    constructor() { }

    setPatrol(patrol: Event) {
        this.patrol = null;
        this.patrol = patrol;
        // console.log(MapService.name, 'patrol', this.patrol);
    }

    getPatrol(): Observable<any> {
        if (this.patrol) {
            return of(this.patrol);
        }
    }

    setPatrolTemplate(patrolTemplate: EventTemplate) {
        this.patrolTemplate = null;
        this.patrolTemplate = patrolTemplate;
        // console.log(MapService.name, 'patrolTemplate', this.patrolTemplate);
    }

    getPatrolTemplate(): Observable<any> {
        if (this.patrolTemplate) {
            return of(this.patrolTemplate);
        }
    }

    setPitstops(pitstops: Event[]) {
        this.pitstops = [];
        this.pitstops = this.pitstops.concat(pitstops);
        // console.log(MapService.name, 'pitstops', this.pitstops);
    }

    getPitstops(): Observable<any> {
        if (this.pitstops.length > 0) {
            return of(this.pitstops);
        }
    }

    setPitstopTemplates(pitstopTemplates: PitStop[]) {
        this.pitstopTemplates = [];
        this.pitstopTemplates = this.pitstopTemplates.concat(pitstopTemplates);
        // console.log(MapService.name, 'pitstopTemplates', this.pitstopTemplates);
    }

    getPitstopTemplates(): Observable<any> {
        if (this.pitstopTemplates) {
            return of(this.pitstopTemplates);
        }
    }
}
