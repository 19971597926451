export const IAceeptMessageConstant = {
    COLLECTION_UTILS: 'utils',
    COLLECTION_CLIENTS: 'clients',
    COLLECTION_SUBSCRIPTION: 'subscriptions',
    COLLECTION_EVENTS: 'events',
    COLLECTION_INCIDENTS: 'incidents',
    COLLECTION_EVENT_ITEMS: 'items',
    COLLECTION_EVENTINFO: 'data',
    COLLECTION_EVENT_TEMPLATE: 'eventTemplates',
    COLLECTION_USERS: 'users',
    COLLECTION_ADDRESSES: 'addresses',
    COLLECTION_CUSTOMERS: 'customers',
    COLLECTION_CUSTOMER_ACCESS: 'customer_access',
    COLLECTION_INVITES: 'invites',
    COLLECTION_PACKAGE: 'packages',
    COLLECTION_ATTENDANCE: 'attendance',
    COLLECTION_TRAVEL_HISTORY: 'travelHistory',
    COLLECTION_FIRE_DRILL: 'fireAlerts',
    COLLECTION_FIRE_DRILL_RESPONSES: 'responses',
    EMPLOYERMENU: 'assets/json/employer-menu.json',
    CUSTOMERMENU: 'assets/json/customer-menu.json',
    SUBSCRIPTIONLIST: 'assets/json/subscrption.json',
    SUBSCRIPTIONPACKAGELIST: 'assets/json/subscrption-package.json',
    SUCESSFULLYSAVECUSTOMER: 'Succesfully Saved The Customer',
    SUCESSFULLY_LOGIN: 'Succesfully Login',
    SUCESSFULLYSAVESUB: 'Succesfully Created The Subscrption',
    SUCESSFULLYSAVE: 'Succesfully Created',
    SUCESSFULLYENDEDPATROL: 'Current Patrol Ended ',
    MONTHLY: 30,
    QUATRALLY: 90,
    YEARLY: 365,
    PACKAGE_TERM_MONTHLY: 'monthly',
    PACKAGE_TERM_QUATRALLY: 'quatraly',
    PACKAGE_TERM_YEARLY: 'yearly',
    EVENT_TYPE_HALT: 'halt',
    EVENT_TYPE_PATROL: 'patrol',
    EVENT_TYPE_PITSTOP: 'patrol-pitstop',
    TEMPLATE_TYPE_PATROL: 'patrol',
    TEMPLATE_TYPE_PATROL_PITSTOP: 'patrol-pitstop',
    REGISTRATION_TYPE_HALT: 'halt',
    REGISTRATION_TYPE_PATROL: 'patrol',
    REGISTRATION_TYPE_CUSTOM: 'custom',
    REGISTRATION_TYPE_GENERAL: 'general',
    USER_ROLE_EMPLOYEE: 'employee',
    USER_ROLE_CUSTOMER: 'customer',
    USER_ROLE_CLIENT: 'client',
    REGISTRATION_EMAIL_IS_NOT_VERIFED: 'email is not verified',
    SUBSCRIPTION_NOT_PAID: 'subscription is not done',
    EMPLOYEE_SET_PASSWORD: 'password not set',
    EMPLOYEE_RESET_PASSWORD: 'password reset',
    CUSTOMER_SET_PASSWORD: 'password not set',
    CUSTOMER_RESET_PASSWORD: 'password reset',
    USERSTATUSCHECK: 'All Ok',
    APP_LOGOUT: 'log-out',
    PITSTOP_DIFFERENCE_RANGE: 150,
};
