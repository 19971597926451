import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { SharedModule } from '../shared/shared.module';
import { CreatePatrolComponent } from './create-patrol/create-patrol.component';
import { EmployeePatrolRoutingModule } from './employee-patrol.routing';
import { PatrolDashboardComponent } from './patrol-dashboard/patrol-dashboard.component';
import { SelectPatrolComponent } from './select-patrol/select-patrol.component';
import { ValidateLocationComponent } from './validate-location/validate-location.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule.forRoot(),
    SharedModule,
    EmployeePatrolRoutingModule,
    IonicSelectableModule
  ],
  declarations: [
    PatrolDashboardComponent,
    CreatePatrolComponent,
    ValidateLocationComponent,
    SelectPatrolComponent
  ]
})
export class EmployeePatrolModule { }
