import { AccountMenuComponent } from './../shared/account-menu/account-menu.component';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HaltDashboardComponent } from './halt-dashboard/halt-dashboard.component';
import { EmployeeHaltRoutingModule } from './employee.halt.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CreateHaltComponent } from './create-halt/create-halt.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EmployeeHaltRoutingModule,
    IonicModule.forRoot(),
    SharedModule,
    IonicSelectableModule,
    NgxIonicImageViewerModule

  ],
  declarations: [
    HaltDashboardComponent,
    CreateHaltComponent
  ],
  entryComponents: [AccountMenuComponent],
})
export class EmployeeHaltModule {
  constructor() {
    //// console.log('EmployeeHaltModule loaded.');
  }
}
