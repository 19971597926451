import { EmployeeUpdateComponent } from './employee-update/employee-update.component';
import { AttendanceReportComponent } from './attendance-report/attendance-report.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerRoutingModule } from './employer.routing';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ReportsComponent } from './reports/reports.component';
import { PatrolsListComponent } from './patrols-list/patrols-list.component';
import { SettingsComponent } from './settings/settings.component';
import { CreatePatrolComponent } from './create-patrol/create-patrol.component';
import { SharedModule } from '../shared/shared.module';
import { AddNewCustomerComponent } from './add-new-customer/add-new-customer.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { CustomersComponent } from './customers/customers.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AddNewStopComponent } from './add-new-stop/add-new-stop.component';
import { PitstopListComponent } from './pitstop-list/pitstop-list.component';
import { EmployerMainComponent } from './employer-main/employer-main.component';
import { PatrolsComponent } from './patrols/patrols.component';
import { HaltsComponent } from './halts/halts.component';
import { InviteEmployeeComponent } from './invite-employee/invite-employee.component';
import { DeactivatedEmployeeListComponent } from './deactivated-employee-list/deactivated-employee-list.component';
import { EmployerCustomerInfoComponent } from './employer-customer-info/employer-customer-info.component';
import { DistanceTrackerComponent } from './distance-tracker/distance-tracker.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { ViewPatrolDetailsComponent } from './view-patrol-details/view-patrol-details.component';
import { InviteCustomerComponent } from './invite-customer/invite-customer.component';
import { CustomerAccessComponent } from './customer-access/customer-access.component';
import { IncidentsAccessComponent } from './incidents-access/incidents-access.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskInputComponent } from './task-input/task-input.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    EmployerRoutingModule,
    IonicModule.forRoot(),
    IonicSelectableModule,
  ],
  declarations: [
    AttendanceReportComponent,
    DashboardComponent,
    ReportsComponent,
    PatrolsListComponent,
    SettingsComponent,
    SubscriptionComponent,
    CreatePatrolComponent,
    AddNewCustomerComponent,
    EmployeeListComponent,
    CustomersComponent,
    AddNewStopComponent,
    PitstopListComponent,
    EmployerMainComponent,
    PatrolsComponent,
    HaltsComponent,
    InviteEmployeeComponent,
    InviteCustomerComponent,
    DeactivatedEmployeeListComponent,
    EmployerCustomerInfoComponent,
    EmployeeUpdateComponent,
    DistanceTrackerComponent,
    ViewPatrolDetailsComponent,
    CustomerAccessComponent,
    IncidentsAccessComponent,
    TaskListComponent,
    TaskInputComponent,
    BarChartComponent
  ],
  entryComponents: [InviteCustomerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmployerModule {
  constructor() {
    // console.log('EmployerModule loaded.');
  }
}
