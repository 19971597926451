import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() iconame: string;
  @Input() position: string;
  @Input() isBackBtn = false;
  @Input() backBtnposition: string;
  @Input() enableMenu = false;
  @Output() buttonClickHandlerO = new EventEmitter();
  @Output() backBtnClickHandlerO = new EventEmitter();
  @Output() filterClickHandlerO = new EventEmitter();
  @Output() menuClickHandlerO = new EventEmitter();
  @Input() directBack = true;
  @Input() filter: string;
  @Input() menu: string;
  @Input() headerNav:boolean;
  constructor(public location: Location, private navCtrl: NavController) {
    this.headerNav = true;
   }

  ngOnInit() { }

  buttonClickHandler(event) {
    this.buttonClickHandlerO.emit(event);
  }

  back() {
    this.backBtnClickHandlerO.emit();
    if (this.directBack) {
      this.location.back();
    }
  }

  onFilter() {
    this.filterClickHandlerO.emit();
  }

  onClickMenu() {
    this.menuClickHandlerO.emit();
  }
}
