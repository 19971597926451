import { SnackBarService } from 'src/app/services/snackbar.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserSubscription } from './../../entity/subscription';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  subScriptionList: UserSubscription[] = [];
  numberOfEmployeeUsed = 0;
  constructor(private userService: UserService, private loaderService: LoaderService, private snackbarService: SnackBarService) { }

  ngOnInit() {
    this.fecthCurrentSubscrption();
  }

  fecthCurrentSubscrption() {
    const clientId = this.userService.currentUser.clientId;
    if (clientId) {
      this.getNumberOfActiveEmployeeOfClient(clientId);
      this.loaderService.presentLoading('Loading subscription details')
      this.userService.fetchCurrentSubscrption(clientId).subscribe((subScription) => {
        // console.log(SubscriptionComponent.name, subScription, 'current-sub');
        // this.loaderService.dismissLoading();
        if (subScription) {
          this.subScriptionList = subScription;
        }
      });
    }
  }

  getNumberOfActiveEmployeeOfClient(clientId: string) {
    this.userService.getNumberOfActiveEmployeeOfClient(clientId).subscribe((numberOfEmployeeUsed) => {
      // console.log(SubscriptionComponent.name, numberOfEmployeeUsed, 'numberOfEmployeeUsed');
      if (numberOfEmployeeUsed) {
        this.numberOfEmployeeUsed = numberOfEmployeeUsed;
      }
    });
  }

  onAddUser() {
    this.snackbarService.showToaster('Feature yet to be implemented');
  }

}
