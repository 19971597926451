import { Component, OnInit, OnDestroy, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
// import { AttendanceService } from 'src/app/services/attendance.services';
//import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from "src/app/services/user.service";
import { Location } from "@angular/common";
import { User } from "src/app/entity/user";
import { IAceeptMessageConstant } from "src/app/core/constants/i-accept-msg.constant";
import { Attendance } from "../entity/attendance";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { AngularFirestore } from "@angular/fire/firestore";
import { Geoposition } from "@ionic-native/geolocation/ngx";
import { Observable } from "rxjs";
import { firestore } from "firebase";
import { GoogleMap, GoogleMaps } from "@ionic-native/google-maps/ngx";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";
import { Subscription } from "rxjs/internal/Subscription";
import { File } from "@ionic-native/file/ngx";
import { AppLocationService } from "../services/app-location.service";
import { SnackBarService } from "../services/snackbar.service";
import { AlertController, NavController, ModalController } from "@ionic/angular";
import { CustomFormModalComponent } from "../shared/custom-form-modal/custom-form-modal.component";
import { LoaderService } from "../services/loader.service";
import { UploadService } from "../services/upload.service";
import { Upload } from "../entity/upload";
import { LocationAccessComponent } from "../location-access/location-access.component";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
@Component({
  selector: "app-attendance",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.scss"],
  providers: [UploadService],
})
export class AttendanceComponent implements OnInit, OnDestroy {
  [x: string]: any;
  backBtnposition = "start";
  title = "Attendance";
  btnLoading = false;
  locationPermission = false;
  locationAccuracy = false;
  currentUser: User;
  attendanceId: string;
  currentEmployeeAttendance: Attendance;
  isLocationPermissionEnabled: boolean;
  map: GoogleMap;
  locationCoords: any;
  locationSubscription: Subscription;
  isUploadProgress: Observable<number>;
  uploadedImageUrlList = [];
  isAttendanceStarted = false;
  marker: any;
  attendanceHistory: Attendance[] = [];
  diffinHours: number;
  clicked = false;
  reenableButton = new EventEmitter<boolean>(false);
  public loaded = false;
  imageRef: string;
  imageEle: HTMLImageElement;
  imageWidth: number = 960;
  imageHeight: number = 1280;


  constructor(
    private router: Router,
    private userService: UserService,
    private location: Location,
    private file: File,
    // private formBuilder: FormBuilder, private userService: UserService,
    private uploadService: UploadService,
    public alertController: AlertController,
    private locationService: AppLocationService,
    private angularFirestore: AngularFirestore,
    private camera: Camera,
    private webview: WebView,
    private snackBarService: SnackBarService,
    private loaderService: LoaderService,
    private navCtrl: NavController,
    public modalController: ModalController,
    private androidPermissions: AndroidPermissions,
  ) { }

  ngOnInit() {
    this.getCurrentUser();
    this.startTrackingLocation();
    this.userService.listenToAttendanceChange().subscribe((attendance) => {
      this.currentEmployeeAttendance = attendance;
    });
    this.locationSubscription = this.locationService.isLocation().subscribe(
      (location) => {
        console.log(
          "🚀 ~ file: attendance.component.ts ~ line 84 ~ AttendanceComponent ~ this.locationSubscription=this.locationService.isLocation ~ location",
          location
        );
      },
      (err) => {
        console.log(
          "🚀 ~ file: attendance.component.ts ~ line 82 ~ AttendanceComponent ~ this.locationSubscription=this.locationService.isLocation ~ err",
          err
        );
      }
    );
    console.log("ngOnInit currentEmployeeAttendance", this.currentEmployeeAttendance);
  }

  ionViewDidEnter() {
    if (this.currentEmployeeAttendance) {
      this.validateAttendanceDuration(this.currentEmployeeAttendance);
    }
  }

  validateAttendanceDuration(currentEmployeeAttendance) {
    console.log("ionViewDidEnter currentEmployeeAttendance", currentEmployeeAttendance);
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
      this.getCurrentEmployeeAttendanceHistory();
    });
  }

  getCurrentEmployeeAttendance() {
    const attendance = this.userService.getEmployeeAttendance();
    console.log("getCurrentEmployeeAttendance -> attendance", attendance);
    if (attendance) {
      this.currentEmployeeAttendance = attendance;
      this.isAttendanceStarted = true;
    } else {
      this.isAttendanceStarted = false;
    }
  }

  getCurrentEmployeeAttendanceHistory() {
    this.loaderService.presentLoading("Fetching attendance data..");
    this.getCurrentEmployeeAttendance();
    this.userService.getCurrentEmployeeAttendanceHistory(this.currentUser.clientId, this.currentUser.id).subscribe((attendanceHistory) => {
      this.loaderService.dismissLoading();
      console.log(AttendanceComponent.name, "attandance history", attendanceHistory);
      this.attendanceHistory = attendanceHistory;
    });
  }

  calcActiveTime(timeEnd, timeStart) {
    if (timeEnd === null) {
      return 0;
    } else {
      const diff = timeEnd - timeStart;
      // // console.log(AttendanceComponent.name, 'diff', diff);
      const hours = diff / 1000 / 60;
      //// console.log(AttendanceComponent.name, 'hours', hours);
    }
  }

  async captureImageForAttendance(type, attendance) {
    console.time("canvas process");
    const options: CameraOptions = {
      quality: 20,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
      cameraDirection: 1, // this.camera.Direction.FRONT,
    };
    try {
      const dateTimeObj = new Date();
      const imagePath = await this.camera.getPicture(options);
      console.log("captured image", imagePath);
      // this.loaderService.presentLoading("Image is captured. Please wait for image to be processed");
      const loadingObject = await this.loaderService.newLoader("Please wait while the Image is being uploaded");
      loadingObject.present();
      const currentName = imagePath.substr(imagePath.lastIndexOf("/") + 1);
      console.log("currentName", currentName);
      const correctPath = imagePath.substr(0, imagePath.lastIndexOf("/") + 1);
      console.log("correctPath", correctPath);
      const fileName = this.angularFirestore.createId() + "_" + `${type}` + ".jpg";
      console.log("fileName", fileName);
      const filePath = await this.copyFileToLocalDir(correctPath, currentName, fileName);
      console.log("filePath", filePath);
      //converting the image into blob data instead of uploading
      let imageBlob = await this.uploadService.getBlob(filePath);
      this.createCanvas(imageBlob, fileName, type, attendance);
      // console.log("upload", uploadedImageUrl);
      // console.log("this should be the updated blob", imageBlob2);
      loadingObject.dismiss();
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  createCanvas(imageBlob, fileName, type, attendance) {
      // console.log("a blob image object", imageBlob);
      //converting the image into URL for image element src attribute
      const url = URL.createObjectURL(imageBlob);
      console.log("src attribute url is", url);
      this.imageEle = new Image();
      this.imageEle.src = url;
      this.imageEle.addEventListener("load" , async () => {
        console.log("intrinsic width", this.imageEle.naturalWidth);
        console.log("intrinsic height", this.imageEle.naturalHeight);
        this.imageWidth = this.imageEle.naturalWidth;
        this.imageHeight = this.imageEle.naturalHeight;
        let canvas  = document.createElement('canvas');
        canvas.width = this.imageWidth;
        canvas.height = this.imageHeight+320;
        let ctx = canvas.getContext('2d');
        ctx.font = '32px "Sans"';
        ctx.drawImage(this.imageEle, 0, 0, this.imageWidth, this.imageHeight);
        ctx.fillStyle = '#FFFFFF';
        await this.drawOnCanvas(canvas, ctx, fileName, type, attendance);

        type === "punchIn" ? this.presentAlert("Your attendance has started") : this.presentAlert("Your attendance has ended");  
        URL.revokeObjectURL(url);
      });
  }

  async drawOnCanvas(canvas, ctx, fileName, type, attendance) {
    const dateTimeObj = new Date();

    if(type === "punchIn") {
      ctx.fillText("Start Latitude: " + `${attendance.startLocation.latitude.toFixed(2).toString()}` , this.imageWidth/5 , this.imageHeight+100);
      ctx.fillText("Start Longitude: " + `${attendance.startLocation.longitude.toFixed(2).toString()}`, this.imageWidth/2 , this.imageHeight+100);
      ctx.fillText("Start Date: " + `${dateTimeObj.toDateString()}`, this.imageWidth/5 , this.imageHeight+ 200);
      ctx.fillText("Start DateTime: " + `${dateTimeObj.toLocaleTimeString()}`, this.imageWidth/2, this.imageHeight+ 200);
    }

    if(type === "punchOut") {
      ctx.fillText("End Latitude: " + `${attendance.endLocation.latitude.toFixed(2).toString()}` , this.imageWidth/5 , this.imageHeight+100);
      ctx.fillText("End Longitude: " + `${attendance.endLocation.longitude.toFixed(2).toString()}`, this.imageWidth/2 , this.imageHeight+100);
      ctx.fillText("End Date: " + `${dateTimeObj.toDateString()}`, this.imageWidth/5 , this.imageHeight+ 200);
      ctx.fillText("End DateTime: " + `${dateTimeObj.toLocaleTimeString()}`, this.imageWidth/2 , this.imageHeight+ 200);
    }

    await this.canvasToBlobAndUpload(canvas, fileName, type, attendance);
  }

  async canvasToBlobAndUpload(canvas, fileName, type, attendance) {
    let uploadedImageUrl;
    await canvas.toBlob(async (blob) => {
      console.log("blob inside the toBlob()", blob);
      uploadedImageUrl = await this.uploadService.uploadBlobForImage(blob, fileName, this.currentUser);
      console.log("upload", uploadedImageUrl);
      
      if(type === "punchIn") {
          
        attendance.punchInImage = uploadedImageUrl;
        
        this.userService.createAttendance(attendance).then(() => {
          // this.loaderService.presentLoading("Image is successfully uploaded");
          this.btnLoading = false;
        });

      }

      if(type === "punchOut") {
        
        attendance.punchOutImage = uploadedImageUrl;
        this.currentEmployeeAttendance.punchOutImage = uploadedImageUrl;
        console.log(uploadedImageUrl, "attendanceout");

        this.userService.updateAttendance(this.currentEmployeeAttendance.id, this.currentEmployeeAttendance).then(() => {
          // this.loaderService.presentLoading("Image is successfully uploaded");
          this.btnLoading = false;
          // // console.log(AttendanceComponent.name, 'attendance ended successfully');
          // // console.log(AttendanceComponent.name, 'currentEmployeeAttendance end time', this.currentEmployeeAttendance.endTime);
          this.userService.setEmployeeAttendance(null);
          // // console.log(AttendanceComponent.name, 'end attendance', this.currentEmployeeAttendance);
        });
      }
    },'image/jpeg', 1.0);
  }

  imageUpload(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      // this.startUpload(file);
    }
  }

  async startUpload(filePath: any, fileName: string) {
    console.log("new file name ", fileName);
    // storage path
    const imagePath = `events/${this.currentUser.id}/${fileName}`;
    console.log("impath", imagePath);
    this.imageRef = imagePath;
    const uplaod = new Upload();
    console.log("pload", uplaod);
    uplaod.imagePath = imagePath;
    uplaod.filePath = filePath;
    uplaod.name = fileName;
    // this.loaderService.presentLoading("Uploading image");
    const imageData = await this.uploadService.fileUploadNew(uplaod);
    console.log(imageData, "imagedata");
    this.loaderService.dismissLoading();
    if (imageData) {
      //alert('image uploaded');
      this.snackBarService.showToaster("Image uploaded successfully!");
    }
    return imageData;
  }

  deleteImage(url) {
    const index = this.uploadedImageUrlList.indexOf(url);
    // to remove locally
    this.uploadedImageUrlList.splice(index, 1);
    this.title = "Create Halt";
    this.updateAttendanceImages();
    // delete in firebase
    this.uploadService.deleteFileStorage(url).then(() => {
      //// console.log(CreateHaltComponent.name, 'deleted');
    });
  }

  pathForImage(img) {
    if (img === null) {
      return "";
    } else {
      const converted = this.webview.convertFileSrc(img);
      return converted;
    }
  }

  async copyFileToLocalDir(namePath, currentName, newFileName): Promise<string> {
    //alert('tst')
    console.log(AttendanceComponent.name, "copy from ", namePath, currentName, " to ", this.file.dataDirectory);
    return new Promise((resolve, reject) => {
      this.file.copyFile(namePath, currentName, this.file.dataDirectory, newFileName).then(
        (success) => {
          console.log("file dataDirectory")
          let filePath = this.file.dataDirectory + newFileName;
          resolve(filePath);
        },
        (error) => {
          console.log(AttendanceComponent.name, "Error loading image", error);
          reject("error loading image");
        }
      );
    });
  }

  async logAttendance(state: string, isImageRequired: boolean = false) {
    this.btnLoading = true;
    const attendance = new Attendance();
    // alert();
    // intiate camera ()
    if (!this.locationCoords) {
      this.presentAlert("Could not fetch current location, please try again");
      return;
    }

    attendance.startTime = firestore.FieldValue.serverTimestamp();
    attendance.clientId = this.currentUser.clientId;
    attendance.createdByUser = this.currentUser.id;
    attendance.startLocationAccuracy = this.locationCoords.coords.accuracy;
    attendance.startLocation = new firestore.GeoPoint(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
    console.log("the attendance value is", attendance.startLocation);

    if (state === "start") {
      if (isImageRequired) {
        console.log(isImageRequired, "imageused");
        await this.captureImageForAttendance("punchIn", attendance);
        // console.log("attendanceim", attendanceImage);
      }
      else {
        const loadingObject = await this.loaderService.newLoader("Recording attendance");
        loadingObject.present();
        this.userService.createAttendance(attendance).then(() => {
          this.btnLoading = false;
          loadingObject.dismiss();
          this.presentAlert("Your attendance has started");
        });
      }
      // if (isImageRequired) {
      //   attendance.punchInImage = attendanceImage;
      // }

    }

    if (state === "end") {
      // let attendanceImage = null;
      this.currentEmployeeAttendance.endTime = firestore.FieldValue.serverTimestamp();
      this.currentEmployeeAttendance.endLocationAccuracy = this.locationCoords.coords.accuracy;
      // tslint:disable-next-line: max-line-length
      this.currentEmployeeAttendance.endLocation = new firestore.GeoPoint(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
      // // console.log(AttendanceComponent.name, 'currentEmployeeAttendance id', this.currentEmployeeAttendance.id);
      // // console.log(AttendanceComponent.name, 'currentEmployeeAttendance', this.currentEmployeeAttendance);
      if (isImageRequired === true) {
        console.log(isImageRequired, "imageused");
        await this.captureImageForAttendance("punchOut", this.currentEmployeeAttendance);
      }
      else {
        const loadingObject = await this.loaderService.newLoader("Recording attendance");
        loadingObject.present();
        this.userService.updateAttendance(this.currentEmployeeAttendance.id, this.currentEmployeeAttendance).then(() => {
          loadingObject.dismiss();
          this.btnLoading = false;
          // // console.log(AttendanceComponent.name, 'attendance ended successfully');
          // // console.log(AttendanceComponent.name, 'currentEmployeeAttendance end time', this.currentEmployeeAttendance.endTime);
          this.userService.setEmployeeAttendance(null);
          this.presentAlert("Your attendance has ended");
          // // console.log(AttendanceComponent.name, 'end attendance', this.currentEmployeeAttendance);
        });
      }

      // if (isImageRequired)
    } else {
      this.btnLoading = false;
    }
  }

  async showLocationPermissionsModal() {
    const resp = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION);
    if (!resp.hasPermission) {
      const modal = await this.modalController.create({
        component: LocationAccessComponent,
        componentProps: {
          type: "ivisit",
        },
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      return data.granted;
    }
    return true;
  }

  async startTrackingLocation() {
    this.locationPermission = await this.showLocationPermissionsModal();
    if (this.locationPermission) {
      this.loaderService.presentLoading("Fetching location..");
      try {
        this.locationSubscription = this.locationService.startLocation().subscribe((loc: any) => {
          // console.log("location accuracy", loc);
          this.locationCoords = loc;
          if (loc.coords.accuracy < 100) {
            this.locationAccuracy = true;
          } else {
            this.locationAccuracy = false;
          }
        });
        // this.locationCoords = await this.locationService.getCurrentLocation();
      } catch (err) {
        this.snackBarService.showToaster("Failed to fetch location", 2000);
      }
      this.loaderService.dismissLoading();
    } else {
      this.isLocationPermissionEnabled = false;
      const alert = await this.alertController.create({
        backdropDismiss: false,
        header: "Alert",
        message: "Location permission is required to continue using the application. Please provide the permission and try again.",
        buttons: [
          {
            text: "OK",
            handler: () => { },
          },
        ],
        cssClass: "p-3",
      });
      await alert.present();
    }
  }

  stopTrackingLocation() {
    this.locationSubscription.unsubscribe();
  }
  ngOnDestroy() {
    this.stopTrackingLocation();
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: "Alert",
      message: data,
      buttons: [
        {
          text: "OK",
          handler: () => {
            alert.dismiss();
            this.location.back();
          },
        },
      ],
      cssClass: "p-3",
    });

    await alert.present();
  }

  async showImageForAttendanceAlert(state: string) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Alert",
      //subHeader: 'Subtitle',
      message: "Do you want to capture image for attendance.",
      buttons: [
        {
          text: "Yes",
          role: "yes",
          handler: () => {
            this.logAttendance(state, true);
          },
        },
        {
          text: "NO",
          role: "NO",
          handler: () => {
            this.logAttendance(state, false);
          },
        },
      ],
    });

    await alert.present();
  }

  getTimeinHHMMFormat(mins) {
    if (mins >= 0) {
      const num = mins;
      const hours = num / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.floor(minutes);
      const seconds = (minutes - rminutes) * 60;
      const rseconds = Math.round(seconds);
      return rhours + ":" + rminutes + ":" + rseconds;
    } else {
      return "";
    }
  }

  onNavigateBack() {
    this.location.back();
  }

  onDeletePopOver(attendance) {
    //console.log('delete event', attendance);
    this.confirmDelete("Are you sure to delete this entry", attendance);
  }

  async confirmDelete(data, attendance) {
    const alert = await this.alertController.create({
      header: "Alert",
      backdropDismiss: false,
      message: data,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            alert.dismiss();
          },
        },
        {
          text: "Delete",
          handler: () => {
            this.userService.deleteAttendance(attendance.id).then(() => {
              this.getCurrentEmployeeAttendanceHistory();
              this.snackBarService.showToaster("Attendace has been deleted");
            });
            alert.dismiss();
          },
        },
      ],
      cssClass: "p-3",
    });

    await alert.present();
  }
}
