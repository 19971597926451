import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { NavController } from "@ionic/angular";
import { Incident } from "src/app/entity/incident";
import { User } from "src/app/entity/user";
import { IncidentService } from "src/app/services/incident.service";
import { LoaderService } from "src/app/services/loader.service";
import { UploadService } from "src/app/services/upload.service";
import { UserService } from "src/app/services/user.service";
import { StreamingMedia, StreamingVideoOptions, StreamingAudioOptions } from "@awesome-cordova-plugins/streaming-media/ngx";

@Component({
  selector: "app-patrol-incident",
  templateUrl: "./patrol-incident.component.html",
  styleUrls: ["./patrol-incident.component.scss"],
  providers: [UploadService],
})
export class PatrolIncidentComponent implements OnInit {
  title = "Incidents";
  slideOpts = {
    // initialSlide: 1,
    speed: 100,
  };

  currentUser: User;
  incidents: Incident[] = [];

  constructor(
    private incidentService: IncidentService,
    private router: NavController,
    private location: Location,
    private userService: UserService,
    private photoViewer: PhotoViewer,
    private loaderService: LoaderService,
    private streamingMedia: StreamingMedia
  ) {}

  ionViewDidEnter() {
    this.fetchAllIncidents();
  }

  ngOnInit() {}

  async fetchAllIncidents() {
    this.loaderService.showLoader();
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    this.incidents = await this.incidentService.getAllIncidentsReportedByEmployee(this.currentUser.id);
    this.loaderService.hideLoader();
  }

  incidentPage() {
    this.router.navigateForward(["/incidents/create"]);
  }

  onNavigateBack() {
    this.location.back();
  }

  onViewImage(image) {
    console.log("🚀 ~ file: patrol-incident.component.ts ~ line 61 ~ PatrolIncidentComponent ~ onViewImage ~ image", image);
    this.photoViewer.show(image, "", { share: true });
  }

  onPlayAudio(audioUrl) {

    let options: StreamingAudioOptions = {
      successCallback: () => {
        console.log("Video played");
      },
      errorCallback: (e) => {
        console.log("Error streaming");
      },
    };
    this.streamingMedia.playAudio(audioUrl, options);
  }

  onPlayVideo(videoUrl) {
    let options: StreamingVideoOptions = {
      successCallback: () => {
        console.log("Video played");
      },
      errorCallback: (e) => {
        console.log("Error streaming");
      },
      orientation: "landscape",
      shouldAutoClose: true,
      controls: true,
    };
    this.streamingMedia.playVideo(videoUrl, options);
  }
}
