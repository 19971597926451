import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from 'src/app/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userService.getCurrentUser().pipe(map((result) => {
            //// console.log(result, 'res');
            if (result == null || !result) {
                this.router.navigate(['home']);
                return false;
            } else {
                return true;
            }
        }));
    }
}
