import { Injectable } from '@angular/core';
import { HttpHandler, HttpErrorResponse, HttpRequest, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { tap, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from './loader.service';

export class CustomHttpParams extends HttpParams {
    constructor(public hideLoaderOnDemand: boolean) {
        super();
    }
}

@Injectable()
export class LoaderInterceptorService {
    constructor(private loaderService: LoaderService) { }
    private servercallcount = 0;

    intercept(req: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {

        this.increaseRequests(req.params instanceof CustomHttpParams && req.params.hideLoaderOnDemand);
        // console.log(req.params instanceof CustomHttpParams && req.params.hideLoaderOnDemand, 'hideLoaderOnDemand');
        return httpHandler.handle(req).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    this.decreaseRequests();
                }
            }),
            catchError((err: HttpErrorResponse) => {
                this.decreaseRequests();
                return throwError(err);
            }));


    }
    private decreaseRequests() {
        this.servercallcount--;
        if (this.servercallcount === 0) {
            setTimeout(() => this.loaderService.hideLoader(), 0);
        }
    }

    private increaseRequests(hideLoader = false) {
        this.servercallcount++;
        if (!hideLoader && this.servercallcount > 0) {
            setTimeout(() => this.loaderService.showLoader(), 0);
        }
    }
}
