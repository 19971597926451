import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  @Input() message: string;
  @Input() buttonText: string;
  @Input() buttonReq: boolean;
  @Output() buttonHandleO = new EventEmitter();
  constructor() { }

  ngOnInit() { }

  buttonHadeler(event) {
    this.buttonHandleO.emit(event);
  }

}
