import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { ClientService } from 'src/app/services/client.service';
import { EmployerLogin } from '../entity/employer';
import { UserSubscription } from '../entity/subscription';
import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
import { SnackBarService } from './../services/snackbar.service';

@Component({
  selector: 'app-employer-login',
  templateUrl: './employer-login.component.html',
  styleUrls: ['./employer-login.component.scss'],
})
export class EmployerLoginComponent implements OnInit {
  employerLoginForm: FormGroup;
  btnLoading = false; 
  teamMember = false;
  isEmailVerified = true;

  constructor(
    private formBuilder: FormBuilder, private loaderService: LoaderService,
    private router: Router, private location: Location, private alertController: AlertController,
    private userService: UserService, private clientService: ClientService,
    private snackBarService: SnackBarService, private navCtrl: NavController,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // console.log('params', params); 
      if (params.userType === 'team-member') {
        this.teamMember = true;
        // console.log('teamMember', this.teamMember);
      } else if (params.userType === 'employer') {
        this.teamMember = false;
        // console.log('teamMember', this.teamMember);
      } else {
        this.teamMember = false;
      }
    });
    this.initEmployerLogin();
  }

  initEmployerLogin() {
    this.employerLoginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  onEmployerLogin(employerLogin: EmployerLogin) {
    this.btnLoading = true;
    console.log(EmployerLoginComponent.name, 'Employer login credentials:', employerLogin);
    this.loaderService.presentLoading('Verifying user');
    this.userService.emailLogin(employerLogin.email, employerLogin.password).then(() => {
      this.btnLoading = false;
      // this.loaderService.dismissLoading();
    }).catch(error => {
      this.btnLoading = false;
      this.handleError(error);
      // this.loaderService.dismissLoading();
    });
  }

  onGoBack() {
    this.navCtrl.navigateBack(['/home']);
  }

  onSignUp(userType) {
    // console.log('userType', userType);
    if (userType === 'employer') {
      this.navCtrl.navigateForward(['/employer-signup']);
    } else if (userType === 'team-member') {
      this.navCtrl.navigateForward(['/team-member-signup']);
    }
  }

  onForgotPassword() {
    this.navCtrl.navigateForward(['/forgot-password']);
  }

  handleError(error) {
    if (error.code === 'auth/popup-blocked') {
      this.presentAlert('Your browser has disabled Popups. Please Try again');
    } else if (error.code === 'auth/popup-closed-by-user') {
      this.presentAlert('Please reload and try again');
    } else {
      this.snackBarService.showToaster(error.message);
    }
    return error.message;
  }

  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Alert',
      message: alertData,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  checkUserAsVerifiedHisEmail(clientId) {
    this.userService.checkUserAsVerifiedHisEmail().subscribe((isEmailVerifed) => {
      if (isEmailVerifed) {
        this.isEmailVerified = isEmailVerifed;
        this.checkCurrentUserSubscrption(clientId);
      } else {
        this.isEmailVerified = false;
        return;
      }
    });
  }

  checkCurrentUserSubscrption(clientId) {
    this.userService.fetchCurrentSubscrption(clientId).subscribe((sub: UserSubscription[]) => {
       console.log(
         "currentSubscription: " , EmployerLoginComponent.name, sub);
      if (sub) {
        this.navCtrl.navigateRoot(['/employer/dashboard']);
      } else {
        console.log(EmployerLoginComponent.name, 'subscription-not-done');
        this.navCtrl.navigateForward(['/subscription']);
        this.presentAlert('Kindly confirm your subscription');
      }
    });
  }
}
