import { UserSubscription } from './../../entity/subscription';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Invite } from 'src/app/entity/invite';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { Location } from '@angular/common';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { LoaderService } from 'src/app/services/loader.service';
import { AlertController } from '@ionic/angular';
import { Contacts, Contact, ContactField, ContactName } from '@ionic-native/contacts/ngx';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-invite-employee',
  templateUrl: './invite-employee.component.html',
  styleUrls: ['./invite-employee.component.scss'],
})
export class InviteEmployeeComponent implements OnInit {
  inviteEmployeeForm: FormGroup;
  currentUser: User;
  invites: Invite[] = [];
  hideInvites = true;
  numberOfEmployeLeft;
  noOfUser: number;
  currentSubscrption: UserSubscription;
  numberOfActiveUser = 0;
  reportType: string;
  editEmployee = false;
  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private location: Location, private alertController: AlertController,
    private contacts: Contacts, private loaderService: LoaderService,
    private snackService: SnackBarService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.createInviteEmployeeForm();
    this.getCurentUser();
    // this.mobileNumberPatternCheck();
  }

  getCurentUser() {
    this.loaderService.presentLoading('Loading...');
    this.userService.getCurrentUser().subscribe(user => {
      this.loaderService.dismissLoading();
      if (user) {
        this.currentUser = user;
        this.currentSubscrption = this.userService.currentSubscrption;
        if (this.currentSubscrption) {
          // console.log("current subscription -> ", this.currentSubscrption);
          this.noOfUser = this.currentSubscrption.users;
          this.getNumberOfActiveEmployeeOfClient(this.currentUser.clientId);
          // console.log(InviteEmployeeComponent.name, this.noOfUser, ' this.noOfUser ');
        }
      }
    });
  }

  getNumberOfActiveEmployeeOfClient(clientId: string) {
    this.userService.getNumberOfActiveEmployeeOfClient(clientId).subscribe((numberOfEmployeeUsed) => {
      // console.log(InviteEmployeeComponent.name, numberOfEmployeeUsed, 'numberOfEmployeeUsed');
      this.numberOfActiveUser = numberOfEmployeeUsed;
      this.getInvitesSent();
      this.loaderService.dismissLoading();
    });
  }

  createInviteEmployeeForm() {
    this.inviteEmployeeForm = this.formBuilder.group({
      name: ['', Validators.required],
      contactNumber: ['', Validators.required],
      emailAddress: ['', Validators.pattern(/^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/)],
      reportingType: ['', Validators.required],
    });
  }

  // mobileNumberPatternCheck() {
  //   this.inviteEmployeeForm.controls.contactNumber.valueChanges.subscribe(value => {
  //     // console.log(InviteEmployeeComponent.name, 'number has changed:', value ? value.length : null);
  //     if (value.length > 10) {
  //       this.inviteEmployeeForm.controls['contactNumber'].markAsTouched();
  //     }
  //   });
  // }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  addContactFromDevice() {
    this.contacts.pickContact().then(
      (contact) => {
        if (contact.phoneNumbers[0].value.indexOf('+91') > -1) {
          contact.phoneNumbers[0].value = contact.phoneNumbers[0].value.substring(3);
          // console.log(InviteEmployeeComponent.name, 'Access to device Contacts!', contact);
          this.inviteEmployeeForm.patchValue({ name: contact.displayName, contactNumber: contact.phoneNumbers[0].value });
        } else {
          this.inviteEmployeeForm.patchValue({ name: contact.displayName, contactNumber: contact.phoneNumbers[0].value });
        }
      },
      (error: any) => { console.error('Error saving contact.', error); }
    );
  }

  changeReportType() {
    // console.log(InviteEmployeeComponent.name, this.reportType, 'reportType');
    if (this.reportType === 'Team Member') {
      this.inviteEmployeeForm.get('emailAddress').setValidators([Validators.required]);
      this.inviteEmployeeForm.get('emailAddress').updateValueAndValidity();
      this.inviteEmployeeForm.get('contactNumber').setValidators(null);
      this.inviteEmployeeForm.get('contactNumber').updateValueAndValidity();
    } else {
      this.inviteEmployeeForm.get('contactNumber').setValidators([Validators.required]);
      this.inviteEmployeeForm.get('contactNumber').updateValueAndValidity();
      this.inviteEmployeeForm.get('emailAddress').setValidators(null);
      this.inviteEmployeeForm.get('emailAddress').updateValueAndValidity();
    }
  }


  onInviteEmployee() {
    this.loaderService.presentLoading('Creating...');
    // console.log(InviteEmployeeComponent.name, 'Invite details', this.inviteEmployeeForm.value);
    const values = this.inviteEmployeeForm.value;
    const invite = new Invite();
    invite.employeeName = values.name;
    invite.employeeType = values.reportingType;
    invite.emailAddress = values.emailAddress;
    invite.contactNumber = values.contactNumber;
    invite.clientId = this.currentUser.clientId;
    invite.createdDate = new Date();

    if (invite.employeeType === 'Team member') {
      this.userService.getInvitesSentByClientForTeamMember(invite.emailAddress).
        subscribe((invites) => {
          this.loaderService.dismissLoading();
          // console.log(InviteEmployeeComponent.name, 'Team member data', invites);
          if (!invites || invites.length === 0) {
            this.userService.inviteEmployee(invite).then(() => {
              this.snackService.showToaster('Invite sent successfully');
              this.inviteEmployeeForm.reset();
              this.location.back();
              this.loaderService.dismissLoading();
            });
          }
        });
      return;
    } else {
      // general subscription invite follows the same flow as other subscriptions, with respect to contact number.
      this.userService.getInviteForContactNumber(invite.contactNumber).
        subscribe((invites) => {
          this.loaderService.dismissLoading();
          // console.log(InviteEmployeeComponent.name, 'Employee data', invites);
          if (!invites || invites.length === 0) {
            this.userService.inviteEmployee(invite).then(() => {
              this.snackService.showToaster('Invite sent successfully');
              this.inviteEmployeeForm.reset();
              this.location.back();
              this.loaderService.dismissLoading();
            });
          } else {
            this.loaderService.dismissLoading();
            this.snackService.showToaster('Employee already exists');
          }
        });
    }
  }

  getInvitesSent() {
    this.loaderService.presentLoading('Loading...');
    this.userService.getInvitesSentByClient(this.currentUser.clientId).subscribe(invites => {
      this.loaderService.dismissLoading();
      if (invites) {
        this.invites = invites;
        // console.log(InviteEmployeeComponent.name, this.invites.length, 'inviteslen');
        this.numberOfEmployeLeft = this.noOfUser - ((this.numberOfActiveUser ? this.numberOfActiveUser : 0) + (this.invites.length));
        // console.log(InviteEmployeeComponent.name, 'numberOfEmployeLeft', this.numberOfEmployeLeft);
      }
    });
  }

  onShowInvites(data: string) {
    if (data === 'invite') {
      this.hideInvites = true;
    } else if (data === 'view') {
      this.hideInvites = false;
    }
  }

  segmentButtonClicked(ev: any) {
    // console.log(InviteEmployeeComponent.name, 'Segment button clicked', ev);
  }

  deleteInvite(invite: Invite) {
    console.log('invite', invite);
    this.loaderService.presentLoading('Deleting...');
    this.userService.deleteInvite(invite.id).then(() => {
      this.loaderService.dismissLoading();
      this.snackService.showToaster('Deleted Sucessfully');
      this.getInvitesSent();
    });
  }

  async presentAlert(data, invite) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.deleteInvite(invite);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  updateEmployee() {
    alert('update');
  }
}
