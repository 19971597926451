import { NavController, AlertController } from '@ionic/angular';
import { Customer, Clients } from './../entity/clients';
import { SnackBarService } from './../services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EmployerLogin } from '../entity/employer';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MustMatch } from '../utilities/must-match.validator';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-employer-signup',
  templateUrl: './employer-signup.component.html',
  styleUrls: ['./employer-signup.component.scss'],
})
export class EmployerSignupComponent implements OnInit {
  employerSignUpForm: FormGroup;
  btnLoading = false;
  stepper1 = true;
  stepper2 = false;

  constructor(
    private formBuilder: FormBuilder, private location: Location,
    private navCtrl: NavController, private loaderService: LoaderService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private alertController: AlertController) { }

  ngOnInit() {
    this.initEmployerForm();
  }

  initEmployerForm() {
    this.employerSignUpForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/)])],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*\d).{4,30}$/)]],
      confirmPassword: ['', Validators.required],
      companyName: ['', Validators.required],
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      city: ['', Validators.required],
      gstNumber: [''],
      country: ['', Validators.required],
      line1: ['', Validators.required],
      province: ['', Validators.required],
      zipcode: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });
  }

  onCreateEmployer(employerLogin: EmployerLogin) {
    this.btnLoading = true;
    // console.log(EmployerSignupComponent.name, employerLogin, 'employee');
    this.loaderService.presentLoading('Verifying user details');
    this.userService.checkUserAlreadySignUp(employerLogin.email)
      .then((userexists) => {
        // console.log(EmployerSignupComponent.name, userexists.length, 'data');
        if (!userexists.length) {
          this.btnLoading = false;
          this.loaderService.dismissLoading();
          if (employerLogin) {
            const clientAddress = {
              city: employerLogin.city,
              country: employerLogin.country,
              line1: employerLogin.line1,
              province: employerLogin.province,
              zipcode: employerLogin.zipcode
            };
            // console.log(EmployerSignupComponent.name, clientAddress, 'clientAddress');
            this.userService.emailSignUp(employerLogin.email, employerLogin.password).then((user) => {
              // console.log(EmployerSignupComponent.name, 'authenticated user', user);
              this.userService.createUserInDB(user, 'client', clientAddress, employerLogin.companyName,
                employerLogin.name, employerLogin.phoneNumber, false, null, null, employerLogin.gstNumber);
              this.presentAlert('Successfully created, Kindly verfiy your email to login');
              this.userService.sendEmailVerification().then((a) => {
              });
            }).catch(error => {
              this.loaderService.dismissLoading();
              this.handleError(error);
            });
          }
        } else {
          this.btnLoading = false;
          this.loaderService.dismissLoading();
          this.presentAlert('User Already Registered With This Email ');
        }
      });
  }

  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Alert',
      message: alertData,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
          }
        }],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  handleError(error) {
    if (error.code === 'auth/popup-blocked') {
      this.presentAlert('Your browser has disabled Popups. Please Try again');
    } else if (error.code === 'auth/popup-closed-by-user') {
      this.presentAlert('Please reload and try again');
    } else {
      this.snackBarService.showToaster(error.message);
    }
    return error.message;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
 
  onGoBack() {
    this.employerSignUpForm.reset();
    this.navCtrl.navigateBack(['/home']);
  }

  onNavigateTo(position) {
    if (position === 'step1') {
      this.stepper1 = true;
      this.stepper2 = false;
    }
    if (position === 'step2') {
      this.stepper1 = false;
      this.stepper2 = true;
    }
  }

  onSignIn(userType) {
    this.stepper1 = true;
    this.stepper2 = false;
    this.employerSignUpForm.reset();
    this.navCtrl.navigateForward(['/employer-login'], {
      queryParams: {
        userType,
      }
    });
  }
}
