import { LoaderService } from 'src/app/services/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from './../services/snackbar.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../services/user.service';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { WindowService } from '../services/window.service';
import { User } from '../entity/user';
import { Invite } from '../entity/invite';
import { Location } from '@angular/common';
import { AlertController, NavController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-employee-signup',
  templateUrl: './employee-signup.component.html',
  styleUrls: ['./employee-signup.component.scss'],
})
export class EmployeeSignupComponent implements OnInit {
  showOtp = false;
  Otp: string;
  phoneNumber: string;
  windowRef: any;
  verficationCode: string;
  userName: string;
  currentInvite: Invite;
  refConfirm: any;
  OTPwaitTime = false;
  resetPasswordForm = false;
  regestieredWithMultipleCustomer = false;
  customersList;
  clientIdList = [];
  selectedCustomerId = '';
  userList: User[] = [];
  btnLoading = false;

  constructor(
    private location: Location,
    private win: WindowService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private loaderService: LoaderService,
    private alertController: AlertController,
    private route: ActivatedRoute,
    private angularFirestoreDb: AngularFirestore,
    private router: Router, private zone: NgZone,
    private navCtrl: NavController) {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }
  }

  ionViewDidEnter() {
    this.OTPwaitTime = false;
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('btn-sign-up', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log(EmployeeSignupComponent.name, 'captcha verified', response);
        // this.verifyInviteAndSignUp();
      }
    });
    const winRef = this.windowRef;
    // this.windowRef.recaptchaVerifier.render().then(function (widgetId) {
    // winRef.recaptchaWidgetId = widgetId;
    // });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      //// console.log(EmployeeSignupComponent.name, 'params:', params);
      if (params.feature === 'reset-password') {
        this.resetPasswordForm = true;
      } else {
        this.resetPasswordForm = false;
      }
    });
    // this.windowRef.recaptchaVerifier.render();
  }

  verifyInviteAndSignUp() {
    this.btnLoading = true;
    this.loaderService.presentLoading('Sending OTP to registered mobile number');
    if (!this.resetPasswordForm && !this.regestieredWithMultipleCustomer) {
      this.loaderService.dismissLoading();
      this.userService.getInviteForContactNumber(this.phoneNumber).subscribe(invites => {
        //// console.log(EmployeeSignupComponent.name, 'got invites', invites);
        if (invites && invites.length > 0) {
          this.loaderService.dismissLoading();
          this.btnLoading = false;
          this.currentInvite = invites[0];
          this.clientIdList.push(this.currentInvite.clientId);
          this.checkIfUserRegestieredWithMultipleCustomer();
        } else {
          this.btnLoading = false;
          this.loaderService.dismissLoading();
          this.presentAlert('There is no invite for this phone number from an employer, please contact your employer.');
        }
      }, (err) => {
        this.btnLoading = false;
        this.loaderService.dismissLoading();
        //// console.log(EmployeeSignupComponent.name, 'error fetching invites', err);
        this.presentAlert('Error fetching invite for this phone number, please contact your employer.');
      });
    } else {
      this.loaderService.dismissLoading();
      this.btnLoading = false;
      this.getOtp();
    }
  }

  getOtp() {
    if (this.regestieredWithMultipleCustomer && this.selectedCustomerId === '') {
      //// console.log(EmployeeSignupComponent.name, 'selected customer id', this.selectedCustomerId);
      this.presentAlert('Please select a customer to get OTP');
      return false;
    }
    // console.log(EmployeeSignupComponent.name, 'selected customer id', this.selectedCustomerId);
    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = '+91' + this.phoneNumber;
    this.OTPwaitTime = true;
    firebase.auth().signInWithPhoneNumber(num, appVerifier)
      .then(result => {
        this.loaderService.dismissLoading();
        this.zone.run(() => {
          this.windowRef.confirmationResult = result;
          this.showOtp = true;
        });
      }, (err) => {
        this.zone.run(() => {
          this.OTPwaitTime = false;
          // console.log('error sending otp', err);
          if (err && err.code === 'auth/too-many-requests') {
            this.presentAlert('Too many attempts, please try again');
          } else {
            this.presentAlert('Failed to send OTP, please try again');
          }
        });
      })
      .catch(error => {
        this.loaderService.dismissLoading();
        this.zone.run(() => {
          this.OTPwaitTime = false;
          // console.log(EmployeeSignupComponent.name, error);
        });
      });
  }


  verfiyOtpAndLogin() {
    let docId: string;
    this.btnLoading = true;
    const code = this.verficationCode;
    console.log(EmployeeSignupComponent.name, 'verification code', code);
    this.windowRef.confirmationResult
      .confirm(code)
      .then(result => {
        if (result) {
          this.btnLoading = false;
          console.log(EmployeeSignupComponent.name, result.user, 'result.user');
          if (this.resetPasswordForm) {
            this.userService.setPasswordResetFlow();
            setTimeout(() =>
              this.router.navigate(['/set-password'], {
                queryParams: {
                  feature: 'reset-password',
                  userType: 'employee',
                }
              }), 1500);
          } else {
            this.btnLoading = false;
            const num = '+91' + this.phoneNumber;
            docId = this.regestieredWithMultipleCustomer ? this.angularFirestoreDb.createId() : result.user.uid;
            // console.log(EmployeeSignupComponent.name, docId, 'docId');
            setTimeout(() => {
              this.userService.setUserDoc(docId, result.user.email, 'employee', this.currentInvite.clientId, this.userName, num,
                this.currentInvite.employeeType, true).then((user) => {
                  // console.log(EmployeeSignupComponent.name, user, 'created-user');
                  const id = this.regestieredWithMultipleCustomer ? docId : result.user.uid;
                  this.userService.saveLoggedInUserData(num, id, null);
                  this.userService.deleteInvite(this.currentInvite.id);
                });
            }, 1000);
          }
        }
      }, () => {
        this.btnLoading = false;
        this.presentAlert(`Invalid OTP`);
        this.verficationCode = '';
      })
      .catch(error => {
        this.btnLoading = false;
        // console.log(EmployeeSignupComponent.name, error, 'error');
        this.verficationCode = '';
        this.presentAlert(`Error processing OTP, please try again`);
      });
  }

  checkIfUserRegestieredWithMultipleCustomer() {
    const num = '+91' + this.phoneNumber;
    this.userService.checkUserExistsStatus(num, true).subscribe((userList: User[]) => {
      if (userList.length) {
        // this.regestieredWithMultipleCustomer = true;
        this.userList = userList;
        userList.map((user) => {
          this.clientIdList.push(user.clientId);
          this.userService.updateCurrentUser(user.id, user.clientId, user.registrationType, false);
        });
        const clientInfo = [];
        if (this.clientIdList.length) {
          this.clientIdList.map((clientId) => {
            this.userService.getClientDataBasedOnClientId(clientId).subscribe((user) => {
              clientInfo.push(user[0]);
              this.customersList = [...clientInfo];
              // console.log(EmployeeSignupComponent.name, this.customersList, 'this.customersList');
              // console.log(EmployeeSignupComponent.name, clientInfo, 'clientInfo');
            });
          });
        }
        this.presentAlert(`User Already Registered With This Number Please Select A Customer`);
        this.regestieredWithMultipleCustomer = true;
        // this.getOtp();
      } else {
        this.getOtp();
      }
    });
  }


  selectCustomer(event) {
    this.selectedCustomerId = event.target.value;
    // console.log(EmployeeSignupComponent.name, this.selectedCustomerId, 'selectedCustomer');
    if (this.selectedCustomerId) {
      const userFound = this.userList.find((item) => item.clientId === this.selectedCustomerId);
      if (userFound) {
        this.presentAlert(`User Already Registered With This Number Please Login`);
      } else {
        this.regestieredWithMultipleCustomer = true;
        this.getOtp();
      }
    }
  }

  onGoBack() {
    this.navCtrl.navigateBack(['/']);
  }

  resendOTP() {
    this.showOtp = false;
  }

  async presentAlert(alertData) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: alertData,
      buttons: ['OK'],
      cssClass: 'p-3',
    });
    await alert.present();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  onSignIn() {
    this.navCtrl.navigateForward(['/employee-login']);
  }
}
