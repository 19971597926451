export class Clients {
    companyName: string;
    id: string;
    addresses: Addresses;
    customers?: Customer;
}

export class Customer {
    address: string;
    companyName: string;
    approx_location: any;
    id: string;
    location: any;
    contactName: string;
    contactNumber: string;
    contactEmail: string[];
}

export class Addresses {
    city: string;
    country: string;
    line1: string;
    line2: string;
    province: string;
    zipcode: string;
}

export class GeoLocation {
    Latitude: number;
    Longitude: number;
}