import { UserService } from 'src/app/services/user.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, AlertController, ModalController, Platform } from '@ionic/angular';
import { User } from 'src/app/entity/user';
import { InviteCustomerComponent } from '../invite-customer/invite-customer.component';
import { Customer } from 'src/app/entity/clients';
import { LoaderService } from 'src/app/services/loader.service';
import { ClientService } from 'src/app/services/client.service';
import { HttpClientModule } from '@angular/common/http';
import { ImagePicker, ImagePickerOptions } from '@ionic-native/image-picker/ngx';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { UploadService } from '../../services/upload.service';
import { Upload } from '../../entity/upload';
import { File } from '@ionic-native/file/ngx';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [UploadService]
})
export class SettingsComponent implements OnInit {

  currentUser: User;
  customers: Customer[] = [];
  logoFile: File = null;
  isApp = false;
  // tslint:disable-next-line: max-line-length
  constructor(private snackbarService: SnackBarService, private camera: Camera,
    public modalController: ModalController, private alertController: AlertController, private clientService: ClientService, private file: File,
    private router: Router, private userService: UserService, private loaderService: LoaderService, private imagePicker: ImagePicker,
    private platform: Platform, private angularFirestore: AngularFirestore, private uploadService: UploadService) { }


  ngOnInit() {
    this.getCurrentuser();
    this.getCustomers();
    this.platform.ready().then(() => {
      console.log('platform', this.platform.is('desktop'), this.platform.is('mobileweb'));
      if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
        this.isApp = false;
      } else {
        this.isApp = true;
      }
    });
  }

  getCurrentuser() {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  getCustomers() {
    this.loaderService.presentLoading('Loading customers list');
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.clientService.getCustomersOfClient(currentUser.clientId).subscribe(customers => {
        this.loaderService.dismissLoading();
        this.customers = customers;
        this.customers.sort((customerA: Customer, customerB: Customer) => {
          if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
          return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
        });
      });
    });
  }

  onChangePassword() {
    this.userService.sendPasswordResetLink(this.currentUser.email).then(() => {
      this.router.navigate(['/emailverification'], {
        queryParams: {
          feature: 'reset',
          userType: 'employer'
        }
      });
    }, (error) => {
      this.snackbarService.showToaster(error);
    });
  }

  async onLogoUpload(event) {
    console.log("on file upload", event);
    this.logoFile = event.target.files[0];

    try {
      const fileName = this.angularFirestore.createId() + '.jpeg';
      console.log('fname', fileName);
      const imagePath = `logo/${this.currentUser.id}/${fileName}`;
      const uploadedImageUrl = await this.uploadService.fileUploadOnBrowser(this.logoFile, imagePath);
      console.log('upload', uploadedImageUrl);
      await this.saveLogo(uploadedImageUrl);
      this.snackbarService.showToaster('Image uploaded successfully!');
    } catch (err) {
      console.error("failed to upload image", err);
      this.snackbarService.showToaster('Image uploaded failed! please try again.');

    }
  }

  async onUpdateLogo() {
    console.log("onupdatelogo")
    const options: CameraOptions = {
      quality: 10,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      allowEdit: false,
      targetWidth: 200,
      targetHeight: 200,
    };
    console.log("camera options", options);
    this.camera.getPicture(options).then(async (results) => {
      for (var i = 0; i < results.length; i++) {
        console.log('Image URI: ' + results[i]);
        //const imagePath = await this.imagePicker.getPictures({});
        console.log('captured image', results[i]);
        const currentName = results[i].substr(results[i].lastIndexOf('/') + 1);
        console.log('name', currentName.substr);
        const correctPath = results[i].substr(0, results[i].lastIndexOf('/') + 1);
        console.log('path', correctPath);
        const fileName = this.angularFirestore.createId() + '.jpeg';
        console.log('fname', fileName);
        const filePath = await this.copyFileToLocalDir(correctPath, currentName, fileName);
        console.log('fpath', filePath);
        const uploadedImageUrl = await this.startUpload(filePath, fileName);
        console.log('upload', uploadedImageUrl);
        await this.saveLogo(uploadedImageUrl);
        return uploadedImageUrl;
      }
    }, (err) => {
      alert("error fetching image");
      console.log("error fetching image ", err);
    });
    //  this.imagePicker.getPictures(options)
  }

  async saveLogo(logoUrl: string) {
    await this.clientService.updateClientLogo(this.currentUser.clientId, logoUrl);
  }

  async startUpload(filePath: any, fileName: string) {
    console.log('new file name ', fileName);
    // storage path
    const imagePath = `logo/${this.currentUser.id}/${fileName}`;
    console.log('impath', imagePath);
    const uplaod = new Upload();
    console.log("pload", uplaod);
    uplaod.imagePath = imagePath;
    uplaod.filePath = filePath;
    uplaod.name = fileName;
    this.loaderService.presentLoading('Uploading image');
    const imageData = await this.uploadService.fileUploadNew(uplaod);
    console.log(imageData, 'imagedata');
    this.loaderService.dismissLoading();
    if (imageData) {
      this.snackbarService.showToaster('Image uploaded successfully!');
    }
    return imageData;
  }

  async copyFileToLocalDir(namePath, currentName, newFileName): Promise<string> {
    console.log(SettingsComponent.name, 'copy from ', namePath, currentName, ' to ', this.file.dataDirectory);
    return new Promise((resolve, reject) => {
      this.file.copyFile(namePath, currentName, this.file.dataDirectory, newFileName).then(success => {
        let filePath = this.file.dataDirectory + newFileName;
        resolve(filePath);
      }, error => {
        console.log(SettingsComponent.name, 'Error loading image', error);
        reject('error loading image');
      });
    });
  }


  onChangePackage() {
    this.snackbarService.showToaster('Feature yet to be implemented');
  }

  async onInviteCustomer() {
    const modal = await this.modalController.create({
      component: InviteCustomerComponent,
    });
    return await modal.present();
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'YES',
          handler: () => {
            this.onChangePassword();
          }
        }
      ],
      cssClass: 'p-3',
    });
    await alert.present();
  }

}
