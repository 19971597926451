import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouteMapComponent } from './route-map/route-map.component';
import { ViewPatrolDetailsComponent } from './view-patrol-details/view-patrol-details.component';


const customerRoutes: Routes = [
    {
        path: 'customer-view/dashboard',
        component: DashboardComponent,
    },
    {
        path: 'customer-view/view-patrol-details',
        component: ViewPatrolDetailsComponent,
    },
    {
        path: 'customer-view/route-map',
        component: RouteMapComponent,
    },


    { path: 'customer-view', redirectTo: 'customer/login', pathMatch: 'full' },

];

@NgModule({
    imports: [
        RouterModule.forChild(customerRoutes)
    ],
    exports: [RouterModule]
})
export class CustomerRoutingModule { }
