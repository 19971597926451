export class User {
    clientId: string;
    id: string;
    name: string;
    email: string;
    role: string;
    phonenumber?: string;
    registrationType?: string;
    createdDate?: any;
    isActive?: boolean;
    gstNumber?: string;
    panNumber?: string;
    customerAccessId?: string;
    logo: string;
    description: string;

    constructor() {
        this.clientId = null;
        this.id = null;
        this.name = null;
        this.role = null;
        this.phonenumber = null;
        this.email = null;
        this.registrationType = null;
        this.isActive = null;
        this.gstNumber = null;
        this.panNumber = null;
        this.logo =null;
        this.description = null;
    }
}

export class Devices {
    deviceId: string;
    fingerprint: string;
}