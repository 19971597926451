import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatrolService } from 'src/app/services/patrol.service';
import { EventTemplate, PitStop } from 'src/app/entity/event.template';
import { LoaderService } from 'src/app/services/loader.service';
import { EventService } from 'src/app/services/event.services';
import { Event } from 'src/app/entity/event';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { MapService } from 'src/app/services/map-data';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

@Component({
  selector: 'app-view-patrol-details',
  templateUrl: './view-patrol-details.component.html',
  styleUrls: ['./view-patrol-details.component.scss'],
})
export class ViewPatrolDetailsComponent implements OnInit {

  patrol: Event;
  template: EventTemplate;
  pitstops: Event[];
  pitstopsList: PitStop[] = [];
  private topLimit: number = 10
  dataSource: any = [];
  deviceMismatch: boolean;

  slideOpts = {
    // initialSlide: 1,
    speed: 100,
  };

  constructor(
    private patrolService: PatrolService,
    private eventService: EventService,
    private mapService: MapService,
    private navCtrl: NavController,
    private photoViewer: PhotoViewer) {
  }

  ngOnInit() {
    this.getPatrol();
    this.getPatrolTemplate();
  }

  loadData(event) {
    setTimeout(() => {
      this.topLimit += 10;
      this.dataSource = this.pitstopsList.slice(0, this.topLimit);
      event.target.complete();
      if (this.dataSource.length === this.pitstopsList.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  getPatrol() {
    this.mapService.getPatrol().subscribe((patrol) => {
      this.patrol = patrol;
      if (this.patrol) {
        this.getPitstops(this.patrol);
      }
    });
  }

  getPatrolTemplate() {
    this.mapService.getPatrolTemplate().subscribe((template) => {
      this.template = template;
      if (this.template) {
        this.getPitstopTemplates(this.template.id);
      }
    });
  }

  getPitstops(patrol) {
    // console.log(patrol, 'patrol');
    this.patrolService.getPitStopsForPatrol(patrol.templateId, patrol.startTime.toDate(),
      patrol.endTime ? patrol.endTime.toDate() : null)
      .subscribe(pitstops => {
        this.pitstops = pitstops;

        this.validateDevice(pitstops);
      });
  }

  getPitstopTemplates(patrolId: string) {
    this.eventService.getPitstopsOfPatrol(patrolId).subscribe(pitstops => {
      if (pitstops.length) {
        this.pitstopsList = pitstops;
        this.pitstopsList = this.pitstopsList.filter(pitstop => pitstop.isActive);
        this.pitstopsList.sort((pitstopA: PitStop, pitstopB: PitStop) => {
          if (pitstopA.name.toLowerCase() === pitstopB.name.toLowerCase()) { return 0; }
          return (pitstopA.name.toLowerCase() > pitstopB.name.toLowerCase()) ? 1 : -1;
        });
        this.dataSource = this.pitstopsList;
      }
    });
  }

  getPitstopName(templateItemId: string) {
    const pitstop = this.pitstopsList.find(item => item.id === templateItemId);
    // // console.log(PatrolDetailsModalComponent.name, 'pitstop name', pitstop.name);
    return pitstop ? pitstop.name : 'Unknown';
  }

  getPitstop(pitstopId: string): Event {
    // // console.log(PatrolDetailsModalComponent.name, 'getPitstop pitsop ID', pitstopId);
    // // console.log(PatrolDetailsModalComponent.name, 'getPitstop pitstops', this.pitstops);
    if (this.pitstops) {
      const res = this.pitstops.find(item => item.templateItemId === pitstopId);
      //console.log('pitstop', res);
      return res;
    } else {
      return null;
    }
  }


  getLocationErrorConfirmation(templateItemId) {
    // // console.log(PatrolDetailsModalComponent.name, 'location error pitstop templateItemId', templateItemId);
    if (templateItemId && this.patrol.data.errorPitstops) {
      return this.patrol.data.errorPitstops.find((item) => item === templateItemId) ? true : false;
    } else {
      return false;
    }
  }

  getMissedPitstopConfirmation(templateItemId) {
    // console.log(templateItemId, 'templateItemId');
    // // console.log(PatrolDetailsModalComponent.name, 'missed pitstop templateItemId', templateItemId);
    if (templateItemId && this.patrol.data.missedPitstops) {
      return this.patrol.data.missedPitstops.find((item) => item === templateItemId) ? true : false;
    } else {
      return false;
    }
  }

  getImagesList(pitstopId) {
    // // console.log(PatrolDetailsModalComponent.name, 'getImagesList() pitstop id', pitstopId);
    // // console.log(PatrolDetailsModalComponent.name, 'getImagesList() pitstops', this.pitstops);
    return this.pitstops.find((item) => item.templateItemId === pitstopId);
  }

  getTimeinHHMMFormat(mins) {
    const num = mins;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = Math.round(seconds);
    if (rhours >= 0) {
      return rhours + ' ' + 'h' + ' ' + ':' + ' ' + rminutes + ' ' + 'm' + ' ' + ':' + ' ' + rseconds + ' ' + 's';
    } else {
      return 0 + ' ' + 'h' + ' ' + ':' + ' ' + 0 + ' ' + 'm' + ' ' + ':' + ' ' + 0 + ' ' + 's';
    }
  }

  getCreatedByUserName(createdByUserId) {
    // // console.log(PatrolDetailsModalComponent.name, 'userId', createdByUserId);
    // console.log('pitstops', this.pitstops);
    if (this.pitstops) {
      const res = this.pitstops.find((item) => item.createdByUser === createdByUserId);
      // console.log('pitstopres', res);
      if (res) {
        return res.createdByUserName;
      } else {
        return 'UNKNOWN';
      }
    } else {
      return 'UNKNOWN';
    }
  }

  getPitstopNotes(pitstopId) {
    if (this.pitstops) {
      const pitStop = this.pitstops.find((item) => item.templateItemId === pitstopId);
      // // console.log('pitstop notes', pitStop);
      if (pitStop) {
        return pitStop ? pitStop.data.notes : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  onViewRoute() {
    this.mapService.setPitstops(this.pitstops);
    this.mapService.setPitstopTemplates(this.pitstopsList);
    this.navCtrl.navigateRoot(['/employer/customer-info'], { queryParams: { view: 'pitstop-details' } });
  }

  onViewImage(image) {
    this.photoViewer.show(image, '', { share: true });
  }

  validateDevice(pitstops) {
    //console.log('validateDevice -> pitstops', pitstops)
    const startDeviceId = pitstops[0].data.deviceId;
    pitstops.map((pitstop) => {
      if (startDeviceId === pitstop.data.deviceID) {
        this.deviceMismatch = false;
      } else {
        this.deviceMismatch = true;
      }
    })
  }
}
