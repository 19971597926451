import { Injectable } from "@angular/core";
import { AngularFireStorage, AngularFireUploadTask } from "@angular/fire/storage";
import { AngularFirestore } from "@angular/fire/firestore";
import { Upload } from "../entity/upload";
import { Observable, VirtualTimeScheduler } from "rxjs";
import { finalize, tap, map } from "rxjs/operators";
import { File } from "@ionic-native/file/ngx";
import * as firebase from "firebase";

@Injectable()
export class UploadService {
  percentage: Observable<number>;
  snapshot: Observable<any>;
  UploadedFileURL: Observable<string>;
  images: Observable<Upload[]>;
  fileSize: number;
  task: AngularFireUploadTask;
  profileUrl: Observable <string | null>;
  constructor(private angularFireStorage: AngularFireStorage, private database: AngularFirestore, private file: File) {}

  readInputFile(arg:string) {
    const ref = this.angularFireStorage.ref(arg);
    this.profileUrl = ref.getDownloadURL();
    this.profileUrl.subscribe((res) => console.log(res));
  }

  fileUpload(upload: Upload): Observable<string> {
    return new Observable<string>((observer) => {
      this.getBlob(upload.filePath).then((blob) => {
        // console.log(UploadService.name, "got the blob...");
        this.task = this.angularFireStorage.upload(upload.imagePath, blob);
        const fileRef = this.angularFireStorage.ref(upload.imagePath);
        this.task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              // console.log(UploadService.name, "image uploaded, getting url...");
              fileRef.getDownloadURL().subscribe((url) => {
                // console.log(UploadService.name, "download url ", url);
                observer.next(url);
              });
            })
          )
          .subscribe();
      });
    });
  }

  audioFileUpload(upload: Upload): Observable<string> {
    return new Observable<string>((observer) => {
      this.getBlob(upload.filePath).then((blob) => {
        // console.log(UploadService.name, "got the blob...");
        this.task = this.angularFireStorage.upload(upload.audioPath, blob);
        const fileRef = this.angularFireStorage.ref(upload.audioPath);
        this.task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              // console.log(UploadService.name, "image uploaded, getting url...");
              fileRef.getDownloadURL().subscribe((url) => {
                // console.log(UploadService.name, "download url ", url);
                observer.next(url);
              });
            })
          )
          .subscribe();
      });
    });
  }

  videoFileUpload(upload: Upload): Observable<string> {
    return new Observable<string>((observer) => {
      this.getBlob(upload.filePath).then((blob) => {
        // console.log(UploadService.name, "got the blob...");
        this.task = this.angularFireStorage.upload(upload.videoPath, blob);
        const fileRef = this.angularFireStorage.ref(upload.videoPath);
        this.task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              // console.log(UploadService.name, "image uploaded, getting url...");
              fileRef.getDownloadURL().subscribe((url) => {
                // console.log(UploadService.name, "download url ", url);
                observer.next(url);
              });
            })
          )
          .subscribe();
      });
    });
  }

  async fileUploadOnBrowser(file: File, imagePath: string) {
    await this.angularFireStorage.upload(imagePath, file);
    const fileRef = this.angularFireStorage.ref(imagePath);
    // console.log(UploadService.name, "image uploaded, getting url...");
    return await fileRef.getDownloadURL().toPromise();
  }

  async fileUploadNew(upload: Upload) {
    const blob = await this.getBlob(upload.filePath);
    console.log(UploadService.name, "got the blob...", blob);
    await this.angularFireStorage.upload(upload.imagePath, blob);
    const fileRef = this.angularFireStorage.ref(upload.imagePath);
    // console.log(UploadService.name, "image uploaded, getting url...");
    return await fileRef.getDownloadURL().toPromise();
  }

  async canvasBlobUpload(canvasBlob, imagePath){
    await this.angularFireStorage.upload(imagePath, canvasBlob);
    const fileRef = this.angularFireStorage.ref(imagePath);
    return await fileRef.getDownloadURL().toPromise();
  }

  getBlob(filePath: string): Promise<Blob> {
    // console.log(UploadService.name, "resolving file path", filePath);
    return new Promise((resolve, reject) => {
      this.file
        .resolveLocalFilesystemUrl(filePath)
        .then((entry) => {
          const fileEntry: any = entry;
          fileEntry.file((file) => {
            this.readFile(file).then(
              (blob) => {
                resolve(blob);
              },
              () => reject("Error reading file")
            );
          });
        })
        .catch(() => {
          // console.log(UploadService.name, 'Error while reading file.');
          reject("Error reading file");
        });
    });
  }

  readFile(file: any): Promise<Blob> {
    // console.log(UploadService.name, "reading file");
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imgBlob = new Blob([reader.result], {
          type: "image/jpeg",
        });
        resolve(imgBlob);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  uploadBlobForImage(blob, fileName, currentUser) {
    const imagePath = `events/${currentUser.id}/${fileName}`;
    console.log("impath", imagePath);
    const url = this.canvasBlobUpload(blob, imagePath);
    return url;
  }

  deleteFileStorage(url: string) {
    return this.angularFireStorage.storage.refFromURL(url).delete();
  }
}
