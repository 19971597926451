import { Clients, Customer } from './../entity/clients';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { SnackBarService } from './snackbar.service';
import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { IAceeptMessageConstant } from '../core/constants/i-accept-msg.constant';
import { PatrolService } from './patrol.service';
import { CustomerAccess } from '../entity/customerAccess';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  clientCollections: AngularFirestoreCollection<Clients>;
  customerCollections: AngularFirestoreCollection<Customer>;
  customerAccessCollections: AngularFirestoreCollection<CustomerAccess>;


  constructor(
    public fireAuth: AngularFireAuth, private router: Router, private angularFirestoreDb: AngularFirestore,
    private snackBarService: SnackBarService, private patrolService: PatrolService) {
    this.clientCollections = this.angularFirestoreDb.collection<Clients>(IAceeptMessageConstant.COLLECTION_CLIENTS);
  }

  getCustomerByEmail(email: string): Observable<any> {
    return this.angularFirestoreDb.collectionGroup<Customer>(IAceeptMessageConstant.COLLECTION_CUSTOMERS,
      ref => ref.where('contactEmail', 'array-contains', email))
      .get().pipe(map(res => res.docs.map(doc => doc.data())));
  }

  fetchSignInMethodsForEmail(email: string) {
    return this.fireAuth.auth.fetchSignInMethodsForEmail(email);
  }
  /* save  customers */
  saveCustomer(customer: Customer, clientId: string): Promise<any> {
    if (customer.id === '') {
      const clientRef = this.angularFirestoreDb.doc(`${IAceeptMessageConstant.COLLECTION_CLIENTS}/${clientId}`)
        .collection(IAceeptMessageConstant.COLLECTION_CUSTOMERS);
      const customerCreationRes = clientRef.add({ ...customer });
      customerCreationRes.then((ref) => {
        ref.update({
          id: ref.id
        });
      });
      return customerCreationRes;
    } else {
      const clientRef = this.angularFirestoreDb.doc(`${IAceeptMessageConstant.COLLECTION_CLIENTS}/${clientId}`)
        .collection(IAceeptMessageConstant.COLLECTION_CUSTOMERS).doc(customer.id);
      return clientRef.set({ ...customer });
    }
  }

  inviteAccessToCustomer(customerAccess: CustomerAccess): Promise<any> {
    customerAccess.id = this.angularFirestoreDb.createId();
    if (customerAccess.id) {
      const customerAccessObj = { ...customerAccess };
      const customerAccessRef = this.angularFirestoreDb.collection(IAceeptMessageConstant.COLLECTION_CUSTOMER_ACCESS).doc(customerAccess.id).set(customerAccessObj);
      return customerAccessRef;
    }
  }

  updateInviteAccessToCustomer(id: string, customerAccessSites: string[]): Promise<any> {
    return this.angularFirestoreDb.collection<CustomerAccess>(IAceeptMessageConstant.COLLECTION_CUSTOMER_ACCESS).doc(id).update({ sites: customerAccessSites });
  }

  deleteInviteAccessToCustomer(id: string): Promise<any> {
    return this.angularFirestoreDb.collection<CustomerAccess>(IAceeptMessageConstant.COLLECTION_CUSTOMER_ACCESS).doc(id).delete();
  }

  fetchCustomerAccessListByClientId(clientId: string): Observable<any> {
    return this.angularFirestoreDb.collection<CustomerAccess>(IAceeptMessageConstant.COLLECTION_CUSTOMER_ACCESS,
      ref => ref
        .where('clientId', '==', clientId).orderBy('addedDate', 'desc'))
      .get().pipe(map(res => res.docs.map(doc => doc.data())));
  }

  fetchCustomerAccessListByEmailId(clientId: string, emailId: string): Observable<any> {
    return this.angularFirestoreDb.collection<CustomerAccess>(IAceeptMessageConstant.COLLECTION_CUSTOMER_ACCESS,
      ref => ref
        .where('clientId', '==', clientId)
        .where('email', '==', emailId)
        .orderBy('addedDate', 'desc'))
      .get().pipe(map(res => res.docs.map(doc => doc.data())));
  }

  /* get all  customers */
  getCustomersOfClient(clientId: string): Observable<any> {
    return this.angularFirestoreDb.collection<Clients>(IAceeptMessageConstant.COLLECTION_CLIENTS)
      .doc(clientId).collection(IAceeptMessageConstant.COLLECTION_CUSTOMERS).get()
      .pipe(map(res => res.docs.map(doc => doc.data())));
  }


  // delete customer
  // TODO: call a cloud function to delete customer related data recursively
  deleteCustomer(clientId: string, customerId: string) {
    const customerDeletion = this.angularFirestoreDb.collection<Customer>(IAceeptMessageConstant.COLLECTION_CLIENTS).doc(clientId)
      .collection(IAceeptMessageConstant.COLLECTION_CUSTOMERS).doc(customerId).delete();
    customerDeletion.then(() => {
      this.patrolService.removePatrolsOfCustomer(clientId, customerId);
    });
    return customerDeletion;
  }

  // get customer
  getCustomer(clientId: string, id: string): Observable<Customer> {
    return this.angularFirestoreDb.collection<Customer>(IAceeptMessageConstant.COLLECTION_CLIENTS).doc(clientId)
      .collection(IAceeptMessageConstant.COLLECTION_CUSTOMERS, ref => ref.where('id', '==', id))
      .get().pipe(map(res => {
        const customers = res.docs.map(doc => doc.data() as Customer);
        return customers && customers.length ? customers[0] as Customer : null;
      }));
  }

  getCustomerById(customerId: string): Observable<Customer> {
    // console.log(ClientService.name, 'fetching customer by id ', customerId);
    return this.angularFirestoreDb.collectionGroup<Customer>(IAceeptMessageConstant.COLLECTION_CUSTOMERS,
      ref => ref.where('id', '==', customerId))
      .get().pipe(map(res => {
        const customers = res.docs.map(doc => doc.data() as Customer);
        return customers && customers.length ? customers[0] as Customer : null;
      }));
  }

  // getCustomerById(customerId: string): Observable<any> {
  //   return this.angularFirestoreDb.collectionGroup<any>(IAceeptMessageConstant.COLLECTION_CUSTOMERS,
  //     ref => ref.where('id', '==', customerId)).get().pipe(map(customers => {
  //       customers.forEach(customer => {
  //         console.log("fetching parent ", customer.ref.parent.parent);
  //         customer.ref.parent.parent.get().then(par => {
  //           console.log("parent", par.data());
  //           // par.forEach(res => {
  //           //   console.log("parent", res.data());
  //           // })

  //         });
  //       });
  //       console.log(ClientService.name, 'fetch customer', customers);
  //       return customers ? customers[0] : null;
  //     }));
  // }
  // update customer
  updateCustomer(clientId: string, customer: Customer) {
    if (!customer.id) {
      return Promise.reject('cannot update customer without id');
    }
    return this.angularFirestoreDb.collection<Customer>(IAceeptMessageConstant.COLLECTION_CLIENTS).doc(clientId)
      .collection(IAceeptMessageConstant.COLLECTION_CUSTOMERS).doc(customer.id).update({ ...customer });
  }

  async updateClientLogo(clientId: string, logo: string) {
    const doc = this.angularFirestoreDb.doc(`${IAceeptMessageConstant.COLLECTION_CLIENTS}/${clientId}`);
    console.log('updateClientLogo -> client doc', doc);
    await doc.update({ logo });
    return doc;
  }

  async getClientData(clientId: string) {
    const doc = await this.angularFirestoreDb.doc(`${IAceeptMessageConstant.COLLECTION_CLIENTS}/${clientId}`).get().toPromise();
    if (doc.exists) {
      return doc.data();
    }
    return null;
  }

}

