import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ClientService } from 'src/app/services/client.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { MustMatch } from 'src/app/utilities/must-match.validator';

@Component({
  selector: 'app-customer-signup',
  templateUrl: './customer-signup.component.html',
  styleUrls: ['./customer-signup.component.scss'],
})
export class CustomerSignupComponent implements OnInit {
  customerLoginForm: FormGroup;
  btnLoading = false;

  constructor(
    private formBuilder: FormBuilder, private loaderService: LoaderService,
    private router: Router, private location: Location, private userService: UserService,
    private snackBarService: SnackBarService, private navCtrl: NavController,
    private route: ActivatedRoute, private clientService: ClientService) { }

  ngOnInit() {
    this.initCustomerLogin();
  }


  onGoBack() {
    this.customerLoginForm.reset();
    this.navCtrl.navigateBack(['/home']);
  }

  getValidationError() {
    return "invalid"
  }

  initCustomerLogin() {
    this.customerLoginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });
    console.log('customer login form', this.customerLoginForm);

  }


  onCreateAccount(loginFormValue) {
    // console.log('value', loginFormValue);
    if (loginFormValue.password === loginFormValue.confirmPassword) {
      this.userService.emailSignUp(loginFormValue.email, loginFormValue.password).then((user) => {
        // console.log('authenticated user', user);
        window.alert('Successfully created, Kindly verfiy your email to login');
        this.userService.sendEmailVerification().then((a) => {
          this.navCtrl.navigateBack(['/home']);
        });
      }).catch(error => {
        this.loaderService.dismissLoading();
        this.handleError(error);
      });

    } else {
      this.snackBarService.showToaster('Password must match');
    }
  }

  handleError(error) {
    if (error.code === 'auth/popup-blocked') {
      window.alert('Your browser has disabled Popups. Please Try again');
    } else if (error.code === 'auth/popup-closed-by-user') {
      window.alert('Please reload and try again');
    } else {
      this.snackBarService.showToaster(error.message);
    }
    return error.message;
  }

}
