import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { PatrolService } from 'src/app/services/patrol.service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { IAceeptMessageConstant } from './core/constants/i-accept-msg.constant';
import { MenuItem } from './entity/menu';
import { UserSubscription } from './entity/subscription';
import { User } from './entity/user';
import { OnBoardingModalComponent } from './on-boarding-modal/on-boarding-modal.component';
import { ConnectionService } from './services/connection.service';
import { LoaderService } from './services/loader.service';
import { UserService } from './services/user.service';
import { AngularFirestore } from "@angular/fire/firestore";
// import { AngularFireMessaging } from '@angular/fire/messaging';
//import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { AppLocationService } from "./services/app-location.service";
import { firestore } from "firebase";
// import { MessagingService } from './services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  isLoading: boolean;
  userId: string;
  menuList: MenuItem[] = [];
  tabLink = [];
  isConnected;
  status: string;
  currentSubscrption: UserSubscription;
  currentUser: User;
  isHalt = true;
  title = 'Halts';
  showMenu = false;
  message: any;
  deviceAppVersion: string;
  token: string;
  pushPayload: any;
  hasPermission: boolean;
  showDrill: boolean = false;
  fireDrillAlerts = [];
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private userService: UserService,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private router: Router,
    private menu: MenuController,
    private navCtrl: NavController,
    public alertController: AlertController,
    private location: Location,
    private connectionService: ConnectionService,
    private appVersion: AppVersion,
    private locationAccuracy: LocationAccuracy,
    private patrolService: PatrolService,
    public modalController: ModalController,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private firebaseXFcm: FirebaseX,
    // private afsMessaging: AngularFireMessaging,
    // private messagingService: MessagingService,
    private fcm: FCM,
    private locationService: AppLocationService,
  ) {
    this.deviceAppVersion = "0.0";
    this.initializeApp();
    this.subscription = this.loaderService.isLoading().subscribe(loading => {
      this.isLoading = loading;
    }, (err) => {
      // console.log(AppComponent.name, err, 'err');
    });
    this.getAppVersion();
  }

  //future release feature 
  checkSub() {
    console.log("checking user subscriptions checkSub");
    this.userService.fetchSubscrptionDataBasedOnClientId(this.currentUser.clientId).toPromise().then((sub) => {
      console.log("subscription data from the promise ", sub);
      return this.subDateExpireCheck(sub[0]);
    })
  }

  //future release feature
  subDateExpireCheck(sub) {
    let date = new Date();
      if (typeof sub.subscriptionEndDate === "object") {
        if (sub.subscriptionEndDate >= date) {
          // do nothing and add it to session storage
          console.log('active subscription - do nothing and add it to session storage');
          sessionStorage.setItem("subscription", sub.subscriptionEndDate.valueOf());
        } else {
          // log-out 
          console.log("subscription ended");
          return this.navCtrl.navigateRoot(['/home']);
        }
      } else {
        console.log('sub.subscriptionEndDate',typeof sub.subscriptionEndDate);
        if (sub.subscriptionEndDate >= date.getTime()) {
          // do nothing and add it to session storage
          console.log('active subscription - do nothing and add it to session storage');
          sessionStorage.setItem("subscription", sub.subscriptionEndDate);
        } else {
          // log-out
          console.log("subscription ended");
          return this.navCtrl.navigateRoot(['/home']);
        }
      }
  }

  ionViewDidEnter() {
    this.checkForLatestAppVersion();
  }

  async getAppVersion() {
    this.deviceAppVersion = await this.appVersion.getVersionNumber();
    console.log("Current app version ", this.deviceAppVersion);
  }

  ngOnInit() {
    this.getAppVersion();
    this.onBoarding();
    this.userService.authenticationStatusCheck();
    this.checkCurrentUserTypeAndRedirect();
    //  this.checkNetWrokConnectivity();
    this.checkLocationPermissions();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.router && this.router.url === '/halt/dashboard') {
          this.title = 'Halts';
          this.showMenu = true;
        } else if (this.router && this.router.url === '/patrol/dashboard') {
          this.title = 'Patrols';
          this.showMenu = true;
        } else if (this.router && this.router.url === '/attendance') {
          this.title = 'Attendance';
          this.showMenu = true;
        } else if (this.router && this.router.url === '/general/dashboard') {
          this.title = 'General';
          this.showMenu = true;
        }
         else {
          this.showMenu = false;
        }
      }
    });
  }

  async onBoarding() {
    // console.log(AppComponent.name, 'local storage', localStorage.getItem('onBoarding'));
    if (localStorage.getItem('onBoarding') === 'shown') {
      return;
    }
    const modal = await this.modalController.create({
      component: OnBoardingModalComponent,
      componentProps: {
        type: 'ivisit',
      }
    });
    localStorage.setItem('onBoarding', 'shown');
    return await modal.present();
  }

  checkForTitle() {
    if (this.router && this.router.url.includes('halt')) {
      this.title = 'Halts';
      this.showMenu = true;
    }
    if (this.router && this.router.url.includes('patrol')) {
      this.title = 'Patrols';
      this.showMenu = true;
    }
    if (this.router && this.router.url.includes('attendance')) {
      this.title = 'Attendance';
      this.showMenu = true;
    }
    if (this.router && this.router.url.includes('general')) {
      this.title = 'General';
      this.showMenu = true;
    }
  }

  async checkForLatestAppVersion() {
    this.deviceAppVersion = await this.appVersion.getVersionNumber();
    console.log(AppComponent.name, 'device app version', this.deviceAppVersion);
    const deviceType = this.platform.platforms();
    // // console.log(AppComponent.name, 'device type', deviceType);
    // const android = deviceType.find(item => item === 'android');
    // const ios = deviceType.find(item => item === 'ios');
    if (deviceType.find(item => item === 'android')) {
      this.userService.getAppVersion('android').subscribe((fireBaseAppVersion) => {
        // // console.log(AppComponent.name, 'fireBaseAppVersion', fireBaseAppVersion);
        if (fireBaseAppVersion !== this.deviceAppVersion) {
          this.presentUpdateAppAlert('Latest version of the app is required. Kindly update the app');
        }
      });
    } else if (deviceType.find(item => item === 'ios')) {
      this.userService.getAppVersion('ios').subscribe((res) => {
        // // console.log(AppComponent.name, 'res', res);
      });
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('../assets/scripts/firebase-messaging-sw.js')
          .then((registration) => {
            //console.log('Registration successful, scope is:', registration);
          }).catch((err) => {
            //console.log('Service worker registration failed, error:', err);
          });
      }
    }
  }

  checkNetWrokConnectivity() {
    this.connectionService.monitor().subscribe((isOnline) => {
      // // console.log(AppComponent.name, isOnline, 'isOnline');
    }, (err) => {
      //// console.log(AppComponent.name, err, 'err');
    });
  }

  checkCurrentUserTypeAndRedirect() {
    this.userService.getCurrentUser().subscribe(currentUser => {
      if (currentUser) {
        this.currentUser = currentUser;
        this.getCurrentSubscrption();
        //this.loaderService.presentLoading('Loading user info..');
        this.verfiyUserStatus(currentUser);
        this.checkForTitle();
      } else {
        if (this.router.url.indexOf('customer-view') != -1) {
          this.navCtrl.navigateRoot('/customer-login');
        } else {
          this.navCtrl.navigateRoot(['/home']);
        }
      }
    });
  }

  //future release feature
  getCurrentSubscrption() {

    this.userService.getCurrentSubscrption().subscribe((sub) => {
      if (sub) {
        console.log(AppComponent.name, sub, 'currentSubscrption-employee');
        this.currentSubscrption = sub;
        if(typeof sub.subscriptionEndDate === "object") {
          sessionStorage.setItem("subscription", sub.subscriptionEndDate.valueOf().toString());
        }
        if(typeof sub.subscriptionEndDate === "number") {
          sessionStorage.setItem("subscription", new Number(sub.subscriptionEndDate).toString());
        }
        return this.subDateExpireCheck(sub);
      }
    });

    const subEndDateValue = sessionStorage.getItem("subscription");
    
    if(!subEndDateValue) {
      this.checkSub();
    }
    else {
      return this.subEndDateCheck(subEndDateValue); 
    }
  }

  subEndDateCheck(subEndDate) {
    let epochDate = Date.now();
    if (subEndDate >= epochDate) {
      // do nothing
      console.log('active subscription - do nothing');
    } else {
      // log-out 
      console.log("subscription ended");
      return this.navCtrl.navigateRoot(['/home']);
    }
  }

  reDirectionBaseonSubscrption() {
    // make this case sensitive (to work for Halt and halt)
    if (this.currentSubscrption) {
      if (this.currentSubscrption.type === 'halt' || this.currentSubscrption.type === 'custom') {
        this.navCtrl.navigateRoot(['employer/dashboard/halts']);
      }
      if (this.currentSubscrption.type === 'patrol') {
        this.navCtrl.navigateRoot(['employer/dashboard/patrols']);
      }
      if (this.currentSubscrption.type === 'general') {
        this.navCtrl.navigateRoot(['employer/dashboard/employee-list']);
      }
    }
  }

  redirectBasedOnPayload () {

    if(this.pushPayload){
      if (this.pushPayload.type === "report-incident") {
        //navigate to employer/incident-access
        // for both client, team members
        // double check for general category
        console.log("navigating to employer/incident-access");

        return this.navCtrl.navigateRoot([`employer/incidents-access`], {
          queryParams: {
            customer: this.pushPayload.customer,
            employee: this.pushPayload.employee,
            date: this.pushPayload.date
          }
        });
  
      }
      
      if (this.pushPayload.type === "halt-summary") {
        // navigate to halt/dashboard
        // for both client, team members
        // double check for general category
        console.log("navigating to employer/dashboard/halts");
        return this.navCtrl.navigateRoot(['employer/dashboard/halts']);
  
      }
      
      if (this.pushPayload.type === "patrol-summary") {
        // navigate to patrol/dashboard
        // for both client, team members
        // double check for general category
        console.log("navigating to employer/dashboard/patrols");
        return this.navCtrl.navigateRoot(['employer/dashboard/patrols']);
  
      } 
      
      if (this.pushPayload.type === "attendance-punch-in") {
        // navigate to employer/attendance-report
        // for both client, team members
        // double check for general category
        console.log("navigating to employer/attendance-report");
        return this.navCtrl.navigateRoot(['employer/attendance-report'], {
          queryParams: {
            date: this.pushPayload.date
          }
        });
      }

      if (this.pushPayload.type === "attendance-punch-out"){
        // this should be employee account navigation
        // double check for general category
        console.log("navigating to attendance button in dashboard");
   
        if (this.currentUser.registrationType) {
          if ((this.currentUser.registrationType).toLowerCase() === 'halt' || (this.currentSubscrption.type).toLowerCase() === 'custom') {
            return this.navCtrl.navigateRoot(['halt/dashboard']);
          }
          if ((this.currentUser.registrationType).toLowerCase() === 'patrol') {
            return this.navCtrl.navigateRoot(['patrol/dashboard']);
          }
          if ((this.currentUser.registrationType).toLowerCase() === 'general') {
            return this.navCtrl.navigateRoot(['general/dashboard']);
          }
        }
      }

      // if(this.pushPayload.type === "alert") {
      //     return this.navCtrl.navigateRoot(['employee-alert'], {
      //       queryParams: {
      //        docId: this.pushPayload.docId,
      //        subCollectionId: this.pushPayload.subCollectionId
      //       }
      //     });
      // }
    }

  }

  async fcmToken() {
        
    await this.platform.ready();
    console.log('FCM setup started');

    if (!this.platform.is('cordova')) {
      return;
    }
    console.log('In cordova platform');

    // check for permission ---
    // for android-12 and below it always returns true - keeping this for future android versions(13 and above) and ios

    // also need to add some tags to config.xml if anything goes wrong??
    const hasPermissionBool: boolean = await this.firebaseXFcm.grantPermission();
    // console.log("hasPermission() returns ->", hasPermissionBool);
    
    await this.firebaseXFcm.setAutoInitEnabled(true);
    this.token = await this.firebaseXFcm.getToken();

    await this.checkForFcmToken();
  }

  async checkForFcmToken() {
    // checking for existing record in fcmToken collection
    const fcmTokenCollectionRef = this.afs.collection("fcmToken");
    const check = await fcmTokenCollectionRef.ref
      .where('userId', '==', this.currentUser.id)
      .where('userName', '==', this.currentUser.name)
      .get();
    
    // .empty property on querySnapshot returns true if the querySnapshot is empty
    if(check.empty === true) {
      // add a document if there is no record for the current user in firestore
      const docuID = this.afs.createId();
      const documentFCM = fcmTokenCollectionRef.doc(docuID);
      documentFCM.set({ id: docuID, token: this.token, userId: this.currentUser.id, userName: this.currentUser.name });
      console.log("added document - check");
    }
    else {
      const document = [];
      check.forEach(documentSnapshot => {
        document.push(documentSnapshot.data());
      });
      console.log("logging the old document => ", document);
      this.afs.collection("fcmToken").doc(document[0].id).update( { token : this.token } );
    }

    this.fcmTokenRefreshCheck();
    this.onNotificationReceived();
  }

  fcmTokenRefreshCheck() {
    console.log('Subscribing to token updates');
    this.firebaseXFcm.onTokenRefresh().subscribe(async (token:string) => {
      if( this.token === token) {
        // do nothing as the refreshed token is the same as old token
        console.log("same token -> ", token);
      } else {
        // refresh token generated
        this.token = token;
        // update to the collection 
        const docQuerySnapshot = await this.afs.collection("fcmToken").ref.where('userId', '==', this.currentUser.id).get();
        const document = [];
        docQuerySnapshot.forEach(documentSnapshot => {
          document.push(documentSnapshot.data());
        });
        console.log("logging the queried document => ", document);
        this.afs.collection("fcmToken").doc(document[0].id).update( { token : this.token } );
        console.log('onTokenRefresh received event with: ', token);
  
      }
    });
  }

  onNotificationReceived() {
    console.log('Subscribing to new notifications');
    this.fcm.onNotification().subscribe(data => {
      if(data.wasTapped){
      //   // console.log("Received in background fcm ", data);
      //   // this.pushPayload = data;
      //   // this.redirectBasedOnPayload();
      } else {
        console.log("Received in foreground fcm", data);
        this.pushPayload = data;
        this.redirectBasedOnPayload();
      //   if(data.type === "alert") {
      //     this.locationService.getCurrentLocation().then((loc) => {
      //       console.log(" alert locationCoords ", loc);
      //       // this.presentModalAlert("Fire drill call", loc, data);
      //       // localStorage.setItem("responseStatus", "pending");
      //       // localStorage.setItem("fireDrillDocId", data.docId);
      //       // localStorage.setItem("responseDocId", data.subCollectionId);
      //       this.showDrill = true;
      //       this.snackBarService.showFireDrillToaster("Fire Drill Alert", loc, data.docId, data.subCollectionId);
      //     }).catch((err) => {
      //       console.error("ERROR ", err);
      //     });
      //   }
      };
    });

    this.firebaseXFcm.onMessageReceived().subscribe(payload => {
      console.log("payload", payload);
      //modal for foreground notification and response to be collected in the modal
      // response button
      //payload.tap === "foreground" ||
      if(payload.tap === "background") {
        console.log("received a message ---- using firebasex ", payload);
        this.pushPayload = payload;
        this.redirectBasedOnPayload();
        // if(payload.type === "alert") {
        //   this.locationService.getCurrentLocation().then((loc) => {
        //     console.log(" alert locationCoords ", loc);
        //     // this.presentModalAlert("Fire drill call", loc, data);
        //     // localStorage.setItem("responseStatus", "pending");
        //     // localStorage.setItem("fireDrillDocId", payload.docId);
        //     // localStorage.setItem("responseDocId", payload.subCollectionId);
        //     this.showDrill = true;
        //     this.snackBarService.showFireDrillToaster("Fire Drill Alert", loc, payload.docId, payload.subCollectionId);
        //   }).catch((err) => {
        //     console.error("ERROR ", err);
        //   });
        // }
      }
    });
  }
  // getFcmToken() {
  //   this.fcm.getToken().then(token => {
  //     console.log(token);
  //   });
  // }

  // getFcmRefreshToken() {
  //   this.fcm.onTokenRefresh().subscribe(token => {
  //     console.log(token);
  //   });
  // }

  // sendPushNotification() {
  //   const userId = '2222';
  //   this.messagingService.requestPermission(userId);
  //   this.messagingService.receiveMessage();
  //   this.message = this.messagingService.currentMessage;

  //   this.messagingService.sendPushMessage("Web push notification", "HI, Firebase test messsage");
  // }


  // getFcmNotiifcation() {
  //   this.fcm.onNotification().subscribe(data => {
  //     console.log(data);
  //     if (data.wasTapped) {
  //       console.log('Received in background');
  //       // this.router.navigate([data.landing_page, data.price]);
  //     } else {
  //       console.log('Received in foreground');
  //       // this.router.navigate([data.landing_page, data.price]);
  //     }
  //   });
  // }

  checkLocationPermissions() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        //// console.log(AppComponent.name, 'location services must be requested');
        // the accuracy option will be ignored by iOS
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => {
            //// console.log(AppComponent.name, 'Location permission request successful');
          },
          error => {
            //// console.log(AppComponent.name, 'Error requesting location permissions', error);
          }
        );
      } else {
        //// console.log(AppComponent.name, 'location permissions already granted');
      }
    }, error => {
      //// console.log(AppComponent.name, 'error requesting location access' + error);
    });
  }


  verfiyUserStatus(currentUser) {
    //console.log('currentUser', currentUser);
    this.userService.verifyUserStatus().subscribe((statusObject) => {
      //console.log(AppComponent.name, statusObject, 'status');
      // this.patrolService.removepatrolsWithTemplateId("2VbIP5h3QBM0uo8y4inH"); // USE ONLY TO DELETE TEMPLATES IF DOESNT EXIST IN FIREBASE
      if (statusObject) {
        if (statusObject.statusCode === IAceeptMessageConstant.REGISTRATION_EMAIL_IS_NOT_VERIFED &&
          (currentUser.role === IAceeptMessageConstant.USER_ROLE_CLIENT || currentUser.role === IAceeptMessageConstant.USER_ROLE_CUSTOMER)) {
          //this.loaderService.dismissLoading();
          //this.presentAlert('Kindly verify your email ID');
          this.navCtrl.navigateRoot(['/emailverification']);
          return;
        }
        if (statusObject.statusCode === IAceeptMessageConstant.SUBSCRIPTION_NOT_PAID &&
          currentUser.role === IAceeptMessageConstant.USER_ROLE_CLIENT) {
          //this.loaderService.dismissLoading();
          // tslint:disable-next-line: max-line-length
          this.presentAlert('Kindly contact support team to have your account enabled. Please send a mail to mohan@iaccept.in or Whatsapp on +91 8073980614.');
          // To be done when subscription module is decided
          // this.navCtrl.navigateRoot(['/subscription-handling'], { queryParams: { userRole: IAceeptMessageConstant.USER_ROLE_CLIENT } });
          return;
        }
        if (statusObject.statusCode === IAceeptMessageConstant.EMPLOYEE_SET_PASSWORD &&
          currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE) {
          //this.loaderService.dismissLoading();
          this.navCtrl.navigateRoot(['/set-password']);
          return;
        }
        if (statusObject.statusCode === IAceeptMessageConstant.EMPLOYEE_RESET_PASSWORD &&
          currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE) {
          //this.loaderService.dismissLoading();
          this.router.navigate(['/set-password'], {
            queryParams: {
              feature: 'reset-password',
              userType: 'employee',
            }
          });
          // this.navCtrl.navigateRoot(['/set-password']);
          return;
        }
        if (statusObject.statusCode === IAceeptMessageConstant.SUBSCRIPTION_NOT_PAID &&
          currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE) {
          //this.loaderService.dismissLoading();
          this.navCtrl.navigateRoot(['/subscription-handling'], { queryParams: { userRole: IAceeptMessageConstant.USER_ROLE_EMPLOYEE } });
          return;
        }
        if (statusObject.statusCode === IAceeptMessageConstant.USERSTATUSCHECK) {
          //this.loaderService.dismissLoading();
          this.reDirectionToParticularModule(currentUser);
        }
      }
    });
  }

  fcmTokenAndNotifcation(){ 
    this.fcmToken().then(response => {
      this.redirectBasedOnPayload();
      console.log("redirecting based on payload");
    });
  }

  reDirectionToParticularModule(currentUser) {
    //console.log(AppComponent.name, currentUser, 'currentuser');
    if ((currentUser.role).toLowerCase() === IAceeptMessageConstant.USER_ROLE_CLIENT) {
      //this.loaderService.dismissLoading();
      this.reDirectionBaseonSubscrption();
      this.fcmTokenAndNotifcation();
    } else if (currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE &&
      (currentUser.registrationType).toLowerCase() === IAceeptMessageConstant.REGISTRATION_TYPE_HALT) {
      //this.loaderService.dismissLoading();
      this.navCtrl.navigateRoot(['/halt/dashboard']);
      this.isHalt = true;
      this.fcmTokenAndNotifcation();
      //// console.log('isHalt', this.isHalt);
    } else if (currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE &&
      (currentUser.registrationType).toLowerCase() === IAceeptMessageConstant.REGISTRATION_TYPE_PATROL) {
      //this.loaderService.dismissLoading();
      this.navCtrl.navigateRoot(['/patrol/dashboard']);
      this.isHalt = false;
      this.fcmTokenAndNotifcation();
      //// console.log('isHalt', this.isHalt);
    } else if (currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE &&
      (currentUser.registrationType).toLowerCase() === IAceeptMessageConstant.REGISTRATION_TYPE_CUSTOM) {
        //this.loaderService.dismissLoading();
        this.navCtrl.navigateRoot(['/halt/dashboard']);
        this.fcmTokenAndNotifcation();
    } else if (currentUser.role === IAceeptMessageConstant.USER_ROLE_EMPLOYEE &&
      (currentUser.registrationType).toLowerCase() === IAceeptMessageConstant.REGISTRATION_TYPE_GENERAL) {
      //this.loaderService.dismissLoading();
      console.log("general");
      this.navCtrl.navigateRoot(['/general/dashboard']);
      this.fcmTokenAndNotifcation();
    } else if (currentUser.role === IAceeptMessageConstant.USER_ROLE_CUSTOMER) {
      //this.loaderService.dismissLoading();
      this.navCtrl.navigateRoot(['/customer-view/dashboard']);
      this.fcmTokenAndNotifcation();
    }
    else {
      this.navCtrl.navigateRoot(['/']);
      //this.loaderService.presentLoading(`Doesn't match ...`);
      //this.loaderService.dismissLoading();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.menu.enable(true, 'menu');
      this.statusBar.overlaysWebView(false);
      this.statusBar.styleLightContent();
    });

    this.connectionService.monitor().subscribe(isConnected => {
      //// console.log(AppComponent.name, isConnected, 'isconected');
      if (this.isConnected !== isConnected) {
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.status = 'ONLINE';
          this.snackBarService.showToaster('You are back online');
        } else {
          this.status = 'OFFLINE';
          this.snackBarService.showToaster('You are offline');
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.userService.logout();
            this.router.navigate(['home']);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  async presentModalAlert(data, loc, dataPayload) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            let respRef = this.afs.collection('fireAlerts').doc(dataPayload.docId).collection('responses').doc(dataPayload.subCollectionId);
            console.log("responses collection reference ", respRef);
            respRef.update({
              data: {
                "coords": new firestore.GeoPoint(loc.coords.latitude, loc.coords.longitude),
                "time": firestore.FieldValue.serverTimestamp()
              },
              responded: true,
            });
          }
        }
      ],
      backdropDismiss: false,
      cssClass: 'p-3',
    });

    await alert.present();
  }

  async presentUpdateAppAlert(data) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'UPDATE',
          handler: () => {
            window.open('https://play.google.com/store/apps/details?id=in.ivisit.app');
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  switchTo() {
    this.menu.close();
    if (this.isHalt) {
      this.navCtrl.navigateRoot(['patrol/dashboard/']);
      this.isHalt = false;
    } else {
      this.navCtrl.navigateRoot(['halt/dashboard/']);
      this.isHalt = true;
    }
  }

  onContactSupport() {
    this.menu.close();
    if (this.isHalt) {
      this.navCtrl.navigateForward(['halt/contact/']);
    } else {
      this.navCtrl.navigateForward(['patrol/contact/']);
    }
  }

  onGotoAttendance() {
    this.menu.close();
    this.navCtrl.navigateForward(['/attendance']);
  }

  onDashboard() {
    this.menu.close();
    this.navCtrl.navigateForward(['/general/dashboard']);
  }

  onDrillCallsList() {
    this.menu.close();
    this.navCtrl.navigateForward(['/employee-alert']);
  }

  async logoutAlert() {
    this.menu.close();
    const alert = await this.alertController.create({
      header: 'Alert',
      message: 'Are you sure that you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.onClickLogout();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  onClickLogout() {
    this.userService.logout().then(async() => {
      const docQuerySnapshot = await this.afs.collection("fcmToken").ref.where('userId', '==', this.currentUser.id).get();
      const document = [];
      docQuerySnapshot.forEach(documentSnapshot => {
        document.push(documentSnapshot.data());
      });
      console.log("logging the queried document => ", document);
      await this.afs.collection("fcmToken").doc(document[0].id).delete();
      console.log("deletion completed");
      await this.firebaseXFcm.setAutoInitEnabled(false);
      await this.firebaseXFcm.unregister();
      this.navCtrl.navigateBack(['home']);
    });
  }
}
