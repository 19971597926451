import { User } from './../../entity/user';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PopoverController, IonRouterOutlet, NavController } from '@ionic/angular';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  currentUser: User;
  isHalt: boolean;

  constructor(private userService: UserService, private router: Router, private nav: NavController,
              public popoverController: PopoverController,
              private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkIfHaltorPatrol();
    this.getCurrentuser();
  }

  checkIfHaltorPatrol() {
    if (this.router && this.router.url.includes('halt')) {
      this.isHalt = true;
    } else {
      this.isHalt = false;
    }
  }

  getCurrentuser() {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
        // console.log(AccountMenuComponent.name, 'currentUser', this.currentUser);
      }
    });
  }

  switchTo() {
    if (this.isHalt) {
      this.nav.navigateRoot(['patrol/dashboard/']);
      this.popoverController.dismiss();
      this.isHalt = false;
    } else {
      this.popoverController.dismiss();
      this.nav.navigateRoot(['halt/dashboard/']);
      this.isHalt = true;
    }
 }

  onClickLogout() {
    this.popoverController.dismiss('logout');
  }

  onContactSupport() {
    if (this.isHalt) {
      this.nav.navigateForward(['halt/contact/']);
      this.popoverController.dismiss();
    } else {
      this.nav.navigateForward(['patrol/contact/']);
      this.popoverController.dismiss();
    }
  }
}
