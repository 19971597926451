import { LoaderService } from './../../services/loader.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entity/user';
import { Attendance } from 'src/app/entity/attendance';
import { ExcelService } from 'src/app/services/excel.service';
import { Platform } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UserSubscription } from 'src/app/entity/subscription';

@Component({ 
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.scss'],
})
export class AttendanceReportComponent implements OnInit {

  currentUser: User;
  currentSubscrption: UserSubscription;
  reportType: string = "";
  attendanceList: Attendance[] = [];
  attendanceDisplayList: Attendance[] = [];
  employees: User[] = [];
  searchString: string;
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  noDataFound = false;
  hideFilter: boolean = false;
  showFilteredReports: boolean;
  btnLoading = false;
  isMobile: boolean;
  constructor(
    private loaderService: LoaderService, private userService: UserService,
    private excelService: ExcelService, private platform: Platform, private route: ActivatedRoute) {
    this.filterFromDate = new Date();
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
    this.isMobile = !this.platform.is('desktop');
    // console.log(this.isMobile, 'cordova');
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  getRoutingParams() {
    this.route.queryParams.subscribe((params) => {
      if(params.date) {
        console.log("attendance report - > date -> ", params.date);
        console.log("parsed epoch data -> ", parseInt(params.date));

        let fromDateParam = new Date(parseInt(params.date));
        console.log("logging from date received in params", fromDateParam);

        this.filterFromDate = fromDateParam;
        this.filterFromDateRawValue = this.filterFromDate.toISOString();

        // let toDate = new Date(parseInt(params.date));
        // toDate.setMinutes(toDate.getMinutes() + 10);
        // console.log("logging toDate based on date received in params", toDate);

        this.filterToDate = fromDateParam;
        this.filterToDateRawValue = this.filterToDate.toISOString();

        this.loaderService.presentLoading('Loading attendance logs');
        this.onFilter();
      }
    });
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    this.filterFromDate = new Date(date);
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    this.filterToDateRawValue = date;
    this.filterToDate = new Date(date);
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.currentSubscrption = this.userService.currentSubscrption;
        this.getRoutingParams();
        // this.getEmployeesAttendance(this.currentUser.clientId, this.filterFromDate, this.filterToDate);
      }
    });
  }

  onFilter() {
    const formDate = new Date(this.filterFromDate).setHours(0, 0, 0, 0);
    const toDate = new Date(this.filterToDate).setHours(23, 59, 59, 59);

    this.attendanceList = [];
    this.attendanceDisplayList = [];

    // console.log(AttendanceReportComponent.name, 'from: ', new Date(formDate), ' to: ', new Date(toDate));
    this.hideFilter = !this.hideFilter;
    this.btnLoading = true;
    this.showFilteredReports = !this.showFilteredReports;
    this.loaderService.presentLoading('Loading attendance logs');
    this.getEmployeesAttendance(this.currentUser.clientId, new Date(formDate), new Date(toDate), this.reportType? this.reportType: null);
  }

  getEmployeesAttendance(clientId: string, fromDate: Date, toDate: Date, reportType?: any) {
    if(reportType){
      console.log(" filtering based on the subscription => ", reportType);
      this.userService.getEmployeesOfClientBasedOnSubscription(clientId, reportType).subscribe((data) => {
        console.log(" all the users of specific report type" , data);
        let usersBasedOnReport = data.map((user) => user.id);
        console.log(" all the users id with chosen report type array " , usersBasedOnReport);
        if(usersBasedOnReport.length <=0){
          this.hideFilter = false;
          this.btnLoading = false;
          return this.noDataFound = true;
        } else {
          this.getEmployeeResults(clientId, fromDate, toDate, usersBasedOnReport);
        }
      })
    } else {
      this.getEmployeeResults(clientId, fromDate, toDate);
    }
  }

  getEmployeeResults(clientId: string, fromDate: Date, toDate: Date, usersBasedOnReport?: string[]) {
    this.userService.getAllEmployeeAttendance(clientId, fromDate, toDate).subscribe((data) => {
      this.btnLoading = false;
      // this.loaderService.dismissLoading();
      this.attendanceList = [];
      this.attendanceDisplayList = [];
      
      if (data && data.length > 0) {
        console.log("checking by filtering through usersBasedOnReport => ", usersBasedOnReport);
        // console.log("backend data => ", data);
        //future release feature
        this.attendanceDisplayList = this.attendanceList = data.filter((record) => usersBasedOnReport.includes(record.createdByUser));
        // this.attendanceDisplayList = this.attendanceList;
        // console.log(AttendanceReportComponent.name, 'attendanceList', this.attendanceList);
        // console.log(AttendanceReportComponent.name, 'attendanceDisplayList', this.attendanceDisplayList);
        this.getEmployeesOfClient(clientId);
        this.noDataFound = false;
      } else if (data.length <= 0) {
        this.noDataFound = true;
      }
    });
  }

  getEmployeesOfClient(clientId: string) {
    this.userService.getEmployeesOfClient(clientId).subscribe(employees => {
      this.employees = employees;
      // console.log(AttendanceReportComponent.name, 'employees', this.employees);
    });
  }

  getEmployeeName(id: string) {
    const employee = this.employees.find((item => item.id === id));
    return employee ? employee.name : 'unknown';
  }

  getEmployeeStatus(id: string) {
    const employee = this.employees.find((item => item.id === id));
    if (employee) {
      return employee.isActive ? 'ACTIVE' : 'INACTIVE';
    }
  }

  getTimeinHHMMFormat(mins) {
    if (mins >= 0) {
      const num = mins;
      const hours = (num / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.floor(minutes);
      const seconds = (minutes - rminutes) * 60;
      const rseconds = Math.round(seconds);
      return rhours + 'h' + ':' + rminutes + 'm' + ':' + rseconds + 's';
    } else {
      return 0 + 'hours' + ':' + 0 + 'mins';
    }
  }

  dateChange(event) {
    // console.log(event, 'event');
    this.attendanceDisplayList = [];
    this.hideFilter = false;
  }

  changeReportType(event) {
    // console.log(event, 'event');
    // change reportType based on select
    this.reportType = event.detail.value;
  }

  onChange(searchString) {
    // console.log(AttendanceReportComponent.name, 'search value', searchString);
    const lowercasedValue = searchString.toLowerCase().trim();
    const filteredEmployees = this.employees.filter((item) => item.name.toString().toLowerCase().includes(lowercasedValue));
    // console.log(AttendanceReportComponent.name, 'filteredEmployees', filteredEmployees);
    this.attendanceDisplayList = this.attendanceList.filter((attendance) => {
      return filteredEmployees.find((employee) => employee.id === attendance.createdByUser) ? true : false;
    });
    console.log(AttendanceReportComponent.name, 'attendanceDisplayList', this.attendanceDisplayList);
    // console.log(AttendanceReportComponent.name, 'attendanceList', this.attendanceList);
    if (this.attendanceDisplayList.length <= 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

  onShowFilter() {
    this.hideFilter = !this.hideFilter;
    this.showFilteredReports = !this.showFilteredReports;
  }

  showFilteredResults() {
    this.showFilteredReports = !this.showFilteredReports;
    this.hideFilter = !this.hideFilter;
  }

  onExportAttendance() {
    //console.log('exportAttendance');
    const reportsArray = [];
    let endTime: any;
    let startTime: any;
    let startLocation: string, endLocation: string;
    let punchInImage: string, punchOutImage: string;
    //console.log('export attendance', this.attendanceDisplayList);
    const employeeAttendanceLog = [...this.attendanceDisplayList];
    //console.log('employeeAttendanceLog', employeeAttendanceLog);
    employeeAttendanceLog.map((attendance) => {
      let duration: any;
      let deviceMatch: any;
      if (attendance.endTime !== null && attendance.endTime !== undefined) {
        endTime = attendance.endTime.toDate().toLocaleTimeString();
      }
      if (attendance.startTime !== null && attendance.startTime !== undefined) {
        startTime = attendance.startTime.toDate().toLocaleTimeString();
      }
      // tslint:disable-next-line: max-line-length
      if (attendance.endTime !== null && attendance.endTime !== undefined && attendance.startTime !== null && attendance.startTime !== undefined) {
        duration = this.getTimeinHHMMFormat((attendance.endTime.toMillis() - attendance.startTime.toMillis()) / (1000 * 60));
      }

      if (attendance.startLocation) {
        startLocation = `https://www.google.com/maps/search/?api=1&query=${attendance.startLocation.latitude},${attendance.startLocation.longitude}`;
      }
      if (attendance.endLocation) {
        endLocation = `https://www.google.com/maps/search/?api=1&query=${attendance.endLocation.latitude},${attendance.endLocation.longitude}`;
      }

      if (attendance.startTimeDeviceId && attendance.endTimeDeviceId) {
        if (attendance.startTimeDeviceId === attendance.endTimeDeviceId) {
          deviceMatch = 'Valid Device'
        } else {
          deviceMatch = 'Device Mismatch'
        }
      }
      reportsArray.push({
        employeeName: this.getEmployeeName(attendance.createdByUser),
        date: attendance.startTime.toDate().toLocaleDateString(),
        startTime,
        endTime,
        duration: duration || 0,
        startLocation,
        endLocation,
        punchInImage: attendance.punchInImage,
        punchOutImage: attendance.punchOutImage,
        device: deviceMatch
      });
    });
    //console.log('export attendance latest', reportsArray);
    const groupedAttendance = this.groupAttendanceByEmployee(reportsArray);
    this.excelService.exportAttendanceToExcel(groupedAttendance, 'groupedAttendance', this.isMobile);
  }

  groupAttendanceByEmployee(reportsArray) {
    //console.log('groupAttendanceByEmployee');
    return reportsArray.reduce((values, attendance) => {
      (values[attendance['employeeName']] = values[attendance['employeeName']] || []).push(attendance);
      return values;
    }, {});
  }

  getDeviceStatus(startTimeDeviceId, endTimeDeviceId) {
    //console.log('getDeviceStatus', startTimeDeviceId, endTimeDeviceId);
    if (startTimeDeviceId && endTimeDeviceId) {
      if (startTimeDeviceId === endTimeDeviceId) {
        return 'Same Device'
      } else {
        return 'Device Mismatch'
      };
    };
    return ''
  }

  compareAlphNumericString(str1, str2) {
    // console.log('compareAlphNumericString');
    // variable declaration
    let i, j = 0;

    // Length of first string
    let len1 = str1.length;

    // Length of second string
    let len2 = str2.length;

    // To check each and every characters
    // of both string
    while (i <= len1 && j <= len2) {

      // If the current character of the first
      // string is not an alphanumeric character,
      // increase the pointer i
      while (i < len1 &&
        (!((str1[i].charCodeAt() >= 'a'.charCodeAt(0) &&
          str1[i].charCodeAt() <= 'z'.charCodeAt(0)) ||
          (str1[i].charCodeAt() >= 'A'.charCodeAt(0) &&
            str1[i].charCodeAt() <= 'Z'.charCodeAt(0)) ||
          (str1[i].charCodeAt() >= '0'.charCodeAt(0) &&
            str1[i].charCodeAt() <= '9'.charCodeAt(0))))) {
        i++;
      }

      // If the current character of the second
      // string is not an alphanumeric character,
      // increase the pointer j
      while (j < len2 &&
        (!((str2[j].charCodeAt() >= 'a'.charCodeAt(0) &&
          str2[j].charCodeAt() <= 'z'.charCodeAt(0)) ||
          (str2[j].charCodeAt() >= 'A'.charCodeAt(0) &&
            str2[j].charCodeAt() <= 'Z'.charCodeAt(0)) ||
          (str2[j].charCodeAt() >= '0'.charCodeAt(0) &&
            str2[j].charCodeAt() <= '9'.charCodeAt(0))))) {
        j++;
      }

      // if all alphanumeric characters of
      // both strings are same then return true
      if (i == len1 && j == len2) {
        return 'same device';
      }

      // if any alphanumeric characters of
      // both strings are not same then return false
      else if (str1[i] != str2[j]) {
        return 'device mismatch';
      }
      // If current character matched,
      // increase both pointers to
      // check the next character
      else {
        i++;
        j++;
      }
    }

    // If not same, then return false
    return false;
  }
}
