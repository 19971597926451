import { Component, OnInit } from '@angular/core';
import {
  GoogleMap,
  GoogleMaps,
  MarkerCluster,
  GoogleMapsEvent,
  Marker,
  LatLng,
  Environment,
  Polyline,
} from '@ionic-native/google-maps/ngx';
import { GoogleMapOptions, CameraPosition } from '@ionic-native/google-maps';
import { MapService } from 'src/app/services/map-data';
import { Platform } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: 'app-alert-map',
  templateUrl: './alert-map.component.html',
  styleUrls: ['./alert-map.component.scss'],
})
export class AlertMapComponent implements OnInit {
  title: String = "Response map markers"
  map: GoogleMap;
  marker: any;
  markerCluster: MarkerCluster;
  employeeAlertsList: any[] = [];

  constructor(
    private afs: AngularFirestore,
    private platform: Platform,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    await this.getRoutingParams();
  }

  async getRoutingParams() {
    this.route.queryParams.subscribe((params) => {
      console.log("params ", params.alertsId);
      this.afs.collection('fireAlerts').doc(params.alertsId).collection('responses').get().subscribe((doc) => {
        doc.docs.map(d => {
          // this.mapCoords(d.data());
          this.employeeAlertsList.push(d.data());
        });
      });
    });
  }
  
  async ionViewDidEnter() {
    await this.platform.ready();
    this.loadMap();
  }

  loadMap() {
    Environment.setEnv({
      API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI',
      API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI'
    });
    this.map = GoogleMaps.create('map_canvas', {
      preferences: {
        zoom: {
          minZoom: 3,
          maxZoom: 18,
        },
      },
    });

    console.log("Map object reference ", this.map);

    this.employeeAlertsList.forEach((run) => {
      this.mapCoords(run);
    });

  };

  mapCoords(runRecord) {
    console.log("runRecord ", runRecord);
    if(runRecord.responded === true) {
      this.addMarkerToMap(runRecord.employeeName, runRecord.data.coords.latitude, runRecord.data.coords.longitude);
    }
  }

  addMarkerToMap(employeeName:string, lat: number, long: number) {
    console.log("this map object reference ", this.map);
    if (this.map) {
      // if (this.marker) {
      //   this.map.clear();
      // }
      this.map.addMarker({
        title: employeeName,
        icon: 'orange',
        animation: 'DROP',
        position: {
          lat: lat,
          lng: long
        }
      }).then(marker => {
        // this.marker = marker;
        this.map.setCameraTarget({ lat: lat, lng: long });
      });
    }
  }
}
