import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IAceeptMessageConstant } from 'src/app/core/constants/i-accept-msg.constant';
import { UserSubscription } from './../entity/subscription';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { SnackBarService } from '../services/snackbar.service';
import { Package } from '../entity/package';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  showPackageType = true;
  packageList: Package[] = [];
  selectedSub: Package;
  selectedPackagePrice: number;
  noOfusers = 1;
  totalAmount = 0.00;
  validityPeriod: number;
  startDate = new Date();
  endDate;
  currentSubscrption: UserSubscription = null;
  constructor(private userService: UserService, private snackBarService: SnackBarService, private router: NavController) { }

  ngOnInit() {
    this.getSubscrptionList();
    this.currentSubscrption = this.userService.currentSubscrption;
    // console.log(SubscriptionComponent.name, this.userService.currentSubscrption, 'currentSubscrption');
  }


  getSubscrptionList() {
    this.userService.getPackages().subscribe((subList) => {
      this.packageList = subList;
    });
  }


  selectSubscrption(selectedSub: Package) {
    this.showPackageType = false;
    this.selectedSub = selectedSub;
    // console.log(SubscriptionComponent.name, 'this.selectedSub', selectedSub);
  }

  selectSubscrptionPackage(packageType: string, packagerate: number) {
    // console.log(SubscriptionComponent.name, packageType, 'subpack');
    // console.log(SubscriptionComponent.name, packagerate, 'packagerate');
    if (packageType) {
      this.selectedPackagePrice = packagerate;
      const future = new Date();
      if (packageType === IAceeptMessageConstant.PACKAGE_TERM_MONTHLY) {
        this.totalAmount = (this.noOfusers * packagerate * 1);
        // this.getTotalSubscrptionAmount(this.noOfusers, packagerate);
        this.endDate = future.setDate(future.getDate() + IAceeptMessageConstant.MONTHLY);
      }
      if (packageType === IAceeptMessageConstant.PACKAGE_TERM_QUATRALLY) {
        this.totalAmount = (this.noOfusers * packagerate * 3);
        // this.getTotalSubscrptionAmount(this.noOfusers, packagerate);
        this.endDate = future.setDate(future.getDate() + IAceeptMessageConstant.QUATRALLY);
      }
      if (packageType === IAceeptMessageConstant.PACKAGE_TERM_YEARLY) {
        this.totalAmount = (this.noOfusers * packagerate * 12);
        // this.getTotalSubscrptionAmount(this.noOfusers, packagerate);
        this.endDate = future.setDate(future.getDate() + IAceeptMessageConstant.YEARLY);
      }
    }

  }

  getNumberOfUser(noOfUser: number) {
    this.getTotalSubscrptionAmount(noOfUser, this.selectedPackagePrice);
  }

  getTotalSubscrptionAmount(users: number, price: number) {
    if (users && price) {
      this.totalAmount = (users * price * this.validityPeriod);
    }
  }

  continuePayment() {
    // console.log(SubscriptionComponent.name, new Date(this.endDate), 'this.endDate');
    const subscrption = new UserSubscription();
    subscrption.id = null;
    subscrption.amountPaid = this.totalAmount;
    subscrption.clientId = this.userService.currentUser.clientId;
    subscrption.subscribedDate = this.startDate;
    subscrption.subscriptionEndDate = new Date(this.endDate);
    subscrption.type = this.selectedSub.name;
    subscrption.users = this.noOfusers;
    subscrption.id = null;
    this.userService.setSubscrptionDoc(subscrption).subscribe((sub) => {
      if (sub) {
        this.snackBarService.showToaster(IAceeptMessageConstant.SUCESSFULLYSAVESUB);
        this.reDirectionBaseonSubscrption(subscrption);
        this.userService.setCurrentSubscrption(subscrption);
      }
    }, (err) => {
      this.snackBarService.showToaster(err);
    });

    // console.log(SubscriptionComponent.name, subscrption, 'subscrption');
  }


  reDirectionBaseonSubscrption(currentSubscrption) {
    if (currentSubscrption) {
      if (currentSubscrption.type === 'halt' || currentSubscrption.type === 'custom') {
        this.router.navigateRoot(['employer/dashboard/halts']);
      }
      if (currentSubscrption.type === 'patrol') {
        this.router.navigateRoot(['employer/dashboard/patrols']);
      }
    }
  }

}
