import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Customer } from 'src/app/entity/clients';
import { ClientService } from 'src/app/services/client.service';
import { Location } from '@angular/common';
import { User } from 'src/app/entity/user';
import { GoogleMap, GoogleMaps, GoogleMapsEvent, Environment } from '@ionic-native/google-maps/ngx';
import { AppLocationService } from 'src/app/services/app-location.service';
import { Subscription } from 'rxjs';
import { Geoposition } from '@ionic-native/geolocation/ngx';
import { firestore } from 'firebase';
import { LoaderService } from 'src/app/services/loader.service';
import { Contacts, Contact, ContactField, ContactName } from '@ionic-native/contacts/ngx';
import { Platform, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-add-new-customer',
  templateUrl: './add-new-customer.component.html',
  styleUrls: ['./add-new-customer.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AddNewCustomerComponent implements OnInit, OnDestroy {
  userId: string;
  customerForm: FormGroup;
  editCustomer = false; // for editing customer
  documentRefId: string;
  clientId: string;
  currentUser: User;
  customer: Customer;
  customers: Customer[];
  map: GoogleMap;
  locationCoords: any;
  locationSubscription: Subscription;
  marker: any;
  customerLocationSet = false;
  title = 'Create customer';
  stepper1 = true;
  stepper2 = false;
  locationDetails: any;
  btnLoading = false;
  inputLat: number;
  inputLng: number;

  constructor(
    private formBuilder: FormBuilder, public alertController: AlertController, private router: Router, private location: Location,
    private clientService: ClientService, private snackBarService: SnackBarService, private userService: UserService,
    private route: ActivatedRoute, private locationAccuracy: LocationAccuracy, private locationService: AppLocationService,
    private loaderService: LoaderService, private contacts: Contacts, private platform: Platform) {
    this.createCustomerForm();
  }

  ngOnInit() {
    this.getCurrentUser();
    this.route.queryParams.subscribe((params) => {
      if (params['customerId']) {
        this.editCustomer = true;
        this.title = 'Update customer';
        this.getCustomerById(params['customerId']);
      }
    });
    this.checkLocationPermissionsAndTrack();
    this.mobileNumberPatternCheck();
  }

  mobileNumberPatternCheck() {
    this.customerForm.controls.contactNumber.valueChanges.subscribe(value => {
      // console.log(AddNewCustomerComponent.name, 'number has changed:', value.length);
      if (value.length > 10) {
        this.customerForm.controls['contactNumber'].markAsTouched();
      }
    });
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user: User) => this.currentUser = user);
    this.getCustomersOfClient(this.currentUser.clientId);
  }

  getCustomersOfClient(clientId) {
    this.clientService.getCustomersOfClient(clientId).subscribe((customers: Customer[]) => this.customers = customers);
  }

  ngOnDestroy() {
    this.stopTrackingLocation();
  }

  ionViewDidEnter() {
    this.loadMap();
  }

  loadMap() {
    // console.log(AddNewCustomerComponent.name, 'loading map...', this.editCustomer);
    Environment.setEnv({
      API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI',
      API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyC1nqkjqjdMe-406pmbxg2eWwNelmU41HI'
    });
    this.map = GoogleMaps.create('map_canvas');
    if (this.locationDetails) {
      console.log(AddNewCustomerComponent.name, 'previous location');
      this.setCustomerLocation(this.locationDetails.lat, this.locationDetails.lng);
    } else if (!this.editCustomer && this.locationCoords && !this.customerLocationSet) {
      console.log(AddNewCustomerComponent.name, 'new location');
      this.setCustomerLocation(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
    }
    console.log(AddNewCustomerComponent.name, 'map created', this.map);
  }

  setCustomerLocation(lat: number, lng: number) {

    console.log(AddNewCustomerComponent.name, 'setting location on map', lat, lng, this.map);
    this.addMarkerToMap(lat, lng).then(marker => {
      this.customerLocationSet = true;
      this.marker = marker;
      this.map.setCameraTarget({ lat, lng });
      this.map.setCameraZoom(18);
      this.map.addEventListener(GoogleMapsEvent.CAMERA_MOVE).subscribe(data => {
        this.marker.setPosition(data[0].target);
        //this.stopTrackingLocation();
      });
      this.map.addEventListener(GoogleMapsEvent.CAMERA_MOVE_END).subscribe(data => {
        this.marker.setPosition(data[0].target);
      });
      // this.stopTrackingLocation();
    });
  }

  addMarkerToMap(lat: number, long: number): Promise<any> {
    if (this.map) {
      if (this.marker) {
        this.map.clear();
      }
      return this.map.addMarker({
        title: 'you are here',
        icon: 'orange',
        animation: 'DROP',
        position: {
          lat,
          lng: long
        }
      });
    }
  }

  checkLocationPermissionsAndTrack() {
    if (this.platform.is("desktop")) {
      this.startTrackingLocation();
      return;
    }
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        console.log(AddNewCustomerComponent.name, 'location services must be requested');
        // the accuracy option will be ignored by iOS
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => {
            console.log(AddNewCustomerComponent.name, 'Location permission request successful');
            this.startTrackingLocation();
          },
          error => {
            // console.log(AddNewCustomerComponent.name, 'Error requesting location permissions', error);
          }
        );
      } else {
        console.log(AddNewCustomerComponent.name, 'location permissions already granted');
        this.startTrackingLocation();
      }
    }, error => {
      console.log(AddNewCustomerComponent.name, 'error requesting location access' + error);
    });
  }

  async startTrackingLocation() {
    try {
      // this.locationCoords = await this.locationService.getCurrentLocation();
      this.locationSubscription = this.locationService.startLocation().subscribe(loc => {
        console.log("logging locationCoords ", loc);
        this.locationCoords = loc;
        console.log("startTrackingLocation", this.locationCoords, this.map);
        if (this.inputLat && this.inputLng) {
          this.setCustomerLocation(this.inputLat, this.inputLng);
        } else {
          this.setCustomerLocation(this.locationCoords.coords.latitude, this.locationCoords.coords.longitude);
          // console.log(AddNewCustomerComponent.name, 'got new location coords', this.locationCoords);
        }
      });
    } catch (err) {
      this.snackBarService.showToaster("Failed to fetch location", 2000);
    }
  }

  stopTrackingLocation() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
      this.locationSubscription = null;
    }
  }

  getCustomerById(customerId: string) {
    this.clientService.getCustomer(this.currentUser.clientId, customerId).subscribe(customer => {
      this.customer = customer;
      //this.stopTrackingLocation();
      if (this.customer.location) {
        this.setCustomerLocation(this.customer.location.latitude, this.customer.location.longitude);
        // console.log(AddNewCustomerComponent.name, 'got customer location', this.customer.location);
      }
      // console.log(AddNewCustomerComponent.name, 'fetched customer', customer);

      this.customerForm.patchValue({
        id: customer.id,
        companyName: customer.companyName,
        contactName: customer.contactName,
        contactNumber: customer.contactNumber,
        contactEmail: customer.contactEmail ? customer.contactEmail.join() : null,
        address: customer.address,
        location: customer.location
      });
    });
  }

  createCustomerForm() {
    this.customerForm = this.formBuilder.group({
      id: [''],
      companyName: ['', Validators.required],
      contactName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      contactEmail: [''],
      address: ['', [Validators.required]],
      location: [''],
    });
  }

  genrateId() {
    // console.log(AddNewCustomerComponent.name, this.getRandomInt(10, 18));
    const autoGeneraatedId = this.getRandomInt(10, 18);
    this.customerForm.get('id').setValue(autoGeneraatedId);
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) * (23354543)) + min;
  }

  addCustomer() {
    this.btnLoading = true;
    const markerPosition = this.locationDetails;
    const customerFormValues = this.customerForm.value;
    const customer = new Customer();
    customer.id = customerFormValues.id;
    customer.address = customerFormValues.address;
    customer.companyName = customerFormValues.companyName;
    // console.log(AddNewCustomerComponent.name, 'addCustomer() customer email', customerFormValues.contactEmail);
    customer.contactEmail = customerFormValues.contactEmail ? customerFormValues.contactEmail.split(',') : [];
    customer.contactName = customerFormValues.contactName;
    customer.contactNumber = customerFormValues.contactNumber;
    customer.location = new firestore.GeoPoint(markerPosition.lat, markerPosition.lng);
    // console.log(AddNewCustomerComponent.name, 'addCustomer() customer data', customer);
    if (!this.editCustomer) {
      const duplicateCustomerID = this.customers.find((cust) => cust.id === customer.id);
      // console.log(AddNewCustomerComponent.name, 'duplicateCustomerID', duplicateCustomerID);
      if (duplicateCustomerID) {
        this.btnLoading = false;
        this.presentAlert('Customer ID already exist. Kindly provide a differend customer ID');
        return;
      }
    }
    this.saveCustomer(customer);
  }

  saveCustomer(customer: Customer) {
    // console.log(AddNewCustomerComponent.name, 'saveCustomer() customer data', customer);
    if (!this.editCustomer) {
      // console.log(AddNewCustomerComponent.name, customer, 'customer-add');
      this.loaderService.presentLoading('Adding customer data');
      this.clientService.saveCustomer(customer, this.currentUser.clientId).then(() => {
        this.btnLoading = false;
        // console.log(AddNewCustomerComponent.name, 'Customer created successfully');
        this.loaderService.dismissLoading();
        this.snackBarService.showToaster('Successfully added customer data');
        this.location.back();
      }, () => {
        this.loaderService.dismissLoading();
        this.btnLoading = false;
        this.snackBarService.showToaster('Error creating customer');
      });
    } else {
      // console.log(AddNewCustomerComponent.name, customer, 'customer-updating');
      this.loaderService.presentLoading('Updating customer info');
      this.clientService.updateCustomer(this.currentUser.clientId, customer).then((data) => {
        // console.log(AddNewCustomerComponent.name, 'updated');
        this.btnLoading = false;
        this.loaderService.dismissLoading();
        this.snackBarService.showToaster('Successfully updated customer data');
        this.location.back();
      }, (err) => {
        this.btnLoading = false;
        this.loaderService.dismissLoading();
        // console.log(AddNewCustomerComponent.name, err, 'err-updating-customers');
      });
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  addContactFromDevice() {
    // const markerPosition = this.marker.getPosition();
    this.contacts.pickContact().then(
      (contact) => {
        if (contact.phoneNumbers[0].value.indexOf('+91') > -1) {
          contact.phoneNumbers[0].value = contact.phoneNumbers[0].value.substring(3);
          // console.log(AddNewCustomerComponent.name, 'Access to device Contacts!', contact);
          this.customerForm.patchValue({ contactName: contact.displayName, contactNumber: contact.phoneNumbers[0].value });
        } else {
          this.customerForm.patchValue({ contactName: contact.displayName, contactNumber: contact.phoneNumbers[0].value });
        }
      },
      (error: any) => { console.error('Error saving contact.', error); }
    );
  }

  async presentAlert(data) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  moveToStepper2() {
    this.locationDetails = this.marker.getPosition();
    // console.log(AddNewCustomerComponent.name, 'captured location details', this.locationDetails);
    this.locationCoords = null;
    this.customerLocationSet = false;
    this.stepper1 = false;
    this.stepper2 = true;
    this.map.destroy();
  }
  moveToStepper1() {
    // this.checkLocationPermissionsAndTrack();
    this.loadMap();
    this.stepper1 = true;
    this.stepper2 = false;

  }
  onInputLocation() {
    if (this.inputLat && this.inputLng) {
      if (this.map) {
        if (this.marker) {
          this.map.clear();
        }
        this.setCustomerLocation(this.inputLat, this.inputLng);
      }
    }
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
