import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralDashboardComponent } from './general-dashboard/general-dashboard.component';
import { EmployeeGeneralRoutingModule } from './employee.general.routing';
@NgModule({
  imports: [
    CommonModule,
    EmployeeGeneralRoutingModule
  ],
  declarations: [
    GeneralDashboardComponent,
  ],
  // providers:[FCM],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmployeeGeneralModule { }
