import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.services';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from 'src/app/services/loader.service';
import * as d3 from "d3";
import { HaltService } from 'src/app/services/halt.services';
import { EventTemplate } from 'src/app/entity/event.template';
import { Event } from 'src/app/entity/event';
import { PatrolService } from 'src/app/services/patrol.service';
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnDestroy {
  private topLimit: number = 50;
  title: string;
  currentUser: any;
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  btnLoading: boolean;
  hideFilter: boolean;
  patrolRange: boolean;
  haltRange: boolean;
  patrolsLastDay:boolean;
  haltsLastDay:boolean;
  tableHeadColumn1Label: string;
  tableHeadColumn2Label: string;
  nilHeadLabel: string;
  nilHeadValue: string;
  AllCustomers:any[] = [];
  patrols: Event[] = [];
  dataSource: any[] = [];
  filteredActivePatrols: Event[] = [];
  filteredActiveCustomers = {};
  filteredActiveEmployees = {};
  uniqueSites: any[] = [];
  unvisitedSites: any[] = [];
  patrolTemplates: EventTemplate[] = [];
  activePatrolSites: EventTemplate[] = [];
  actNilData: Map<string, number>;
  computedData: any[] = [];
  computedActiveData: any[] = [];
  computedNilData: any[] = [];
  constructor(private loaderService: LoaderService, private userService: UserService,private haltService: HaltService,  private eventService: EventService, private patrolService: PatrolService, private route: ActivatedRoute) { 

  }
  
  ngOnDestroy() {
// 
  }

  ngOnInit() {
    this.patrolRange = this.haltRange = this.patrolsLastDay = this.haltsLastDay = false;
    this.title = "";
    this.tableHeadColumn1Label = "";
    this.tableHeadColumn2Label = "";
    this.btnLoading = false;
    this.route.queryParams.subscribe((params) => {
      if(params && params.patrolRange){
        // console.log("patrols" , params.patrolRange);
        this.patrolRange = true;
        this.tableHeadColumn1Label = "Patrols";
        this.tableHeadColumn2Label = "Beats";
        this.nilHeadLabel = "Patrols";
        this.nilHeadValue = "Nil Patrol Days";
      } else if (params && params.haltRange) {
        // console.log("halts", params.haltRange);
        this.haltRange = true;
        this.tableHeadColumn1Label = "Employee";
        this.tableHeadColumn2Label = "Visits";
        this.nilHeadLabel = "Employee";
        this.nilHeadValue = "Nil Halt Days";
      } else if (params && params.patrolsLastDay) {
        this.patrolsLastDay = true;
        this.title = "Patrol";
        this.tableHeadColumn1Label = "Patrols";
        this.tableHeadColumn2Label = "Beats";
        this.nilHeadLabel = "Patrols";
        this.nilHeadValue = "Beats";
      }
      else if (params && params.haltsLastDay){
        this.haltsLastDay = true;
        this.title = "Halt";
        this.tableHeadColumn1Label = "Employee";
        this.tableHeadColumn2Label = "Visits";
        this.nilHeadLabel = "Employee";
        this.nilHeadValue = "Visits";
      }
      else {
        // do nothing
      }
    });
    this.userService.getCurrentUser().subscribe(async currentUser => {
      this.currentUser = currentUser;

      // this.AllCustomers = await this.eventService.getAllCustomersForAClient(this.currentUser.clientId).toPromise();

      this.getPatrolsOfClient(); // current day patrols only??

      // console.log("Customers for the client => ", this.AllCustomers);
      
      if (this.patrolRange === true || this.haltRange === true ) {
        // do nothing, wait for user to filter        
      }  
      else if(this.patrolsLastDay === true) {
        this.getPatrolsLastDay(this.currentUser.clientId);
      }
      else if(this.haltsLastDay === true) {
        this.getHaltsLastDay(this.currentUser.clientId);
      }
    })
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    this.filterFromDate = new Date(date);
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    this.filterToDateRawValue = date;
    this.filterToDate = new Date(date);
  }

  async getPatrolsLastDay(clientID: string){
    const loader = await this.loaderService.newLoader("Loading, please wait");
    loader.present();

    this.eventService.getAllPatrolsInLast24Hrs(clientID).subscribe(events => {
      // console.log("events length ", events.length);
      if (events.length >0) {
        this.actNilData = new Map<string, number>();
        this.filteredActivePatrols = this.removeDuplicates(events, 'templateId');
        this.groupEventsByCustomer(events);
        this.groupEventsByEmployees(events);

        const temp = Object.keys(this.filteredActiveCustomers);
        this.unvisitedSites = this.uniqueSites.filter((item) => {
          return !temp.find((filteredItem) => filteredItem === item)
        });
        
        this.unvisitedSites.forEach((el) => {
          if (!this.actNilData.has(el)) {
            this.actNilData.set(el, 1);
          }
          else {
            let count = this.actNilData.get(el);
            count++;
            this.actNilData.set(el, count);
          }
        });

        const data = d3.rollup( events, v => v.length , d => d.data.customerName);

        this.uniqueSites.forEach(site => {
        if(this.actNilData.has(site)) {
          // only nil data here
          this.computedData.push({ site: site, count: "0", nilCount: "0.5" });
          this.computedNilData.push({ site: site, nilCount: "0" }); // keeping nil to zeros for last 24 hrs
          
        }
        else { // only active data here
          if(data.has(site)) {
            this.computedData.push({ site: site, count: "" + data.get(site), nilCount: "0" }); // no nil values recorded
            this.computedActiveData.push({ site: site, count: "" + data.get(site)});
          }
        }
        });
        
        const maxValue = d3.max(data.values() as any);
        // console.log("maxValue ", maxValue);
        
        // console.log("test array -> ", this.computedData);

        this.createChart(Number(maxValue), this.computedData, "patrol");
        loader.dismiss();
      }
      else {
        console.log("active ->", this.uniqueSites);

        this.uniqueSites.forEach(site => {
          this.computedData.push({ site: site, count: "0", nilCount: "0.5" });
          this.computedNilData.push({ site: site, nilCount: "0" });              
        });
                
        // console.log("test array -> ", this.computedData);

        this.createChart(1, this.computedData, "patrol");
        loader.dismiss();
      }
    });
  }

  async getHaltsLastDay(clientID: string){
    const loader = await this.loaderService.newLoader("Loading, please wait");
    loader.present();
    let haltEmployees = [];
    this.userService.getAllHaltEmployesBasedOnClientid(clientID).subscribe(employees => {
      employees.forEach(employee => haltEmployees.push(employee.name));
      // console.log(haltEmployees);
    });

    this.haltService.getAllHaltsInLast24Hrs(clientID).subscribe(events => {
      // console.log("events length", events.length);
      if (events.length >0) {
        const data = d3.rollup( events, v => v.length , d => d.createdByUserName);
        this.actNilData = new Map<string, number>();
        this.groupEventsByEmployees(events);
        // console.log("active employees -> ",this.filteredActiveEmployees);
        const temp = Object.keys(this.filteredActiveEmployees);
        this.unvisitedSites = haltEmployees.filter((employee) => {
          return !temp.find((filteredItem) => filteredItem === employee)
        });
        // console.log("unvisited -> ",this.unvisitedSites);

        this.unvisitedSites.forEach((el) => {
          if (!this.actNilData.has(el)) {
            this.actNilData.set(el, 1);
          }
          else {
            let count = this.actNilData.get(el);
            count++;
            this.actNilData.set(el, count);
          }
        });

        haltEmployees.forEach(site => {
          if(this.actNilData.has(site)) {
            // only nil data here
            this.computedData.push({ site: site, count: "0", nilCount: "0.5" });
            this.computedNilData.push({ site: site, nilCount: "0" }); // keeping nil to zeros for last 24 hrs
            
          }
          else { // only active data here
            if(data.has(site)) {
              this.computedData.push({ site: site, count: "" + data.get(site), nilCount: "0" }); // no nil values recorded
              this.computedActiveData.push({ site: site, count: "" + data.get(site) });
            }
          }
        });
        
        const maxValue = d3.max(data.values() as any);
        console.log("maxValue ", maxValue);
        
        console.log("test array -> ", this.computedData);

        this.createChart(Number(maxValue), this.computedData, "halt");
        loader.dismiss();
      }
      else {
        haltEmployees.forEach(site => {
            this.computedData.push({ site: site, count: "0", nilCount: "0.5" });
            this.computedNilData.push({ site: site, nilCount: "0" });
        });
        
        console.log("test array -> ", this.computedData);

        this.createChart(1, this.computedData, "halt");
        loader.dismiss();
      }
  });
}

  createChart(upperBound: number, dataArray: any[], chartTemplate: string) {

    const allSites = dataArray.map(d => d.site);
    // console.log(allSites);

    let valuesG: any[] = [ ];
    valuesG.push("count");
    valuesG.push("nilCount");
    // console.log("valuesG", valuesG);


    // set the dimensions and margins of the graph
    var margin = { top: 50, right: 50, bottom: 300, left: 80 };
    var width = 1000 - margin.left - margin.right;
    var height = 600 - margin.top - margin.bottom;

    if( window.innerWidth <768 ) {
      var margin = { top: window.innerWidth/10 , right: window.innerWidth/10, bottom: window.innerHeight * 0.45, left: window.innerHeight/10  }
      var width = window.innerWidth - margin.left - margin.right;
      var height = window.innerHeight - margin.top - margin.bottom;
    }
    //color
    let color: d3.ScaleOrdinal<string, string, never>;
    let legendAxisDef: string[];
    if ( chartTemplate === "patrol" ) {
      color = d3.scaleOrdinal(['#69b3a2', 'red'])
      .domain(valuesG);
      legendAxisDef = ["actual patrols", "nil patrols"];
    } else  {
      color = d3.scaleOrdinal(['#7e42f5', 'red'])
      .domain(valuesG);
      legendAxisDef = ["actual halts", "nil halts"];
    }

    var paddingVar: number;
    if (dataArray.length < 3){
      paddingVar = 0.7;
      // console.log("paddingVar", paddingVar);
    }
    else if (dataArray.length < 5){
      paddingVar = 0.5;
      // console.log("paddingVar", paddingVar);
    }
    else {
      paddingVar = 0.2;
      // console.log("paddingVar", paddingVar);
    }

    // append the svg object to the body of the page
    var svg = d3.select("#my_dataviz")
    .append("svg")
      // .attr("width", width + margin.left + margin.right)
      // .attr("height", height + margin.top + margin.bottom)
      .attr("viewBox", [0, 0, width+ margin.left + margin.right, height + margin.top + margin.bottom])
    .append("g")
      .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
            
    // X axis
    var x = d3.scaleBand()
    .range([ 0, width ])
    .domain(allSites)
    .padding(paddingVar);

    // Another scale for subgroup position?
    var xSubgroup = d3.scaleBand()
    .domain(valuesG)
    .range([0, x.bandwidth()])
    .padding(0.05)
  
    svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x))
    .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end");

    // Add Y axis
    var y = d3.scaleLinear()
    .domain([0, upperBound])
    .range([height, 0]);

    //use ticks and filter, format to change the y-axis domain values to rounded-off whole numbers
    var yTicks = y.ticks()
    .filter(tick => Number.isInteger(tick));

    svg.append("g")
    .call(d3.axisLeft(y).tickValues(yTicks).tickFormat(d3.format('d')));

 // Bars
    svg.append("g")
       .selectAll("g")
       .data(dataArray)
       .enter()
       .append("g")
       .attr("transform", function(d) { return "translate(" + x(d.site) + ",0)"; })
       .selectAll("rect")
       .data(function(d) { return valuesG.map(function(key) { return {key: key, value: d[key]}; }); })
       .enter().append("rect")
         .attr("x", function(d) { 
          return xSubgroup(d.key); })
         .attr("y", function(d) { return y(d.value); })
         .attr("width", xSubgroup.bandwidth())
         .attr("height", function(d) { return height - y(d.value); })
         .attr("fill", function(d) { return color(d.key); });

    // legend texts for the bars
    var legend = svg.append("g")
       .attr("font-family", "sans-serif")
       .attr("font-size", 10)
       .attr("text-anchor", "end")
       .selectAll("g")
       .data(legendAxisDef)
       .enter().append("g")
         .attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });
   
     legend.append("rect")
         .attr("x", width - 19)
         .attr("width", 19)
         .attr("height", 19)
         .attr("fill", color);
   
     legend.append("text")
         .attr("x", width - 24)
         .attr("y", 9.5)
         .attr("dy", "0.32em")
         .text(function(d) { return d; });
   
  }

  async onFilterBoth() {
    const loader = await this.loaderService.newLoader("Loading, please wait");
    loader.present();
    const formDate = new Date(this.filterFromDate).setHours(0, 0, 0, 0);
    const toDate = new Date(this.filterToDate).setHours(23, 59, 59, 59);
    // this.fetchedData = [];
    if(this.haltRange) {
      let haltEmployees = [];
      this.userService.getAllHaltEmployesBasedOnClientid(this.currentUser.clientId).subscribe(employees => {
        employees.forEach(employee => haltEmployees.push(employee.name));
        // console.log(haltEmployees);
      });
      this.haltService.getAllHaltsOfClientRanged(this.currentUser.clientId, new Date(formDate), new Date(toDate)).subscribe(events => {
        // console.log("events length", events.length);
        // console.log("event-> ", events);
        if(events.length > 0) {
          const data = d3.rollup( events, v => v.length , d => d.createdByUserName);

          //filtering for each day to find nil patrols 
          let start = new Date(formDate);
          let end = new Date(formDate);
          end.setHours(23, 59, 59);
          
          this.actNilData = new Map<string, number>();
          // while loop to iterate on all the data
          while (start <= new Date(toDate)) {
            let dateArray: any[] = [];
            events.forEach(ele => {
              if (new Date(ele.startTime.seconds * 1000 + ele.startTime.nanoseconds / 1000000) >= start && new Date(ele.startTime.seconds * 1000 + ele.startTime.nanoseconds / 1000000) <= end) {
                dateArray.push(ele);
                // testData.push(ele.data.customerName);
              }
            });

            this.groupEventsByEmployees(dateArray);
            // console.log("active employees -> ",this.filteredActiveEmployees);
            const temp = Object.keys(this.filteredActiveEmployees);
            this.unvisitedSites = haltEmployees.filter((employee) => {
              return !temp.find((filteredItem) => filteredItem === employee)
            });
            // console.log("unvisited -> ",this.unvisitedSites);

            this.unvisitedSites.forEach((el) => {
              if (!this.actNilData.has(el)) {
                this.actNilData.set(el, 1);
              }
              else {
                let count = this.actNilData.get(el);
                count++;
                this.actNilData.set(el, count);
              }
            });

            start.setDate(start.getDate() + 1);
            end.setDate(end.getDate() +1);
            
          }

          haltEmployees.forEach(site => {
            if(this.actNilData.has(site)) {
              if(data.has(site)) {
                this.computedData.push({ site: site, count: "" + data.get(site), nilCount: "" + this.actNilData.get(site) });
                this.computedActiveData.push({ site: site, count: "" + data.get(site) });
                this.computedNilData.push({ site: site, nilCount: "" + this.actNilData.get(site) });
              }
              else {
                this.computedData.push({ site: site, count: "0", nilCount: "" + this.actNilData.get(site) });
                this.computedNilData.push({ site: site, nilCount: "" + this.actNilData.get(site) });
              }
            } else {
              if(data.has(site)) {
                this.computedData.push({ site: site, count: "" + data.get(site), nilCount: "0" });
                this.computedActiveData.push({ site: site, count: "" + data.get(site) });
              }
              else {
                this.computedData.push({ site: site, count: "0", nilCount: "0" });
              }
            }
          });
          
          const maxValue = d3.max(Array.of((d3.max(data.values()) as any), (d3.max(this.actNilData.values()) as any) ));
          console.log("maxValue ", maxValue);
          
          console.log("test array -> ", this.computedData);

          this.createChart(Number(maxValue), this.computedData, "halt");
          loader.dismiss();
        }
        else {
          let diff = (Math.round((toDate - formDate) / (1000 * 60 * 60 * 24)));
          this.groupEventsByEmployees([]);
          // console.log("active employees -> ",this.filteredActiveEmployees);
          const temp = Object.keys(this.filteredActiveEmployees);
          this.unvisitedSites = haltEmployees.filter((employee) => {
            return !temp.find((filteredItem) => filteredItem === employee)
          });
          // console.log("unvisited -> ",this.unvisitedSites);

          haltEmployees.forEach(site => {
            if(this.unvisitedSites.includes(site)) {
              this.computedData.push({ site: site, count: "0", nilCount: "" + diff });
            } 
            else {
              this.computedData.push({ site: site, count: "0", nilCount: "0" });
            }
          });
  
          const maxValue = d3.max([diff]);
          console.log("maxValue ", maxValue);
          
          console.log("test array -> ", this.computedData);

          this.createChart(Number(maxValue), this.computedData, "patrol");
          loader.dismiss();
        }
      })
    } else {
      this.eventService.getAllPatrolsForADateRange(this.currentUser.clientId, new Date(formDate), new Date(toDate)).subscribe((events:Event[]) => {
        // console.log("events length", events.length);
        if(events.length > 0) {
          // let testData = [];
          //filtering for each day to find nil patrols 
          let start = new Date(formDate);
          let end = new Date(formDate);
          end.setHours(23, 59, 59);
          
          this.actNilData = new Map<string, number>();
          // while loop to iterate on all the data
          while (start <= new Date(toDate)) {
            let dateArray: Event[] = [];
            events.forEach(ele => {
              if (new Date(ele.startTime.seconds * 1000 + ele.startTime.nanoseconds / 1000000) >= start && new Date(ele.startTime.seconds * 1000 + ele.startTime.nanoseconds / 1000000) <= end) {
                dateArray.push(ele);
                // testData.push(ele.data.customerName);
              }
            });
            
            this.filteredActivePatrols = this.removeDuplicates(dateArray, 'templateId');
            this.groupEventsByCustomer(dateArray);
            this.groupEventsByEmployees(dateArray);

            const temp = Object.keys(this.filteredActiveCustomers);
            this.unvisitedSites = this.uniqueSites.filter((item) => {
              return !temp.find((filteredItem) => filteredItem === item)
            });
            
            this.unvisitedSites.forEach((el) => {
              if (!this.actNilData.has(el)) {
                this.actNilData.set(el, 1);
              }
              else {
                let count = this.actNilData.get(el);
                count++;
                this.actNilData.set(el, count);
              }
            });

            start.setDate(start.getDate() + 1);
            end.setDate(end.getDate() +1);
            
          }

          const data = d3.rollup( events, v => v.length , d => d.data.customerName);

          this.uniqueSites.forEach(site => {
            if(this.actNilData.has(site)) {
              if(data.has(site)) {
                this.computedData.push({ site: site, count: "" + data.get(site), nilCount: "" + this.actNilData.get(site) });
                this.computedActiveData.push({ site: site, count: "" + data.get(site) });
                this.computedNilData.push({ site: site, nilCount: "" + this.actNilData.get(site) });
              }
              else {
                this.computedData.push({ site: site, count: "0", nilCount: "" + this.actNilData.get(site) });
                this.computedNilData.push({ site: site, nilCount: "" + this.actNilData.get(site) });
              }
            } else {
              if(data.has(site)) {
                this.computedData.push({ site: site, count: "" + data.get(site), nilCount: "0" });
                this.computedActiveData.push({ site: site, count: "" + data.get(site) });
              }
              else {
                this.computedData.push({ site: site, count: "0", nilCount: "0" });
              }
            }
          });
          
          const maxValue = d3.max([(d3.max(data.values()) as any), (d3.max(this.actNilData.values()) as any)]);
          // console.log("maxValue ", maxValue);
          
          // console.log("test array -> ", this.computedData);

          this.createChart(Number(maxValue), this.computedData, "patrol");
          loader.dismiss();
        }

        else {
          // calculate the number of days and add that total to all the active patrols
          let diff = (Math.round((toDate - formDate) / (1000 * 60 * 60 * 24)));
          this.filteredActivePatrols = this.removeDuplicates([], 'templateId');
          this.groupEventsByCustomer([]);
          this.groupEventsByEmployees([]);

          const temp = Object.keys(this.filteredActiveCustomers);
          this.unvisitedSites = this.uniqueSites.filter((item) => {
            return !temp.find((filteredItem) => filteredItem === item)
          });

          this.uniqueSites.forEach(site => {
            if(this.unvisitedSites.includes(site)) {
                  this.computedData.push({ site: site, count: "0", nilCount: "" + diff });
            } 
            else {
                this.computedData.push({ site: site, count: "0", nilCount: "0" });
            }
          });
          
          const maxValue = d3.max([diff]);
          // console.log("maxValue ", maxValue);
          
          // console.log("test array -> ", this.computedData);

          this.createChart(Number(maxValue), this.computedData, "patrol");
          loader.dismiss();
        }
      })
    }
  }

  removeDuplicates(originalArray, prop) {
    const newArray = [];
    const lookupObject = {};
    for (var i in originalArray) { 
      lookupObject[originalArray[i][prop]] = originalArray[i]; 
    }
    for (i in lookupObject) { 
      newArray.push(lookupObject[i]);
     }
    return newArray;  
  }
    
  getUnVisitedSiteDetails() {
    const temp = Object.keys(this.filteredActiveCustomers);
    this.unvisitedSites = this.uniqueSites.filter((item) => {
      return !temp.find((filteredItem) => filteredItem === item)
    });
    // console.log(this.unvisitedSites);
  }

  getPatrolsOfClient() {
    this.getPatrolTemplatesOfClient();
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    this.patrolService.getAllPatrolsOfClient(this.currentUser.clientId, today).subscribe(patrols => {
      // console.log("patrols ->" , patrols);
      this.loaderService.dismissLoading();
      this.patrols = patrols;
      this.dataSource = patrols.slice(0, this.topLimit);
      this.filteredActivePatrols = this.removeDuplicates(this.patrols, 'templateId');
      this.groupEventsByCustomer(this.patrols);
      this.groupEventsByEmployees(this.patrols);
      this.getUnVisitedSiteDetails();
      });
    }

    async getPatrolTemplatesOfClient() {
      this.eventService.getAllPatrolTemplatesOfClient(this.currentUser.clientId).subscribe(templates => {
      //  console.log("templates ->", templates);
       templates.map((template) => {
        if (template.isActive === true) {
          if (!this.uniqueSites.find((item) => item === template.customerName)) {
          this.uniqueSites.push(template.customerName);
          }
        }
       })
       this.patrolTemplates = templates;
       this.activePatrolSites = templates.filter((template) => template.isActive === true);
       });
    }
       
    groupEventsByCustomer(patrols: Event[]) {
      this.filteredActiveCustomers = patrols.reduce((values, patrol) => {
       (values[patrol.data['customerName']] = values[patrol.data['customerName']] || []).push(patrol);
       return values;
      }, {});
      // console.log('Filtered customers', this.filteredActiveCustomers);
      }

    groupEventsByEmployees(patrols: Event[]) {
      this.filteredActiveEmployees = patrols.reduce((values, patrol) => {
       (values[patrol['createdByUserName']] = values[patrol['createdByUserName']] || []).push(patrol);
       return values;
      }, {});
      // console.log('Filtered employees', this.filteredActiveEmployees);
    }

}