import { NavController, PopoverController, AlertController, ModalController, MenuController } from "@ionic/angular";
import { SnackBarService } from "src/app/services/snackbar.service";
import { ClientService } from "src/app/services/client.service";
import { PatrolService } from "src/app/services/patrol.service";
import { UserService } from "src/app/services/user.service";
import { OnInit, EventEmitter } from "@angular/core";
import { EventService } from "src/app/services/event.services";
import { UploadService } from "src/app/services/upload.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { File } from "@ionic-native/file/ngx";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";
import {
  MediaCapture,
  MediaFile,
  CaptureError,
  CaptureImageOptions,
  CaptureVideoOptions,
  CaptureAudioOptions,
} from "@awesome-cordova-plugins/media-capture/ngx";
import { Router, ActivatedRoute } from "@angular/router";
import { LoaderService } from "src/app/services/loader.service";
import { MapService } from "src/app/services/map-data";
import { Component } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Location } from "@angular/common";
import { Upload } from "src/app/entity/upload";
import { HaltService } from "src/app/services/halt.services";
import { IAceeptMessageConstant } from "src/app/core/constants/i-accept-msg.constant";
import { Customer } from "src/app/entity/clients";
import { Incident } from "src/app/entity/incident";
import { IncidentService } from "src/app/services/incident.service";
import { User } from "src/app/entity/user";
import { Device } from "@ionic-native/device/ngx";
import { CreateHaltComponent } from "src/app/employee-halt/create-halt/create-halt.component";
import { Platform } from "@ionic/angular";

@Component({
  selector: "app-report-incident",
  templateUrl: "./report-incident.component.html",
  styleUrls: ["./report-incident.component.scss"],
  providers: [UploadService, MediaCapture],
})
export class ReportIncidentComponent implements OnInit {
  title = "Incidents";
  backBtnposition = "start";
  webview: any;
  currentUser: User;
  uploadedImageUrlList = [];
  uploadedVideoUrlList = [];
  uploadedAudioUrlList = [];
  selectedCustomerId = "";
  incident: Incident;
  reenableButton = new EventEmitter<boolean>(false);
  customersList = [];

  constructor(
    private userService: UserService,
    private incidentService: IncidentService,
    private clientService: ClientService,
    private uploadService: UploadService,
    private snackBarService: SnackBarService,
    private angularFirestore: AngularFirestore,
    private file: File,
    private camera: Camera,
    public alertController: AlertController,
    public popoverController: PopoverController,
    public modalController: ModalController,
    private loaderService: LoaderService,
    private location: Location,
    private mediaCapture: MediaCapture,
    private plt: Platform
  ) { }

  ngOnInit(): void {
    this.incident = new Incident();
    this.loadData();
  }

  async onSubmit() {
    this.incident.clientId = this.currentUser.clientId;
    this.incident.createdByUser = this.currentUser.id;
    this.incident.createdByUserName = this.currentUser.name;
    this.incident.createdTime = new Date();
    this.incident.imageAttachments = this.uploadedImageUrlList;
    this.incident.videoAttachments = this.uploadedVideoUrlList;
    this.incident.audioAttachments = this.uploadedAudioUrlList;
    this.loaderService.showLoader();
    await this.incidentService.createIncident(this.incident);
    this.loaderService.hideLoader();
    this.navigateBack();
  }

  navigateBack() {
    this.location.back();
  }

  async loadData() {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    await this.getCustomersList();
  }

  async getCustomersList() {
    this.customersList = await this.clientService.getCustomersOfClient(this.currentUser.clientId).toPromise();
    this.customersList.sort((customerA: Customer, customerB: Customer) => {
      if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) {
        return 0;
      }
      return customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase() ? 1 : -1;
    });
  }

  onSelectCustomer(event) {
    if (event.value) {
      const customer = this.customersList.find((item) => item.id === event.value.id);
      this.incident.customerId = customer.id;
      this.incident.customerName = customer.companyName;
    }
  }

  captureIncidentImage() {
    const options: CameraOptions = {
      quality: 20,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
    };

    this.camera.getPicture(options).then(
      (imagePath) => {
        const currentName = imagePath.substr(imagePath.lastIndexOf("/") + 1);
        const correctPath = imagePath.substr(0, imagePath.lastIndexOf("/") + 1);
        const fileExtension = this.getFileExtension(currentName);
        const fileName = this.angularFirestore.createId() + "." + fileExtension;
        this.copyFileToLocalDir(correctPath, currentName, fileName).then((filePath) => {
          this.startUpload(filePath, fileName);
        });
      },
      (err) => {
        window.alert(err.message);
      }
    );
  }

  imageUpload(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      //this.startUpload(file);
    }
  }

  onClickBackBtn() {
    this.location.back();
  }

  startUpload(filePath: any, fileName: string) {
    // storage path
    const imagePath = `events/${this.currentUser.id}/${fileName}`;
    const upload = new Upload();
    upload.imagePath = imagePath;
    upload.filePath = filePath;
    upload.name = fileName;
    this.loaderService.presentLoading("Uploading image");
    this.uploadService.fileUpload(upload).subscribe((imageData) => {
      this.loaderService.dismissLoading();
      if (imageData) {
        this.snackBarService.showToaster("Image uploaded successfully!");
        this.uploadedImageUrlList.push(imageData);
      }
    });
  }

  deleteImage(url) {
    const index = this.uploadedImageUrlList.indexOf(url);
    // to remove locally
    this.uploadedImageUrlList.splice(index, 1);
    // delete in firebase
    this.uploadService.deleteFileStorage(url).then(() => {
      console.log("🚀 ~ file: report-incident.component.ts ~ line 183 ~ ReportIncidentComponent ~ this.uploadService.deleteFileStorage ~ deleteFileStorage");
    });
  }

  captureIncidentVideo() {
    let options: CaptureVideoOptions = { limit: 1 };
    this.mediaCapture.captureVideo(options).then(
      (data: MediaFile[]) => {
        const fileName = data[0].fullPath.substr(data[0].fullPath.lastIndexOf("/") + 1);
        const fileExtension = this.getFileExtension(fileName);
        const newFileName = this.angularFirestore.createId() + "." + fileExtension;
        this.videoUpload(data[0].fullPath, newFileName);
      },
      (err) => window.alert(err.message)
    );
  }

  videoUpload(filePath: any, newFileName: string) {
    const videoPath = `events/${this.currentUser.id}/${newFileName}`;
    const upload = new Upload();
    upload.videoPath = videoPath;
    upload.filePath = filePath;
    upload.name = newFileName;
    this.loaderService.presentLoading("Uploading video");
    this.uploadService.videoFileUpload(upload).subscribe((videoData) => {
      this.loaderService.dismissLoading();
      if (videoData) {
        this.snackBarService.showToaster("Video uploaded successfully!");
        this.uploadedVideoUrlList.push(videoData);
      }
    });
  }

  captureIncidentAudio() {
    let options: CaptureAudioOptions = { limit: 1 };
    this.mediaCapture.captureAudio(options).then(
      (data: MediaFile[]) => {
        const fileName = data[0].fullPath.substr(data[0].fullPath.lastIndexOf("/") + 1);
        const fileExtension = this.getFileExtension(fileName);
        const newFileName = this.angularFirestore.createId() + "." + fileExtension;
        this.audioUpload(data[0].fullPath, newFileName);
      },
      (err) => window.alert(err.message)
    );
  }

  audioUpload(filePath: any, newFileName: string) {
    const audioPath = `events/${this.currentUser.id}/${newFileName}`;
    const upload = new Upload();
    upload.audioPath = audioPath;
    upload.filePath = filePath;
    upload.name = newFileName;
    this.loaderService.presentLoading("Uploading audio");
    this.uploadService.audioFileUpload(upload).subscribe((audioData) => {
      this.loaderService.dismissLoading();
      if (audioData) {
        this.snackBarService.showToaster("Audio uploaded successfully!");
        this.uploadedAudioUrlList.push(audioData);
      }
    });
  }

  getFileExtension(filename) {
    const extension = filename.split(".").pop();
    return extension;
  }

  copyFileToLocalDir(path, fileName, newFileName): Promise<string> {
    return new Promise((resolve, reject) => {
      this.file.copyFile(path, fileName, this.file.dataDirectory, newFileName).then(
        (success) => {
          let filePath = this.file.dataDirectory + newFileName;
          resolve(filePath);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  onAddAttachment(value) {
    if (value == "image") {
      this.captureIncidentImage();
    } else if (value == "audio") {
      this.captureIncidentAudio();
    } else if (value == "video") {
      this.captureIncidentVideo();
    }
  }
}
