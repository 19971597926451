import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { firestore } from "firebase";
import { AngularFirestore } from "@angular/fire/firestore";
import { AlertController } from '@ionic/angular';
import { FireDrillCallService } from 'src/app/services/fire-drill-call.service';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    
    constructor(public toastController: ToastController,private afs: AngularFirestore,
        private fireDrillCallService: FireDrillCallService, private alertController: AlertController) {

    }

    async showToaster(message: string, duration = 2000) {
        const toast = await this.toastController.create({
            message,
            position: 'top',
            duration: 3000,
            buttons: [
                {
                    side: 'end',
                    icon: 'checkmark-circle',
                }]
        });
        toast.present();
    }

    async showAlert(){
        const alert = await this.alertController.create({
            header: 'Alert',
            message: "Response has been recorded",
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  alert.dismiss();
                }
              }
            ],
            cssClass: 'p-3',
          });
          await alert.present();
    }

    async showFireDrillToaster(message: string, loc, docId, subCollectionId) {
        const toast = await this.toastController.create({
            message,
            position: 'top',
            buttons: [
                {
                    text: 'Respond',
                    side: 'end',
                    handler: () =>{
                        console.log("sending response");
                        this.firestoreWrite(docId, subCollectionId, loc);
                        this.fireDrillCallService.toasterShown = false;
                        this.showAlert();
                        toast.dismiss();
                        // localStorage.setItem("responseStatus", "recorded");
                        // localStorage.removeItem("fireDrillDocId");
                        // localStorage.removeItem("responseDocId");
                    }
                }],
            color: 'favorite',
            cssClass: 'fire-toast'
        });
        await toast.present();
    }

    async showToasterMiddle(message: string) {
        const toast = await this.toastController.create({
            message,
            position : 'middle',
            duration: 4000,
            buttons: [
                {
                    side: 'end'
                }
            ],
            cssClass: 'wait-toast'
        });
        toast.present();
    }

    hideToast() {
        return this.toastController.dismiss();
    }

    firestoreWrite(docId, subCollectionId, locCoords) {
        let respRef = this.afs.collection('fireAlerts').doc(docId).collection('responses').doc(subCollectionId);
        console.log("responses collection reference ", respRef);
        respRef.update({
          data: {
            "coords": new firestore.GeoPoint(locCoords.coords.latitude, locCoords.coords.longitude),
            "time": firestore.FieldValue.serverTimestamp()
          },
          responded: true,
        });
      }
}
