import { Injectable, NgZone, Query } from "@angular/core";
import { LocationAccuracy } from "@ionic-native/location-accuracy/ngx";
import { Geolocation, Geoposition } from "@ionic-native/geolocation/ngx";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { EventTemplate } from "../entity/event.template";
import { IAceeptMessageConstant } from "../core/constants/i-accept-msg.constant";
import * as geofirex from "geofirex";
import * as firebase from "firebase";
import { GeoFireClient, FirePoint } from "geofirex";
import { find, takeUntil, takeWhile, timeout } from "rxjs/operators";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppLocationService {
  private readonly isLocationSrc = new Subject<boolean>();
  eventTemplateCollection: AngularFirestoreCollection<EventTemplate>;
  geo: GeoFireClient;
  constructor(private geoLocation: Geolocation, private angularFirestoreDb: AngularFirestore) {
    this.geo = geofirex.init(firebase);
  }

  isLocation() {
    return this.isLocationSrc.asObservable();
  }

  startLocation() {
    return this.geoLocation.watchPosition({ enableHighAccuracy: true });
  }

  endLocation() {
    this.isLocationSrc.next(false);
  }

  getLiveLocation() {
    return this.geoLocation.watchPosition({ enableHighAccuracy: true });
  }

  async getCurrentLocation() {
    return new Promise<Geoposition>((resolve, reject) => {
      let location = null;
      this.geoLocation
        .watchPosition({ enableHighAccuracy: true })
        .pipe(
          find((loc: any) => {
            console.log("location service: checking location", loc);
            return loc.coords.accuracy < 100;
          })
        )
        .subscribe(
          (loc: any) => {
            console.log("location service: got new location", loc);
            location = loc;
            resolve(location);
          },
          (error) => {
            console.log("location service: error fetching location", error);
            reject(null);
          },
          () => {
            console.log("location service: final coords", location);
            //resolve(location);
          }
        );
    });
  }

  getPatrolGeoLocation(clientId: string, lat, lng): Observable<any> {
    const center = this.geo.point(lat, lng);
    // console.log('center', center);
    const firestoreRef = this.angularFirestoreDb.firestore.collectionGroup(IAceeptMessageConstant.COLLECTION_EVENT_ITEMS).where("clientId", "==", clientId);
    const query = this.geo.query(firestoreRef);
    return query.within(center, 0.5, "geoData");
  }

  async getCurrentLocationPromise() {
    return await this.geoLocation.getCurrentPosition({ enableHighAccuracy: true, maximumAge: 5000 });
  }
}
