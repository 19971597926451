import { NavController, AlertController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { Customer } from 'src/app/entity/clients';
import { SnackBarService } from './../../services/snackbar.service';
import { ClientService } from 'src/app/services/client.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/entity/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoaderService } from 'src/app/services/loader.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { ExcelService } from 'src/app/services/excel.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  customerList: Customer[] = [];
  userId;
  currentClient: User;
  searchString: string;
  private topLimit: number = 100;
  dataSource: any = [];
  isMobile: boolean;
  
  constructor(
    private clientService: ClientService, private userService: UserService,
    private snackBarService: SnackBarService, private alertController: AlertController,
    private router: NavController,
    public fireAuth: AngularFireAuth, private loaderService: LoaderService,
    private excelService: ExcelService, private platform: Platform) {
  }

  ngOnInit() {
    this.getCurrentUser();
    this.isMobile = !this.platform.is('desktop');
  }

  loadData(event) {
    setTimeout(() => {
      this.topLimit += 100;
      this.dataSource = this.customerList.slice(0, this.topLimit);
      event.target.complete();
      if (this.dataSource.length === this.customerList.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  getCurrentUser() {
    this.loaderService.presentLoading('Loading customers list');
    this.userService.getCurrentUser().subscribe((user: User) => {
      this.loaderService.dismissLoading();
      if (user) {
        this.currentClient = user;
        this.fetchCustomerList(user.clientId);
      }
    });
  }

  fetchCustomerList(clientId) {
    if (clientId) {
      this.clientService.getCustomersOfClient(clientId).subscribe(customerList => {
        this.customerList = customerList;
        console.log("customerList ", customerList);
        this.customerList.sort((customerA: Customer, customerB: Customer) => {
          if (customerA.companyName.toLowerCase() === customerB.companyName.toLowerCase()) { return 0; }
          return (customerA.companyName.toLowerCase() > customerB.companyName.toLowerCase()) ? 1 : -1;
        });
        this.dataSource = this.customerList.slice(0, this.topLimit);
      });
    }
    this.loaderService.dismissLoading();
  }

  deleteCustomer(customer: Customer) {
    this.loaderService.presentLoading('Deleting customer data');
    this.clientService.deleteCustomer(this.currentClient.clientId, customer.id).then(() => {
      this.loaderService.dismissLoading();
      this.snackBarService.
        showToaster(`Successfully deleted the customer ${customer.companyName}`);
    });
  }

  editCustomer(customer: Customer) {
    this.router.navigateRoot(['/employer/create-customer'], {
      queryParams: {
        customerId: customer.id,
        edit: true,
      }
    });
  }

  onExportCustomers() {
    const customersArray = [];
    this.customerList.map(customer => {
      customersArray.push({
        name: customer.companyName,
        contactName: customer.contactName,
        contactNumber: customer.contactNumber,
        address: customer.address
      });
    });
    this.excelService.exportCustomersToExcel(customersArray, 'CustomersList', this.isMobile);

  }

  async presentAlert(data, customer) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.deleteCustomer(customer);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }
}



