import { Component, OnInit } from '@angular/core';
import { User } from '../entity/user';
import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
import { TravelHistory } from '../entity/travelHistory';

@Component({
  selector: 'app-employee-distance-tracker',
  templateUrl: './employee-distance-tracker.component.html',
  styleUrls: ['./employee-distance-tracker.component.scss'],
})
export class EmployeeDistanceTrackerComponent implements OnInit {

  backBtnposition = 'start';
  title = 'Distance Tracker';
  currentUser: User;
  filterFromDate: Date;
  filterToDate: Date;
  filterFromDateRawValue: any;
  filterToDateRawValue: any;
  hideFilter: boolean;
  showFilteredReports: boolean;
  btnLoading = false;
  noDataFound = false;
  travelDistanceList: TravelHistory[] = [];
  travelDistanceDisplayList: TravelHistory[] = [];

  constructor(
    private loaderService: LoaderService,
    private userService: UserService,
  ) {
    this.filterFromDate = new Date();
    this.filterFromDate.setMonth(this.filterFromDate.getMonth() - 1);
    this.filterToDate = new Date();
    this.filterFromDateRawValue = this.filterFromDate.toISOString();
    this.filterToDateRawValue = this.filterToDate.toISOString();
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  get formattedFromDate() {
    return this.filterFromDateRawValue;
  }

  set formattedFromDate(date) {
    this.filterFromDateRawValue = date;
    //// console.log(EmployeeDistanceTrackerComponent.name, 'from date val : ', new Date(date));
    this.filterFromDate = new Date(date);
    //// console.log(EmployeeDistanceTrackerComponent.name, 'setting from date', this.filterFromDate, date);
  }

  get formattedToDate() {
    return this.filterToDateRawValue;
  }

  set formattedToDate(date) {
    this.filterToDateRawValue = date;
    //// console.log(EmployeeDistanceTrackerComponent.name, 'to date val : ', date);
    this.filterToDate = new Date(date);
    //// console.log(EmployeeDistanceTrackerComponent.name, 'setting to date', this.filterToDate, date);
  }

  onFilter() {
    //// console.log(EmployeeDistanceTrackerComponent.name, 'from: ', this.filterFromDate, ' to: ', this.filterToDate);
    this.hideFilter = !this.hideFilter;
    this.btnLoading = true;
    this.showFilteredReports = !this.showFilteredReports;
    this.loaderService.presentLoading('Loading distance covered logs');
    this.getTravelHistory(this.currentUser.clientId, this.currentUser.id, this.filterFromDate, this.filterToDate);
  }

  getTravelHistory(clientId, employeeId, fromDate, toDate) {
    this.userService.getTravelHistoryOfEmployeeById(clientId, employeeId, fromDate, toDate).subscribe((data) => {
      this.btnLoading = false;
      this.loaderService.dismissLoading();
      this.travelDistanceList = [];
      this.travelDistanceDisplayList = [];
      if (data && data.length > 0) {
        this.travelDistanceList = data;
        this.travelDistanceDisplayList = this.travelDistanceList;
        //// console.log(EmployeeDistanceTrackerComponent.name, 'travelDistanceList', this.travelDistanceList);
        this.noDataFound = false;
      } else if (data.length <= 0) {
        this.noDataFound = true;
      }
    });
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      //// console.log(EmployeeDistanceTrackerComponent.name, 'current user', this.currentUser);
    });
  }

  getDistanceCoveredinKms(distanceTravelled) {
    const distanceinMtrs = parseInt(distanceTravelled, 10);
    return (distanceinMtrs / 1000).toFixed(1) + ' ' + 'kms';
  }

}
