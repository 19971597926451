import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { EventTemplate, PitStop } from 'src/app/entity/event.template';
import { Event } from 'src/app/entity/event';
import { PatrolService } from 'src/app/services/patrol.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { EventService } from 'src/app/services/event.services';

@Component({
  selector: 'app-patrol-details-modal',
  templateUrl: './patrol-details-modal.component.html',
  styleUrls: ['./patrol-details-modal.component.scss'],
})
export class PatrolDetailsModalComponent implements OnInit {

  @Input() patrol: Event;
  @Input() template: EventTemplate;
  currentUser: User;
  pitstops: Event[];
  pitstopsList: PitStop[] = [];

  slideOpts = {
    // initialSlide: 1,
    speed: 100,
  };
  constructor(
    public modalController: ModalController, private patrolService: PatrolService,
    private userService: UserService, private eventService: EventService) {
  }

  ngOnInit() {
    this.getCurrentUser();
    this.getPitstopsOfPatrol(this.patrol);
    // // console.log(PatrolDetailsModalComponent.name, 'patrol', this.patrol);
    // console.log(PatrolDetailsModalComponent.name, 'template', this.template);
    if (this.template) {
      this.getPitstopsList(this.template.id);
    }
    // console.log(this.patrol, 'patrol');
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  getPitstopsOfPatrol(patrol) {
    console.log(patrol, 'patrol');
    this.patrolService.getPitStopsForPatrol(patrol.templateId, patrol.startTime.toDate(),
      patrol.endTime ? patrol.endTime.toDate() : null)
      .subscribe(pitstops => {
        console.log(pitstops, 'pitstopss');
        this.pitstops = pitstops;
      });
  }

  getPitstopsList(patrolId: string) {
    this.eventService.getPitstopsOfPatrol(patrolId).subscribe(pitstops => {
      if (pitstops.length) {
        this.pitstopsList = pitstops;
        this.pitstopsList = this.pitstopsList.filter(pitstop => pitstop.isActive);
        this.pitstopsList.sort((pitstopA: PitStop, pitstopB: PitStop) => {
          if (pitstopA.name.toLowerCase() === pitstopB.name.toLowerCase()) { return 0; }
          return (pitstopA.name.toLowerCase() > pitstopB.name.toLowerCase()) ? 1 : -1;
        });
      }
      // console.log(PatrolDetailsModalComponent.name, 'pitstopsList', this.pitstopsList);
    });
  }

  getPitstopName(templateItemId: string) {
    const pitstop = this.pitstopsList.find(item => item.id === templateItemId);
    // // console.log(PatrolDetailsModalComponent.name, 'pitstop name', pitstop.name);
    return pitstop ? pitstop.name : 'Unknown';
  }

  getPitstop(pitstopId: string): Event {
    // // console.log(PatrolDetailsModalComponent.name, 'getPitstop pitsop ID', pitstopId);
    // // console.log(PatrolDetailsModalComponent.name, 'getPitstop pitstops', this.pitstops);
    if (this.pitstops) {
      return this.pitstops.find(item => item.templateItemId === pitstopId);
    } else {
      return null;
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  getLocationErrorConfirmation(templateItemId) {
    // // console.log(PatrolDetailsModalComponent.name, 'location error pitstop templateItemId', templateItemId);
    if (templateItemId && this.patrol.data.errorPitstops) {
      return this.patrol.data.errorPitstops.find((item) => item === templateItemId) ? true : false;
    } else {
      return false;
    }
  }

  getMissedPitstopConfirmation(templateItemId) {
    // console.log(templateItemId, 'templateItemId');
    // // console.log(PatrolDetailsModalComponent.name, 'missed pitstop templateItemId', templateItemId);
    if (templateItemId && this.patrol.data.missedPitstops) {
      return this.patrol.data.missedPitstops.find((item) => item === templateItemId) ? true : false;
    } else {
      return false;
    }
  }

  getImagesList(pitstopId) {
    // // console.log(PatrolDetailsModalComponent.name, 'getImagesList() pitstop id', pitstopId);
    // // console.log(PatrolDetailsModalComponent.name, 'getImagesList() pitstops', this.pitstops);
    return this.pitstops.find((item) => item.templateItemId === pitstopId);
  }

  getTimeinHHMMFormat(mins) {
    const num = mins;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = Math.round(seconds);
    if (rhours >= 0) {
      return rhours + ' ' + 'h' + ' ' + ':' + ' ' + rminutes + ' ' + 'm' + ' ' + ':' + ' ' + rseconds + ' ' + 's';
    } else {
      return 0 + ' ' + 'h' + ' ' + ':' + ' ' + 0 + ' ' + 'm' + ' ' + ':' + ' ' + 0 + ' ' + 's';
    }
  }

  getCreatedByUserName(createdByUserId) {
    // // console.log(PatrolDetailsModalComponent.name, 'userId', createdByUserId);
    // console.log('pitstops', this.pitstops);
    if (this.pitstops) {
      const res = this.pitstops.find((item) => item.createdByUser === createdByUserId);
      // console.log('pitstopres', res);
      if (res) {
        return res.createdByUserName;
      } else {
        return 'UNKNOWN';
      }
    } else {
      return 'UNKNOWN';
    }
  }

  getPitstopNotes(pitstopId) {
    if (this.pitstops) {
      const pitStop = this.pitstops.find((item) => item.templateItemId === pitstopId);
      // // console.log('pitstop notes', pitStop);
      if (pitStop) {
        return pitStop ? pitStop.data.notes : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
