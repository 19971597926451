import { NavController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { EventTemplate } from 'src/app/entity/event.template';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entity/user';
import { EventService } from 'src/app/services/event.services';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/entity/clients';
import { take } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-patrols-list',
  templateUrl: './patrols-list.component.html',
  styleUrls: ['./patrols-list.component.scss'],
})
export class PatrolsListComponent implements OnInit {
  patrolTemplates: EventTemplate[] = [];
  customerList: Customer[] = [];
  currentUser: User;
  customer: Customer;
  searchString: string;
  private topLimit: number = 30
  dataSource: any = [];
  constructor(private userService: UserService, private eventService: EventService, private router: NavController,
    private alertController: AlertController, private clientService: ClientService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.fetchPatrolTemplatesOfClient();
  }

  loadData(event) {
    setTimeout(() => {
      this.topLimit += 30;
      this.dataSource = this.patrolTemplates.slice(0, this.topLimit);
      event.target.complete();
      if (this.dataSource.length === this.patrolTemplates.length) {
        event.target.disabled = true;
      }
    }, 500);
  }


  // getCustomerById(customerId) {
  //   this.clientService.getCustomerById(customerId).pipe(take(1)).subscribe(customer => this.customerList[customerId] = customer);
  // }

  getCustomerById(clientId, customerId) {
    // console.log(PatrolsListComponent.name, 'getCustomerById() clientId customerId', clientId, customerId);
    this.clientService.getCustomer(clientId, customerId).subscribe((customer) => {
      this.customer = customer;
      // console.log(PatrolsListComponent.name, 'getCustomerById() customer', this.customer);
    });
  }

  fetchPatrolTemplatesOfClient() {
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.eventService.getAllPatrolTemplatesOfClient(this.currentUser.clientId).subscribe(patrolTemplates => {
        this.patrolTemplates = patrolTemplates;
        this.patrolTemplates.sort((patrolA: EventTemplate, patrolB: EventTemplate) => {
          if (patrolA.name.toLowerCase() === patrolB.name.toLowerCase()) { return 0; }
          return (patrolA.name.toLowerCase() > patrolB.name.toLowerCase()) ? 1 : -1;
        });
        patrolTemplates.map(patrol => this.getCustomerById(patrol.clientId, patrol.customerId));
        this.dataSource = this.patrolTemplates.slice(0, this.topLimit);
        // console.log(PatrolsListComponent.name, 'fetched patrol templates ', patrolTemplates);
      });
    });
  }

  onPatrolClick(patrol) {
    if (patrol.isActive === true) {
      this.router.navigateRoot(['/employer/pitstop-list'], { queryParams: { patrol: patrol.id } });
    }
  }

  onChangeState(event, patrolId, isActiveState) {
    if (!isActiveState) {
      this.presentAlert('Are you sure that you want to deactivate', event, patrolId, isActiveState);
    } else {
      this.presentAlert('Are you sure that you want to activate', event, patrolId, isActiveState);
    }
  }

  onPatrolRemove(patrolId: string, isActivateState) {
    if (!isActivateState) {
      this.eventService.deactivatePatrol(patrolId);
    } else {
      this.eventService.activatePatrol(patrolId);
    }
    this.fetchPatrolTemplatesOfClient();

  }

  async presentAlert(data, event, patrolId: string, isActiveState) {
    event.stopPropagation();
    const alert = await this.alertController.create({
      header: 'Alert',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: 'OK',
          handler: () => {
            this.onPatrolRemove(patrolId, isActiveState);
          }
        }
      ],
      cssClass: 'p-3',
    });

    await alert.present();
  }

  editPatrol(event, patrolId) {
    event.stopPropagation();
    this.router.navigateForward(['/employer/create-patrol'], { queryParams: { patrol: patrolId, user: this.currentUser.registrationType } });
  }
}
